import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import SecurityCard, {
  Descritption,
  ShowModeContent,
  iconTypes,
} from './SecurityCard';

class MobilePhoneSecurityCard extends Component {
  handleChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { phone, onCancel, onSave, isValid, dirty } = this.props;
    return (
      <SecurityCard
        editModeHeight={97}
        type={iconTypes.MOBILE_PHONE}
        onCancel={onCancel}
        onSave={onSave}
        isValid={isValid}
        dirty={dirty}
      >
        <ShowModeContent>
          <Descritption>
            <InputWrapper>
              <InputMask mask="(999) 999-9999" value={phone} disabled />
            </InputWrapper>
          </Descritption>
        </ShowModeContent>
      </SecurityCard>
    );
  }
}

MobilePhoneSecurityCard.propTypes = {
  phone: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

export default MobilePhoneSecurityCard;

const InputWrapper = styled.div`
  input {
    flex: 1 1 auto;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    margin: 0 !important;
    border: none !important;
    color: rgb(94, 98, 99);
    outline: none;
    font-weight: 300;
    background: transparent !important;
    -webkit-text-fill-color: rgb(94, 98, 99);
    opacity: 1;
    padding: 0 !important;

    &:focus {
      font-size: 16px !important;
    }
  }
`;
