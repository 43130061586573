import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';

import Addresses from 'routes/MyAccount/routes/Addresses';

import Modal from 'components/Modal';
import ScrollableContent from 'components/ScrollableContent';

class MyAddresses extends Component {
  render() {
    const { closeModal, show } = this.props;
    return (
      <Modal show={show} onCloseClick={closeModal} title="My Addresses">
        <ScrollableContentWrapper>
          <ScrollableContent>
            <Content>{show && <Addresses modal />}</Content>
          </ScrollableContent>
        </ScrollableContentWrapper>
      </Modal>
    );
  }
}

MyAddresses.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  padding: 15px 15px 15px 15px;
  width: 100%;
  margin: 0 auto;

  ${media.tablet`
    padding: 5px;
  `};
`;

export default MyAddresses;
