import React, { Component } from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import { media } from 'utils/mediaQueries';

import LocationInput from './LocationInput';

import Icon from 'components/Icon';

import PlaceAutocompleteService from 'utils/PlaceAutocompleteService';
import GeolocationService from 'utils/GeolocationService';
import GeocoderService from 'utils/GeocoderService';

import ClickOutside from 'components/ClickOutside';

const THROTTLE_DURATION = 150;

class LocationSelectInput extends Component {
  state = {
    showItems: true,
    value: this.props.initialLocation || '',
    predictions: [],
    geologationInProgress: false,
  };

  // ---
  // HELPER FUNCTIONS
  // ---
  getPredictions = throttle(text => {
    PlaceAutocompleteService.predict(text, { byZipcode: true })
      .then(predictions => {
        this.setState({ predictions });
      })
      .catch(err => {
        console.error(err);
        this.setState({ predictions: [] });
      });
  }, THROTTLE_DURATION);

  // ---
  // LIFECYCLE
  // ---
  componentWillReceiveProps(nextProps) {
    if (this.props.initialLocation !== nextProps.initialLocation) {
      this.setState({ value: nextProps.initialLocation });
    }
  }

  // ---
  // HANDLERS
  // ---
  handleItemClick = text => {
    this.setState({ showItems: false, value: text });
    this.props.onPredictionClick(text);
  };

  handleInputFocus = event => {
    this.setState({ showItems: true });
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
    if (!event.target.value) {
      this.setState({ predictions: [] });
    } else {
      this.getPredictions(event.target.value);
    }
  };

  handleClearClick = _ => {
    this.props.onClearClick && this.props.onClearClick();
    this.setState({ value: '', predictions: [] });
  };

  handleGeolocationClick = _ => {
    this.setState({ geologationInProgress: true });
    GeolocationService.getLocation()
      .then(location => GeocoderService.getAddress(location))
      .then(address => {
        this.setState({ value: address, geologationInProgress: false });
        this.props.onGeolocationCompleted(address);
        return address;
      })
      .then(address => {
        this.getPredictions(address);
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    const { placeholder, initialLocation, locationsPage } = this.props;
    return (
      <ClickOutside
        onOutsideClick={() => {
          this.setState({ showItems: false, value: initialLocation });
        }}
      >
        <SelectInputWrapper>
          <LocationInput
            onFocus={this.handleInputFocus}
            onClearClick={this.handleClearClick}
            onGeolocationClick={this.handleGeolocationClick}
            geologationInProgress={this.state.geologationInProgress}
            value={this.state.value}
            onChange={this.handleChange}
            placeholder={placeholder}
          />
          <ItemsList locationsPage={locationsPage}>
            {this.state.showItems &&
              this.state.predictions.map((text, index) => (
                <Item onClick={() => this.handleItemClick(text)} key={index}>
                  <IconWrapper>
                    <Icon name="icon-address-small" size="30px" />
                  </IconWrapper>
                  <Text>{text}</Text>
                </Item>
              ))}
          </ItemsList>
        </SelectInputWrapper>
      </ClickOutside>
    );
  }
}

LocationSelectInput.propTypes = {
  initialLocation: PropTypes.string,
  placeholder: PropTypes.string,
  onPredictionClick: PropTypes.func,
  onClearClick: PropTypes.func,
  onGeolocationCompleted: PropTypes.func,
  locationsPage: PropTypes.bool,
};

const SelectInputWrapper = styled.div`
  position: relative;
  z-index: 10000;
`;

const ItemsList = styled.div`
  width: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: translateY(100%);
  border-radius: 2px;
  ${({ locationsPage }) =>
    !locationsPage
      ? media.tablet`
      bottom: initial;
      transform: initial;
      position: relative;
      top: 22px;
    `
      : ''};
`;

const Item = styled.button`
  padding: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #ececec;
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Text = styled.div`
  width: calc(100% - 20px);
  margin-left: 10px;
  font-size: 15px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
`;

export default LocationSelectInput;
