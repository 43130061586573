import { handleActions, createAction } from 'redux-actions';
import { isUserLoggedInSelector } from 'redux/modules/user';
import { actions as spinnerActions } from 'redux/modules/spinner/reducer';
import { camelizeKeys } from 'humps';
import wretches from 'wretches';

// ---
// CONSTANTS
// ---
export const SET_PROMOTIONS = 'promotions/SET_PROMOTIONS';
export const SET_LOYALTY = 'promotions/SET_LOYALTY';
export const SET_STAMPS = 'promotions/SET_STAMPS';
// ---
// ACTION CREATORS
// ---
export const setPromotions = createAction(SET_PROMOTIONS);
const setLoyalty = createAction(SET_LOYALTY);
const setStamps = createAction(SET_STAMPS);

const initialState = {
  items: [],
  loyalty: {
    restaurantSettings: { nthOrderGiftRules: {} },
    consumer: { points: 0, pointsInCurrency: 0, lastOrders: [] },
  },
  stamps: [],
};

export default handleActions(
  {
    [SET_PROMOTIONS]: (state, action) => ({
      ...state,
      items: action.payload,
    }),
    [SET_LOYALTY]: (state, action) => ({
      ...state,
      loyalty: action.payload,
    }),
    [SET_STAMPS]: (state, action) => ({
      ...state,
      stamps: action.payload,
    }),
  },
  initialState,
);

const getPromotions = () => (dispatch, getState) => {
  const promotionsWretch = isUserLoggedInSelector(getState())
    ? wretches.promotions
    : wretches.generalPromotions;
  dispatch(spinnerActions.showSpinner());
  promotionsWretch
    .get()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(setPromotions(camelizeKeys(data)));
    })
    .catch(() => {
      dispatch(spinnerActions.hideSpinner());
    });
};

const getLoyaltyInfo = foce => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.foc
    .url(`/${foce}/info/`)
    .get()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(setLoyalty(data));
    })
    .catch(() => {
      dispatch(spinnerActions.hideSpinner());
    });
};

const getMyStamps = foce => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.foc
    .url(`/${foce}/stamps/`)
    .get()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(setStamps(data));
    })
    .catch(() => {
      dispatch(spinnerActions.hideSpinner());
    });
};

export const actions = {
  getPromotions,
  getLoyaltyInfo,
  getMyStamps,
};
