import {
  compose,
  withProps,
  withHandlers,
  branch,
  renderComponent,
} from 'recompose';
import { withFormik } from 'formik';
import EnterVerificationCode from 'routes/Modals/EnterVerificationCode';
import NoAccountFound from 'routes/Modals/NoAccountFound';

const enterFirstLastNameHOC = compose(
  withProps(({ values: { phoneNumber } }) => ({
    phoneNumber,
  })),
  withFormik({
    mapPropsToValues: () => ({
      firstName: '',
      lastName: '',
      showCodeForm: false,
    }),
    enableReinitialize: true,
    validate: values => {
      const errors = {};
      if (!values.firstName) {
        errors.firstName = 'Required';
      }
      if (!values.lastName) {
        errors.lastName = 'Required';
      }
      return errors;
    },
    handleSubmit: (_, { setFieldValue }) => {
      setFieldValue('showCodeForm', true);
    },
  }),
  withHandlers({
    onFirstNameFieldChange: ({ setFieldValue }) => e =>
      setFieldValue('firstName', e.target.value),
    onLastNameFieldChange: ({ setFieldValue }) => e =>
      setFieldValue('lastName', e.target.value),
    onTryAgainClick: ({ resetNumberForm, resetForm }) => () => {
      resetForm();
      resetNumberForm();
    },
    onRequestCodeFail: ({ setFieldError }) => () => {
      setFieldError('form', 'No account found');
    },
  }),
  withProps(({ values: { firstName, lastName } }) => ({ firstName, lastName })),
  branch(({ errors: { form } }) => !!form, renderComponent(NoAccountFound)),
  branch(
    ({ values: { showCodeForm } }) => !!showCodeForm,
    renderComponent(EnterVerificationCode),
  ),
);

export default enterFirstLastNameHOC;
