import React from 'react';
import styled from 'styled-components';
import { history } from 'routes';
import inform from 'utils/inform';
import {
  landingUrlSelector,
  restaurantInfoSelector,
} from 'redux/modules/general';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { actions as cartActions } from 'redux/modules/cart';

const goToLanding = () => (dispatch, getState) => {
  const state = getState();
  const cartSubtotal = cartSubtotalSelector(state);
  const { name } = restaurantInfoSelector(state);
  const landingUrl = landingUrlSelector(state);
  const onSuccess = () => {
    if (landingUrl) {
      dispatch(cartActions.finishOrder());
      window.location.href = landingUrl;
    } else {
      history.push('/');
    }
  };
  if (landingUrl) {
    if (cartSubtotal) {
      inform({
        title: 'Start new order?',
        message: (
          <React.Fragment>
            You have an active order from <LocationName>{name}</LocationName>.
            Would you like to clear order and start a new order from a different
            location instead?
          </React.Fragment>
        ),
        buttonText: 'New Order',
        showCancelButton: true,
        buttonChoice: true,
        onBeforeButtonClick: onSuccess,
      });
    } else {
      inform({
        title: 'Change Location?',
        message: (
          <React.Fragment>
            You have selected <LocationName>{name}</LocationName>. Would you
            like to order form a different location?
          </React.Fragment>
        ),
        buttonText: 'Change',
        showCancelButton: true,
        buttonChoice: true,
        onBeforeButtonClick: onSuccess,
      });
    }
  } else {
    onSuccess();
  }
};

const LocationName = styled.span`
  font-weight: bold;
`;

export default goToLanding;
