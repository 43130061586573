import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModeManagerHOC, { modes } from 'HOC/ModeManager';

import values from 'lodash/values';

import TouchHnalder from 'components/TouchHandler';

class AutoScrollTop extends Component {
  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

AutoScrollTop.propTypes = {
  children: PropTypes.node,
};

const ScrollableContent = ({ mode, children, touchHandlerRef }) => {
  switch (mode) {
    case modes.MOBILE_MODE:
      return <AutoScrollTop>{children}</AutoScrollTop>;
    default:
      return <TouchHnalder ref={touchHandlerRef}>{children}</TouchHnalder>;
  }
};

ScrollableContent.propTypes = {
  mode: PropTypes.oneOf(values(modes)),
  children: PropTypes.node,
  touchHandlerRef: PropTypes.func,
};

ScrollableContent.defaultProps = {
  mode: modes.MOBILE_MODE,
};

export default ModeManagerHOC(ScrollableContent);
