import { compose, defaultProps, withHandlers, withProps } from 'recompose';
import pickBy from 'lodash/pickBy';
import { PizzaPartGroup } from './PizzaPartGroup';

const MixAndMatchGroupHOC = compose(
  defaultProps({
    value: {},
    parts: [-0.5, 0.5],
  }),
  withHandlers({
    handleChange: ({ value, onChange }) => (optionId, sideValue) => {
      onChange(
        pickBy({
          ...pickBy(value, v => v !== sideValue),
          [optionId]: sideValue,
        }),
      );
    },
  }),
  withProps(({ data: { options } }) => ({
    options,
  })),
);

export default MixAndMatchGroupHOC(PizzaPartGroup);
