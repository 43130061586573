import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';

class SaveAsFavorite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      isSelected: false,
    };
  }

  openFavorite = () => {
    const scrollable = document.querySelector('#modal-content');
    setTimeout(() => {
      scrollable.scrollTo(0, scrollable.scrollHeight);
    }, 0);
    this.setState({ isOpened: true });
  };

  selectItem = () => this.setState({ isSelected: true });

  render() {
    const { isOpened, isSelected } = this.state;
    const { onClick } = this.props;
    return (
      <Wrapper>
        <Top onClick={this.openFavorite}>
          <IconWrapper>
            <Icon name="icon-favorite-address-small" size="26px" />
          </IconWrapper>
          <Title active={isOpened}>Save as favorite</Title>
          {isSelected && (
            <CheckIcon>
              <Icon name="icon-check-small" size="32px" />
            </CheckIcon>
          )}
        </Top>

        {isOpened && (
          <Bottom>
            <Button>
              <Input
                onClick={() => {
                  onClick('HOME');
                  this.selectItem();
                }}
                type="radio"
                name="favorite"
                id="favoriteHome"
              />
              <Label htmlFor="favoriteHome">Home</Label>
            </Button>
            <Button>
              <Input
                onClick={() => {
                  onClick('WORK');
                  this.selectItem();
                }}
                type="radio"
                name="favorite"
                id="favoriteWork"
              />
              <Label htmlFor="favoriteWork">Work</Label>
            </Button>
            <Button>
              <Input
                onClick={() => {
                  onClick('OTHER');
                  this.selectItem();
                }}
                type="radio"
                name="favorite"
                id="favoriteOther"
              />
              <Label htmlFor="favoriteOther">Other</Label>
            </Button>
          </Bottom>
        )}
      </Wrapper>
    );
  }
}

SaveAsFavorite.propTypes = {
  // selectedValue: PropTypes.oneOf(['HOME', 'WORK', 'OTHER']),
  onClick: PropTypes.func,
};

const Wrapper = styled.div`
  background: #fff;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Top = styled.button`
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #5f6263;
`;

const Title = styled.div`
  flex: 1 1 auto;
  font-size: 17px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  font-weight: ${({ active }) => (active ? '400' : 'lighter')};
  margin-left: 10px;
`;

const CheckIcon = styled.div`
  width: 20px;
  height: 16px;
  color: #54a30a;
`;

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Button = styled.div`
  width: 33.3%;
  padding: 15px 15px 15px 45px;
  position: relative;
  border-top: 1px solid #eeefef;

  &:not(:first-child) {
    border-left: 1px solid #eeefef;
  }
`;

const Input = styled.input`
  display: none;

  &:checked ~ label {
    font-weight: normal;

    &::before {
      background: #54a300;
      border-color: #54a300;
    }
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Label = styled.label`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 0.91;
  font-weight: lighter;

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border: 2px solid #959595;
    border-radius: 50%;
    left: 15px;
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

export default SaveAsFavorite;
