import { compose, defaultProps } from 'recompose';
import { withFormik } from 'formik';
import { validatePasswordAllChecks } from 'utils/validatePassword';

const registerStepTwoHOC = compose(
  defaultProps({ title: 'Register', action: 'account_create' }),
  withFormik({
    mapPropsToValues: () => ({
      password1Field: '',
      password2Field: '',
    }),
    validate: ({ password1Field, password2Field }) => {
      const errors = {};
      if (!password1Field) {
        errors.password1Field = 'Required';
      }
      if (!password2Field) {
        errors.password2Field = 'Required';
      }
      const passwordErrors = validatePasswordAllChecks(password1Field);
      if (passwordErrors && passwordErrors.length) {
        errors.password1Field = passwordErrors;
      }
      if (password1Field !== password2Field) {
        errors.password2Field =
          'Confirm password must match the previous entry';
      }
      return errors;
    },
    handleSubmit: ({ password1Field }, { props: { setPassword } }) => {
      setPassword(password1Field);
    },
  }),
);

export default registerStepTwoHOC;
