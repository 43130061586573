import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import InformPopup from 'utils/inform/InformPopup';
import FocusLock from 'react-focus-lock';

class ErrorBoundaryComponent extends React.Component {
  componentDidCatch(error, info) {
    this.props.onCatch(error, info);
  }

  render() {
    const { hasError, onPopupClose } = this.props;
    if (hasError) {
      return (
        <FocusLock>
          <InformPopup
            title="Oops..."
            message="Something went wrong. You will be redirected to the home page."
            buttonChoice={false}
            showCancelButton={false}
            onCloseClick={undefined}
            cancelButtonText={null}
            onButtonClick={onPopupClose}
            buttonText="OK"
          />
        </FocusLock>
      );
    }
    return this.props.children;
  }
}

ErrorBoundaryComponent.propTypes = {
  hasError: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onCatch: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func.isRequired,
};

const withErrorBoundary = compose(
  withRouter,
  withStateHandlers(
    { hasError: false },
    { setHasError: () => () => ({ hasError: true }) },
  ),
  withHandlers({
    onPopupClose: ({ history }) => () => {
      history.push('/');
      window.location.reload(true);
    },
    onCatch: ({ setHasError }) => (error, info) => {
      setHasError();
      console.log('componentDidCatchError', error, info);
    },
  }),
);

export const ErrorBoundary = withErrorBoundary(ErrorBoundaryComponent);
