import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';
import { reformatAddress } from 'utils/reformatAddress';

const AddressItem = ({
  active,
  name,
  address,
  deliveryNotes,
  addressDetails,
  onClick,
}) => (
  <Wrapper onClick={onClick}>
    <IconWrapper>
      <Icon name="icon-address-selected-small" size="30px" />
    </IconWrapper>
    {active && (
      <IconCheck>
        <Icon name="icon-check-small" size="30px" />
      </IconCheck>
    )}
    <Texts>
      {name ? (
        <Title>{name}</Title>
      ) : (
        <>
          <AddressTitle>{reformatAddress(address)[0]}</AddressTitle>
          <AddressTitle>{reformatAddress(address)[1]}</AddressTitle>
        </>
      )}
      <Description>{name && reformatAddress(address)[0]}</Description>
      <Description>{name && reformatAddress(address)[1]}</Description>
      {addressDetails && <Details>{addressDetails}</Details>}
      {deliveryNotes && <Details>{deliveryNotes}</Details>}
    </Texts>
  </Wrapper>
);

AddressItem.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
  address: PropTypes.string,
  deliveryNotes: PropTypes.string,
  addressDetails: PropTypes.string,
  onClick: PropTypes.func,
};

const Wrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const IconCheck = styled.div`
  position: absolute;
  width: 21px;
  height: 16px;
  top: 20px;
  right: 15px;
  transform: translateY(-25%);
  color: #54a30a;
`;

const Texts = styled.div`
  margin-left: 10px;
  flex: 1 1 auto;
`;

const Title = styled.div`
  font-size: 17px;
  font-family: 'Roboto';
  color: #000;
  text-transform: lowercase;
  margin-bottom: 5px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const AddressTitle = styled.div`
  font-size: 17px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  line-height: 1.4;
`;

const Description = styled.div`
  font-size: ${({ active }) => (active ? 'font-size: 17px;' : '14px')};
  font-family: 'Roboto', sans-serif;
  color: ${({ active }) => (active ? 'rgb(66, 70, 72)' : 'rgb(116, 119, 120)')};
  font-weight: ${({ active }) => (active ? '400' : '300')};
  line-height: 20px;
`;

const Details = styled.div`
  font-size: 14px;
  font-family: 'Roboto';
  color: rgb(116, 119, 120);
  margin-top: 5px;
  font-weight: 300;
`;

export default AddressItem;
