import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { history } from 'routes';
import { actions as modalActions } from 'components/ReduxModal';
import renderNothingIfNotLoggedIn from 'utils/renderNothingIfNotLoggedIn';
import Orders from '../components/Orders';

const OrdersHOC = compose(
  connect(null, {
    closeModal: modalActions.closeModal,
  }),
  renderNothingIfNotLoggedIn,
  lifecycle({
    componentDidMount() {
      const { isUserLoggedIn } = this.props;
      if (!isUserLoggedIn) {
        history.push('/signin');
      }
    },
  }),
);

export default OrdersHOC(Orders);
