import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SubFooter = () => (
  <Bottom>
    <Links>
      <LinkStyled
        to={{
          pathname: `/terms-of-use`,
          state: { modal: true },
        }}
      >
        Terms of use
      </LinkStyled>
      <LinkStyled
        to={{
          pathname: `/privacy-policy`,
          state: { modal: true },
        }}
      >
        Privacy policy
      </LinkStyled>
    </Links>
    <Copyright>
      Copyright © {new Date().getFullYear()} Foodie Order. All rights reserved.
    </Copyright>
  </Bottom>
);

const Bottom = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 15px;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 15px 0;
`;

const Copyright = styled.div`
  color: #6f7378;
  text-transform: uppercase;
  line-height: 1.6;
  text-align: center;
  font-size: 9px;
  letter-spacing: 0.7px;
  margin-top: 5px;
`;

const Links = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled(Link)`
  display: block;
  color: #6f7378;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 5px;
  font-size: 9px;
  font-weight: 300;

  &:not(:first-child)::before {
    content: '|';
    margin-right: 10px;
    display: inline-block;
  }
`;

export default SubFooter;
