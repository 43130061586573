import { compose, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { history } from 'routes';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { getDishesCount } from 'redux/modules/cart';
import { actions as modalActions } from 'components/ReduxModal';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import ModeManagerHOC, { modes } from 'HOC/ModeManager';
import ReviewOrderButton from './ReviewOrderButton';

const ReviewOrderButtonHOC = compose(
  withRouter,
  connect(
    state => ({
      orderPrice: cartSubtotalSelector(state),
      count: getDishesCount(state),
      type: getDeliveryType(state),
      deliveryTimeType: deliveryTimeTypeSelector(state),
    }),
    {
      showModal: modalActions.showModal,
    },
  ),
  withHandlers({
    handleReviewOrderClick: ({ type, deliveryTimeType, showModal }) => () => {
      if (type && deliveryTimeType) {
        showModal('review-order');
      } else {
        history.push(`/`);
      }
    },
  }),
  ModeManagerHOC,
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { show },
        },
        handleReviewOrderClick,
        mode,
      } = this.props;
      if (show === 'cart' && mode === modes.MOBILE_MODE) {
        handleReviewOrderClick();
      }
    },
  }),
);

export default ReviewOrderButtonHOC(ReviewOrderButton);
