import React from 'react';
import styled from 'styled-components';
import {
  compose,
  withProps,
  withHandlers,
  branch,
  renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { orderAheadInfoSelector } from 'redux/modules/general';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Overlay from 'utils/Overlay';
import CalendarContainerPortal from './CalendarContainerPortal';

const DatePickerStyled = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1020;
  position: relative;

  .react-datepicker {
    border-color: #e0e3e7;
    border-radius: 2px;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__header {
    padding-top: 13px;
    border-bottom: none;
  }

  .react-datepicker__navigation {
    top: 13px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0.5rem;
    color: #424648;
  }

  .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
  }

  .react-datepicker__day--selected {
    color: white;
    background-color: #4eb11c;
    border-radius: 2px;
    :hover {
      //border-radius: 0;
      background-color: #387b14;
    }
  }
`;

const ArrowAnchor = styled.div`
  position: relative;
  z-index: 1;
`;

const ArrowContainer = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  transform: rotate(45deg);
  top: -6px;
  left: 40px;
  background-color: #f0f0f0;
  border: 0 solid #e0e3e7;
  border-top-width: 1.5px;
  border-left-width: 1.5px;
  border-radius: 1px;
`;

const CalendarDayPicker = ({
  calendarValue,
  includeDates,
  handleCalendarPick,
  hideCalendar,
}) => (
  <CalendarContainerPortal>
    <DatePickerStyled>
      <div>
        <ArrowAnchor>
          <ArrowContainer />
        </ArrowAnchor>
        <DatePicker
          inline
          selected={calendarValue}
          includeDates={includeDates}
          onChange={handleCalendarPick}
        />
      </div>
    </DatePickerStyled>
    <Overlay onClick={hideCalendar} zIndex={1000} inPlace />
  </CalendarContainerPortal>
);

CalendarDayPicker.propTypes = {
  calendarValue: PropTypes.shape({}).isRequired,
  includeDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCalendarPick: PropTypes.func.isRequired,
  hideCalendar: PropTypes.func.isRequired,
};

const calendarDayPickerHOC = compose(
  connect(state => ({
    orderAheadInfo: orderAheadInfoSelector(state),
  })),
  branch(
    ({ orderAheadInfo: { maxDaysInAdvance } }) => (+maxDaysInAdvance || 0) < 7,
    renderNothing,
  ),
  // convert everything to and from Moment.js
  withProps(({ value, options }) => ({
    calendarValue: moment(value),
    includeDates: options
      .filter(({ timestamps }) => timestamps.length)
      .map(({ day }) => moment(day)),
  })),
  withHandlers({
    handleCalendarPick: ({ onChange, hideCalendar }) => v => {
      hideCalendar();
      onChange(v.toDate());
    },
  }),
);

export default calendarDayPickerHOC(CalendarDayPicker);
