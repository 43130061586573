import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import { actions as userActions } from 'redux/modules/user';
import { validatePasswordAllChecks } from 'utils/validatePassword';

const recoverPasswordHOC = compose(
  withRouter,
  connect(null, { setPassword: userActions.setPassword }),
  withFormik({
    mapPropsToValues: ({ token }) => ({
      token,
      password1: '',
      password2: '',
    }),
    enableReinitialize: true,
    validate: ({ password1, password2 }) => {
      const errors = {};
      if (!password1) {
        errors.password1 = 'Required';
      }
      if (!password2) {
        errors.password2 = 'Required';
      }
      const passwordErrors = validatePasswordAllChecks(password1);
      if (passwordErrors && passwordErrors.length) {
        errors.password1 = passwordErrors;
      }
      if (password1 !== password2) {
        errors.password2 = 'Confirm password must match the previous entry';
      }
      return errors;
    },
    handleSubmit: (
      { password1 },
      {
        resetForm,
        setErrors,
        props: {
          history,
          setPassword,
          match: {
            params: { token },
          },
        },
      },
    ) => {
      const onSuccess = () => {
        resetForm();
        history.push('/');
      };
      const onFailure = err => {
        setErrors({
          password1: [err.json.message],
        });
      };
      setPassword({
        token,
        password: password1,
        onSuccess,
        onFailure,
      });
    },
  }),
);

export default recoverPasswordHOC;
