import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  DishItem,
  InformationItem,
  InformationItemText as Text,
  Controls,
  Information,
  Remove,
} from 'components/DishesList';
import VariationItem from 'components/VariationItem';

export const SpecialInstructions = ({ children }) => (
  <InformationItem>
    <Dot />
    <Text>{children}</Text>
  </InformationItem>
);

SpecialInstructions.propTypes = {
  children: PropTypes.node,
};

const DishWithVariationsItem = ({
  count,
  name,
  price,
  variations,
  onRemoveClick,
  onEditClick,
  type,
  specialInstructions,
  readOnly,
}) => (
  <DishItem
    count={count}
    name={name}
    price={price}
    onClick={onEditClick}
    showBottom={
      !!((variations && variations.length) || specialInstructions || !readOnly)
    }
  >
    <Information onClick={onEditClick}>
      {variations &&
        variations.map(variation => (
          <VariationItem
            key={variation.id}
            variationId={variation.id}
            type={variation.type}
            value={variation.value}
          />
        ))}
      {specialInstructions && (
        <SpecialInstructions>{specialInstructions}</SpecialInstructions>
      )}
    </Information>
    <Controls>
      {!readOnly && type === 'REMOVE' && (
        <Remove onClick={onRemoveClick} aria-label="Remove">
          REMOVE
        </Remove>
      )}
      {!readOnly && type === 'EDIT' && (
        <Remove onClick={onEditClick} aria-label="Edit">
          EDIT
        </Remove>
      )}
    </Controls>
  </DishItem>
);

DishWithVariationsItem.propTypes = {
  count: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  variations: PropTypes.object,
  onRemoveClick: PropTypes.func,
  onEditClick: PropTypes.func,
  readOnly: PropTypes.bool,
  specialInstructions: PropTypes.string,
  type: PropTypes.oneOf(['REMOVE', 'EDIT']),
};

DishWithVariationsItem.defaultProps = {
  readOnly: false,
};

export default DishWithVariationsItem;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background: #747778;
  border-radius: 50%;
  margin-top: 6px;
`;
