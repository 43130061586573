import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import VariationItem from 'components/VariationItem';
import Icon from 'components/Icon';
import {
  InformationItem,
  InformationItemText as Text,
} from 'components/DishesList';

const OrderCardItem = ({
  count,
  name,
  variations,
  legacy,
  lineItem: { legacyVariations },
}) =>
  name && (
    <Item>
      <Counter>{count}</Counter>
      <Info>
        <Name>{name}</Name>
        {legacy
          ? legacyVariations.map(variation => (
              <InformationItem key={variation.title}>
                {variation.type === 'regular' ? (
                  <Dot />
                ) : (
                  <PizzaIcon>
                    <Icon
                      name={
                        {
                          [true]: 'icon-pizza-full-small',
                          [variation.type ===
                          'pizza_full']: 'icon-pizza-full-small',
                          [variation.type ===
                          'pizza_right']: 'icon-pizza-right-small',
                          [variation.type ===
                          'pizza_left']: 'icon-pizza-left-small',
                        }.true
                      }
                      size="26px"
                    />
                  </PizzaIcon>
                )}
                <Text>
                  {variation.title} {variation.value}
                </Text>
              </InformationItem>
            ))
          : Object.values(variations).map(variation => (
              <VariationItem
                key={variation.id}
                variationId={variation.id}
                type={variation.type}
                value={variation.value}
                options={variation.options}
                withoutPrices
              />
            ))}
      </Info>
    </Item>
  );

OrderCardItem.propTypes = {
  count: PropTypes.number.isRequired,
  name: PropTypes.string,
  variations: PropTypes.objectOf(PropTypes.object),
  legacy: PropTypes.bool,
};

OrderCardItem.defaultProps = {
  name: '',
  legacy: undefined,
};

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 15px;

  &:not(:first-child) {
    padding-top: 0;
  }
`;

const Counter = styled.div`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Info = styled.div`
  margin-left: 10px;
  flex: 1;
`;

const Name = styled.div`
  color: #424648;
  font-weight: 300;
  margin-bottom: 10px;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background: #747778;
  border-radius: 50%;
  margin-top: 6px;
`;

const PizzaIcon = styled.div`
  width: 15px;
  height: 15px;
  color: #989ca1;
`;

const OrderCardItemHOC = compose(
  withProps(({ cartItem, lineItem }) => ({
    count: cartItem.count,
    name: lineItem.name,
    variations: cartItem.variations,
  })),
);

export default OrderCardItemHOC(OrderCardItem);
