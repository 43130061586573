import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Promotions from 'routes/Promotions';

import Modal from 'components/Modal';
import ScrollableContent from 'components/ScrollableContent';

import { media } from 'utils/mediaQueries';
import { actions as modalActions } from 'components/ReduxModal';

const PromotionsModalHOC = compose(
  connect(null, { modalGoBack: modalActions.modalGoBack }),
);

const PromotionsModal = ({ modalGoBack, show }) => (
  <Modal show={show} onCloseClick={modalGoBack} title="Promotions">
    <ScrollableContentWrapper>
      <ScrollableContent>
        <Content>{show && <Promotions modal />}</Content>
      </ScrollableContent>
    </ScrollableContentWrapper>
  </Modal>
);

PromotionsModal.propTypes = {
  show: PropTypes.bool,
  modalGoBack: PropTypes.func,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  padding: 15px 15px 15px 15px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  ${media.tablet`
    padding: 5px;
  `};
`;

export default PromotionsModalHOC(PromotionsModal);
