import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { orderingSettingsSelector } from 'redux/modules/general';
import { actions as modalActions } from 'components/ReduxModal';
import CartHOC from 'HOC/Cart';
import ReviewOrder from '../components/ReviewOrder';

const ReviewOrderHOC = compose(
  CartHOC,
  withRouter,
  connect(
    state => ({
      disclaimerText: orderingSettingsSelector(state)?.disclaimerText,
    }),
    {
      closeModal: modalActions.closeModal,
      showModal: modalActions.showModal,
    },
  ),
  withHandlers({
    handleCheckoutClick: ({ showModal, checkoutButtonEnabled }) => () => {
      if (checkoutButtonEnabled) {
        showModal('checkout');
      }
    },
  }),
);

export default ReviewOrderHOC(ReviewOrder);
