import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { AppScroll } from 'layouts/AppLayout';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

const UP = 'UP_MOVE';
const DOWN = 'DOWN_MOVE';

const getCoords = touchMoveEvent => {
  const touch = get(touchMoveEvent.touches, '0', {});
  return {
    x: touch.pageX,
    y: touch.pageY,
  };
};

const getMoveDirection = (prevTouch, currentTouch) =>
  prevTouch.y - currentTouch.y < 0 ? DOWN : UP;

class TouchHandler extends Component {
  // Store data about prev touch
  prevTouchCoordinates = null;

  handleTouchMove = event => {
    if (event.cancelable && this.prevTouchCoordinates) {
      const direction = getMoveDirection(
        this.prevTouchCoordinates,
        getCoords(event),
      );

      // Check if reach top bounds
      const preventDownScroll =
        direction === DOWN && this.parentNode.scrollTop <= 0;

      // Check if reach bottom bounds
      const { clientHeight, scrollTop } = this.parentNode;
      const preventUpScroll =
        direction === UP &&
        clientHeight + scrollTop >= this.contentNode.offsetHeight;

      if (preventDownScroll || preventUpScroll) event.preventDefault();
    }
    event.stopPropagation();
    this.prevTouchCoordinates = getCoords(event);
  };

  handletouchEnd = event => {
    this.prevTouchCoordinates = null;
  };

  render() {
    const { children, onScroll, zeroHeight, ...restProps } = this.props;
    return (
      <AppScroll
        onScroll={onScroll}
        onTouchEnd={this.handletouchEnd}
        onTouchMove={this.handleTouchMove}
        innerRef={node => (this.parentNode = node)}
        {...restProps}
        id="app-scroll"
      >
        <Wrapper
          innerRef={node => (this.contentNode = node)}
          zeroHeight={zeroHeight}
        >
          {children}
        </Wrapper>
      </AppScroll>
    );
  }
}

TouchHandler.propTypes = {
  children: PropTypes.node,
  onScroll: PropTypes.func,
  zeroHeight: PropTypes.bool,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${media.tablet`
    height: ${({ zeroHeight }) => (zeroHeight ? 'initial' : '100%')};
  `} ${media.desktop`
    height: 100%;
  `};
`;

export default TouchHandler;
