import { createSelector } from 'reselect';

import has from 'lodash/has';
import map from 'lodash/map';

import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';
import scheduleFilteredMenuSelector from 'selectors/scheduleFilteredMenuSelector';

const categoriesSelector = state => state.categories.entities;

export const scheduleFilteredCategoriesSelector = createSelector(
  [scheduleFilteredMenuSelector, categoriesSelector],
  ({ id: menuID, categories: categoryIDs }, categories) => {
    const filteredCategories =
      categoryIDs && categoryIDs.map(id => categories[id]);
    const withSortedItems =
      filteredCategories &&
      filteredCategories.map(({ categoryItems, ...rest }) => ({
        ...rest,
        items: map(categoryItems[menuID], item => +item),
      }));
    return withSortedItems || [];
  },
);

export const availableOnAwareScheduleFilteredCategoriesSelector = createSelector(
  [scheduleFilteredCategoriesSelector, availableAtDateItemsSelector],
  (categories, availableItems) =>
    categories
      .map(cat => ({
        ...cat,
        items: cat.items.filter(item => has(availableItems, item)),
      }))
      .filter(({ items }) => items.length !== 0),
);
