import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import forgotPasswordHOC from 'HOC/forgotPasswordHOC';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import ModalComponents from './ModalComponents';

const ForgotPassword = ({
  title,
  login,
  values: { emailOrNumberField },
  errors,
  touched,
  handleBlur,
  handleSubmit,
  onEmailOrNumberFieldChange,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <TextField
              label="Recovery Email"
              value={emailOrNumberField}
              name="emailOrNumberField"
              type="email"
              onChange={onEmailOrNumberFieldChange}
              onBlur={handleBlur}
              helperText={
                touched.emailOrNumberField && errors.emailOrNumberField
              }
              error={touched.emailOrNumberField && errors.emailOrNumberField}
              fullWidth
              autoFocus={autoFocus}
            />
          )}
        </AutoFocusIfNonTouchDevice>
        <ModalComponents.FormLink
          to={{
            pathname: `/forgot-email`,
            state: { login, modal: true },
          }}
        >
          Forgot your email?
        </ModalComponents.FormLink>
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Next
          </Button>
        </ModalComponents.FormButton>
      </ModalComponents.Form>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

ForgotPassword.propTypes = {
  title: PropTypes.string.isRequired,
  login: PropTypes.string,
  values: PropTypes.shape({ emailOrNumberField: PropTypes.string }).isRequired,
  errors: PropTypes.shape({ emailOrNumberField: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ emailOrNumberField: PropTypes.bool }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onEmailOrNumberFieldChange: PropTypes.func.isRequired,
};

ForgotPassword.defaultProps = {
  login: undefined,
};

export default forgotPasswordHOC(ForgotPassword);
