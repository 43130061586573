import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeInAnimation from 'components/FadeInAnimation';
import { TransitionGroup } from 'react-transition-group';
import { media } from 'utils/mediaQueries';
import Icon from 'components/Icon';
import ReviewOrderButton from 'components/ReviewOrderButton';

import ProductContainer from './containers/ProductContainer';

const ANIMATION_DURATION = 300;

const Favorites = ({ favoriteItems, favoritesLoading }) => (
  <Content>
    {Object.values(favoriteItems).length ? (
      <TransitionGroup>
        {Object.values(favoriteItems).map(favorite => (
          <FadeInAnimation
            key={favorite.id}
            unmountOnExit
            duration={ANIMATION_DURATION}
            timeout={{ enter: 10, exit: ANIMATION_DURATION }}
          >
            {style => (
              <ProductWrapper style={style}>
                <ProductContainer id={favorite.id} dishId={favorite.id} />
              </ProductWrapper>
            )}
          </FadeInAnimation>
        ))}
      </TransitionGroup>
    ) : (
      !favoritesLoading && (
        <NoFavoritesWrapper>
          <NoFavorites>
            <Icon name="icon-favorite-small" size="160px" />
            <div>You have no favorites at this time.</div>
          </NoFavorites>
        </NoFavoritesWrapper>
      )
    )}
    <ReviewOrderButton />
  </Content>
);

Favorites.propTypes = {
  favoriteItems: PropTypes.objectOf(PropTypes.object).isRequired,
  favoritesLoading: PropTypes.bool.isRequired,
};

export default Favorites;

const Content = styled.div`
  padding: 15px;
  // max-width: 452px;
  width: 100%;

  ${media.tablet`
    padding: 22px;
  `};
`;

const ProductWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

  &:not(:first-child) {
    margin-top: 15px;
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

const NoFavoritesWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoFavorites = styled.div`
  text-align: center;
  font-weight: lighter;
  font-family: 'Montserrat', serif;
  color: #9e9e9e;
  padding-bottom: 60px;

  > div:first-child {
    height: 160px;
  }
`;
