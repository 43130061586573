import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

const AnimationBackward = ({
  in: inProp,
  timeout,
  duration,
  children,
  ...restProps
}) => {
  const transitionStyles = {
    entering: { transform: 'translate3d(0, 0, 0)' },
    entered: {
      transform: 'translate3d(0,0,0)',
    },
  };

  const defaultStyle = {
    transition: `transform ${duration}ms`,
    transform: 'translate3d(0, 0, 0)',
  };

  return (
    <Transition in={inProp} timeout={timeout} {...restProps}>
      {state =>
        children({ ...defaultStyle, ...transitionStyles[state] }, state)
      }
    </Transition>
  );
};

AnimationBackward.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
  timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  children: PropTypes.func,
};

export default AnimationBackward;
