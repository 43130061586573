import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { orderAheadInfoSelector } from 'redux/modules/general';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import isToday from 'date-fns/is_today';
import isSameDay from 'date-fns/is_same_day';
import Select from './Select';

const FORMAT_STRING = 'ddd, MMM Do';

const DayPicker = ({
  selectValue,
  onSelectChange,
  selectOptions,
  onClick,
  hideCalendar,
}) => (
  <Select
    searchable={false}
    options={selectOptions}
    value={selectValue}
    onChange={onSelectChange}
    disabled={selectOptions.length <= 1}
    onClose={hideCalendar}
    onClick={onClick}
  />
);

DayPicker.propTypes = {
  selectValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
    disabled: PropTypes.bool,
  }),
  onSelectChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.object,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  hideCalendar: PropTypes.func.isRequired,
};

DayPicker.defaultProps = {
  selectValue: undefined,
  onClick: undefined,
};

const DayPickerHOC = compose(
  connect(state => ({
    orderAheadInfo: orderAheadInfoSelector(state),
  })),
  withProps(({ options }) => ({
    selectOptions: options.map(({ day, timestamps }) => ({
      label: isToday(day) ? 'Today' : format(day, FORMAT_STRING),
      value: day,
      disabled: timestamps.length === 0,
    })),
  })),
  withProps(({ value, selectOptions }) => ({
    selectValue: selectOptions.find(option => isSameDay(option.value, value)),
  })),
  withHandlers({
    onSelectChange: ({ onChange }) => option => {
      onChange(option.value);
    },
  }),
  withProps(({ orderAheadInfo: { maxDaysInAdvance }, showCalendar }) => ({
    ...((+maxDaysInAdvance || 0) >= 7 && { onClick: showCalendar }),
  })),
);

export default DayPickerHOC(DayPicker);
