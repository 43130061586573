import pickBy from 'lodash/pickBy';
import fromPairs from 'lodash/fromPairs';
import countBy from 'lodash/countBy';

const backendVariationValueToCart = ({
  type,
  options = [],
  left = [],
  right = [],
  full = [],
}) =>
  pickBy(
    fromPairs(
      options.map(option => [
        option,
        {
          [true]: 1,
          [['pizza_toppings', 'pizza_toppings_small'].includes(type) &&
          full.includes(option)]: 1,
          [['pizza_toppings', 'mix_and_match'].includes(type) &&
          left.includes(option)]: -0.5,
          [['pizza_toppings', 'mix_and_match'].includes(type) &&
          right.includes(option)]: 0.5,
          [type === 'combo']: countBy(options)[`${option}`],
        }.true,
      ]),
    ),
  );

export default backendVariationValueToCart;
