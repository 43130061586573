import { createSelector } from 'reselect';
import { paymentMethodSelector } from 'redux/modules/cart';
import { defaultPaymentSelector } from 'redux/modules/payments';

const paymentValidSelector = createSelector(
  [paymentMethodSelector, defaultPaymentSelector],
  (method, defaultPayment) =>
    method === 'CASH' || (method === 'CARD' && !!defaultPayment),
);

export default paymentValidSelector;
