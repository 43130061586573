import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withPropsOnChange } from 'recompose';

import Icon from 'components/Icon';

import { media } from 'utils/mediaQueries';
import { DeliveryTimeTypeTimePicker } from 'components/DeliveryTimeTypeTimePicker';

const DeliveryTimeTypeScheduleComponent = ({
  active,
  onClick,
  filled,
  scheduleTime,
  deliveryType,
  deliveryConditions,
  setScheduleTime,
  scheduleTimeError,
}) => (
  <React.Fragment>
    <Wrapper error={scheduleTimeError}>
      <Top onClick={onClick}>
        <IconWrapper>
          <Icon name="icon-schedule-small" size="26px" />
        </IconWrapper>
        <Label active={active}>Schedule an order</Label>
        {scheduleTimeError ? (
          <ErrorPill>Required</ErrorPill>
        ) : (
          <CheckWrapper active={active && filled}>
            <Icon name="icon-check-small" size="26px" />
          </CheckWrapper>
        )}
      </Top>
      {active && (
        <DeliveryTimeTypeTimePicker
          scheduleTime={scheduleTime}
          deliveryType={deliveryType}
          deliveryConditions={deliveryConditions}
          setScheduleTime={setScheduleTime}
        />
      )}
    </Wrapper>
    {scheduleTimeError && <ErrorText>{scheduleTimeError}</ErrorText>}
    <div id="calendar-container" />
  </React.Fragment>
);

DeliveryTimeTypeScheduleComponent.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  filled: PropTypes.bool.isRequired,
  deliveryType: PropTypes.oneOf(['PICKUP', 'DELIVERY']),
  deliveryConditions: PropTypes.shape({
    availability: PropTypes.array,
  }),
  scheduleTime: PropTypes.string,
  setScheduleTime: PropTypes.func.isRequired,
  scheduleTimeError: PropTypes.string,
};

DeliveryTimeTypeScheduleComponent.defaultProps = {
  scheduleTime: null,
  scheduleTimeError: null,
  deliveryType: null,
  deliveryConditions: null,
};

const Wrapper = styled.div`
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  border: ${p => p.error && '2px solid #F40A15'};

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Top = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  padding: 13px 15px;
`;

const CheckWrapper = styled.div`
  position: absolute;
  width: 21px;
  height: 16px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 0.2s ease;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #54a30a;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Label = styled.button`
  flex: 1 1 auto;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: #000;
  padding-left: 10px;
  outline: none;
  font-weight: ${({ active }) => (active ? '400' : '300')};
`;

const ErrorPill = styled.div`
  background-color: #f40a15;
  border-radius: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  padding: 5px;
`;

const ErrorText = styled.div`
  color: #f40a15;
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
`;

const deliveryTimeTypeScheduleHOC = compose(
  withPropsOnChange(['scheduleTime'], ({ scheduleTime }) => ({
    filled: !!scheduleTime,
  })),
);

export const DeliveryTimeTypeSchedule = deliveryTimeTypeScheduleHOC(
  DeliveryTimeTypeScheduleComponent,
);
