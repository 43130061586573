import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PaymentsManagement from 'HOC/PaymentsManagement';

import Modal from 'components/Modal';
import ScrollableContent from 'components/ScrollableContent';

import { media } from 'utils/mediaQueries';

const PaymentsModal = ({ closeModal, show }) => (
  <Modal show={show} onCloseClick={closeModal} title="Payments">
    <ScrollableContentWrapper>
      <ScrollableContent>
        <Content>{show && <PaymentsManagement modal />}</Content>
      </ScrollableContent>
    </ScrollableContentWrapper>
  </Modal>
);

PaymentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  padding: 15px 15px 15px 15px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  ${media.tablet`
    padding: 5px;
  `};
`;

export default PaymentsModal;
