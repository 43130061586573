import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';

export const paymentsSelector = state => state.payments;

export const defaultPaymentSelector = createSelector(
  paymentsSelector,
  payments => payments.defaultPayment,
);
export const paymentsEntitiesSelector = createSelector(
  [paymentsSelector, defaultPaymentSelector],
  (payments, defaultPayment) =>
    mapValues(payments.entities, (v, k) => ({
      ...v,
      id: +k,
      defaultPayment: +defaultPayment === +k,
      selectedPayment: +payments.selectedPayment === +k,
    })),
);

export const selectedPaymentSelector = createSelector(
  [paymentsEntitiesSelector],
  payments =>
    Object.values(payments).find(payment => !!payment.selectedPayment) ||
    Object.values(payments).find(payment => !!payment.defaultPayment),
);

export const stripeLoadedSelector = createSelector(
  paymentsSelector,
  payments => payments.stripeLoaded,
);
