import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';
import { media } from 'utils/mediaQueries';

import PopUpAnimation from 'components/PopUpAnimation';
// import { RootPortalHOC } from 'components/RootPortal'
import PopUp from 'components/PopUp';
import DocumentElementStylesHelper from 'components/DocumentElementStylesHelper';
import ModalHeader from './ModalHeader';

const ANIMATION_DURATION = 350;

const preventEvent = e => {
  e.preventDefault();
};

const Modal = ({ children, style, title, onCloseClick, smallPaddings }) => (
  <PopupWrapper onTouchMove={preventEvent} style={style}>
    <ModalContent>
      <FocusLock>
        <ModalHeader onCloseClick={onCloseClick} smallPaddings={smallPaddings}>
          {title}
        </ModalHeader>
        <Content id="modal-content">{children}</Content>
      </FocusLock>
    </ModalContent>
  </PopupWrapper>
);

Modal.propTypes = {
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
  smallPaddings: PropTypes.bool,
};

const AnimatedModal = ({
  show,
  children,
  title,
  onCloseClick,
  smallPaddings,
}) => (
  <PopUp show={show} onOutsideClick={onCloseClick}>
    {show && <DocumentElementStylesHelper />}
    <PopUpAnimation
      unmountOnExit
      appear
      in={show}
      duration={ANIMATION_DURATION}
      timeout={{ enter: 10, exit: ANIMATION_DURATION }}
    >
      {style => (
        <Modal
          style={style}
          title={title}
          onCloseClick={onCloseClick}
          smallPaddings={smallPaddings}
        >
          {children}
        </Modal>
      )}
    </PopUpAnimation>
  </PopUp>
);

const modalPropTypes = {
  isFavorite: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCloseClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  smallPaddings: PropTypes.bool,
};

AnimatedModal.propTypes = {
  ...modalPropTypes,
};

export default AnimatedModal;

const Content = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  ${media.tablet`
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    height: 100%;
    max-height: calc(85vh - 55px);
  `};
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const PopupWrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  position: fixed;
  overflow: hidden;
  z-index: 40;
  background: #f3f4f5;

  ${media.tablet`
    width: 440px;
    right: initial;
    left: calc(50% - 220px);
    top: 115px;
    max-height: 84vh;
    bottom: initial;
  `};
`;
