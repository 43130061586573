import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputErrors = ({ children }) => (
  <PasswordErrorsContainer>
    <PasswordErrors>
      <ErrorTitle>{children}</ErrorTitle>
    </PasswordErrors>
    <ArrowAnchor>
      <ArrowContainer />
    </ArrowAnchor>
  </PasswordErrorsContainer>
);

InputErrors.propTypes = {
  children: PropTypes.string.isRequired,
};

const PasswordErrorsContainer = styled.div`
  margin-bottom: 12px;
  border: 1px solid #ff1717;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 14px;
  color: #4a4847;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PasswordErrors = styled.div`
  padding: 16px 20px;
`;

const ArrowAnchor = styled.div`
  background-color: inherit;
  position: relative;
`;

const ArrowContainer = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  transform: rotate(45deg);
  top: -6px;
  left: -6px;
  background-color: inherit;
  border: 0 solid #ff1717;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-radius: 1px;
`;

const ErrorTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  color: #ff1717;
  font-weight: 600;
`;

export default InputErrors;
