import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { actions as userActions } from 'redux/modules/user/reducer';
import pick from 'lodash/pick';

import { Item } from 'components/Card';
import { userSelector } from 'redux/modules/user';
import SecurityCard, {
  Descritption,
  ShowModeContent,
  EditModeContent,
  iconTypes,
  Input,
} from './SecurityCard';

const UserSecurityCard = ({
  values: { firstName, lastName },
  errors,
  handleChange,
  submitForm,
  resetForm,
  isValid,
  dirty,
}) => (
  <SecurityCard
    type={iconTypes.NAME}
    onSave={submitForm}
    onCancel={resetForm}
    isValid={isValid}
    dirty={dirty}
  >
    <ShowModeContent>
      <Descritption>
        {firstName} {lastName}
      </Descritption>
    </ShowModeContent>
    <EditModeContent>
      <InputItem showError={errors.firstName}>
        <Input
          type="text"
          name="firstName"
          value={firstName}
          placeholder="First Name"
          onChange={handleChange}
        />
      </InputItem>
      <InputItem showError={errors.lastName}>
        <Input
          type="text"
          name="lastName"
          value={lastName}
          placeholder="Last Name"
          onChange={handleChange}
        />
      </InputItem>
    </EditModeContent>
  </SecurityCard>
);

UserSecurityCard.propTypes = {
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

const UserSecurityCardHOC = compose(
  connect(
    state => ({
      ...pick(userSelector(state), ['firstName', 'lastName']),
    }),
    {
      updateUser: userActions.updateUser,
    },
  ),
  withFormik({
    mapPropsToValues: ({ firstName, lastName }) => ({
      firstName,
      lastName,
    }),
    enableReinitialize: true,
    validate: ({ firstName, lastName }) => {
      const errors = {};
      if (!firstName) {
        errors.firstName = 'Required';
      }
      if (!lastName) {
        errors.lastName = 'Required';
      }
      return errors;
    },
    handleSubmit: ({ firstName, lastName }, { props: { updateUser } }) => {
      updateUser({ firstName, lastName });
    },
  }),
);

export default UserSecurityCardHOC(UserSecurityCard);

const InputItem = Item.extend`
  padding-left: 40px;
  max-height: 51px;
  :focus-within {
    border: 1px solid #54a300;
  }
`;
