import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';
import { camelizeKeys } from 'humps';
import { isUserLoggedInSelector } from 'redux/modules/user';
import { actions as deliveryActions } from 'redux/modules/delivery/reducer';
import { actions as modalActions, modalSelector } from 'components/ReduxModal';
import { userAddressesSelector } from 'selectors/userAddressesSelector';
import LocationSelectInput from 'components/LocationSelectInput';

import GeolocationService from 'utils/GeolocationService';
import GeocoderService from 'utils/GeocoderService';

import InputFocusWrapper from 'components/InputFocusWrapper';
import Icon from 'components/Icon';

import Modal from 'components/Modal';

import PlaceAutocompleteService from 'utils/PlaceAutocompleteService';
import inform from 'utils/inform';

import SaveAsFavorite from './SaveAsFavorite';

const SelectDeliveryAddressHOC = compose(
  connect(
    state => ({
      modal: modalSelector(state),
      userAddresses: keyBy(userAddressesSelector(state), 'id'),
      isUserLoggedIn: isUserLoggedInSelector(state),
    }),
    {
      closeModal: modalActions.closeModal,
      validatePlaceId: deliveryActions.validatePlaceId,
    },
  ),
  withProps(({ modal }) => ({
    editAddressId: modal.payload && modal.payload.addressId,
  })),
  withProps(({ editAddressId, userAddresses }) => ({
    editingAddress: userAddresses[editAddressId],
  })),
  withFormik({
    touchOnChange: true,
    mapPropsToValues: ({
      editAddressId,
      editingAddress: {
        address,
        aptSuiteFloor,
        businessName,
        specialInstructions,
        name,
        placeId,
      } = {},
    }) => ({
      locationInputText: editAddressId ? address : '',
      items: [],
      address: editAddressId ? address : '',
      aptSuiteFloor: editAddressId ? aptSuiteFloor : '',
      businessName: editAddressId ? businessName : '',
      specialInstructions: editAddressId ? specialInstructions : '',
      saveAs: editAddressId ? name : null,
      placeId,
      place: null,
    }),
    enableReinitialize: true,
    handleSubmit: (
      {
        address,
        aptSuiteFloor,
        businessName,
        specialInstructions,
        saveAs,
        placeId,
        place,
      },
      { props: { onDoneClick, onCloseClick }, resetForm },
    ) => {
      onDoneClick({
        address,
        aptSuiteFloor,
        businessName,
        specialInstructions,
        saveAs,
        ...(placeId && { placeId }),
        place,
      });
      resetForm();
      if (onCloseClick) {
        onCloseClick();
      }
    },
  }),
  withHandlers({
    handleClearClick: ({ setFieldValue }) => () => {
      setFieldValue('locationInputText', '');
      setFieldValue('address', '');
      setFieldValue('items', []);
    },
  }),
  withHandlers(() => {
    let element = null;
    return {
      onRef: () => ref => {
        element = ref;
      },
      focusInput: () => () => {
        element.focus();
      },
    };
  }),
  withHandlers({
    onPlaceIdValidationFailure: ({
      resetForm,
      onCloseClick,
      focusInput,
    }) => () => {
      inform({
        title: 'SORRY!',
        message:
          'Unfortunately, it looks like your address is outside our delivery area. ' +
          'You can enter a different address or change your order to PICKUP.',
        buttonText: 'Change Address',
        onBeforeCloseClick: onCloseClick,
        onBeforeButtonClick: focusInput,
      });
      resetForm();
    },
  }),
  withHandlers({
    getPredictions: ({
      setFieldValue,
      setFieldTouched,
      setSubmitting,
    }) => value =>
      debounce(async input => {
        try {
          const formatter = predictions => predictions;
          setSubmitting(true);
          const predictions = await PlaceAutocompleteService.predict(input, {
            formatter,
          });
          setSubmitting(false);
          setFieldTouched('locationInputText');
          setFieldValue('items', predictions);
        } catch (err) {
          setSubmitting(false);
          setFieldValue('items', []);
        }
      }, 100)(value),
    validatePlaceId: ({
      validatePlaceId,
      onPlaceIdValidationFailure,
    }) => placeId => validatePlaceId(placeId, onPlaceIdValidationFailure),
  }),
  withHandlers({
    handleLocationInputChange: ({ setFieldValue, getPredictions }) => ({
      target: { value } = {},
    } = {}) => {
      setFieldValue('locationInputText', value || '');
      if (value && value.length >= 7) {
        getPredictions(value);
      }
    },
    handleItemClick: ({ setFieldValue, validatePlaceId }) => async value => {
      const validation = await validatePlaceId(value.place_id);
      const place = camelizeKeys(validation);
      const { formatedAddress } = place;
      setFieldValue('locationInputText', formatedAddress);
      setFieldValue('address', formatedAddress);
      setFieldValue('items', []);
      setFieldValue('placeId', value.place_id);
      setFieldValue('place', validation);
    },
    handleGeolocationClick: ({ setFieldValue }) => () => {
      GeolocationService.getLocation()
        .then(location => GeocoderService.getAddress(location))
        .then(address => {
          setFieldValue('locationInputText', address);
          setFieldValue('address', address);
        })
        .catch(err => {
          console.log(err);
        });
    },

    handleSaveAsChange: ({ setFieldValue }) => value => {
      setFieldValue('saveAs', value);
    },

    handleCloseClick: ({ resetForm, onCloseClick }) => () => {
      resetForm();
      if (onCloseClick) {
        onCloseClick();
      }
    },
  }),
  withProps(
    ({
      isSubmitting,
      touched,
      values: { locationInputText, items, address },
    }) => ({
      locationInputError: Boolean(
        !isSubmitting &&
          touched.locationInputText &&
          locationInputText &&
          !items.length &&
          !address,
      ),
    }),
  ),
);

SelectDeliveryAddressHOC.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onDoneClick: PropTypes.func,
};

const SelectDeliveryAddress = ({
  show,
  values,
  handleCloseClick,
  handleSubmit,
  handleSaveAsChange,
  handleChange,
  handleLocationInputChange,
  handleGeolocationClick,
  handleClearClick,
  handleItemClick,
  onRef,
  focusInput,
  editAddressId,
  locationInputError,
  isUserLoggedIn,
}) => (
  <Modal show={show} onCloseClick={handleCloseClick} title="Delivery Address">
    <WrapperAddress>
      <Content>
        <Title>Add a new address:</Title>
        <InputWrapper>
          {show && (
            <LocationSelectInput
              value={values.locationInputText}
              items={values.items}
              onGeolocationClick={handleGeolocationClick}
              onClearClick={handleClearClick}
              onItemClick={handleItemClick}
              onChange={handleLocationInputChange}
              placeholder="Add a new address"
              onRef={onRef}
              focusInput={focusInput}
              error={!!locationInputError}
            />
          )}
          {locationInputError && (
            <LocationInputError>
              <LocationInputErrorTitle>Invalid address</LocationInputErrorTitle>
              <p>
                Please manually fill in your address information and choose your
                address from the dropdown selection in the address box.
              </p>
            </LocationInputError>
          )}
        </InputWrapper>
      </Content>
      {values.address && (
        <Container>
          <Inner>
            <Top>
              <Item>
                <Inputs>
                  <InputFocusWrapper>
                    <Input
                      name="aptSuiteFloor"
                      value={values.aptSuiteFloor}
                      onChange={handleChange}
                      placeholder="Apt / Suite / Floor (optional)"
                    />
                  </InputFocusWrapper>
                  <InputFocusWrapper>
                    <Input
                      name="businessName"
                      value={values.businessName}
                      onChange={handleChange}
                      placeholder="Business Name (optional)"
                    />
                  </InputFocusWrapper>
                </Inputs>
              </Item>

              <Item>
                <Title>Delivery note</Title>
                <InputWrapper>
                  <TextareaWrapper>
                    <IconWrapper>
                      <Icon name="icon-note-small" size="26px" />
                    </IconWrapper>
                    <Textarea
                      name="specialInstructions"
                      value={values.specialInstructions}
                      onChange={handleChange}
                      placeholder="Sample details: Beware of dog, Gate codes, color of home, specific entrances, etc..."
                    />
                  </TextareaWrapper>
                </InputWrapper>

                {isUserLoggedIn && !editAddressId && (
                  <SaveAsFavoriteWrapper>
                    <SaveAsFavorite onClick={handleSaveAsChange} />
                  </SaveAsFavoriteWrapper>
                )}
              </Item>
            </Top>

            <ButtonWrapper>
              <Button onClick={handleSubmit}>
                {editAddressId ? 'UPDATE' : 'DONE'}
              </Button>
            </ButtonWrapper>
          </Inner>
        </Container>
      )}
    </WrapperAddress>
  </Modal>
);

SelectDeliveryAddress.propTypes = {
  show: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    locationInputText: PropTypes.string,
    items: PropTypes.array,
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    specialInstructions: PropTypes.string,
    saveAs: PropTypes.string,
  }).isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSaveAsChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLocationInputChange: PropTypes.func.isRequired,
  handleGeolocationClick: PropTypes.func.isRequired,
  handleClearClick: PropTypes.func.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  focusInput: PropTypes.func.isRequired,
  editAddressId: PropTypes.string,
  locationInputError: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
};

SelectDeliveryAddress.defaultProps = {
  editAddressId: undefined,
};

const Inner = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  flex: 1 1 auto;
`;

const Title = styled.div`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const InputWrapper = styled.div`
  margin-top: 15px;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px 15px;
  max-width: 440px;
  margin: 0 auto;
`;

const WrapperAddress = styled.div`
  max-height: 100vh;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  border: none;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  outline: none;
  min-height: 42px;
  appearance: none;
  border: none;
  margin: 0;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }

  &:not(:first-child) {
    border-top: 1px solid #eeefef;
  }
`;

const Inputs = styled.div`
  margin-top: -24px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Item = styled.div`
  padding: 20px 15px;

  &:not(:first-child) {
    border-top: 1px solid #e8e8e8;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
`;

const Textarea = styled.textarea`
  width: 100%;
  display: block;
  resize: none;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 17px 15px 17px 40px;
  line-height: 20px;
  outline: none;
  border: none;
  appearance: none;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

const TextareaWrapper = styled.div`
  background: #fff;
  position: relative;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  :focus-within {
    border: 1px solid #54a300;
  }
`;

const IconWrapper = styled.div`
  height: 20px;
  width: 16px;
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  top: 15px;
  z-index: 10;
  left: 15px;
  color: #5f6263;
`;

const ButtonWrapper = styled.div`
  //background: #fff;
  padding: 15px;
`;

const Top = styled.div`
  flex: 1 1 auto;
`;

const Button = styled.button`
  padding: 15px;
  background: #54a300;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: ${p => !p.disabled && 'rgb(255, 255, 255)'};
  font-weight: bold;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  text-align: center;
  letter-spacing: 0.5px;
`;

const SaveAsFavoriteWrapper = styled.div`
  margin-top: 22px;
`;

const LocationInputError = styled.div`
  margin-top: 12px;
  color: #f40a15;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
`;

const LocationInputErrorTitle = styled.p`
  font-weight: 400;
  margin-bottom: 8px;
`;

export default SelectDeliveryAddressHOC(SelectDeliveryAddress);
