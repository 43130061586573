import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';
import LocationInput from './LocationInput';

const LocationSelectInput = ({
  showPredictions,
  setShowPredictions,
  value,
  items,
  onChange,
  onClearClick,
  onGeolocationClick,
  onItemClick,
  placeholder,
  onRef,
  error,
}) => (
  <SelectInputWrapper>
    <LocationInput
      onFocus={setShowPredictions}
      onBlur={() => setTimeout(setShowPredictions, 10)}
      onClearClick={onClearClick}
      onGeolocationClick={onGeolocationClick}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      inputRef={onRef}
      error={error}
    />
    <ItemsList>
      {showPredictions &&
        items.map((prediction, index) => (
          <Item
            onClick={() => {
              onItemClick(prediction);
            }}
            key={index}
          >
            <IconWrapper>
              <Icon name="icon-address-small" size="30px" />
            </IconWrapper>
            <Text>{prediction.description}</Text>
          </Item>
        ))}
    </ItemsList>
  </SelectInputWrapper>
);

LocationSelectInput.propTypes = {
  value: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    }),
  ),
  onClearClick: PropTypes.func,
  onChange: PropTypes.func,
  onGeolocationClick: PropTypes.func,
  onItemClick: PropTypes.func,
  placeholder: PropTypes.string,
  onRef: PropTypes.func.isRequired,
  focusInput: PropTypes.func.isRequired,
  showPredictions: PropTypes.bool.isRequired,
  setShowPredictions: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

LocationSelectInput.defaultProps = {
  items: [],
};

const LocationSelectInputHOC = compose(
  withStateHandlers(
    { showPredictions: false },
    { setShowPredictions: () => () => ({ showPredictions: true }) },
  ),
);

const SelectInputWrapper = styled.div`
  position: relative;
  z-index: 3;
`;

const ItemsList = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: translateY(100%);
  border-radius: 2px;
  width: 100%;

  ${media.tablet`
    position: relative;
    bottom: initial;
    top: 15px;
    transform: translateY(0);
  `};
`;

const Item = styled.button`
  padding: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #ececec;
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Text = styled.div`
  width: calc(100% - 20px);
  margin-left: 10px;
  font-size: 15px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
`;

export default LocationSelectInputHOC(LocationSelectInput);
