import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeliveryTimeTypeAsap } from 'components/DeliveryTimeTypeAsap';
import { DeliveryTimeTypeSchedule } from 'components/DeliveryTimeTypeSchedule';

const InputWrapper = styled.div`
  margin-top: 15px;
`;

export const DeliveryTimeTypePicker = ({
  soonestButtonEnabled,
  onSoonestClick,
  deliveryTimeType,
  scheduleButtonEnabled,
  onScheduleClick,
  scheduleTime,
  deliveryType,
  setScheduleTime,
  scheduleTimeError,
  deliveryConditions,
}) => (
  <>
    {soonestButtonEnabled && (
      <InputWrapper>
        <DeliveryTimeTypeAsap
          onClick={onSoonestClick}
          active={deliveryTimeType === 'SOONEST'}
          deliveryType={deliveryType}
          deliveryConditions={deliveryConditions}
        />
      </InputWrapper>
    )}
    {scheduleButtonEnabled && (
      <InputWrapper>
        <DeliveryTimeTypeSchedule
          onClick={onScheduleClick}
          active={deliveryTimeType === 'SCHEDULE'}
          deliveryType={deliveryType}
          deliveryConditions={deliveryConditions}
          scheduleTime={scheduleTime}
          setScheduleTime={setScheduleTime}
          scheduleTimeError={scheduleTimeError}
        />
      </InputWrapper>
    )}
  </>
);

DeliveryTimeTypePicker.propTypes = {
  soonestButtonEnabled: PropTypes.bool.isRequired,
  onSoonestClick: PropTypes.func.isRequired,
  deliveryTimeType: PropTypes.string,
  scheduleButtonEnabled: PropTypes.bool.isRequired,
  onScheduleClick: PropTypes.func.isRequired,
  scheduleTime: PropTypes.string,
  scheduleTimeError: PropTypes.string,
  deliveryConditions: PropTypes.shape({
    availability: PropTypes.array,
  }),
};

DeliveryTimeTypePicker.defaultProps = {
  deliveryTimeType: null,
  scheduleTime: null,
  scheduleTimeError: null,
  deliveryConditions: undefined,
};
