import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeModal } from 'components/ReduxModal';
import MyAddresses from '../components/MyAddresses';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeModal: () => closeModal('my-addresses'),
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(MyAddresses);
