import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import SettingsCard from 'components/SettingsCard';
import Icon from 'components/Icon';

import SpecialInstructionsCard from 'components/SpecialInstructionsCard';
import Card, {
  Header,
  Title as CardTitle,
  Label as CardLabel,
} from 'components/Card';
import Footer from 'components/Footer';

import FixedNumber from 'components/FixedNumber';
import { history } from 'routes';

import DesktopHeader from 'containers/DesktopHeader';
import PaymentMethod from './components/PaymentMethod';
import ScheduleSettings from './containers/ScheduleSettings';
import Tip from './containers/TipContainer';
import { TableNameInput } from 'routes/Checkout/components/TableNameInput';
import { DesktopDishesList } from 'components/DesktopHeader/components/DesktopCart';
import OrderSummaryContainer from './containers/OrderSummaryContainer';
import DeliveryMethod from './containers/DeliveryMethod';
import { RedeemPoints } from './RedeemPoints';

const ItemsList = ({ dishes, subtotal, onViewMenuClick }) => (
  <Card>
    <Header>
      <CardTitle> Item List </CardTitle>
      <CardLabel style={{ cursor: 'pointer' }} onClick={onViewMenuClick}>
        EDIT ORDER
      </CardLabel>
    </Header>
    <DesktopDishesList dishes={dishes} readOnly />
  </Card>
);

ItemsList.propTypes = {
  dishes: PropTypes.array,
  subtotal: PropTypes.number,
  onViewMenuClick: PropTypes.func,
};

const Checkout = ({
  price,
  onPlaceOrderClick,
  promocode,
  cartSubtotal,
  dishes,
  showModalPromocode,
  specialInstructions,
  onSpecialInstructionsChange,
  paymentInvalid,
  tipsAllowed,
  loyalty,
  redeemCurrency,
  toggleRedeemCurrency,
  dineInEnabled,
  dineInTable,
  dineInName,
  setDineInTable,
  setDineInName,
  tableNumberInvalid,
  tableNameInvalid,
}) => (
  <Wrapper>
    <HeaderWrapper>
      <DesktopHeader />
    </HeaderWrapper>
    <ContentInner>
      <Container>
        <Content>
          {dineInEnabled ? (
            <>
              <ItemWrapper showError={tableNumberInvalid}>
                <SettingsCard title="Dine-in Name" showError={tableNameInvalid}>
                  <TableNameInput
                    value={dineInName}
                    onChange={setDineInName}
                    error={tableNameInvalid}
                  />
                </SettingsCard>
              </ItemWrapper>
            </>
          ) : (
            <>
              <ItemWrapper dineInHidden={dineInEnabled}>
                <DeliveryMethod />
              </ItemWrapper>

              <ItemWrapper dineInHidden={dineInEnabled}>
                <ScheduleSettings />
              </ItemWrapper>
            </>
          )}

          <ItemWrapper showError={paymentInvalid} dineInHidden={dineInEnabled}>
            <SettingsCard title="Payment method" showError={paymentInvalid}>
              <PaymentMethod />
            </SettingsCard>
          </ItemWrapper>

          {tipsAllowed && (
            <ItemWrapper>
              <SettingsCard title="Tip">
                <Tip />
              </SettingsCard>
            </ItemWrapper>
          )}

          <ItemWrapper dineInHidden={dineInEnabled}>
            <SpecialInstructionsCard
              value={specialInstructions}
              onChange={onSpecialInstructionsChange}
            />
          </ItemWrapper>
        </Content>

        <Content>
          <ItemWrapper>
            <ItemsList
              dishes={dishes}
              subtotal={cartSubtotal}
              onViewMenuClick={() => history.push('/menu/cart')}
            />
            <OrderSummaryContainer />
          </ItemWrapper>

          <ItemWrapper>
            <Promo onClick={showModalPromocode}>
              <IconWrapper>
                <Icon name="icon-promotions-small" size="28px" />
              </IconWrapper>
              <PromoButton>
                {promocode || <span>Enter Promo code</span>}
              </PromoButton>
            </Promo>
          </ItemWrapper>

          <ItemWrapper>
            <RedeemPoints
              loyalty={loyalty}
              redeemCurrency={redeemCurrency}
              toggleRedeemCurrency={toggleRedeemCurrency}
            />
          </ItemWrapper>

          <ItemWrapper>
            <ReviewOrder onClick={onPlaceOrderClick}>
              <Label>Place Order</Label>
              <Amount>
                $<FixedNumber>{price}</FixedNumber>
              </Amount>
            </ReviewOrder>
          </ItemWrapper>
        </Content>
      </Container>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ContentInner>
  </Wrapper>
);

export default Checkout;

Checkout.propTypes = {
  onPlaceOrderClick: PropTypes.func,
  price: PropTypes.number,
  promocode: PropTypes.string,
  dishes: PropTypes.array,
  cartSubtotal: PropTypes.number,
  showModalPromocode: PropTypes.func,
  specialInstructions: PropTypes.string,
  onSpecialInstructionsChange: PropTypes.func,
  paymentInvalid: PropTypes.bool.isRequired,
  tipsAllowed: PropTypes.bool.isRequired,
  loyalty: PropTypes.shape({}).isRequired,
  redeemCurrency: PropTypes.number.isRequired,
  toggleRedeemCurrency: PropTypes.func,
  dineInEnabled: PropTypes.bool.isRequired,
  dineInTable: PropTypes.number.isRequired,
  setDineInTable: PropTypes.func.isRequired,
  tableNumberInvalid: PropTypes.bool.isRequired,
  tableNameInvalid: PropTypes.bool.isRequired,
};

Checkout.defaultProps = {
  toggleRedeemCurrency: undefined,
};

const Content = styled.div`
  width: calc(50% - 20px);

  &:last-child {
    margin-left: 40px;
  }
`;

const ReviewOrder = styled.button`
  background: #54a300;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 17px 10px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 15px 15px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #67c70f;
  }
`;

const Amount = styled.div`
  font-size: 14px;
  opacity: 0.8;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
`;

const Label = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ItemWrapper = styled.div`
  margin-top: 25px;
  border-radius: 2px;
  border: ${p => p.showError && '2px solid red'};
  display: ${p => p.dineInHidden && 'none'};
`;

const Promo = styled.button`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-transform: uppercase;

  span {
    text-transform: initial;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #5f6263;
`;

const PromoButton = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: 300;
  border: none;
  padding: 5px 0;
  margin-left: 10px;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }

  span {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
  padding: 0;
  box-shadow: 1px 1px 1px 0 #dedfe0;
`;

const FooterWrapper = styled.div`
  margin-top: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 885px;
  margin: 12px auto 0 auto;

  ${media.tablet`
    padding: 0 30px;
  `} ${media.desktop`
    padding: 0;
  `};
`;

const ContentInner = styled.div`
  padding-top: 50px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
