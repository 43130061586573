import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Card, { Header, Title as CardTitle, Edit } from 'components/Card';
import MapAddress from 'components/MapAddress';

import { media } from 'utils/mediaQueries';

const DeliveryMethod = ({
  address,
  titleText,
  editButtonEnabled,
  onEditClick,
}) => (
  <Card>
    <Header>
      <CardTitle>{titleText}</CardTitle>
      {editButtonEnabled && (
        <EditWithCursor
          onClick={onEditClick}
          aria-label="Edit Address and Time"
        >
          <Icon name="icon-edit-small" size="22px" />
        </EditWithCursor>
      )}
    </Header>
    <MapAddress address={address} />
  </Card>
);

DeliveryMethod.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    id: PropTypes.number,
    placeId: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }),
  titleText: PropTypes.string.isRequired,
  editButtonEnabled: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

DeliveryMethod.defaultProps = {
  address: {},
};

export default DeliveryMethod;

const EditWithCursor = Edit.extend`
  ${media.desktop`
    cursor: pointer;
  `};
`;
