import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/Icon';

const PopupStyled = styled.div`
  pointer-events: all;
  cursor: default;
  text-align: center;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px;
  width: calc(100% - 30px);
  max-width: 400px;
`;

const MessageStyled = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.322;
  font-weight: 300;
  margin: 24px 40px;
`;

const ActionsStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  flex: 1 0 auto;
  padding: 6px 0;
  :first-child {
    padding-top: 0;
  }
  :last-child {
    padding-bottom: 0;
  }
`;

const ButtonStyled = styled.button`
  text-transform: uppercase;
  color: ${p =>
    ({
      [true]: '#5E6263',
      [p.primary]: '#ffffff',
      [!!p.disabled]: '#d2d3d3',
    }.true)};
  background-color: ${p =>
    ({
      [true]: '#ffffff',
      [p.primary]: '#54a300',
      [!!p.disabled]: '#d2d3d3',
    }.true)};
  border: ${p => (!p.primary ? '1px solid #C8C8C8' : 'none')};
  border-radius: 2px;
  text-align: center;
  text-shadow: 1px 1px 1px hsla(0, 0%, 0%, 0.2);
  padding: 19px 10px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  transition: background 0.2s ease;
  width: 100%;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const BodyContainer = styled.div`
  padding: 0 12px 12px;
`;

const Title = styled.div`
  color: #424648;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 300;
`;

const CloseButton = styled.button`
  color: #424648;
  padding-right: 6px;
`;

const InformPopupAlt = ({
  title,
  message,
  showCancelButton,
  onCloseClick,
  cancelButtonText,
  onButtonClick,
  buttonText,
}) => (
  <PopupStyled>
    <HeaderContainer>
      <Title>{title || ''}</Title>
      <CloseButton onClick={onCloseClick}>
        <Icon name="icon-cross-small" size="24px" />
      </CloseButton>
    </HeaderContainer>
    <BodyContainer>
      <MessageStyled>{message}</MessageStyled>
      <ActionsStyled>
        <ButtonWrapper>
          <ButtonStyled onClick={onButtonClick} primary>
            {buttonText}
          </ButtonStyled>
        </ButtonWrapper>
        <ButtonWrapper>
          {showCancelButton && (
            <ButtonStyled onClick={onCloseClick}>
              {cancelButtonText}
            </ButtonStyled>
          )}
        </ButtonWrapper>
      </ActionsStyled>
    </BodyContainer>
  </PopupStyled>
);

InformPopupAlt.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default InformPopupAlt;
