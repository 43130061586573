import { createSelector } from 'reselect/lib/index';
import getPreparationPlusDeliveryMinutesForTimeFunc from 'selectors/getPreparationPlusDeliveryMinutesForTimeFunc';
import { getNowTime } from 'utils/timeHelpers';

const asapPreparationTimeSelector = createSelector(
  [getPreparationPlusDeliveryMinutesForTimeFunc],
  getPreparationPlusDeliveryMinutesForTime =>
    getPreparationPlusDeliveryMinutesForTime(getNowTime()),
);

export default asapPreparationTimeSelector;
