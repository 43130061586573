import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TableNumberInput = ({ value, onChange, error }) => (
  <Wrapper>
    {Boolean(value) && <span>#</span>}
    <InputStyled
      type="text"
      value={value}
      onChange={onChange}
      placeholder="Enter your table #"
    />
  </Wrapper>
);

TableNumberInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

const Wrapper = styled.div`
  background: #fff;
  border-top: 1px solid #eeefef;
  display: flex;
  align-items: center;
  position: relative;
  :focus-within {
    border: 1px solid #54a300;
  }
  padding: 12px 15px;
  flex-flow: row nowrap;
`;

const InputStyled = styled.input`
  border: none;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  outline: none;
  -webkit-text-fill-color: rgb(66, 70, 72);
  opacity: 1;
  background: transparent !important;
  
  ::placeholder {
    color: #606667;
    font-style: italic;
  }

  ::-webkit-inner-spin-button, 
  ::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance:textfield !important;
`;
