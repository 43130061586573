import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import OrderCardContainer from '../containers/OrderCardContainer';
import NoOrders from './NoOrders';

const OrdersList = ({ orders, closeModal, onLoadMore, type }) =>
  orders.length === 0 ? (
    <NoOrders type={type} />
  ) : (
    <Wrapper>
      {orders.map((order, i, arr) => (
        <CardWrapper key={order.orderId}>
          <OrderCardContainer
            id={order.orderId}
            order={order}
            closeModal={closeModal}
          />
          {arr.length - 1 === i && (
            <VisibilitySensor onChange={visible => onLoadMore(visible, i)} />
          )}
        </CardWrapper>
      ))}
    </Wrapper>
  );

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  closeModal: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

OrdersList.defaultProps = {
  orders: [],
};

const Wrapper = styled.div`
  margin-top: 29px;
  padding: 0 15px 30px 15px;
`;

const CardWrapper = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

export default OrdersList;
