import { createSelector } from 'reselect';
import { deliverySelector } from 'redux/modules/delivery';
import { getDeliveryType } from 'selectors/getDeliveryType';

const deliveryCostSelector = createSelector(
  [getDeliveryType, deliverySelector],
  (deliveryType, { deliveryCharge }) =>
    deliveryType === 'DELIVERY' ? deliveryCharge || 0 : 0,
);

export default deliveryCostSelector;
