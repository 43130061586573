import { createSelector } from 'reselect';
import { orderAheadInfoSelector } from 'redux/modules/general';
import deliveryTypeSelector from 'selectors/deliveryTypeSelector';

export const showIntervalsSelector = createSelector(
  [orderAheadInfoSelector, deliveryTypeSelector, (_, props) => props],
  (orderAheadInfo, deliveryType, props) => {
    const { timeIntervalEnabled, timeIntervalAppliesTo } = orderAheadInfo;
    return (
      timeIntervalEnabled &&
      (timeIntervalAppliesTo === 'both' ||
        timeIntervalAppliesTo.toUpperCase() ===
          (props ? props.deliveryType : deliveryType))
    );
  },
);
