import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withHandlers } from 'recompose';

import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';

// eslint-disable-next-line no-unused-vars
const TextMaskCustom = ({ inputRef, ...rest }) => (
  <MaskedInput
    {...rest}
    mask={[
      '+',
      '1',
      ' ',
      '(',
      /[2-9]/,
      // /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    // eslint-disable-next-line react/jsx-curly-brace-presence
    placeholderChar={'\u2000'}
    showMask={false}
    guide={false}
  />
);

TextMaskCustom.propTypes = {};

export const TablePhoneInput = ({
  value,
  onChange,
  onBlur,
  error,
  ...rest
}) => (
  <Wrapper>
    {Boolean(value)}
    <Input
      id="formatted-text-mask-input"
      placeholder="+1 (999) 999-9999"
      inputComponent={TextMaskCustom}
      error={error}
      {...rest}
      value={rest.value}
      onBlur={onBlur}
    />
  </Wrapper>
);

TablePhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

const Wrapper = styled.div`
  background: #fff;
  border-top: 1px solid #eeefef;
  display: flex;
  align-items: center;
  position: relative;
  :focus-within {
    border: 1px solid #54a300;
  }
  padding: 12px 15px;
  flex-flow: row nowrap;
`;


const TablePhoneInputHOC = compose(
  withHandlers({
    onChange: ({ onChange }) => e => {
      onChange(e.target.value);
    },
  }),
);

export default TablePhoneInputHOC(TablePhoneInput);
