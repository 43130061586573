import React from 'react';
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';

import Card, { Edit, Header } from 'components/Card';
import Overlay from 'utils/Overlay';
import { media } from 'utils/mediaQueries';

// ---
// VARIATION CARD COMPONENTS
// ---
import Button from 'components/Button';
import GhostButton from 'components/Button/GhostButton';
import Icon from 'components/Icon';
import IconClean from 'components/IconClean';
import { PopupWrapper } from 'components/ModalSwitch';
import RadioGroup from './components/RadioGroup';
import PizzaPartGroup, {
  MixAndMatchGroup,
  SmallPizzaPartGroup,
} from './components/PizzaPartGroup';
import CheckboxGroup from './components/CheckboxGroup';
import ComboGroup from './components/ComboGroup';
import { JustNameGroup } from './components/JustNameGroup';

const ScrollableContent = styled.div`
  overflow: auto;
  background: #f3f4f5;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
`;

const CardWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const ButtonWrapper = styled.div`
  background: #fff;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 4;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  pointer-events: auto;

  ${media.tablet`
    max-height: inherit;
  `};
`;

const Header2 = styled.div`
  background: #fff;
  box-shadow: 1px 1px 3px 0 #e6e7e8;
  padding: 16px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Close = styled.div`
  width: 16px;
  height: 16px;
  opacity: 0.5;
`;

const HeaderTitle = styled.span`
  text-align: center;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.2px;
`;

const Inner2 = styled.div`
  color: #54a300;
  padding: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const AccordionValues = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  background-color: #fff;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eeefef;
  background-color: white;
`;

const ChooseButtonWrapper = styled.div`
  background-color: #fff;
  padding: 12px;
`;

const VariationCardModal = ({ title, children, onClose }) => (
  <ModalContent>
    <Header2>
      <Close onClick={onClose}>
        <Icon name="icon-cross-thin-small" size="34px" />
      </Close>
      <HeaderTitle>{title}</HeaderTitle>
      <div />
    </Header2>
    <ScrollableContent>
      <CardWrapper>{children}</CardWrapper>
    </ScrollableContent>
    <ButtonWrapper>
      <Button onClick={onClose}>Accept</Button>
    </ButtonWrapper>
  </ModalContent>
);

const VARIATION_CARDS = {
  pizza_toppings: PizzaPartGroup,
  pizza_toppings_small: SmallPizzaPartGroup,
  mix_and_match: MixAndMatchGroup,
  checkbox: CheckboxGroup,
  radio: RadioGroup,
  combo: ComboGroup,
};

const VariationCard = ({
  data,
  onMultiplePickerClick,
  handleChange,
  value,
  required,
  invalid,
  requirementDetails,
  accordionOpened,
  openAccordion,
  closeAccordion,
}) => {
  const VariationComponent = VARIATION_CARDS[data.type];
  return (
    data?.visible && (
      <Card showError={invalid}>
        <Header>
          <VariationTitle>
            {data.name}
            {requirementDetails && (
              <RequirementDetails>{requirementDetails}</RequirementDetails>
            )}
          </VariationTitle>
          {required && <Required invalid={invalid}> Required </Required>}
        </Header>
        {data?.accordion ? (
          <div>
            {accordionOpened && VariationComponent && (
              <Overlay onClick={closeAccordion}>
                <PopupWrapper
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <VariationCardModal
                    onClose={closeAccordion}
                    title={data.name}
                  >
                    <Card showError={invalid}>
                      <Header>
                        <VariationTitle>{requirementDetails}</VariationTitle>
                        {required && (
                          <Required invalid={invalid}> Required </Required>
                        )}
                      </Header>
                      <VariationComponent
                        data={data}
                        onChange={handleChange}
                        value={value}
                        onMultiplePickerClick={onMultiplePickerClick}
                      />
                    </Card>
                  </VariationCardModal>
                </PopupWrapper>
              </Overlay>
            )}
            {value ? (
              <AccordionValues>
                <JustNameGroup data={data} value={value} />
                <EditButtonWrapper>
                  <Edit onClick={openAccordion}>
                    <Inner2>
                      <IconClean name="icon-edit-small" size="22px" />
                      <span>EDIT</span>
                    </Inner2>
                  </Edit>
                </EditButtonWrapper>
              </AccordionValues>
            ) : (
              <ChooseButtonWrapper>
                <GhostButton onClick={openAccordion} withArrow>
                  Choose
                </GhostButton>
              </ChooseButtonWrapper>
            )}
          </div>
        ) : (
          VariationComponent && (
            <VariationComponent
              data={data}
              onChange={handleChange}
              value={value}
              onMultiplePickerClick={onMultiplePickerClick}
            />
          )
        )}
      </Card>
    )
  );
};

VariationCard.propTypes = {
  data: PropTypes.object,
  handleChange: PropTypes.func,
  onMultiplePickerClick: PropTypes.func,
  value: PropTypes.object,
  required: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  requirementDetails: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

VariationCard.defaultProps = {
  requirementDetails: undefined,
};

const VariationCardHOC = compose(
  withStateHandlers(
    { accordionOpened: false },
    {
      openAccordion: () => () => ({ accordionOpened: true }),
      closeAccordion: () => () => ({ accordionOpened: false }),
    },
  ),
  withHandlers({
    handleChange: ({ data, onChange }) => value => {
      onChange({
        ...pick(data, ['id', 'type']),
        value,
      });
    },
  }),
  withProps(
    ({
      configuration,
      submitButtonTouched,
      data: { valid, required },
      data,
    }) => ({
      value: get(configuration, 'value'),
      required,
      invalid: submitButtonTouched && !valid,
      variationParams: omit(data, [
        'value',
        'variationPrice',
        'optionsLength',
        'visible',
        'valid',
      ]),
    }),
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        handleChange,
        data: { conditionalValues, visibility, visible },
        value,
        variationParams,
      } = this.props;
      if (
        value &&
        ((conditionalValues &&
          Object.keys(conditionalValues)?.length &&
          !isEqual(variationParams, prevProps.variationParams)) ||
          (visibility &&
            Object.keys(visibility)?.length &&
            prevProps.data.visible &&
            !visible))
      ) {
        handleChange({});
      }
    },
  }),
  withProps(({ data: { min, max } }) => ({
    requirementDetails: {
      [true]: undefined,
      [min === 0 && max === -1]: undefined,
      // “Choose X” min > 0,  max == min
      [min > 0 && max > 0 && min === max]: `Choose ${min}`,
      // “Choose up to X” min == 0,  max >  0
      [!min && max > 0]: `Choose up to ${max}`,
      // “Choose between  X and Y” min > 0, max != min
      [min > 0 && max > min]: `Choose between ${min} to ${max}`,
    }.true,
  })),
);

const Required = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  background: ${p => (p.invalid ? '#c93c32' : '#939393')};
  font-weight: 300;
  letter-spacing: 0.2px;
  padding: 3px 5px;
`;

const RequirementDetails = styled.div`
  margin-top: 10px;
  font-family: Montserrat, sans-serif;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
`;

const VariationTitle = styled.div`
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-weight: 400;
`;

export default VariationCardHOC(VariationCard);
