import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import find from 'lodash/find';
import { itemsSelector } from 'redux/modules/items';
import { expandedVariationsSelector } from 'selectors/expandedVariationsSelector';

const itemsWithOverwrittenPriceSelector = createSelector(
  [itemsSelector, expandedVariationsSelector],
  (items, expandedVariations) =>
    mapValues(items, item => {
      const { price, variations } = item;
      if (!price) {
        const itemVariations = pick(expandedVariations, variations);
        const priceOverwriteVariation = find(itemVariations, 'priceOverwrite');
        const optionPrices =
          priceOverwriteVariation &&
          Object.values(
            mapValues(priceOverwriteVariation.options, option => option.price),
          );
        const overwrittenPriceRange = optionPrices && [
          Math.min(...optionPrices),
          Math.max(...optionPrices),
        ];
        return {
          ...item,
          ...(overwrittenPriceRange && { overwrittenPriceRange }),
        };
      }
      return item;
    }),
);

export default itemsWithOverwrittenPriceSelector;
