import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actions as userActions, userSelector } from 'redux/modules/user';

import withConditionalCDM from './withConditionalCDM';

const withUserInfo = compose(
  connect(
    state => ({
      user: userSelector(state),
    }),
    {
      userInfo: userActions.userInfo,
    },
  ),
  withConditionalCDM(
    ({ user: { fulfilled } }) => !fulfilled,
    ({ userInfo }) => {
      userInfo();
    },
  ),
);

export default withUserInfo;
