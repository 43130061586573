import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SettingsCard from 'components/SettingsCard';
import Icon from 'components/Icon';
import OrderDishesList from 'containers/OrderDishesListContainer';

import SpecialInstructionsCard from 'components/SpecialInstructionsCard';

import Modal from 'components/Modal';
import TouchHandler from 'components/TouchHandler';
import PaymentMethod from 'routes/Checkout/components/PaymentMethod/PaymentMethod';
import ScheduleSettings from 'routes/Checkout/containers/ScheduleSettings';

import DeliveryMethod from '../../../Checkout/containers/DeliveryMethod';
import Tip from './containers/TipContainer';
import OrderSummaryContainer from './containers/OrderSummaryContainer';
import { RedeemPoints } from 'routes/Checkout/RedeemPoints';
import { TableNumberInput } from 'routes/Checkout/components/TableNumberInput';
import { TableNameInput } from 'routes/Checkout/components/TableNameInput';
import {TablePhoneInput} from 'routes/Checkout/components/TablePhoneInput';

const Checkout = ({
  price,
  onPlaceOrderClick,
  promocode,
  show,
  closeModal,
  showModalPromocode,
  paymentInvalid,
  onScrollableNodeRef,
  onPaymentsNodeRef,
  tipsAllowed,
  specialInstructions,
  onSpecialInstructionsChange,
  loyalty,
  redeemCurrency,
  toggleRedeemCurrency,
  dineInEnabled,
  dineInTable,
  setDineInTable,
  tableNumberInvalid,
  onTableNumberInputNodeRef,
  tableNameInvalid,
  dineInName,
  setDineInName,
  onTableNameInputNodeRef,
  dineInPhone,
  setDineInPhone,
  tablePhoneInvalid,
  onTablePhoneInputNodeRef,
}) => (
  <Modal show={show} onCloseClick={closeModal} title="Your order">
    <ScrollableContentWrapper>
      <TouchHandler ref={onScrollableNodeRef}>
        <Content>
          {dineInEnabled ? (
            <>
              <ItemWrapper
                showError={tableNumberInvalid}
                ref={onTableNumberInputNodeRef}
              >
                <SettingsCard
                  title="Table Number"
                  showError={tableNumberInvalid}
                >
                  <TableNumberInput
                    value={dineInTable}
                    onChange={setDineInTable}
                    error={tableNumberInvalid}
                  />
                </SettingsCard>
              </ItemWrapper>
              <ItemWrapper
                showError={tableNameInvalid}
                ref={onTableNameInputNodeRef}
              >
                <SettingsCard title="Name" showError={tableNumberInvalid}>
                  <TableNameInput
                    value={dineInName}
                    onChange={setDineInName}
                    error={tableNameInvalid}
                  />
                </SettingsCard>
              </ItemWrapper>
              <ItemWrapper
                showError={tablePhoneInvalid}
                ref={onTableNameInputNodeRef}
              >
                <SettingsCard title="Phone" showError={tablePhoneInvalid}>
                  <TablePhoneInput
                    type="tel"
                    value={dineInPhone}
                    onChange={setDineInPhone}
                    onBlur={setDineInPhone}
                    error={tablePhoneInvalid}
                  />
                </SettingsCard>
              </ItemWrapper>
            </>
          ) : (
            <>
              <ItemWrapper dineInHidden={dineInEnabled}>
                <DeliveryMethod />
              </ItemWrapper>

              <ItemWrapper dineInHidden={dineInEnabled}>
                <ScheduleSettings />
              </ItemWrapper>
            </>
          )}
          <Inner>
            <ItemWrapper
              showError={paymentInvalid}
              ref={onPaymentsNodeRef}
              dineInHidden={dineInEnabled}
            >
              <SettingsCard title="Payment method" showError={paymentInvalid}>
                <PaymentMethod />
              </SettingsCard>
            </ItemWrapper>

            {tipsAllowed && (
              <ItemWrapper>
                <SettingsCard title="Tip">
                  <Tip />
                </SettingsCard>
              </ItemWrapper>
            )}

            <ItemWrapper>
              <ItemsListContainer>
                <OrderDishesList readOnly />
                <OrderSummaryContainer />
              </ItemsListContainer>
            </ItemWrapper>

            <ItemWrapper dineInHidden={dineInEnabled}>
              <Promo onClick={showModalPromocode}>
                <IconWrapper>
                  <Icon name="icon-promotions-small" size="28px" />
                </IconWrapper>
                <PromoButton>
                  {promocode || <span>Enter Promo code</span>}
                </PromoButton>
              </Promo>
            </ItemWrapper>
            <ItemWrapper dineInHidden={dineInEnabled}>
              <RedeemPoints
                loyalty={loyalty}
                redeemCurrency={redeemCurrency}
                toggleRedeemCurrency={toggleRedeemCurrency}
              />
            </ItemWrapper>
            <ItemWrapper dineInHidden={dineInEnabled}>
              <SpecialInstructionsCard
                value={specialInstructions}
                onChange={onSpecialInstructionsChange}
              />
            </ItemWrapper>
            <ItemWrapper>
              <ReviewOrder onClick={onPlaceOrderClick}>
                <Label>Place Order</Label>
                <Amount>${price.toFixed(2)}</Amount>
              </ReviewOrder>
            </ItemWrapper>
          </Inner>
        </Content>
      </TouchHandler>
    </ScrollableContentWrapper>
  </Modal>
);

Checkout.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  onPlaceOrderClick: PropTypes.func,
  price: PropTypes.number,
  promocode: PropTypes.string,
  showModalPromocode: PropTypes.func,
  paymentInvalid: PropTypes.bool.isRequired,
  tipsAllowed: PropTypes.bool.isRequired,
  onScrollableNodeRef: PropTypes.func.isRequired,
  onPaymentsNodeRef: PropTypes.func.isRequired,
  onTableNumberInputNodeRef: PropTypes.func.isRequired,
  onTableNameInputNodeRef: PropTypes.func.isRequired,
  onTablePhoneInputNodeRef: PropTypes.func.isRequired,
  specialInstructions: PropTypes.string.isRequired,
  onSpecialInstructionsChange: PropTypes.func.isRequired,
  loyalty: PropTypes.shape({}),
  redeemCurrency: PropTypes.number,
  toggleRedeemCurrency: PropTypes.func,
  dineInEnabled: PropTypes.bool.isRequired,
  dineInTable: PropTypes.number.isRequired,
  setDineInTable: PropTypes.func.isRequired,
  setDineInName: PropTypes.func.isRequired,
  setDineInPhone: PropTypes.func.isRequired,
  tableNumberInvalid: PropTypes.bool.isRequired,
  tableNameInvalid: PropTypes.bool.isRequired,
  tablePhoneInvalid: PropTypes.bool.isRequired,
};

Checkout.defaultProps = {
  loyalty: undefined,
  redeemCurrency: null,
  toggleRedeemCurrency: undefined,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Content = styled.div``;

const ItemsListContainer = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
`;

const ReviewOrder = styled.button`
  margin: 30px 0;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background-color: rgb(84, 163, 0);
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 15px 15px;
`;

const Amount = styled.div`
  font-size: 14px;
  opacity: 0.8;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
`;

const Label = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ItemWrapper = styled.div`
  margin: 17px 15px;
  border-radius: 2px;
  border: ${p => p.showError && '2px solid red'};
  display: ${p => p.dineInHidden && 'none'};
`;

const Promo = styled.button`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 15px;

  span {
    text-transform: initial;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #5f6263;
`;

const PromoButton = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  outline: none;
  font-weight: 300;
  border: none;
  padding: 5px 0;
  margin-left: 10px;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }

  span {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

export default Checkout;
