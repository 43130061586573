import React from 'react';
import { compose, branch } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';
import BaseIcon from 'components/Icon';
import dataFetcher from 'HOC/dataFetcher';
import { actions as favoritesActions } from 'redux/modules/favorites/reducer';

// ---
// PROP TYPES SHAPE
// ---

const headerPropTypes = {
  isFavorite: PropTypes.bool,
  title: PropTypes.string,
  onCloseClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
};

// ---
// ICONS_LAYER COMPONENT
// ---
const IconsLayer = ({
  hasImage,
  title,
  isFavorite,
  onCloseClick,
  onFavoriteClick,
}) => (
  <Icons>
    <IconWrapper
      className={`${hasImage ? 'js-interpolated-color-receiver' : ''}`}
      onClick={onCloseClick}
      hasImage={hasImage}
      aria-label="Close"
    >
      <Icon size="34px" name="icon-cross-thin-small" />
    </IconWrapper>
    <Title className="js-opacity-receiver">{title}</Title>
    
  </Icons>
);
IconsLayer.propTypes = { ...headerPropTypes };

// ---
// HEADER WITH IMAGE COMPONENT
// ---
const HeaderWithImage = ({
  opacity,
  title,
  onCloseClick,
  onFavoriteClick,
  isFavorite,
}) => (
  <Head>
    <Background className="js-opacity-receiver" />
    <Opacity className="js-transparency-receiver" />
    <IconsLayer
      hasImage
      title={title}
      isFavorite={isFavorite}
      onCloseClick={onCloseClick}
      onFavoriteClick={onFavoriteClick}
    />
  </Head>
);

HeaderWithImage.propTypes = {
  ...headerPropTypes,
};

// ---
// HEADER WITHOUT IMAGE COMPONENT
// ---
const HeaderWithoutImage = ({
  title,
  onCloseClick,
  onFavoriteClick,
  isFavorite,
}) => (
  <Head>
    <Background noImage />
    <IconsLayer
      title={title}
      isFavorite={isFavorite}
      onCloseClick={onCloseClick}
      onFavoriteClick={onFavoriteClick}
    />
  </Head>
);
HeaderWithoutImage.propTypes = { ...headerPropTypes };

// ---
//  GENERIC HEADER COMPONENT
// ---
const Header = ({
  title,
  onCloseClick,
  onFavoriteClick,
  isFavorite,
  hasImage,
}) => {
  const HeaderComponent = hasImage ? HeaderWithImage : HeaderWithoutImage;
  return (
    <HeaderComponent
      title={title}
      isFavorite={isFavorite}
      onCloseClick={onCloseClick}
      onFavoriteClick={onFavoriteClick}
    />
  );
};

Header.propTypes = {
  ...headerPropTypes,
};

const headerHOC = compose(
  // make sure this is latest HOC
  branch(
    ({ modalShow }) => modalShow,
    dataFetcher(
      state => !state.favorites.fulfilled,
      () => favoritesActions.getFavorites(false),
    ),
  ),
);

export default headerHOC(Header);

const Head = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  transform: translate3d(0, 0, 0); /* Force header show on safar */
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
`;

const Icons = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  position: relative;
`;

const Opacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 50px;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  height: 50px;
  opacity: ${p => (p.noImage ? 1 : 0)};
  box-shadow: ${({ image }) =>
    image ? 'none' : '0px 1px 3px 0px rgba(0, 0, 0, 0.1)'};
`;

const IconWrapper = styled.button`
  min-width: 15px;
  width: 15px;
  height: 15px;
  color: ${p => p.hasImage && 'rgb(255, 255, 255)'};

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Icon = styled(BaseIcon).attrs({
  size: '34px',
})`
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin-left: 20px;
`;

const Title = styled.div`
  color: rgb(66, 70, 72);
  white-space: nowrap;
  overflow: hidden;
  margin: 0 15px;
  text-overflow: ellipsis;
  opacity: 0;
`;
