import { compose, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { isUserLoggedInSelector } from 'redux/modules/user';

const renderNothingIfNotLoggedIn = compose(
  connect(state => ({
    isUserLoggedIn: isUserLoggedInSelector(state),
  })),
  branch(({ isUserLoggedIn }) => !isUserLoggedIn, renderNothing),
);

export default renderNothingIfNotLoggedIn;
