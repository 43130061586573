import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Cutlery } from 'assets/cutlery.svg';
import { ReactComponent as Reward } from 'assets/reward.svg';
import { ReactComponent as GiftReward } from 'assets/gift-reward.svg';

const StampIconStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  border-radius: 50%;

  font-family: 'Poppins', sans-serif;
  font-size: 11px;

  border-style: solid;
  border-width: 1px;
  border-color: ${p => (p.isOrder ? '#54A300' : '#C8C8C8')};
  color: ${p => (p.isOrder ? '#54A300' : '#C8C8C8')};
`;

const StampStyled = styled.div`
  min-width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrderOrigin = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  color: #303030;
  padding: 8px 0;
`;

export const StampIcon = ({ order, reward, pointsReward }) => (
  <StampIconStyled isOrder={order}>
    {
      {
        [true]: <Cutlery />,
        [reward]: <GiftReward />,
        [Boolean(reward && pointsReward)]: (
          <>
            <Reward />
            <div>{reward && pointsReward}</div>
          </>
        ),
      }.true
    }
  </StampIconStyled>
);

StampIcon.propTypes = {
  order: PropTypes.bool.isRequired,
  reward: PropTypes.bool.isRequired,
  pointsReward: PropTypes.bool.isRequired,
};

export const Stamp = ({ isOrder, isReward, order, reward, withOrigin }) => (
  <StampStyled>
    <StampIcon
      order={isOrder}
      reward={isReward}
      pointsReward={reward && reward.pointsReward}
    />
    {withOrigin && (
      <OrderOrigin>
        {isOrder && (order?.origin === 'offline' ? 'In Store' : 'Online')}
      </OrderOrigin>
    )}
  </StampStyled>
);

Stamp.propTypes = {
  isOrder: PropTypes.bool.isRequired,
  isReward: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    origin: PropTypes.string,
  }),
  reward: PropTypes.shape({
    pointsReward: PropTypes.number,
  }),
  withOrigin: PropTypes.bool,
};

Stamp.defaultProps = {
  order: undefined,
  reward: undefined,
  withOrigin: true,
};
