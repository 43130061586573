import { handleActions, createAction } from 'redux-actions';
import omit from 'lodash/omit';
// ---
// CONSTANTS
// ---
export const SET_UP = 'dishConfigurator/SET_UP';
export const SET = 'dishConfigurator/SET';
export const SET_SUCCEED = 'dishConfigurator/SET_SUCCEED';
export const CLEAR = 'dishConfigurator/CLEAR';
export const UPDATE_VARIATION = 'dishConfigurator/UPDATE_VARIATION';
export const UPDATE_CONFIGURATION = 'dishConfigurator/UPDATE_CONFIGURATION';

// ---
// ACTION_CREATORS
// ---
export const set = createAction(SET);
export const setUp = createAction(SET_UP);
export const setSucceed = createAction(SET_SUCCEED);
export const clear = createAction(CLEAR);
export const updateVariation = createAction(UPDATE_VARIATION);
export const updateConfiguration = createAction(UPDATE_CONFIGURATION);
const initialState = {};

export default handleActions(
  {
    [SET_SUCCEED]: (state, action) => ({ ...action.payload }),
    [CLEAR]: () => ({}),
    [UPDATE_VARIATION]: (state, action) => ({
      ...state,
      variations: {
        ...omit(state.variations, action.payload.id),
        ...(Object.keys(action.payload.value).length >= 1 && {
          [action.payload.id]: {
            ...(state.variations[action.payload.id] || {}),
            ...action.payload,
          },
        }),
      },
    }),
    [UPDATE_CONFIGURATION]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [SET_UP]: (state, action) => action.payload,
  },
  initialState,
);

export const actions = {
  set,
  setUp,
  setSucceed,
  clear,
  updateVariation,
  updateConfiguration,
};
