import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';
import { getFixedPrice } from 'utils/currencyHelpers';

const Product = ({
  item: { image, description, title, price, count, soldOut },
  onClick,
  onFavoriteClick,
}) => (
  <Wrapper onClick={onClick} transparent={soldOut}>
    {image && <Image img={image} />}
    <Info small={!!image}>
      <Name>
        <Title> {title} </Title>
        <IconWrapper
          active={!soldOut}
          onClick={event => {
            event.stopPropagation();
            onFavoriteClick && onFavoriteClick();
          }}
        >
          <Icon
            name={
              soldOut ? 'icon-favorite-small' : 'icon-favorite-selected-small'
            }
            size="28px"
          />
        </IconWrapper>
      </Name>
      <Description>{description}</Description>
      <Bottom>
        <Price>${getFixedPrice(price)}</Price>
        {soldOut && <Label>SOLD OUT</Label>}
      </Bottom>
    </Info>
  </Wrapper>
);

Product.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    count: PropTypes.number,
    soldOut: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
};

export default Product;

const Wrapper = styled.button`
  background: #fff;
  opacity: ${({ transparent }) => (transparent ? '.6' : '1')};
  display: flex;
  flex-flow: row nowrap;
`;

const Image = styled.div`
  min-width: 100px;
  min-height: 100px;
  background: url(${({ img }) => img}) no-repeat center / cover;
`;

const Info = styled.div`
  padding: 16px;
  flex: 1 1 auto;
  max-width: ${({ small }) => (small ? 'calc(100% - 100px)' : '100%')};
`;

const Name = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  white-space: nowrap;
  overflow-x: hidden;
  line-height: 22px;
  text-overflow: ellipsis;
  padding-right: 15px;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: ${({ active }) => (active ? '#ED4E75' : '#000')};
`;

const Description = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: rgb(116, 119, 120);
  white-space: nowrap;
  overflow-x: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-top: 5px;
`;

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const Price = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Label = styled.div`
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  color: rgb(250, 250, 250);
  background: #555;
  display: inline-block;
  padding: 2px 5px;
  font-weight: 400;
  letter-spacing: 0.5px;
`;
