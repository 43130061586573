import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NavigationHeader } from 'components/Navigation';
import { media } from 'utils/mediaQueries';

const MenuNavigationHeader = ({
  backPath,
  deliveryDay,
  deliveryType,
  deliveryTimeFormatted,
  dineInEnabled,
}) => (
  <NavigationHeader backPath={backPath}>
    <Title>
      <Top>
        <Type>
          {
            {
              [true]: 'Not set',
              [deliveryType === 'DELIVERY']: 'Delivery',
              [deliveryType === 'PICKUP']: 'Pickup',
              [deliveryType === 'PICKUP' && dineInEnabled]: 'Dine-in',
            }.true
          }
          :
        </Type>
        <Day>{deliveryDay}</Day>
      </Top>
      <span>&nbsp;-&nbsp;</span>
      <Time>
        {deliveryTimeFormatted.map(text => (
          <span>{text}</span>
        ))}
      </Time>
    </Title>
  </NavigationHeader>
);

MenuNavigationHeader.propTypes = {
  backPath: PropTypes.string,
  deliveryDay: PropTypes.string,
  deliveryType: PropTypes.string,
  deliveryTimeFormatted: PropTypes.string,
  dineInEnabled: PropTypes.bool.isRequired,
};

export default MenuNavigationHeader;

const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Time = styled.div`
  display: inline-block;
  //font-size: 13px;
  //font-family: 'Roboto';
  //color: rgb(116, 119, 120);
  > *:not(:first-child) {
    display: none;
    ${media[400]`
      display: inline;  
    `};
  }
`;

const Day = styled.div`
  display: inline-block;
  //margin: 0 5px 0 3px;
  //font-size: 16px;
  //font-weight: 300;
`;

const Type = styled.div`
  display: inline-block;
  //font-size: 16px;
  //font-family: 'Montserrat';
  color: rgb(66, 70, 72);
  text-align: center;
  font-weight: 400;
  margin-right: 7px;
  //letter-spacing: 0.3px;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
