import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Item } from 'components/Card';
import SecurityCard, {
  Descritption,
  ShowModeContent,
  EditModeContent,
  iconTypes,
  Input,
} from './SecurityCard';

class EmailSecurityCard extends Component {
  handleChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { email, onCancel, onSave, isValid, error, dirty } = this.props;
    return (
      <SecurityCard
        editModeHeight={97}
        type={iconTypes.EMAIL}
        onSave={onSave}
        onCancel={onCancel}
        isValid={isValid}
        dirty={dirty}
      >
        <ShowModeContent>
          <Descritption>{email}</Descritption>
        </ShowModeContent>
        <EditModeContent>
          <InputItem showError={error}>
            <Input
              type="email"
              value={email}
              placeholder="Email"
              onChange={this.handleChange}
              required
            />
          </InputItem>
        </EditModeContent>
      </SecurityCard>
    );
  }
}

EmailSecurityCard.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

export default EmailSecurityCard;

const InputItem = Item.extend`
  padding-left: 40px;
  max-height: 51px;
  :focus-within {
    border: 1px solid #54a300;
  }
`;
