import { createSelector } from 'reselect';

import round from 'lodash/round';

import { serviceFeeSelector } from 'redux/modules/general';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { paymentMethodSelector } from 'redux/modules/cart';
import { getDeliveryType } from 'selectors/getDeliveryType';

const serviceFeeTotalSelector = createSelector(
  [
    cartSubtotalSelector,
    serviceFeeSelector,
    paymentMethodSelector,
    getDeliveryType,
  ],
  (
    cartSubtotal,
    {
      fee,
      type,
      minimum,
      reducedLimit,
      reducedFee,
      appliesToPayment, // both/card/cash
      appliesToMethod, // both/delivery/pickup
    },
    paymentMethod,
    deliveryType,
  ) => {
    if (
      appliesToPayment &&
      appliesToPayment !== 'both' &&
      paymentMethod &&
      appliesToPayment.toLowerCase() !== paymentMethod.toLowerCase()
    ) {
      return 0;
    }
    if (
      appliesToMethod &&
      appliesToMethod !== 'both' &&
      deliveryType &&
      appliesToMethod.toLowerCase() !== deliveryType.toLowerCase()
    ) {
      return 0;
    }
    const newFee = cartSubtotal < reducedLimit ? reducedFee : fee;
    const resultingFee = round(
      Math.max(
        0,
        minimum,
        type === 'amount' ? newFee : (cartSubtotal * newFee) / 100,
      ),
      2,
    );
    return resultingFee;
  },
);

export default serviceFeeTotalSelector;
