import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import DishesList, { DeliveryTimeInfo } from 'components/DishesList';

import DishWithVariationsItemContainer from './containers/DishWithVarioationsItemContainer';

const ReviewOrder = ({
  orderDishes,
  subtotal,
  deliveryEstimateText,
  mode,
  onEditClick,
  withScheduleTime,
  onDeliveryTimeClick,
  readOnly,
}) => (
  <DishesList
    onEditClick={onEditClick}
    subtotal={subtotal}
    type={mode}
    readOnly={readOnly}
  >
    {withScheduleTime && (
      <DeliveryTimeInfo
        deliveryEstimateText={deliveryEstimateText}
        onDeliveryTimeClick={onDeliveryTimeClick}
      />
    )}
    <Separator />
    <TransitionGroup>
      {orderDishes.map(dishId => (
        <CSSTransition key={dishId} timeout={750} classNames="slide">
          <DishWithVariationsItemContainer
            key={dishId}
            id={dishId}
            type={mode}
            readOnly={readOnly}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </DishesList>
);

ReviewOrder.propTypes = {
  orderDishes: PropTypes.arrayOf(PropTypes.object),
  subtotal: PropTypes.number.isRequired,
  deliveryEstimateText: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  withScheduleTime: PropTypes.bool,
  readOnly: PropTypes.bool,
  onDeliveryTimeClick: PropTypes.func.isRequired,
};

ReviewOrder.defaultProps = {
  orderDishes: [],
  withScheduleTime: false,
  readOnly: false,
};

const Separator = styled.div`
  border-top: 1px solid #eeefef;
`;

export default ReviewOrder;
