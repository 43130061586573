import { handleActions, createAction } from 'redux-actions';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import find from 'lodash/find';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import uuidv4 from 'uuid/v4';
import setReduxPersistTimeout from 'utils/setReduxPersistTimeout';
import { actions as spinnerActions } from 'redux/modules/spinner/reducer';
import wretches from 'wretches';
import { decamelizeKeys } from 'humps';
import inform from 'utils/inform';

// ---
// CONSTANTS
// ---
export const ADD_ITEM = 'cart/ADD_ITEM';
export const UPDATE_ITEM = 'cart/UPDATE_ITEM';
export const REMOVE_ITEM = 'cart/REMOVE_ITEM';
export const MARK_ITEM_REMOVING = 'cart/MARK_ITEM_REMOVING';
export const REMOVE_ITEM_BY_DISH_ID = 'cart/REMOVE_ITEM_BY_DISH_ID';
export const SET_TIP = 'cart/SET_TIP';
export const UPDATE_CART = 'cart/UPDATE_CART';
export const SET_AUTO_PROMOCODE = 'cart/SET_AUTO_PROMOCODE';
export const SET_PROMOCODE = 'cart/SET_PROMOCODE';
export const REMOVE_PROMOCODE = 'cart/REMOVE_PROMOCODE';
export const CLEAR_CART = 'cart/CLEAR_CART';
export const FINISH_ORDER = 'cart/FINISH_ORDER';
export const SET_CART_ITEMS = 'cart/SET_CART_ITEMS';
export const APPEND_CART_ITEMS = 'cart/APPEND_CART_ITEMS';
const SET_SPECIAL_INSTRUCTIONS = 'cart/SET_SPECIAL_INSTRUCTIONS';
const SET_PAYMENT_METHOD_CASH = 'cart/SET_PAYMENT_METHOD_CASH';
const SET_PAYMENT_METHOD_CARD = 'cart/SET_PAYMENT_METHOD_CARD';
const SET_REDEEM_CURRENCY = 'cart/SET_REDEEM_CURRENCY';

// ---
// ACTION CREATORS
// ---
export const addItem = createAction(ADD_ITEM, payload => ({
  ...payload,
  id: uuidv4(),
}));
export const setCartItems = createAction(SET_CART_ITEMS);
export const appendCartItems = createAction(APPEND_CART_ITEMS);
export const updateItem = createAction(UPDATE_ITEM);
const removeItemId = createAction(REMOVE_ITEM);
const markItemRemoving = createAction(MARK_ITEM_REMOVING);
export const removeItemByDishId = createAction(REMOVE_ITEM_BY_DISH_ID);
export const setTip = createAction(SET_TIP);
export const updateCart = createAction(UPDATE_CART);
const setAutoPromocode = createAction(SET_AUTO_PROMOCODE);
export const setPromocode = createAction(SET_PROMOCODE);
export const removePromocode = createAction(REMOVE_PROMOCODE);
export const clearCart = createAction(CLEAR_CART);
const finishOrder = createAction(FINISH_ORDER);
export const setSpecialInstructions = createAction(SET_SPECIAL_INSTRUCTIONS);
const setPaymentMethodCash = createAction(SET_PAYMENT_METHOD_CASH);
const setPaymentMethodCard = createAction(SET_PAYMENT_METHOD_CARD);
const setRedeemCurrency = createAction(SET_REDEEM_CURRENCY);

const promocodeValidate = (
  body,
  { silent = false, noSpinner = false } = {},
) => dispatch => {
  if (!silent && !noSpinner) {
    dispatch(spinnerActions.showSpinner());
  }
  wretches.promocodeValidate
    .json(decamelizeKeys(body))
    .post()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      if (data.status) {
        dispatch(
          setPromocode({
            promocode: body.coupon,
            promocodeAction: { type: data.type, amount: data.amount },
          }),
        );
      }
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      if (!silent) {
        inform({
          title: 'Oops...',
          message: err.json.message,
          buttonText: 'OK',
        });
      }
      dispatch(removePromocode());
    });
};

const initialState = {
  items: {},
  tip: undefined,
  autoPromocode: null,
  promocode: null,
  promocodeAction: {},
  redeemCurrency: null,
  specialInstructions: '',
  paymentMethod: 'CARD',
};

const getItemsWithoutDuplicates = (existingItems, incomingItems) =>
  // Don't add identical items. Increase existing.
  reduce(
    incomingItems,
    (acc, incomingItem) => {
      const pickIdentityFields = item =>
        pick(item, ['dishId', 'specialInstructions', 'variations']);
      const item = find(acc, itemToCompare =>
        isEqual(
          pickIdentityFields(incomingItem),
          pickIdentityFields(itemToCompare),
        ),
      );
      return {
        ...acc,
        ...(item
          ? {
              [item.id]: {
                ...item,
                count: item.count + incomingItem.count,
              },
            }
          : { [incomingItem.id]: incomingItem }),
      };
    },
    existingItems,
  );

const removeItem = id => dispatch => {
  dispatch(markItemRemoving(id));
  setTimeout(() => {
    dispatch(removeItemId(id));
  }, 1000);
};

export default handleActions(
  {
    [ADD_ITEM]: (state, action) => ({
      ...state,
      items: getItemsWithoutDuplicates(state.items, {
        newItem: action.payload,
      }),
      persistExpiresAt: setReduxPersistTimeout(),
    }),
    [SET_CART_ITEMS]: (state, action) => ({
      ...state,
      items: action.payload,
      persistExpiresAt: setReduxPersistTimeout(),
    }),
    [APPEND_CART_ITEMS]: (state, action) => ({
      ...state,
      items: getItemsWithoutDuplicates(state.items, action.payload),
      persistExpiresAt: setReduxPersistTimeout(),
    }),
    [UPDATE_ITEM]: (state, action) => ({
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: {
          ...state.items[action.payload.id],
          ...action.payload,
        },
      },
    }),
    [MARK_ITEM_REMOVING]: (state, action) => ({
      ...state,
      items: {
        ...state.items,
        [action.payload]: {
          ...state.items[action.payload],
          removing: true,
        },
      },
    }),
    [REMOVE_ITEM]: (state, action) => ({
      ...state,
      items: omit(state.items, [action.payload]),
    }),
    [REMOVE_ITEM_BY_DISH_ID]: (state, action) => ({
      ...state,
      items: omitBy(state.items, ({ dishId }) =>
        action.payload.includes(dishId),
      ),
    }),
    [SET_TIP]: (state, action) => ({
      ...state,
      tip: action.payload,
    }),
    [UPDATE_CART]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [SET_AUTO_PROMOCODE]: (state, action) => ({
      ...state,
      autoPromocode: action.payload,
    }),
    [SET_PROMOCODE]: (state, { payload: { promocode, promocodeAction } }) => ({
      ...state,
      promocode,
      promocodeAction,
    }),
    [REMOVE_PROMOCODE]: state => ({
      ...state,
      promocode: null,
      promocodeAction: {},
    }),
    [FINISH_ORDER]: state => ({
      ...state,
      ...initialState,
    }),
    [CLEAR_CART]: state => ({
      ...state,
      items: [],
    }),
    [SET_SPECIAL_INSTRUCTIONS]: (state, action) => ({
      ...state,
      specialInstructions: action.payload,
    }),
    [SET_PAYMENT_METHOD_CASH]: state => ({
      ...state,
      paymentMethod: 'CASH',
    }),
    [SET_PAYMENT_METHOD_CARD]: state => ({
      ...state,
      paymentMethod: 'CARD',
    }),
    [SET_REDEEM_CURRENCY]: (state, action) => ({
      ...state,
      redeemCurrency: action.payload,
    }),
  },
  initialState,
);

export const actions = {
  addItem,
  setCartItems,
  appendCartItems,
  updateItem,
  removeItem,
  removeItemByDishId,
  setTip,
  updateCart,
  setPromocode,
  removePromocode,
  clearCart,
  finishOrder,
  setSpecialInstructions,
  setPaymentMethodCash,
  setPaymentMethodCard,
  promocodeValidate,
  setAutoPromocode,
  setRedeemCurrency,
};
