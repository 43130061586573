import wretch from 'wretch';
import { SUBDOMAIN, MOLTENCORE_HOST } from 'utils/hostname';

const API_ROOT_URL = `//api-${SUBDOMAIN}.foodieordersecure.com/public/v1`;

const MOLTENCORE_API_ROOT_URL = `//${MOLTENCORE_HOST}/api`;
const authToken = localStorage.getItem('authTokenDineIn');
const wretchBase = wretch().errorType('json');

const moltencoreApiRoot = wretchBase.url(MOLTENCORE_API_ROOT_URL);

const apiRoot = wretchBase.url(API_ROOT_URL);
const addressValidate = apiRoot.url('/delivery/validate');
const general = apiRoot.url('/general');
const generalPromotions = apiRoot.url('/order/coupon');
const recoverPassword = apiRoot.url('/user/recover');
const requestSMSCode = apiRoot.url('/user/request_sms_code');
const setPassword = apiRoot.url('/user/password');
const userCreate = apiRoot.url('/user/create/');
const userLogin = apiRoot.url('/user/login');
const validateEmail = apiRoot.url('/user/validate_email');
const validateSMSCode = apiRoot.url('/user/validate_sms_code');
const checkoutDineIn = apiRoot.url('/orders');

const authenticatedApiRoot = apiRoot.auth(authToken && `Bearer ${authToken}`);
const checkout = authenticatedApiRoot.url('/orders');
const reorder = authenticatedApiRoot.url('/orders/reorder');
const favorites = authenticatedApiRoot.url('/favorites');
const ordersList = authenticatedApiRoot.url('/orders/list');
const payments = authenticatedApiRoot.url('/user/payments');
const paymentsMakeDefault = authenticatedApiRoot.url('/user/payments/default');
const promocodeValidate = authenticatedApiRoot.url('/order/coupon/validate');
const promotions = authenticatedApiRoot.url('/order/coupon');
const userAddresses = authenticatedApiRoot.url('/user/addresses/');
const userInfo = authenticatedApiRoot.url('/user/login');
const userUpdate = authenticatedApiRoot.url('/user/update');

const foc = moltencoreApiRoot.url(`/foc`);

export default {
  authenticatedApiRoot,
  addressValidate,
  apiRoot,
  checkout,
  checkoutDineIn,
  foc,
  favorites,
  general,
  ordersList,
  generalPromotions,
  payments,
  paymentsMakeDefault,
  promocodeValidate,
  promotions,
  recoverPassword,
  reorder,
  requestSMSCode,
  setPassword,
  userAddresses,
  userCreate,
  userInfo,
  userLogin,
  userUpdate,
  validateEmail,
  validateSMSCode,
};
