import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LocationCard = ({
  name,
  address,
  url,
  distance: { text: distanceText },
}) => (
  <Wrapper
    onClick={() => {
      window.location.href = url;
    }}
    aria-label={name}
  >
    <div>
      <Name>{name}</Name>
      {distanceText && <Distance>{`(${distanceText} away)`}</Distance>}
    </div>
    <Address>{address}</Address>
  </Wrapper>
);

LocationCard.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  distance: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.number,
  }),
};

LocationCard.defaultProps = {
  distance: { value: 0, text: '' },
};

const Wrapper = styled.button`
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 25px;
`;

const Name = styled.div`
  display: inline-block;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Distance = styled.div`
  display: inline-block;
  font-size: 15px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  font-weight: lighter;
  margin-left: 8px;
`;

const Address = styled.div`
  font-size: 15px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  font-weight: lighter;
  margin-top: 10px;
`;

export default LocationCard;
