import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';
import get from 'lodash/get';
import values from 'lodash/values';

import ModeManagerHOC, { modes } from 'HOC/ModeManager';
import ModalLayout from './ModalLayout';

export const AppLayout = ({
  mode,
  children,
  props,
  className,
  ...bothProps
}) => {
  const mobileProps = get(props, 'mobile', {});
  const webProps = get(props, 'web', {});
  switch (mode) {
    case modes.CORDOVA_MODE:
      return (
        <ModalLayout className={className} {...bothProps} {...webProps}>
          {children}
        </ModalLayout>
      );
    case modes.MOBILE_MODE:
      return (
        <AppContent className={className} {...bothProps} {...mobileProps}>
          {children}
        </AppContent>
      );
    default:
      return (
        <ModalLayout className={className} {...bothProps} {...webProps}>
          {children}
        </ModalLayout>
      );
  }
};

AppLayout.propTypes = {
  mode: PropTypes.oneOf(values(modes)),
  children: PropTypes.node,
  props: PropTypes.object,
  className: PropTypes.string,
};

AppLayout.defaultProps = {
  mode: modes.MOBILE_MODE,
};

export default ModeManagerHOC(AppLayout);

export const AppContent = styled.div`
  height: 100%;
  > div:not([class]) {
    height: 100%;
  }
`;

export const AppScroll = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;

  ${media.tablet`
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    height: 100%;
  `} ${media.desktop`
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    height: 100%;
  `};
`;
