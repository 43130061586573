import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import startsWith from 'lodash/startsWith';
import EntrypointViewHOC from 'routes/EntrypointView';
import AnimatedWrapper from 'routes/AnimatedWrapper';
import ModalSwitch from 'components/ModalSwitch';
import Idle from 'react-idle';
import Menu from './Menu';
import Orders from './Orders';
import MyAccount from './MyAccount';
import Promotions from './Promotions';
import CheckoutPage from './Checkout';
import ThankyouDineIn from './ThankyouDineIn';

import { getTime } from 'utils/timeHelpers';

import AppLayout from 'layouts/AppLayout';
import Spinner from 'components/Spinner';

import RouterTabView, {
  RouterTabItem,
  RouterTabBar,
} from 'components/RouterTabView';

import {
  Flavors,
  AddNewAddress,
  DishDetails,
  ReviewOrder,
  Checkout,
  PromotionsModal,
  OrdersModal,
  FavoritesModal,
  MyAddresses,
  SecurityModal,
  PaymentsModal,
  Promocode,
} from './Modals';
import ReduxModalProvider from 'components/ReduxModal';
import UrlSetupHelper from 'HOC/UrlSetupHelper';
import SmartlookHelper from 'HOC/SmartlookHelper';
import { IDLE_TIMEOUT } from 'utils/constants';
import ForgotEmail from 'routes/Modals/ForgotEmail';
import ForgotPassword from 'routes/Modals/ForgotPassword';
import TermsOfUse from 'routes/Modals/TermsOfUse';
import PrivacyPolicy from 'routes/Modals/PrivacyPolicy';
import RecoverPassword from 'routes/Modals/RecoverPassword';
import SignIn from 'routes/Modals/SignIn';
import Register from 'routes/Modals/Register';
import { MyRewards } from 'routes/Modals/MyRewards';
import deliveryDaySelector from 'selectors/deliveryDaySelector';
// import IconsDemo from "components/IconsDemo";

const MenuWithNavigation = ({
  deliveryDay,
  deliveryTime,
  deliveryType,
  match,
  ...restProps
}) => <Menu {...restProps} />;

MenuWithNavigation.propTypes = {
  deliveryDay: PropTypes.string,
  deliveryTime: PropTypes.string,
  deliveryType: PropTypes.string,
  match: PropTypes.object,
};

const MenuWithNamvigationContainer = connect(state => ({
  deliveryDay: deliveryDaySelector(state),
  deliveryTime: getTime(state.delivery.scheduleTime),
  deliveryType: state.delivery.type,
}))(MenuWithNavigation);

const TabView = ({ goToLanding, location: { pathname } = {} }) => (
  // <RouterTabView defaultPath="/locations">
  <RouterTabView defaultPath="/">
    <RouterTabItem path="/my-account">
      <MyAccount />
    </RouterTabItem>
    <RouterTabItem path="/orders">
      <Orders />
    </RouterTabItem>
    <RouterTabItem path="/ThankyouDineIn">
      <ThankyouDineIn />
    </RouterTabItem>
    {/* <RouterTabItem path="/iconsdemo"> */}
    {/* <IconsDemo /> */}
    {/* </RouterTabItem> */}
    <RouterTabItem path="/promotions">
      <Promotions />
    </RouterTabItem>
    <RouterTabItem path="/menu/:show?">
      <MenuWithNamvigationContainer />
    </RouterTabItem>
    <RouterTabItem path="/checkout">
      <CheckoutPage />
    </RouterTabItem>
    <RouterTabItem path="/">
      <AnimatedWrapper />
    </RouterTabItem>
    {/* <RouterTabItem path="/"> */}
    {/* <Delivery /> */}
    {/* </RouterTabItem> */}

    {/* TAB BAR ITEMS */}
    {['/menu', '/my-account/favorites', '/orders/thank-you'].every(
      url => !startsWith(pathname, url),
    ) && <RouterTabBar />}
  </RouterTabView>
);

const ConnectedTabView = EntrypointViewHOC(TabView);

export default () => (
  <AppLayout props={{ web: { onTouchMove: e => e.preventDefault() } }}>
    <Fragment>
      <Idle
        timeout={IDLE_TIMEOUT}
        onChange={() => {
          window.location.href = '/';
        }}
      />
      <UrlSetupHelper />
      <SmartlookHelper />
      <ModalSwitch
        routes={[
          <Route path="/forgot-email" component={ForgotEmail} />,
          <Route path="/forgot-password" component={ForgotPassword} />,
          <Route path="/sign-in" component={SignIn} />,
          <Route path="/register/:phoneNumber?" component={Register} />,
          <Route path="/terms-of-use" component={TermsOfUse} />,
          <Route path="/privacy-policy" component={PrivacyPolicy} />,
          <Route path="/recover/:token" component={RecoverPassword} />,
          <Route path="/myrewards" component={MyRewards} />,
        ]}
      >
        <Route redirectTo="/" component={ConnectedTabView} />
      </ModalSwitch>
      <ReduxModalProvider>
        {activeModal => (
          <Fragment>
            <Flavors show={activeModal === 'flavors'} />
            <AddNewAddress show={activeModal === 'add-new-address'} />
            <DishDetails show={activeModal === 'dish-details'} />
            <ReviewOrder show={activeModal === 'review-order'} />
            <Checkout show={activeModal === 'checkout'} />
            <PromotionsModal show={activeModal === 'promotions'} />
            <OrdersModal show={activeModal === 'orders'} />
            <FavoritesModal show={activeModal === 'favorites'} />
            <MyAddresses show={activeModal === 'my-addresses'} />
            <SecurityModal show={activeModal === 'security'} />
            <PaymentsModal show={activeModal === 'payments'} />
            <Promocode show={activeModal === 'promocode'} />
          </Fragment>
        )}
      </ReduxModalProvider>
    </Fragment>
    <Spinner />
  </AppLayout>
);

export { default as history } from './history';
