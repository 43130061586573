import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navigation, { NavigationHeader } from 'components/Navigation';

import ScrollableContent from 'components/ScrollableContent';
import { ThankyouDineInHOC } from 'routes/Modals/containers/ThankyouDineIn';
import { media } from 'utils/mediaQueries';
import Icon from 'components/Icon';

const ThankyouDineIn = ({ modal, ThankyouDineIn,history }) => (
  <Navigation headerHeight={modal ? '0' : '50px'}>
    {!modal && <NavigationHeader>Order Created</NavigationHeader>}
    <ScrollableContent>
      <Content>
    <Container>
      <Greetings>
        <IconWrapper>
          <Icon name="icon-thank-you-small" size="145px" />
        </IconWrapper>
        <Text tabIndex={0}>Thank you!</Text>
      </Greetings>

      <Message>
        <SmallIconWrapper>
          <Icon name="icon-orders-small" size="28px" />
        </SmallIconWrapper>
        <Label tabIndex={0}>Order has been processed</Label>
      </Message>
    </Container>
    <ButtonWrapper>
      <Button onClick={() => history.push(`/`)}>START A NEW ORDER</Button>
    </ButtonWrapper>
  </Content>
    </ScrollableContent>
  </Navigation>
);

ThankyouDineIn.propTypes = {
  modal: PropTypes.bool,
  loyaltyEnabled: PropTypes.bool,
  points: PropTypes.number,
};

export default ThankyouDineInHOC(ThankyouDineIn);

const Content = styled.div`
  padding: 15px;
`;





const Container = styled.div`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Greetings = styled.div`
  padding: 35px 15px;
  border-bottom: 1px solid #eeefef;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto;
  color: #54a30a;
`;

const Text = styled.div`
  font-size: 23px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  text-align: center;
  margin-top: 25px;
`;

const Message = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 18px 15px;
`;

const SmallIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #97999b;
`;

const Label = styled.div`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  margin-left: 15px;
`;

const Button = styled.button`
  text-transform: uppercase;
  background: #54a300;
  color: #fff;
  border-radius: 2px;
  background-color: rgb(84, 163, 0);
  text-shadow: 1px 1px 1px #4a860c;
  padding: 17px 10px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px;
  text-align: center;
  transition: background 0.2s ease;

  ${media.tablet`
    cursor: pointer;
  `} ${media.desktop`
    &:hover {
      background: #67c70f;
    }
  `};
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
`;
