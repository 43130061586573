import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import range from 'lodash/range';
import keyBy from 'lodash/keyBy';

import { cartItemsSelector, actions as cartActions } from 'redux/modules/cart';
import cartItemsPriceSelector from 'selectors/cartItemsPrice';
import { actions as modalActions } from 'components/ReduxModal';
import itemsWithOverwrittenPriceSelector from 'selectors/itemsWithOverwrittenPriceSelector';

export const CartItemHOC = compose(
  connect(
    state => ({
      cartItems: cartItemsSelector(state),
      items: itemsWithOverwrittenPriceSelector(state),
      cartPrices: cartItemsPriceSelector(state),
    }),
    {
      removeItem: cartActions.removeItem,
      updateItem: cartActions.updateItem,
      showModal: modalActions.showModal,
    },
  ),
  withProps(({ id, cartItems, cartItem }) => ({
    cartItem: cartItem || get(cartItems, id, {}),
  })),
  withProps(({ cartItem, items }) => ({
    item: get(items, cartItem.dishId, {}),
  })),
  withProps(({ cartItem, item, id, cartPrices }) => ({
    // id: dishInfo.id,
    count: cartItem.count,
    name: item.title,
    price: get(cartPrices, id),
    specialInstructions: cartItem.specialInstructions,
    variations:
      (cartItem.variations && Object.values(cartItem.variations)) || [],
    quantityOptions: range(item.minCount, 21).map(number => ({
      label: number,
      value: number,
    })),
  })),
  withProps(({ variations, item: { variations: itemVariations } }) => {
    const nonPizzaVariations = variations.filter(
      v => v.type !== 'pizza_toppings',
    );
    const nonPizzaVariationsMap = keyBy(nonPizzaVariations, 'id');
    const nonPizzaSortedVariations =
      itemVariations
        ?.filter(id => Object.keys(nonPizzaVariationsMap).includes(String(id)))
        ?.map(id => nonPizzaVariationsMap[id]) || [];

    // Special case for pizzas:
    // Sort each option by side (LEFT, RIGHT, WHOLE) regardless of variation it belongs to
    const pizzaVariations = sortBy(
      [].concat(
        ...variations
          .filter(v => v.type === 'pizza_toppings')
          .map(({ value, ...rest }) =>
            Object.entries(value).map(([k, v]) => ({
              ...rest,
              value: { [k]: v },
            })),
          ),
      ),
      ({ value }) => Object.values(value)[0],
    );
    return {
      variations: [...nonPizzaSortedVariations, ...pizzaVariations],
    };
  }),
  withHandlers({
    onRemoveClick: ({ removeItem, id }) => () => {
      removeItem(id);
    },
    onEditClick: ({ id, showModal, readOnly }) => () => {
      if (!readOnly) {
        showModal('dish-details', { cartItemId: id });
      }
    },
    updateDish: ({ updateItem, id }) => value => {
      updateItem({ id, ...value });
    },
  }),
);

export default CartItemHOC;
