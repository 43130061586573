import { compose, withProps, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import sortBy from 'lodash/sortBy';
import qs from 'query-string';

import { generalSelector } from 'redux/modules/general';
import { autoPromocodeSelector } from 'redux/modules/cart';
import DistanceService from 'utils/DistanceService';
import Locations from '../components/Locations';

const LocationsHOC = compose(
  connect(state => ({
    general: generalSelector(state),
    autoPromocode: autoPromocodeSelector(state),
  })),
  withProps(({ general: { logo, locations, name }, autoPromocode }) => ({
    restaurantName: name,
    logo,
    locations: Object.values(locations).map(location => ({
      ...location,
      ...(autoPromocode && {
        url: `${location.url}?${qs.stringify({ coupon: autoPromocode })}`,
      }),
    })),
  })),
  withStateHandlers(
    {
      addressInputVisible: false,
      // addressInputValue: '',
      // locationPredictions: [],
      distances: [],

      userLocation: '',

      // showLocationInput: false,
      // locationText: '',
      // sortByDistance: false,
    },
    {
      showAddressInput: () => () => ({ addressInputVisible: true }),
      hideAddressInput: () => () => ({ addressInputVisible: false }),
      // setAddressInput: () => value => ({ addressInputValue: value }),
      setUserLocation: () => value => ({ userLocation: value }),
      test: ({ userLocation }) => () => ({ addressInputValue: userLocation }),
      setDistances: () => distances => ({ distances }),
    },
  ),
  withHandlers({
    handlePopUpOutsideClick: ({ hideAddressInput, test }) => () => {
      hideAddressInput();
      test();
      // locationText: this.props.userLocation,
    },

    // onGeolocationCompleted: geolocationUserPosition,
    // onPredictionClick: prediction => setUserPosition({ address: prediction }),

    handlePredictionClick: ({
      hideAddressInput,
      setUserLocation,
      locations,
      setDistances,
    }) => prediction => {
      hideAddressInput();
      setUserLocation(prediction);
      DistanceService.compute(
        prediction,
        Object.values(locations).map(({ address }) => address),
      ).then(result => {
        setDistances(result);
      });
    },
  }),
  withProps(
    ({ distances, locations }) =>
      distances.length && {
        locations: sortBy(
          locations.map((location, index) => ({
            ...location,
            distance: distances[index],
          })),
          'distance.value',
        ),
      },
  ),
);

export default LocationsHOC(Locations);
