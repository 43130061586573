import { createSelector } from 'reselect';
import { deliverySelector } from 'redux/modules/delivery';
import { selectedUserAddressSelector } from 'selectors/selectedUserAddressSelector';

const placeIdSelector = createSelector(
  [deliverySelector, selectedUserAddressSelector],
  ({ placeId }, selectedUserAddress) =>
    placeId || (selectedUserAddress && selectedUserAddress.placeId),
);

export default placeIdSelector;
