import { createSelector } from 'reselect';
import jwtDecode from 'jwt-decode';

export const userSelector = state => state.user;

export const tokenPayloadSelector = createSelector(
  userSelector,
  user => (user && user.token && jwtDecode(user.token)) || {},
);

export const firstNameSelector = createSelector(
  [userSelector, tokenPayloadSelector],
  ({ firstName }, { data }) =>
    firstName || (data && data.user && data.user.name) || '',
);

export const defaultAddressSelector = createSelector(
  userSelector,
  user => user.fulfilled && user.defaultAddress,
);

export const isUserLoggedInSelector = createSelector(
  userSelector,
  user => !!user.token,
);
