import { createSelector } from 'reselect';

const promotionsSelector = state => state.promotions;
export const promotionItemsSelector = createSelector(
  promotionsSelector,
  promotions => promotions.items,
);

export const loyaltySelector = createSelector(
  promotionsSelector,
  promotions => promotions.loyalty,
);

export const stampsSelector = createSelector(promotionsSelector, promotions =>
  promotions.stamps.map(stamp => ({
    ...stamp,
    timestamp: new Date(stamp.timestamp),
  })),
);
