import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import StaticMapService from 'utils/StaticMapService';

import Icon from 'components/Icon';
import { reformatAddress } from 'utils/reformatAddress';

const MAP_SIZE_X = 120;
const MAP_SIZE_Y = 400;

const MapAddress = ({
  address: {
    name,
    saveAs,
    aptSuiteFloor,
    place,
    address,
    deliveryAddress,
    fullAddress,
    city,
    zipcode,
    addressDetails,
    businessName,
    notes,
  },
  withIcon,
}) => {
  const mapService = new StaticMapService(MAP_SIZE_X, MAP_SIZE_Y);
  const addr = address || deliveryAddress || '';
  return (
    <Wrapper>
      <Information tabIndex={0}>
        <Title>
          {withIcon && (
            <SmallIconWrapper>
              <Icon name="icon-address-small" size="28px" />
            </SmallIconWrapper>
          )}
          <Name>{capitalize(name || saveAs)}</Name>
        </Title>
        <Description>
          <p>{reformatAddress(addr)[0]}</p>
          <p>{reformatAddress(addr)[1]}</p>
        </Description>
        <Description>{addressDetails}</Description>
        {aptSuiteFloor && (
          <Fragment>
            <Label>Apt / Floor / Suite:</Label>
            <Description>{aptSuiteFloor}</Description>
          </Fragment>
        )}
        {businessName && (
          <Fragment>
            <Label>Business name:</Label>
            <Description>{businessName}</Description>
          </Fragment>
        )}
        {notes && <Description>{notes}</Description>}
      </Information>
      <MapWrapper>
        <img
          alt="Your position"
          src={mapService.getPicture(fullAddress || address || deliveryAddress)}
        />
      </MapWrapper>
    </Wrapper>
  );
};

MapAddress.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    id: PropTypes.number,
    placeId: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }),
  withIcon: PropTypes.bool,
};

MapAddress.defaultProps = {
  address: {},
  withIcon: false,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background: #fff;
`;

const Information = styled.div`
  flex: 1 1 auto;
  padding: 15px 15px;
`;

const SmallIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #97999b;
  margin-right: 15px;
`;

const Title = styled.div`
  display: flex;
`;

const Name = styled.div`
  font-size: 17px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const MapWrapper = styled.div`
  position: relative;
  max-height: 240px;
  width: 120px;
  > img {
    position: absolute;
    object-fit: none;
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #747778;
  margin-top: 6px;
  font-weight: 300;
  line-height: 19px;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
`;

export const styles = {
  Wrapper,
  Information,
  Name,
  MapWrapper,
  Description,
};

export default MapAddress;
