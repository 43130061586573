import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import enterFirstLastNameHOC from 'HOC/enterFirstLastNameHOC';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import AccountDropdown from 'components/AccountDropdown';
import InvisibleSubmit from 'components/InvisibleSubmit';
import ModalComponents from './ModalComponents';

const EnterFirstLastName = ({
  title,
  phoneNumber,
  values: { firstName, lastName },
  errors,
  touched,
  handleBlur,
  handleSubmit,
  onFirstNameFieldChange,
  onLastNameFieldChange,
  resetNumberForm,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <ModalComponents.SubTitle>
        <p>This helps show that this account</p>
        <p>really belongs to you</p>
      </ModalComponents.SubTitle>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AccountDropdown
          value={phoneNumber}
          options={[phoneNumber]}
          onLoginChange={resetNumberForm}
        />
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <TextField
              label="Enter your First Name"
              name="firstName"
              value={firstName}
              onChange={onFirstNameFieldChange}
              onBlur={handleBlur}
              helperText={touched.firstName && errors.firstName}
              error={touched.firstName && errors.firstName}
              fullWidth
              autoFocus={autoFocus}
            />
          )}
        </AutoFocusIfNonTouchDevice>{' '}
        <TextField
          label="Enter your Last Name"
          name="lastName"
          value={lastName}
          onChange={onLastNameFieldChange}
          onBlur={handleBlur}
          helperText={touched.lastName && errors.lastName}
          error={touched.lastName && errors.lastName}
          fullWidth
        />
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Next
          </Button>
        </ModalComponents.FormButton>
        <InvisibleSubmit />
      </ModalComponents.Form>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

EnterFirstLastName.propTypes = {
  title: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onFirstNameFieldChange: PropTypes.func.isRequired,
  onLastNameFieldChange: PropTypes.func.isRequired,
  resetNumberForm: PropTypes.func.isRequired,
};

export default enterFirstLastNameHOC(EnterFirstLastName);
