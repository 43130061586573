import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Link, Route, withRouter } from 'react-router-dom';

import Icon from 'components/Icon';
import goToLandingAction from 'redux/modules/user/goToLanding';
import { landingUrlSelector } from 'redux/modules/general';

const HomeTabBarButton = ({ goToLanding, landingUrl }) => (
  <Route path="/">
    {({ match }) => (
      <TabBarItemLink to="/" onClick={goToLanding}>
        <IconWrapper active={!!match.isExact && !landingUrl}>
          <Icon name="icon-locations-small" size="34px" />
        </IconWrapper>
        <Label active={!!match.isExact && !landingUrl}>
          {landingUrl ? 'Locations' : 'Home'}
        </Label>
      </TabBarItemLink>
    )}
  </Route>
);

HomeTabBarButton.propTypes = {
  landingUrl: PropTypes.string,
  goToLanding: PropTypes.func.isRequired,
};

HomeTabBarButton.defaultProps = {
  landingUrl: undefined,
};

const TabBarItemLink = styled(Link)`
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 5px 10px;
  text-decoration: none;
`;

const Label = styled.div`
  text-align: center;
  font-size: 11px;
  font-family: 'Roboto';
  color: ${({ active }) =>
    active ? 'rgba(52, 155, 52, 0.549)' : 'rgba(66, 70, 72, 0.549)'};
  margin-top: 6px;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: ${({ active }) =>
    active ? 'rgba(52, 155, 52, 0.549)' : 'rgba(66, 70, 72, 0.549)'};
`;

export default withRouter(
  connect(
    state => ({
      landingUrl: landingUrlSelector(state),
    }),
    { goToLanding: goToLandingAction },
  )(HomeTabBarButton),
);
