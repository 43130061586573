import { createSelector } from 'reselect';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';

export const deliveryTypeCashButtonFormattedSelector = createSelector(
  [getDeliveryType, dineInEnabledSelector],
  (deliveryType, dineInEnabled) =>
    ({
      [true]: '',
      [deliveryType === 'DELIVERY']: 'Cash on Delivery',
      [deliveryType === 'PICKUP']: 'Cash on Pickup',
      [deliveryType === 'PICKUP' && dineInEnabled]: 'Cash',
    }.true),
);
