import React from 'react';
import PropTypes from 'prop-types';
import SelectDeliveryAddress from 'routes/Delivery/components/SelectDeliveryAddress';

const AddNewAddress = ({ show, addNewAddress, closeModal }) => (
  <SelectDeliveryAddress
    show={show}
    onCloseClick={closeModal}
    onDoneClick={addNewAddress}
  />
);
// /* <Modal title="Add a New Address" show={show} onCloseClick={closeModal}>
//     <NewAddress
//       isFavoriteRequired
//       onDoneClick={payload => {
//         addAddress(payload);
//         closeModal();
//       }}
//     /> */

AddNewAddress.propTypes = {
  show: PropTypes.bool,
  addNewAddress: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

AddNewAddress.defaultProps = {
  show: false,
};

export default AddNewAddress;
