import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Overlay = ({ children, onClick, zIndex, inPlace }) => {
  const Component = (
    <OverlayStyled onClick={onClick} zIndex={zIndex}>
      {children}
    </OverlayStyled>
  );
  return inPlace
    ? Component
    : createPortal(Component, document.getElementById('overlay'));
};

Overlay.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
  inPlace: PropTypes.bool,
};

Overlay.defaultProps = {
  children: undefined,
  onClick: undefined,
  zIndex: 9998,
  inPlace: false,
};

const OverlayStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${p => p.zIndex};
  content: '';
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
`;

export default Overlay;
