// Just simple icon unlike relatively positioned Icon component

import styled from 'styled-components';

const IconClean = styled.i.attrs({
  className: ({ name }) => name,
  'aria-hidden': true,
})`
  font-size: ${({ size }) => size};
`;

export default IconClean;
