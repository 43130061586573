import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DayPicker from 'components/OrderScheduleDateTimePicker/DayPicker';
import TimePicker from 'components/OrderScheduleDateTimePicker/TimePicker';
import CalendarDayPicker from 'components/OrderScheduleDateTimePicker/CalendarDayPicker';
import Spacer from 'components/OrderScheduleDateTimePicker/Spacer';
import { deliveryTimeTypeTimePickerHOC } from 'HOC/deliveryTimeTypeTimePickerHOC';

const DeliveryTimeTypeTimePickerComponent = ({
  deliveryType,
  dayPickerValue,
  dayPickerOptions,
  setDayPickerValue,
  timePickerValue,
  timePickerOptions,
  setTimePickerValue,
  calendarVisible,
  hideCalendar,
  showCalendar,
  spacerVisible,
  hideSpacer,
  showSpacer,
  cantinaEnabled,
  cantinaWeek,
}) => (
  <React.Fragment>
    {cantinaEnabled ? (
      <Bottom>
        <InputWrapper>
          <Week>
            {cantinaWeek?.start} - {cantinaWeek?.stop}
          </Week>
        </InputWrapper>
      </Bottom>
    ) : (
      <Bottom>
        <SelectWrapper>
          <DayPicker
            value={dayPickerValue}
            options={dayPickerOptions}
            onChange={setDayPickerValue}
            hideCalendar={hideCalendar}
            showCalendar={showCalendar}
          />
        </SelectWrapper>

        <InputWrapper>
          <TimePicker
            value={timePickerValue}
            options={timePickerOptions}
            onChange={setTimePickerValue}
            hideSpacer={hideSpacer}
            showSpacer={showSpacer}
            deliveryType={deliveryType}
          />
        </InputWrapper>
      </Bottom>
    )}
    {calendarVisible && (
      <CalendarDayPicker
        value={dayPickerValue}
        options={dayPickerOptions}
        onChange={setDayPickerValue}
        hideCalendar={hideCalendar}
        showCalendar={showCalendar}
      />
    )}
    {spacerVisible && <Spacer />}
  </React.Fragment>
);

DeliveryTimeTypeTimePickerComponent.propTypes = {
  dayPickerValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
    disabled: PropTypes.bool,
  }),
  dayPickerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.object,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  setDayPickerValue: PropTypes.func.isRequired,
  timePickerValue: PropTypes.shape({
    value: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  timePickerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.object,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  setTimePickerValue: PropTypes.func.isRequired,
  calendarVisible: PropTypes.bool.isRequired,
  hideCalendar: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  spacerVisible: PropTypes.bool.isRequired,
  hideSpacer: PropTypes.func.isRequired,
  showSpacer: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  cantinaEnabled: PropTypes.bool.isRequired,
  cantinaWeek: PropTypes.string,
};

DeliveryTimeTypeTimePickerComponent.defaultProps = {
  dayPickerValue: undefined,
  timePickerValue: undefined,
  cantinaWeek: undefined,
};

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const InputWrapper = styled.div`
  flex: 1;
  border-top: 1px solid #eeefef;
  position: relative;
`;

const SelectWrapper = styled.div`
  flex-basis: 45%;
  border-top: 1px solid #eeefef;
  border-right: 1px solid #eeefef;
  position: relative;
  height: 46px;
`;

const Week = styled.div`
  padding: 14px 0 14px 44px;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: lighter;
`;

export const DeliveryTimeTypeTimePicker = deliveryTimeTypeTimePickerHOC(
  DeliveryTimeTypeTimePickerComponent,
);
