import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';

const orderEstimatesHOC = compose(
  connect(state => ({
    preparationTime: asapPreparationTimeSelector(state),
    deliveryType: getDeliveryType(state),
  })),
  withProps(({ deliveryType, preparationTime }) => ({
    estimateText: {
      [deliveryType ===
      'PICKUP']: `Approx. ${preparationTime} minutes after your order is placed`,
      [deliveryType === 'DELIVERY']: `Arrives under ${preparationTime} minutes`,
    }.true,
  })),
);

export default orderEstimatesHOC;
