import React from 'react';
import PropTypes from 'prop-types';

const RouterTabItem = ({ children, history, location, match }) =>
  React.cloneElement(children, { history, location, match });

RouterTabItem.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default RouterTabItem;
