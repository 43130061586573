import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

const Header = ({ logoUrl, restaurantName }) => (
  <Wrapper>
    <Logo logoUrl={logoUrl} aria-label={restaurantName} tabIndex={0} />
  </Wrapper>
);

Header.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  restaurantName: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  padding: 20px 0;
  background: #fff;
  width: 100%;
  display: flex;
  fex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  ${media.desktop`
    padding: 14px 0;
  `};
`;

const Logo = styled.div`
  width: 220px;
  height: 60px;
  background: url('${p => p.logoUrl}') no-repeat center / contain;

  ${media.desktop`
    width: 195px;
  `}
`;

export default Header;
