import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import RadioButton from 'components/RadioButton';
import PaymentMethodFormHOC from 'HOC/PaymentMethodForm';

import Icon from 'components/Icon';
import CardIcon from './components/CardIcon';

const PaymentMethod = ({
  selectedPayment,
  cardMethodActive,
  cashMethodActive,
  setCardMethod,
  setCashMethod,
  showModalPayments,
  cashEnabled,
  creditCardEnabled,
  cashButtonName,
}) => (
  <Wrapper>
    <Top>
      {creditCardEnabled && (
        <Radio>
          <RadioButton
            name="Credit Card"
            active={cardMethodActive}
            onClick={setCardMethod}
            disabled={!creditCardEnabled}
          />
        </Radio>
      )}
      {cashEnabled && (
        <Radio>
          <RadioButton
            name={cashButtonName}
            active={cashMethodActive}
            onClick={setCashMethod}
            disabled={!cashEnabled}
          />
        </Radio>
      )}
    </Top>
    {cardMethodActive && (
      <Bottom>
        {selectedPayment ? (
          <Fragment>
            <CardIcon brand={selectedPayment.type} />
            <InputWrapper>
              <HiddenNumber>····</HiddenNumber>
              <Number>{selectedPayment.last4}</Number>
            </InputWrapper>
            <Label onClick={showModalPayments}>CHANGE</Label>
          </Fragment>
        ) : (
          <AddNewCardButton onClick={showModalPayments}>
            <IconWrapper>
              <Icon name="icon-credit-card-small" size="36px" />
            </IconWrapper>
            <span>Add new card</span>
          </AddNewCardButton>
        )}
      </Bottom>
    )}
  </Wrapper>
);

PaymentMethod.propTypes = {
  selectedPayment: PropTypes.shape({
    id: PropTypes.number,
    last4: PropTypes.string,
    expiration: PropTypes.string,
    type: PropTypes.string,
  }),
  cashEnabled: PropTypes.bool.isRequired,
  creditCardEnabled: PropTypes.bool.isRequired,
  cashButtonName: PropTypes.string.isRequired,
  cardMethodActive: PropTypes.bool.isRequired,
  cashMethodActive: PropTypes.bool.isRequired,
  setCardMethod: PropTypes.func.isRequired,
  setCashMethod: PropTypes.func.isRequired,
  showModalPayments: PropTypes.func.isRequired,
};

PaymentMethod.defaultProps = {
  selectedPayment: undefined,
};

const Wrapper = styled.div`
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Radio = styled.div`
  flex: 1 1 auto;
  &:not(:first-child) {
    border-left: 1px solid #eeefef;
  }
  ${media.tablet`cursor: pointer;`} ${media.desktop`cursor: pointer;`};
`;

const Bottom = styled.div`
  padding: 15px;
  border-top: 1px solid #eeefef;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-height: 53px;
`;

// const Icon = styled.div`
//   min-width: 40px;
//   min-height: 15px;
//   // background: url(${visaIcon}) no-repeat center / contain;
// `

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  padding: 0 15px;
  align-items: center;
`;

const HiddenNumber = styled.span`
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  max-width: 55px;
  outline: none;
  border: none;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
`;

const Number = styled.span`
  max-width: 55px;
  outline: none;
  border: none;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  margin-left: 5px;
  margin-right: 5px;
`;

const Label = styled.button`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  text-align: right;
  cursor: pointer;
`;

const AddNewCardButton = styled.button`
  margin-left: -9px;
  font-style: italic;
  color: rgb(116, 121, 122);
  cursor: pointer;
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 15px;
  display: inline-block;
  margin-right: 6px;
`;

export default PaymentMethodFormHOC(PaymentMethod);
