import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';

const ModalHeader = ({ onCloseClick, children, smallPaddings }) => (
  <Header sm={smallPaddings}>
    <Close onClick={onCloseClick}>
      <Icon name="icon-cross-thin-small" size="34px" />
    </Close>
    <CloseMobile onClick={onCloseClick}>
      <Icon name="icon-cross-small" size="24px" />
    </CloseMobile>
    <Heading>{children}</Heading>
  </Header>
);

ModalHeader.propTypes = {
  onCloseClick: PropTypes.func,
  children: PropTypes.node,
  smallPaddings: PropTypes.bool,
};

export default ModalHeader;

const Header = styled.div`
  background: #fff;
  box-shadow: 1px 1px 3px 0px #e6e7e8;
  padding: ${({ sm }) => (sm ? '11px 15px' : '20px 15px')};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 11000;
  transform: translate3d(0, 0, 0);
`;

const Close = styled.button`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;

  ${media.tablet`
    display: none;
  `};
`;

const Heading = styled.span`
  text-align: center;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.2px;
`;

const CloseMobile = styled.button`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  display: none;
  cursor: pointer;

  ${media.tablet`
    display: block;
  `};
`;
