import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioButton = ({ name, active, additionalPrice, disabled, onClick }) => (
  <Wrapper onClick={disabled ? undefined : onClick}>
    <Circle active={active} />
    {name && (
      <Name active={active} disabled={disabled}>
        {name}
      </Name>
    )}
    {additionalPrice && <Price> + ${additionalPrice} </Price>}
  </Wrapper>
);

RadioButton.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalPrice: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  name: '',
  active: false,
  disabled: false,
  additionalPrice: undefined,
};

const Wrapper = styled.button`
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
`;

const Circle = styled.div`
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  border: ${({ active }) => (active ? 'none' : '2px solid #929292')};
  background: ${({ active }) => (active ? '#54a300' : 'transparent')};
`;

const Name = styled.div`
  margin-left: 14px;
  flex: 1 1 auto;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: ${p => (p.disabled ? '#BDBDBD' : 'rgb(66, 70, 72)')};
  font-weight: ${({ active }) => (active ? '400' : '300')};
`;

const Price = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

export default RadioButton;
