import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import forgotEmailHOC from 'HOC/forgotEmailHOC';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import ModalComponents from './ModalComponents';

const ForgotEmail = ({
  title,
  values: { phoneNumberField },
  errors,
  touched,
  handleBlur,
  handleSubmit,
  onPhoneNumberFieldChange,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <TextField
              label="Enter your Mobile Number"
              value={phoneNumberField}
              name="phoneNumberField"
              onChange={onPhoneNumberFieldChange}
              onBlur={handleBlur}
              helperText={touched.phoneNumberField && errors.phoneNumberField}
              error={touched.phoneNumberField && errors.phoneNumberField}
              fullWidth
              autoFocus={autoFocus}
            />
          )}
        </AutoFocusIfNonTouchDevice>
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Next
          </Button>
        </ModalComponents.FormButton>
      </ModalComponents.Form>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

ForgotEmail.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.shape({
    phoneNumberField: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({ phoneNumberField: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ phoneNumberField: PropTypes.bool }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onPhoneNumberFieldChange: PropTypes.func.isRequired,
};

export default forgotEmailHOC(ForgotEmail);
