import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navigation, { NavigationHeader } from 'components/Navigation';

import ScrollableContent from 'components/ScrollableContent';

import PaymentForm from 'components/PaymentForm';
import Payment from './components/Payment';

const Payments = ({
  payments,
  modal,
  deletePaymentId,
  makeDefaultPaymentId,
  selectPaymentId,
  expandedPayment,
  expandPaymentId,
  selectorTouched,
  paymentsSelectable,
}) => (
  <Navigation headerHeight={!modal.name ? '50px' : '0'}>
    {!modal.name && <NavigationHeader>Payments</NavigationHeader>}
    <ScrollableContent>
      <Content isModal={modal.name}>
        <Label>Add a new payment</Label>
        <PaymentForm />
        {payments.map(payment => (
          <PaymentWrapper key={payment.id}>
            <Payment
              id={payment.id}
              def={payment.defaultPayment}
              selected={+expandedPayment === +payment.id}
              last4={payment.last4}
              expiration={payment.expiration}
              type={payment.type}
              name={payment.name}
              expanded={+expandedPayment === +payment.id}
              expand={expandPaymentId(payment.id)}
              deletePayment={deletePaymentId(payment.id)}
              makeDefaultPayment={makeDefaultPaymentId(payment.id)}
              selectPayment={selectPaymentId(payment.id)}
              selectorTouched={selectorTouched}
              paymentsSelectable={paymentsSelectable}
            />
          </PaymentWrapper>
        ))}
      </Content>
    </ScrollableContent>
  </Navigation>
);

Payments.propTypes = {
  modal: PropTypes.shape({
    name: PropTypes.string,
  }),
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      defaultPayment: PropTypes.bool,
      selectedPayment: PropTypes.bool,
      last4: PropTypes.string,
      expiration: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  deletePaymentId: PropTypes.func.isRequired,
  makeDefaultPaymentId: PropTypes.func.isRequired,
  selectPaymentId: PropTypes.func.isRequired,
  expandedPayment: PropTypes.number,
  expandPaymentId: PropTypes.func.isRequired,
  selectorTouched: PropTypes.bool.isRequired,
  paymentsSelectable: PropTypes.bool,
};

Payments.defaultProps = {
  modal: undefined,
  payments: [],
  expandedPayment: undefined,
  paymentsSelectable: false,
};

export default Payments;

const Content = styled.div`
  padding: 15px;
  width: 100%;
  margin: ${({ isModal }) => (isModal ? '0 auto' : '20px auto 0 auto')};
`;

const PaymentWrapper = styled.div`
  border-radius: 2px;

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const Label = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(130, 134, 136);
  font-style: italic;
  margin: 0 0 8px 0;
`;
