import React from 'react';
import styled from 'styled-components';

const SoldOut = () => <SoldOutStyled>SOLD OUT</SoldOutStyled>;

const SoldOutStyled = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: rgb(122, 126, 129);
  font-weight: 400;
`;

export default SoldOut;
