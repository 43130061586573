import { createSelector } from 'reselect';

export const deliverySelector = state => state.delivery;

const deliveryTypeSelector = createSelector(
  deliverySelector,
  delivery => delivery.type,
);

export default deliveryTypeSelector;
