import React from 'react';
import PropTypes from 'prop-types';

import MyAddresses from './containers/MyAddressesContainer';

const Addresses = props => <MyAddresses modal={props.modal} />;

Addresses.propTypes = {
  modal: PropTypes.bool,
};

export default Addresses;
