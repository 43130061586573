import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import deliveryDaySelector from 'selectors/deliveryDaySelector';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { showIntervalsSelector } from 'selectors/showIntervalsSelector';
import { cantinaWeekSelector } from 'selectors/cantinaWeekSelector';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';
import { setDeliveryTimeType } from 'redux/modules/delivery';
import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { actions as modalActions } from 'components/ReduxModal';
import format from 'date-fns/format';
import addMinutes from 'date-fns/add_minutes';
import MenuNavigationHeader from '../components/MenuNavigationHeader';

const TIME_FORMAT = 'hh:mma';

const menuNavigationHeaderHOC = compose(
  connect(
    state => ({
      minutesStepSize: timeIntervalSelector(state),
      showIntervals: showIntervalsSelector(state),
      scheduleType: deliveryTimeTypeSelector(state),
      scheduleTime: scheduleTimeSelector(state),
      deliveryTimeType: state.delivery.deliveryTimeType,
      deliveryType: state.delivery.type,
      deliveryDay: deliveryDaySelector(state),
      cantinaWeek: cantinaWeekSelector(state),
      dineInEnabled: dineInEnabledSelector(state),
    }),
    {
      onClick: setDeliveryTimeType,
      showModal: modalActions.showModal,
    },
  ),
  withProps(
    ({
      deliveryDay,
      cantinaWeek,
      deliveryTimeType,
      scheduleTime,
      minutesStepSize,
      showIntervals,
    }) => ({
      deliveryDay: cantinaWeek ? cantinaWeek?.start : deliveryDay,
      deliveryTimeFormatted: {
        [true]: [format(scheduleTime, TIME_FORMAT)],
        [showIntervals]: [
          format(scheduleTime, TIME_FORMAT),
          ` - ${format(
            addMinutes(scheduleTime, minutesStepSize),
            TIME_FORMAT,
          )}`,
        ],
        [deliveryTimeType === 'SOONEST']: ['Soonest Available'],
        [Boolean(cantinaWeek)]: [cantinaWeek?.stop],
      }.true,
    }),
  ),
);

export default menuNavigationHeaderHOC(MenuNavigationHeader);
