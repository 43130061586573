import { createSelector } from 'reselect/lib/index';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import { strRangeToDatetimes } from 'utils/timeHelpers';
import { PREPARATION_TIME_TOLERANCE } from 'utils/constants';

import last from 'lodash/last';
import startOfDay from 'date-fns/start_of_day';
import isWithinRange from 'date-fns/is_within_range';
import subMinutes from 'date-fns/sub_minutes';
import format from 'date-fns/format';

import { menusEntitiesSelector } from 'redux/modules/menus';

const scheduleFilteredMenuSelector = createSelector(
  [scheduleTimeSelector, menusEntitiesSelector],
  (scheduleTime, menus) => {
    const dayFormatted = format(scheduleTime, 'YYYY-MM-DD');
    const day = startOfDay(scheduleTime);
    // Only one menu can be active
    const filteredMenus = Object.values(menus).filter(
      menu =>
        scheduleTime &&
        menu.schedule[dayFormatted] &&
        menu.schedule[dayFormatted].some(range => {
          if (range.length === 0) {
            return false;
          }
          const { start, end } = strRangeToDatetimes(day, range);
          return isWithinRange(
            scheduleTime,
            subMinutes(start, PREPARATION_TIME_TOLERANCE),
            end,
          );
        }),
    );
    const menu = last(filteredMenus);
    return menu || {};
  },
);

export default scheduleFilteredMenuSelector;
