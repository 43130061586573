import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DayPicker from './DayPicker';
import TimePicker from './TimePicker';
import CalendarDayPicker from './CalendarDayPicker';
import Spacer from './Spacer';

const ScheduleOrder = ({
  dayPickerValue,
  dayPickerOptions,
  setDayPickerValue,
  timePickerValue,
  timePickerOptions,
  setTimePickerValue,
  calendarVisible,
  hideCalendar,
  showCalendar,
  spacerVisible,
  hideSpacer,
  showSpacer,
}) => (
  <React.Fragment>
    <Bottom>
      <SelectWrapper>
        <DayPicker
          value={dayPickerValue}
          options={dayPickerOptions}
          onChange={setDayPickerValue}
          hideCalendar={hideCalendar}
          showCalendar={showCalendar}
        />
      </SelectWrapper>

      <InputWrapper>
        <TimePicker
          value={timePickerValue}
          options={timePickerOptions}
          onChange={setTimePickerValue}
          hideSpacer={hideSpacer}
          showSpacer={showSpacer}
        />
      </InputWrapper>
    </Bottom>
    {calendarVisible && (
      <CalendarDayPicker
        value={dayPickerValue}
        options={dayPickerOptions}
        onChange={setDayPickerValue}
        hideCalendar={hideCalendar}
        showCalendar={showCalendar}
      />
    )}
    {spacerVisible && <Spacer />}
  </React.Fragment>
);

ScheduleOrder.propTypes = {
  dayPickerValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
    disabled: PropTypes.bool,
  }),
  dayPickerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.object,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  setDayPickerValue: PropTypes.func.isRequired,
  timePickerValue: PropTypes.shape({
    value: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  timePickerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.object,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  setTimePickerValue: PropTypes.func.isRequired,
  calendarVisible: PropTypes.bool.isRequired,
  hideCalendar: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  spacerVisible: PropTypes.bool.isRequired,
  hideSpacer: PropTypes.func.isRequired,
  showSpacer: PropTypes.func.isRequired,
};

ScheduleOrder.defaultProps = {
  dayPickerValue: undefined,
  timePickerValue: undefined,
};

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const InputWrapper = styled.div`
  width: 50%;
  border-top: 1px solid #eeefef;
  position: relative;
`;

const SelectWrapper = styled.div`
  width: 50%;
  border-top: 1px solid #eeefef;
  border-right: 1px solid #eeefef;
  position: relative;
  height: 46px;
`;

export default ScheduleOrder;
