import { compose } from 'recompose';
import { connect } from 'react-redux';

import { availableOnAwareScheduleFilteredCategoriesSelector } from 'redux/modules/categories';
import deliveryEstimateTextSelector from 'selectors/deliveryEstimatesTextSelector';
import CategoriesDesktop from '../components/CategoriesDesktop';

const CategoriesDesktopHOC = compose(
  connect(state => ({
    categories: availableOnAwareScheduleFilteredCategoriesSelector(state),
    deliveryTimeText: deliveryEstimateTextSelector(state),
  })),
);

export default CategoriesDesktopHOC;

const CategoriesDesktopContainer = CategoriesDesktopHOC(CategoriesDesktop);
export { CategoriesDesktopContainer };
