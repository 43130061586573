import { actionTypes as generalActionTypes } from 'redux/modules/general';
import pickBy from 'lodash/pickBy';

const initialState = {
  entities: {},
  order: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case generalActionTypes.GET_GENERAL_SUCCESS:
      return {
        ...state,
        ...pickBy({
          entities: action.payload.entities.menus,
          order: action.payload.result.menus,
        }),
      };
    default:
      return state;
  }
}

export const actions = {};
