import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  branch,
  withStateHandlers,
  renderComponent,
  withProps,
} from 'recompose';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import { getNowTime } from 'utils/timeHelpers';

const RenderChildren = ({ children }) => children;

const Timer = ({ label, timerMM, timerSS }) => (
  <span>
    {label} {timerMM}:{timerSS}
  </span>
);

Timer.propTypes = {
  label: PropTypes.string.isRequired,
  timerMM: PropTypes.string.isRequired,
  timerSS: PropTypes.string.isRequired,
};

const timerHOC = compose(
  withStateHandlers(
    () => ({
      timer: 0,
    }),
    {
      refreshTimer: (_, { till }) => () => {
        const timer = differenceInSeconds(till, getNowTime());
        return {
          timer: timer > 0 ? timer : 0,
        };
      },
    },
  ),
  lifecycle({
    componentDidMount() {
      this.interval = setInterval(this.props.refreshTimer, 100);
    },
    componentWillUnmount() {
      clearInterval(this.interval);
    },
  }),
  withProps(({ timer }) => ({
    timerMM: `${Math.floor(timer / 60)}`,
    timerSS: timer % 60 < 10 ? `0${timer % 60}` : `${timer % 60}`,
  })),
  branch(({ timer }) => timer <= 0, renderComponent(RenderChildren)),
);

export default timerHOC(Timer);
