import React from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';
import { Route, Switch, Redirect } from 'react-router-dom';

import Navigation, {
  EmptyHeader,
  BaseNavigationHeader,
  NavigationHeader,
  Title,
} from 'components/Navigation';

import ScrollableContent from 'components/ScrollableContent';
import Footer from 'components/Footer';
import DesktopHeader from 'containers/DesktopHeader';

import userIsAuthenticatedHOC from 'HOC/userIsAuthenticatedHOC';
import orderContentHOC from 'routes/Orders/containers/orderContentHOC';

import ThankYouContainer from '../containers/ThankYouContainer';
import UpcomingOrdersContainer from '../containers/UpcomingOrdersContainer';
import PastOrdersContainer from '../containers/PastOrdersContainer';

import OrdersTimeSwitcher from './OrdersTimeSwitcher';

const OrdersNavigation = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/thank-you/:orderId`}
      render={() => (
        <HeaderWrapper>
          <MobileHeader>
            <BaseNavigationHeader>
              <Title>Order Processed</Title>
            </BaseNavigationHeader>
          </MobileHeader>
          <DesktopHeader />
        </HeaderWrapper>
      )}
    />
    <Route
      render={() => (
        <NavigationHeader>
          <OrdersTimeSwitcher match={match} />
        </NavigationHeader>
      )}
    />
  </Switch>
);

OrdersNavigation.propTypes = {
  match: PropTypes.object,
};

const Orders = ({ match, redirectTo, ...rest }) => (
  <Navigation>
    <EmptyHeader>
      <OrdersNavigation match={match} />
    </EmptyHeader>
    <ScrollableContent>
      <Switch>
        <Route
          path={`${match.url}/thank-you/:orderId`}
          component={ThankYouContainer}
        />
        <Route path={`${match.url}/past`}>
          <PastOrdersContainer {...rest} type="past" />
        </Route>
        <Route path={`${match.url}/upcoming`}>
          <UpcomingOrdersContainer {...rest} type="upcoming" />
        </Route>
        <Redirect to={`${match.url}/${redirectTo}`} />
      </Switch>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ScrollableContent>
  </Navigation>
);

Orders.propTypes = {
  match: PropTypes.object,
  redirectTo: PropTypes.string,
};

const OrdersHOC = compose(
  userIsAuthenticatedHOC,
  orderContentHOC,
  withProps(({ upcomingOrders }) => ({
    redirectTo: upcomingOrders.length ? 'upcoming' : 'past',
  })),
);

const MobileHeader = styled.div`
  display: block;

  ${media.tablet`
    display: none;
  `};
`;

const HeaderWrapper = styled.div`
  display: none;

  ${media.tablet`

    display: block;
  `};
`;

const FooterWrapper = styled.div`
  margin-top: 59px;
  display: none;
  ${media.tablet`
    display: block;
  `};
`;

export default OrdersHOC(Orders);
