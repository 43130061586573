import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { history } from 'routes';

import arrowIcon from 'assets/arrow.png';

export const BackArrow = ({ onBackClick }) => (
  <Arrow
    onClick={() => {
      onBackClick && onBackClick();
      history.goBack();
    }}
  />
);

BackArrow.propTypes = {
  onBackClick: PropTypes.func,
};

export const BaseNavigationHeader = styled.div`
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 15px;
  box-shadow: 1px 1px 1px 1px #e6e7e8;
  z-index: 2;
`;

export const Arrow = styled.div`
  width: 17px;
  height: 24px;
  background: url(${arrowIcon}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #5f6263;
`;

export const Title = styled.div`
  font-size: 16px;
  font-family: 'Montserrat';
  color: rgb(66, 70, 72);
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.2px;
`;
