import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { isUserLoggedInSelector } from 'redux/modules/user';

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticatedHOC = connectedRouterRedirect({
  // This sends the auth either to the query param route if we have one, or to the landing page if none is specified and the auth is already logged in
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  // This prevents us from adding the query parameter when we send the auth away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no auth data, then we show the page
  authenticatedSelector: state => !isUserLoggedInSelector(state),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export default userIsNotAuthenticatedHOC;
