import { actions as cartActions, cartItemsSelector } from 'redux/modules/cart';
import { actions as modalActions } from 'components/ReduxModal';
import inform from 'utils/inform';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import map from 'lodash/map';
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';
import availableItemIdsSelector from 'selectors/availableItemIdsSelector';
import { lastDeliverySettingsSelector } from 'selectors/lastDeliverySettingsSelector';

const formatTime = (time, deliveryType) =>
  deliveryType === 'SOONEST'
    ? 'Soonest Available'
    : `${isToday(time) ? 'Today' : format(time, 'MM-DD-YYYY')} at ${format(
        time,
        'hh:mm A',
      )}`;

const setScheduleTimeConditionalThunk = (
  time,
  deliveryTimeType,
  onConfirmAction,
  history,
) => (dispatch, getState) => {
  const state = getState();
  const cartItemIds = map(cartItemsSelector(state), 'dishId');
  const {
    lastDeliveryTimeType,
    lastScheduleTime,
  } = lastDeliverySettingsSelector(state);
  const onConfirm = () => {
    dispatch(onConfirmAction(time));
  };
  if (time && cartItemIds && cartItemIds.length) {
    const stateClone = cloneDeep(state);
    const withNewTime = set(stateClone, 'delivery.scheduleTime', time);
    const availableItemIds = availableItemIdsSelector(withNewTime);
    const invalidItems = cartItemIds.filter(
      cartItemId => !availableItemIds.includes(cartItemId),
    );
    if (!invalidItems.length) {
      onConfirm();
    } else {
      const onBeforeButtonClick = () => {
        onConfirm();
        dispatch(cartActions.clearCart());
        dispatch(modalActions.closeModal());
        history.push('/menu');
      };
      inform({
        title: 'Start New Order?',
        message: `You have an active order for ${formatTime(
          lastScheduleTime,
          lastDeliveryTimeType,
        )}. Would you like to clear order and Schedule your order for ${formatTime(
          time,
          deliveryTimeType,
        )} instead?`,
        buttonText: 'New Order',
        showCancelButton: true,
        buttonChoice: true,
        onBeforeButtonClick,
      });
    }
  } else {
    onConfirm();
  }
};

export default setScheduleTimeConditionalThunk;
