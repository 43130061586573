import React from 'react';
import PropTypes from 'prop-types';
import { media } from 'utils/mediaQueries';

import styled from 'styled-components';

const RouterTabBar = ({ children }) => <Wrapper>{children}</Wrapper>;

RouterTabBar.propTypes = {
  children: PropTypes.node,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  background: #f3f4f5;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 20;
  width: 100%;

  ${media.tablet`
    display: none;
  `} ${media.desktop`
    display: none;
  `};
`;

export default RouterTabBar;
