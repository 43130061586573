import get from 'lodash/get';
import ExtendableError from './ExtendableError';

const bestCorrespondAddressFormatter = payload => payload[0].formatted_address;

class GeocoderError extends ExtendableError {
  static formatMessage = status =>
    `Error was oquiered when geocode coordinates. Error code: ${status}`;
  constructor(status) {
    super(GeocoderError.formatMessage(status));
  }
}
const Geocoder = get(window, 'google.maps.Geocoder');

export default class GeocoderService {
  static geocoder = Geocoder && new Geocoder();

  static getAddress({ lat, lng }, formatter = bestCorrespondAddressFormatter) {
    return new Promise((resolve, reject) => {
      if (!GeocoderService.geocoder) {
        reject(
          new Error(
            "Can't find google.maps.Geocoder class. Check your internet connection.",
          ),
        );
      }
      const geocodePayload = { location: { lat, lng } };
      GeocoderService.geocoder.geocode(geocodePayload, (result, status) => {
        if (status !== 'OK') {
          reject(new GeocoderError(status));
        }
        resolve(formatter(result));
      });
    });
  }
}
