import { createSelector } from 'reselect';
import sum from 'lodash/sum';
import promocodeDiscountSelector from 'selectors/promocodeDiscountSelector';
import tipsAllowedSelector from 'selectors/tipsAllowedSelector';
import tipAmountSelector from 'selectors/tipAmountSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import taxesTotalSelector from 'selectors/taxesTotalSelector';
import serviceFeeTotalSelector from 'selectors/serviceFeeTotalSelector';
import deliveryCostSelector from 'selectors/deliveryCostSelector';
import { redeemCurrencySelector } from 'redux/modules/cart';
import { centsToDollars } from 'utils/formatCents';

const totalPriceSelector = createSelector(
  [
    cartSubtotalSelector,
    promocodeDiscountSelector,
    tipsAllowedSelector,
    tipAmountSelector,
    serviceFeeTotalSelector,
    taxesTotalSelector,
    deliveryCostSelector,
    redeemCurrencySelector,
  ],
  (
    cartSubtotal,
    promocodeDiscount,
    tipsAllowed,
    tipAmount,
    serviceFeeTotal,
    taxes,
    deliveryCost,
    redeemCurrency,
  ) =>
    sum([
      cartSubtotal,
      tipsAllowed ? tipAmount : 0,
      taxes,
      serviceFeeTotal,
      deliveryCost,
      -promocodeDiscount,
      -centsToDollars(redeemCurrency),
    ]),
);

export default totalPriceSelector;
