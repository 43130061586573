import { compose, withHandlers, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { actions as spinnerActions } from 'redux/modules/spinner';
import { availableOnAwareScheduleFilteredCategoriesSelector } from 'redux/modules/categories';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { actions as modalActions } from 'components/ReduxModal';
import MenuComponent from '../components/Menu';

const MenuComponentHOC = compose(
  withRouter,
  connect(
    state => ({
      categories: availableOnAwareScheduleFilteredCategoriesSelector(state),
      scheduleTime: scheduleTimeSelector(state),
      orderPrice: cartSubtotalSelector(state),
    }),
    {
      showModal: modalActions.showModal,
      hideSpinner: spinnerActions.hideSpinner,
    },
  ),
  withHandlers({
    handleDishClick: ({ showModal }) => id => {
      showModal('dish-details', { dishId: id });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { scheduleTime, history, hideSpinner } = this.props;
      hideSpinner();
      if (!scheduleTime) {
        history.push(`/`);
      }
    },
  }),
);

export default MenuComponentHOC(MenuComponent);
