import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';

import { actions as userActions } from 'redux/modules/user/reducer';
import { Item } from 'components/Card';
import validatePassword from 'utils/validatePassword';
import SecurityCard, {
  Descritption,
  ShowModeContent,
  EditModeContent,
  iconTypes,
  Input,
} from './SecurityCard';

const PasswordSecurityCard = ({
  values: { oldPassword, password1, password2 },
  errors,
  touched,
  handleChange,
  handleBlur,
  submitForm,
  resetForm,
  isValid,
  dirty,
}) => (
  <SecurityCard
    editModeHeight={199}
    type={iconTypes.PASSWORD}
    onSave={submitForm}
    onCancel={resetForm}
    isValid={isValid}
    dirty={dirty}
  >
    <ShowModeContent>
      <Descritption>********</Descritption>
    </ShowModeContent>
    <EditModeContent>
      <InputItem showError={touched.oldPassword && errors.oldPassword}>
        <Input
          type="password"
          name="oldPassword"
          value={oldPassword}
          placeholder="Enter current password"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputItem>
      <InputItem showError={touched.password1 && errors.password1}>
        <Input
          type="password"
          name="password1"
          value={password1}
          placeholder="Enter new password"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.password1 && errors.password1}
      </InputItem>
      <InputItem showError={touched.password2 && errors.password2}>
        <Input
          type="password"
          name="password2"
          value={password2}
          placeholder="Confirm new password"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.password2 && errors.password2}
      </InputItem>
    </EditModeContent>
  </SecurityCard>
);

PasswordSecurityCard.propTypes = {
  values: PropTypes.shape({
    oldPassword: PropTypes.string,
    password1: PropTypes.string,
    password2: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    oldPassword: PropTypes.string,
    password1: PropTypes.string,
    password2: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    oldPassword: PropTypes.bool,
    password1: PropTypes.bool,
    password2: PropTypes.bool,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

const PasswordSecurityCardHOC = compose(
  connect(null, {
    updateUser: userActions.updateUser,
  }),

  withFormik({
    mapPropsToValues: () => ({
      oldPassword: '',
      password1: '',
      password2: '',
    }),
    validate: ({ oldPassword, password1, password2 }) => {
      const errors = {};
      if (!oldPassword) {
        errors.oldPassword = 'Required';
      }
      const passwordError = validatePassword(password1);
      if (passwordError) {
        errors.password1 = passwordError;
      }
      if (!password2) {
        errors.password2 = 'Required';
      }
      if (password1 !== password2) {
        errors.password2 = 'Passwords must match';
      }
      return errors;
    },
    handleSubmit: (
      { oldPassword, password1: password },
      { props: { updateUser } },
    ) => {
      updateUser({ oldPassword, password });
    },
  }),
);

export default PasswordSecurityCardHOC(PasswordSecurityCard);

const InputItem = Item.extend`
  padding-left: 40px;
  max-height: 51px;
  :focus-within {
    border: 1px solid #54a300;
  }
`;
