import React from 'react';
import styled from 'styled-components';

const InvisibleInput = styled.input`
  display: none;
`;

const InvisibleSubmit = () => <InvisibleInput type="submit" />;

export default InvisibleSubmit;
