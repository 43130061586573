import React from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';

import Icon from 'components/Icon';

import {
  InformationItem,
  InformationItemText as Text,
} from 'components/DishesList';
import { expandedVariationsSelector } from 'selectors/expandedVariationsSelector';
import { getFixedPrice } from 'utils/currencyHelpers';

const pizzaIcons = value =>
  ({
    [value === 1]: 'icon-pizza-full-small',
    [value === 0.5]: 'icon-pizza-right-small',
    [value === -0.5]: 'icon-pizza-left-small',
  }.true);

const pizzaText = value =>
  ({
    [value === 1]: 'WHOLE',
    [value === 0.5]: 'RIGHT',
    [value === -0.5]: 'LEFT',
  }.true);

// ---
// HELPER FUNCTIONS
// ---
const formatPrice = (option, value) => {
  const price =
    +option.partialPrice && Math.abs(value) === 0.5
      ? +option.partialPrice
      : Math.max(value, 1) * +option.price;
  return (!!price && `+$${getFixedPrice(price)}`) || '';
};

// ---
// VARIATION COMPONENTS
// ---

const formatPizzaText = (option, value) =>
  `(${pizzaText(value)}) ${option.name} ${formatPrice(option, value)}`;

const PizzaVariationItem = ({ option, value }) => (
  <InformationItem>
    <PizzaIcon>
      <Icon name={pizzaIcons(value)} size="26px" />
    </PizzaIcon>
    <Text>{formatPizzaText(option, value)}</Text>
  </InformationItem>
);

PizzaVariationItem.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
    partialPrice: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  value: PropTypes.number.isRequired,
};

const formatNoPricePizzaText = (option, value) =>
  `(${pizzaText(value)}) ${option.name}`;

const NoPricePizzaVariationItem = ({ option, value }) => (
  <InformationItem>
    <PizzaIcon>
      <Icon name={pizzaIcons(value)} size="26px" />
    </PizzaIcon>
    <Text>{formatNoPricePizzaText(option, value)}</Text>
  </InformationItem>
);

NoPricePizzaVariationItem.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
    partialPrice: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  value: PropTypes.number.isRequired,
};

const formatSmallPizzaText = (option, value) =>
  `${option.name} ${formatPrice(option, value)}`;

const SmallPizzaVariationItem = ({ option, value }) => (
  <InformationItem>
    <PizzaIcon>
      <Icon name={pizzaIcons(value)} size="26px" />
    </PizzaIcon>
    <Text>{formatSmallPizzaText(option, value)}</Text>
  </InformationItem>
);

SmallPizzaVariationItem.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
    partialPrice: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  value: PropTypes.number.isRequired,
};

const formatSimpleText = (option, value) =>
  `${option.name} ${formatPrice(option, value)}`;

const SimpleVariationItem = ({ option, value }) => (
  <InformationItem>
    <Dot />
    <Text>{formatSimpleText(option, value)}</Text>
  </InformationItem>
);

SimpleVariationItem.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  value: PropTypes.number.isRequired,
};

const PriceOverwrittenVariationItem = ({ option }) => (
  <InformationItem>
    <Dot />
    <Text>{option.name}</Text>
  </InformationItem>
);

PriceOverwrittenVariationItem.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

const formatFlavorText = (option, value) => `${value} ${option.name}`;

const FlavorVariationItem = ({ option, value }) => (
  <InformationItem>
    <Dot />
    <Text>{formatFlavorText(option, value)}</Text>
  </InformationItem>
);

FlavorVariationItem.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  value: PropTypes.number.isRequired,
};

// ---
// GENERIC VARIATION ITEM COMPONENT
// ---

const VariationItem = ({
  value,
  type,
  options,
  priceOverwrite,
  chargeMoreExpensive,
  variationName,
}) => {
  const VariationComponent = {
    [true]: null,
    [priceOverwrite]: PriceOverwrittenVariationItem,
    [type === 'pizza_toppings']: PizzaVariationItem,
    [type === 'pizza_toppings_small']: SmallPizzaVariationItem,
    [type === 'mix_and_match']: PizzaVariationItem,
    [type === 'mix_and_match' &&
    chargeMoreExpensive]: NoPricePizzaVariationItem,
    [type === 'checkbox']: SimpleVariationItem,
    [type === 'radio']: SimpleVariationItem,
    [type === 'combo']: FlavorVariationItem,
  }.true;
  return (
    !isEmpty(options) &&
    VariationComponent && (
      <div>
        {variationName && <VariationName>{variationName}:</VariationName>}
        {value.map(([k, v]) => (
          <VariationComponent
            key={k}
            value={v}
            option={Object.values(options).find(({ id }) => id === +k)}
          />
        ))}
      </div>
    )
  );
};

VariationItem.propTypes = {
  type: PropTypes.oneOf([
    'pizza_toppings',
    'mix_and_match',
    'checkbox',
    'radio',
    'combo',
  ]),
  variationId: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.array),
  options: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  priceOverwrite: PropTypes.bool.isRequired,
  chargeMoreExpensive: PropTypes.bool.isRequired,
};

const VariationItemHOC = compose(
  connect(state => ({
    variationsExpanded: expandedVariationsSelector(state),
  })),
  withProps(({ variationsExpanded, variationId }) => ({
    variationExpanded: variationsExpanded[variationId],
  })),
  withProps(({ value, options, variationExpanded }) => ({
    options: options || variationExpanded?.options || {},
    priceOverwrite: variationExpanded?.priceOverwrite || false,
    chargeMoreExpensive: variationExpanded?.chargeMoreExpensive || false,
    variationName:
      (variationExpanded?.type !== 'pizza_toppings' &&
        variationExpanded?.name) ||
      '',
    value: Object.entries(value),
  })),
  withProps(({ options, withoutPrices }) => ({
    options: mapValues(options, option => ({
      ...option,
      name: option.name || option.displayName,
      ...(withoutPrices && {
        price: undefined,
        partialPrice: undefined,
      }),
    })),
  })),
);

const PizzaIcon = styled.div`
  width: 15px;
  height: 15px;
  color: #989ca1;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background: #747778;
  border-radius: 50%;
  margin-top: 6px;
`;

const VariationName = styled.div`
  color: #424648;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 6px;
`;

export default VariationItemHOC(VariationItem);
