import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import registerStepThreeHOC from 'HOC/registerStepThreeHOC';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import ModalComponents from './ModalComponents';

const RegisterStepThree = ({
  title,
  values: { firstName, lastName, email },
  errors,
  touched,
  handleChange,
  handleBlur,
  handleEmailFieldBlur,
  handleSubmit,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <TextField
              label="Email"
              value={email}
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleEmailFieldBlur}
              helperText={touched.email && errors.email}
              error={touched.email && errors.email}
              fullWidth
              autoFocus={autoFocus}
            />
          )}
        </AutoFocusIfNonTouchDevice>
        <TextField
          label="First Name"
          value={firstName}
          name="firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.firstName && errors.firstName}
          error={touched.firstName && errors.firstName}
          fullWidth
        />
        <TextField
          label="Last Name"
          value={lastName}
          name="lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.lastName && errors.lastName}
          error={touched.lastName && errors.lastName}
          fullWidth
        />
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Create Account
          </Button>
        </ModalComponents.FormButton>
      </ModalComponents.Form>
      <ModalComponents.Separator />
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

RegisterStepThree.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    email: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleEmailFieldBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default registerStepThreeHOC(RegisterStepThree);
