import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import FocusLock from 'react-focus-lock';
import Overlay from 'utils/Overlay';
import { css } from 'glamor';
import InformPopup from './InformPopup';
import InformPopupAlt from './InformPopupAlt';

const inform = ({
  title,
  message,
  buttonText,
  showCancelButton = false,
  cancelButtonText = 'Cancel',
  buttonChoice = false,
  onBeforeCloseClick,
  onBeforeButtonClick,
  alternativeStyles = false,
}) => {
  const onCloseClick = closeFunc => () => {
    if (onBeforeCloseClick) {
      onBeforeCloseClick();
    }
    closeFunc();
  };
  const onButtonClick = closeFunc => () => {
    if (onBeforeButtonClick) {
      onBeforeButtonClick();
    }
    closeFunc();
  };
  const PopupComponent = alternativeStyles ? InformPopupAlt : InformPopup;
  toast.dismiss();
  toast(
    ({ closeToast }) => (
      <Fragment>
        <Overlay onClick={onCloseClick(closeToast)} />
        <FocusLock>
          <PopupComponent
            title={title}
            message={message}
            buttonChoice={buttonChoice}
            showCancelButton={showCancelButton}
            onCloseClick={onCloseClick(closeToast)}
            cancelButtonText={cancelButtonText}
            onButtonClick={onButtonClick(closeToast)}
            buttonText={buttonText}
          />
        </FocusLock>
      </Fragment>
    ),
    {
      closeButton: false,
      // transition: {},
      className: css({
        // width: "500px",
        // colorDefault: "#fff",
        // colorInfo: "#3498db",
        // colorSuccess: "#07bc0c",
        // colorWarning: "#f1c40f",
        // colorError: "#e74c3c",
        // colorProgressDefault: "linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)",
        // mobile: "only screen and (max-width : 480px)",
        // fontFamily: "sans-serif",

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        background: 'inherit',
        color: 'inherit',
        maxHeight: '100%',
        padding: '0',
        marginBottom: '0',
        pointerEvents: 'none',
        borderRadius: 'inherit',
        boxShadow: 'none',
      }),
    },
  );
};
export default inform;
