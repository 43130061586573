import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import values from 'lodash/values';
import { media } from 'utils/mediaQueries';

import DesktopHeader from 'containers/DesktopHeader';

import ScrollableContent from 'components/ScrollableContent';
import ReviewOrderButton from 'components/ReviewOrderButton';

import Navigation, { EmptyHeader } from 'components/Navigation';
import RootPortal from 'components/RootPortal';
import ModeManagerHOC, { modes } from 'HOC/ModeManager';

import MenuNavigationHeader from '../containers/MenuNavigationHeader';
import { CategoriesDesktopContainer } from '../containers/CategoriesContainer';

import DishCard from '../containers/DishCard';

class MenuPage extends Component {
  scrollToCordova = scrollTop => {
    const parentNode = get(this.touchHandlerNode, 'parentNode');
    parentNode.scrollTop = scrollTop - 30;
  };

  scrollToMobile = scrollTop => {
    const scrollableParent = document.getElementById('router-tab-view-content');
    if (scrollableParent) {
      scrollableParent.scrollTop = scrollTop - 55;
    }
  };

  scrollToDesktop = scrollTop => {
    const parentNode = get(this.touchHandlerNode, 'parentNode');
    parentNode.scrollTop = scrollTop - 30;
  };

  scrollToCategory = category => {
    const { mode } = this.props;
    const nodeId = `category-${category.id}`;
    const node = document.getElementById(nodeId);
    if (!node) {
      console.error('Attempt to scroll to the not existing node');
    }
    const { offsetTop } = node;
    switch (mode) {
      case modes.DESKTOP_MODE:
        this.scrollToDesktop(offsetTop);
        break;
      case modes.MOBILE_MODE:
        this.scrollToMobile(offsetTop);
        break;
      case modes.CORDOVA_MODE:
        this.scrollToCordova(offsetTop);
        break;
      default:
    }
  };

  renderCategory = category => {
    const { handleDishClick } = this.props;
    return (
      <Content key={category.id} id={`category-${category.id}-container`}>
        <Anchor id={`category-${category.id}`} />
        <Title>{category.name}</Title>
        {category.description && (
          <Description>{category.description}</Description>
        )}
        <Inner>
          {category.items.map(id => (
            <ProductWrapper key={id}>
              <DishCard id={id} onClick={() => handleDishClick(id)} />
            </ProductWrapper>
          ))}
        </Inner>
      </Content>
    );
  };

  render() {
    const { categories, orderPrice } = this.props;
    return (
      <Navigation menu headerHeight="100px">
        <EmptyHeader>
          <MobileHeader>
            <MenuNavigationHeader />
          </MobileHeader>
        </EmptyHeader>
        <RootPortal>
          <HeaderWrapper>
            <DesktopHeader menu />
          </HeaderWrapper>
        </RootPortal>
        <ScrollableContent
          touchHandlerRef={node => {
            this.touchHandlerNode = node;
          }}
        >
          <Wrapper
            innerRef={node => {
              this.node = node;
            }}
            bigBottomPadding={orderPrice > 0}
          >
            <ContentWrapper>
              <MenuInner>
                <CategoriesDesktopContainer
                  onCategoryClick={this.scrollToCategory}
                />
              </MenuInner>
              <CategoriesInner>
                {categories.map(this.renderCategory)}
              </CategoriesInner>
            </ContentWrapper>
            <ReviewOrderButton />
          </Wrapper>
        </ScrollableContent>
      </Navigation>
    );
  }
}

MenuPage.propTypes = {
  categories: PropTypes.array,
  orderPrice: PropTypes.number,
  mode: PropTypes.oneOf(values(modes)),
  handleDishClick: PropTypes.func,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: ${({ bigBottomPadding }) =>
    bigBottomPadding ? '75px' : '0'};
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding: 15px;
  width: 100%;
  margin: 0 auto;

  &:first-child {
    margin: 0 auto;
  }

  &:last-child {
    min-height: 90vh;
  }

  ${media.tablet`
    max-width: initial;
    padding: 30px 0;

    &:first-child {
      margin-top: 0;
    }
  `} ${media.desktop`
    max-width: 1200px;
    padding: 30px 0;
    
    &:first-child {
      margin-top: 0;
    }
  `};
`;

const ProductWrapper = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;
  }

  ${media.desktop`
    width: 48%;
    
    &:not(:first-child) {
      margin-top: 0;
    }

    &:nth-child(n + 3) {
      margin-top: 32px;
    }

    &:nth-child(2n) {
      margin-left: 4%;
    }
  `};
`;

const Anchor = styled.div`
  position: relative;
  top: -100px;
`;

const Inner = styled.div`
  margin-top: 18px;

  ${media.tablet`
    display: flex;
    flex-flow: column nowrap;
    margin-top: 30px;
  `} ${media.desktop`
    display: flex;
    flex-flow: row wrap;
    margin-top: 30px;
  `};
`;

const Title = styled.div`
  font-size: 21px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  margin-top: 6px;

  ${media.tablet`
    text-align: center;
    font-weight: 300;
  `} ${media.desktop`
    text-align: center;
    font-weight: 300;
  `};
`;

const Description = styled.div`
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #414141;
  margin-top: 15px;
  font-weight: 300;
  line-height: 23px;
`;

const MobileHeader = styled.div`
  ${media.desktop`
    display: none;
  `};

  ${media.tablet`
    display: none;
  `};
`;

const HeaderWrapper = styled.div`
  display: none;
  z-index: 14;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  ${media.desktop`
    display: block;
  `};

  ${media.tablet`
    display: block;
  `};
`;

const CategoriesInner = styled.div`
  ${media.desktop`
    margin-left: 40px;
  `};

  ${media.tablet`
    margin-left: 40px;
  `};
`;

const MenuInner = styled.div`
  min-width: 250px;
  display: none;
  margin-top: 50px;

  ${media.desktop`
    display: block;
  `};

  ${media.tablet`
    display: block;
  `};
`;

const ContentWrapper = styled.div`
  ${media.tablet`
    display: flex;
    flex-flow: row nowrap;
    max-width: 1200px;
    padding: 0 30px;
    margin: 30px auto 0 auto;
  `};

  ${media.desktop`
    display: flex;
    flex-flow: row nowrap;
    max-width: 1200px;
    padding: 0 30px;
    margin: 30px auto 0 auto;
  `};
`;

export default ModeManagerHOC(MenuPage);
