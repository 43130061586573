import React from 'react';
import { hydrate, render } from 'react-dom';

import ConfiguredToastContainer from 'components/ConfiguredToastContainer';
import { requiredEnvs, BUILD_DATE } from 'utils/constants';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

console.log('APP VERSION: ', BUILD_DATE);

if (process.env.NODE_ENV === 'development') {
  // import('mimic');
  // import('screenlog').then(() => {
  //   window.screenLog.init();
  // });
}

requiredEnvs.forEach(env => {
  if (!process.env[env]) {
    console.warn(`You have to set ENV: ${env}`);
  }
});

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
render(
  <ConfiguredToastContainer />,
  document.getElementById('toast-container'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
