import { createSelector } from 'reselect';

import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';

import {
  orderingSettingsSelector,
  taxPercentageSelector,
} from 'redux/modules/general';
import serviceFeeTotalSelector from 'selectors/serviceFeeTotalSelector';
import tipAmountSelector from 'selectors/tipAmountSelector';
import deliveryCostSelector from 'selectors/deliveryCostSelector';
import promocodeDiscountSelector from 'selectors/promocodeDiscountSelector';
import { redeemCurrencySelector } from 'redux/modules/cart';
import { centsToDollars } from 'utils/formatCents';
import cartItemsWithPriceAndTaxesSelector from 'selectors/cartItemsWithPriceAndTaxesSelector';

const taxesTotalSelector = createSelector(
  [
    cartItemsWithPriceAndTaxesSelector,
    taxPercentageSelector,
    orderingSettingsSelector,
    serviceFeeTotalSelector,
    tipAmountSelector,
    deliveryCostSelector,
    promocodeDiscountSelector,
    redeemCurrencySelector,
  ],
  (
    cartItemsWithPriceAndTaxes,
    taxPercentage,
    {
      applyTaxesAfterPromotions,
      applyTaxToDelivery,
      applyTaxToGratuity,
      applyTaxToServiceFee,
    },
    serviceFeeTotal,
    tipAmount,
    deliveryCost,
    promocodeDiscount,
    redeemCurrency,
  ) =>
    Math.max(
      0,
      round(
        sum([
          sumBy(Object.values(cartItemsWithPriceAndTaxes), 'taxTotal'),
          applyTaxesAfterPromotions
            ? -(
                ((promocodeDiscount + centsToDollars(redeemCurrency)) *
                  taxPercentage) /
                100
              )
            : 0,
          applyTaxToDelivery ? (deliveryCost * taxPercentage) / 100 : 0,
          applyTaxToGratuity ? (tipAmount * taxPercentage) / 100 : 0,
          applyTaxToServiceFee ? (serviceFeeTotal * taxPercentage) / 100 : 0,
        ]),
        2,
      ),
    ),
);

export default taxesTotalSelector;
