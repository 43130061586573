import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MyAddresses from 'containers/MyAddressesContainer';
import Navigation, { NavigationHeader } from 'components/Navigation';

import ScrollableContent from 'components/ScrollableContent';

const MyAddressesPage = ({
  onAddressSelect,
  selectedAddress,
  showModal,
  modal,
}) => (
  <Navigation headerHeight={!modal ? '50px' : '0'} addresses={modal}>
    {!modal && <NavigationHeader> My Addresses </NavigationHeader>}
    <ScrollableContent>
      <Content>
        <MyAddresses
          onAddAddressClick={showModal}
          onAddressSelect={onAddressSelect}
          selectedAddress={selectedAddress}
        />
      </Content>
    </ScrollableContent>
  </Navigation>
);

MyAddressesPage.propTypes = {
  showModal: PropTypes.func,
  onAddressSelect: PropTypes.func,
  selectedAddress: PropTypes.shape({}),
  modal: PropTypes.bool,
};

export default MyAddressesPage;

const Content = styled.div`
  padding: 18px 15px;
`;
