import { persistor } from 'redux/store';
import { discardToken } from 'redux/modules/user/reducer';
import { history } from 'routes';
import { firstNameSelector } from 'redux/modules/user/index';
import inform from 'utils/inform';
import { landingUrlSelector } from 'redux/modules/general';

const logOutAction = () => (dispatch, getState) => {
  const state = getState();
  const firstName = firstNameSelector(state);
  const landingUrl = landingUrlSelector(state);
  const onSignout = () => {
    persistor.purge();
    dispatch({ type: 'RESET' });
    dispatch(discardToken);
    if (landingUrl) {
      window.location.href = landingUrl;
    } else {
      history.push('/');
      window.location.reload();
    }
  };
  inform({
    title: 'Sign out',
    message: `You are logged in as ${firstName}. Do you wish to sign out?`,
    buttonText: 'Yes',
    cancelButtonText: 'No',
    buttonChoice: true,
    showCancelButton: true,
    onBeforeButtonClick: onSignout,
  });
};

export default logOutAction;
