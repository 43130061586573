import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Icon = ({ name, size = '16px' }) => (
  <Wrapper size={size} aria-hidden>
    <i className={name} />
  </Wrapper>
);

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'foodieorder';
  color: inherit;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ size }) => size};
    color: inherit;
  }
`;

export default Icon;
