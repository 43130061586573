import { createSelector } from 'reselect';

import mapValues from 'lodash/mapValues';

import cartItemsWithPriceAndTaxesSelector from 'selectors/cartItemsWithPriceAndTaxesSelector';

// Keep this logic in sync with configuredDishVariationsSelector
const cartItemsPriceSelector = createSelector(
  [cartItemsWithPriceAndTaxesSelector],
  cartItems => mapValues(cartItems, ({ itemTotal }) => itemTotal),
);

export default cartItemsPriceSelector;
