import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SubFooter from 'components/SubFooter';
import Icon from 'components/Icon';
import { media } from 'utils/mediaQueries';

import AccountDropdown from 'components/AccountDropdown';
import ModalComponents from './ModalComponents';

const EmailSent = ({ title, resendEmail, values: { email }, resetForm }) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <Wrapper>
        <Greetings>
          <IconWrapper>
            <Icon name="icon-thank-you-small" size="145px" />
          </IconWrapper>
          <Text>Email Sent!</Text>
        </Greetings>
        <AccountDropdown
          value={email}
          options={[email]}
          onLoginChange={resetForm}
        />
        <Text2>
          Check your inbox for an email with a link to reset your password.
        </Text2>
        <Text3>DIDN&#39;T GET A LINK?</Text3>
        <ResendLink onClick={resendEmail}>Resend link</ResendLink>
      </Wrapper>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

EmailSent.propTypes = {
  title: PropTypes.string.isRequired,
  resendEmail: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default EmailSent;

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    padding: 15px 0 0 0;
  `};
`;

const Greetings = styled.div`
  padding: 35px 15px;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto;
  color: #54a30a;
`;

const Text = styled.div`
  font-size: 21px;
  font-family: 'Montserrat', sans-serif;
  color: #424648;
  margin-top: 25px;
`;

const Text2 = styled.div`
  font-size: 16px/22px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #424648;
  margin-top: 8px;
  text-align: center;
  max-width: 70%;
`;

const Text3 = styled.div`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #424648;
  margin-top: 80px;
`;

const ResendLink = styled.div`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-decoration: underline;
  color: #54a300;
  margin-top: 16px;
  cursor: pointer;
`;
