import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';
import RadioButton from 'components/RadioButton';
import CardIcon from 'routes/Checkout/components/PaymentMethod/components/CardIcon';
import { media } from 'utils/mediaQueries';

class Payment extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {
      def,
      last4,
      type,
      deletePayment,
      makeDefaultPayment,
      selected,
      selectPayment,
      expanded,
      expand,
      selectorTouched,
      paymentsSelectable,
    } = this.props;

    return (
      <div>
        <Wrapper>
          {paymentsSelectable && (
            <RadioButtonWrapper onClick={expand}>
              <RadioButton name="" active={selected} onClick={expand} />
            </RadioButtonWrapper>
          )}
          <CardIconWrapper onClick={expand}>
            <Inner>
              <CardIcon brand={type} />
              <InputWrapper>
                <HiddenNumber>····</HiddenNumber>
                <Number>{last4}</Number>
              </InputWrapper>
            </Inner>
          </CardIconWrapper>
          <LabelWrapper onClick={def ? undefined : makeDefaultPayment}>
            <Label def={def}>{def ? 'DEFAULT' : 'MAKE DEFAULT'}</Label>
          </LabelWrapper>
          <EditIconWrapper>
            <EditIcon onClick={deletePayment} aria-label="Remove">
              <Icon name="icon-cross-small" size="26px" />
            </EditIcon>
          </EditIconWrapper>
        </Wrapper>
        {paymentsSelectable && selectorTouched && expanded && (
          <Button onClick={selectPayment}>
            <ButtonLabel>USE THIS PAYMENT METHOD</ButtonLabel>
          </Button>
        )}
      </div>
    );
  }
}

Payment.propTypes = {
  def: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  last4: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  deletePayment: PropTypes.func.isRequired,
  makeDefaultPayment: PropTypes.func.isRequired,
  selectPayment: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  selectorTouched: PropTypes.bool.isRequired,
  paymentsSelectable: PropTypes.bool.isRequired,
};

Payment.defaultProps = {
  name: '',
};

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
`;

const CardIconWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  padding: 0 5px;
`;

const HiddenNumber = styled.span`
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  max-width: 55px;
  outline: none;
  border: none;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  display: none;

  ${media[350]`
   display: inherit; 
  `};
`;

const Number = styled.span`
  max-width: 55px;
  outline: none;
  border: none;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  margin-left: 5px;
  margin-right: 5px;
`;

const LabelWrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;
  text-align: center;
  width: 67px;
  line-height: 16px;
  color: ${({ def }) => (def ? 'rgb(84, 163, 0)' : 'rgb(94, 98, 99)')};
`;

const EditIconWrapper = styled.div`
  flex: 0 1 auto;
  border-left: 1px solid #eeefef;
`;

const EditIcon = styled.button`
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  color: #5f6263;
`;

const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const RadioButtonWrapper = styled.div`
  flex: 0 1 auto;
  height: 50px;
`;

const Button = styled.button`
  margin-top: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: ${p => (p.disabled ? '#d2d3d3' : '#54a300')};
  text-align: center;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  position: relative;
  padding: 25px 15px;
`;

const ButtonLabel = styled.div`
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-align: center;
  text-shadow: 1px 1px 1px #595959;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export default Payment;
