import { createAction, handleActions } from 'redux-actions';

const MOBILE_MODE = 'MOBILE_MODE';
const CORDOVA_MODE = 'CORDOVA_MODE';
const DESKTOP_MODE = 'DESKTOP_MODE';

export const modes = { MOBILE_MODE, CORDOVA_MODE, DESKTOP_MODE };

// ---
// CONSTANTS
// ---
export const SET_MODE = 'modeManager/SET_MODE';

// ---
// ACTION HANDLERS
// ---
export const setMode = createAction(SET_MODE);

// ---
// REDUCER
// ---
export default handleActions(
  {
    [SET_MODE]: (state, action) => action.payload,
  },
  MOBILE_MODE,
);
