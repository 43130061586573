import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

import { media } from 'utils/mediaQueries';
import { Item, Name } from 'components/Card';
import SoldOut from 'components/SoldOut';
import PizzaPartSwitcher from './PizzaPartSwitcher';

const PizzaPartItem = ({
  option: { name, disabled },
  value,
  parts,
  onLeftClick,
  onRightClick,
  onFullClick,
  soldOut,
}) => (
  <PizzaPartItemStyled disabled={soldOut || disabled}>
    <Name active={!!value}>{name}</Name>
    {soldOut ? (
      <SoldOut />
    ) : (
      <PizzaPartSwitcher
        onLeftClick={onLeftClick}
        onRightClick={onRightClick}
        onFullClick={onFullClick}
        value={value}
        parts={parts}
      />
    )}
  </PizzaPartItemStyled>
);

PizzaPartItem.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  parts: PropTypes.array,
  onLeftClick: PropTypes.func.isRequired,
  onRightClick: PropTypes.func.isRequired,
  onFullClick: PropTypes.func.isRequired,
  value: PropTypes.number,
  soldOut: PropTypes.bool.isRequired,
};

const PizzaPartItemHOC = compose(
  withHandlers({
    onChange: ({
      onChange,
      value,
      option: { id, disabled },
      soldOut,
    }) => newValue => {
      if (!(soldOut || disabled)) {
        if (newValue === value) {
          onChange(id, undefined);
        } else {
          onChange(id, newValue);
        }
      }
    },
  }),
  withHandlers({
    onLeftClick: ({ onChange }) => () => {
      onChange(-0.5);
    },
    onRightClick: ({ onChange }) => () => {
      onChange(0.5);
    },
    onFullClick: ({ onChange }) => () => {
      onChange(1);
    },
  }),
);

const PizzaPartItemStyled = Item.extend`
  ${media.desktop`${props => props.hasOneOption && 'cursor: pointer'}`};
`;

export default PizzaPartItemHOC(PizzaPartItem);
