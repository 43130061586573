import React from 'react';
import styled from 'styled-components';
import CalendarContainerPortal from './CalendarContainerPortal';

class Spacer extends React.Component {
  componentDidMount() {
    const scrollableElement = document.getElementById(
      'router-tab-view-content',
    );
    setTimeout(() => {
      scrollableElement.scrollTop =
        scrollableElement.scrollHeight - scrollableElement.clientHeight;
    }, 50);
  }

  render() {
    return (
      <CalendarContainerPortal>
        <SpacerStyled />
      </CalendarContainerPortal>
    );
  }
}

const SpacerStyled = styled.div`
  height: 200px;
  background: none;
`;

export default Spacer;
