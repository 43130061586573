import React from 'react';
import { compose, withProps, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { Elements, injectStripe } from 'react-stripe-elements';
import { actions as paymentsActions } from 'redux/modules/payments';
import { actions as spinnerActions } from 'redux/modules/spinner';
import { actions as modalActions } from 'components/ReduxModal';
import withScriptLoaded from 'HOC/withScriptLoaded';
import PaymentForm from './PaymentForm';

export const PaymentFormHOC = WrappedComponent => {
  const Hocced = compose(
    connect(null, {
      addPayment: paymentsActions.addPayment,
      modalGoBack: modalActions.modalGoBack,
      showSpinner: spinnerActions.showSpinner,
      hideSpinner: spinnerActions.hideSpinner,
    }),
    injectStripe,
    withStateHandlers(
      {
        complete: false,
        wasCompleteFlag: false,
        cardholderName: '',
        cardholderNameError: '',
      },
      {
        setCompleteTo: () => value => ({ complete: value }),
        setWasCompleteFlag: () => () => ({ wasCompleteFlag: true }),
        resetWasCompleteFlag: () => () => ({ wasCompleteFlag: false }),
        updateCardholderName: () => value => ({
          cardholderName: value,
          ...(value && { cardholderNameError: '' }),
        }),
        setCardholderNameError: () => value => ({ cardholderNameError: value }),
      },
    ),
    withProps(({ complete, cardholderName }) => ({
      valid: !!(complete && cardholderName),
    })),
    withHandlers(() => {
      let element = null;
      return {
        onRef: () => ref => {
          element = ref;
        },
        getElement: () => () => element,
      };
    }),
    withHandlers({
      clearForm: ({ getElement, resetWasCompleteFlag }) => () => {
        const el = getElement();
        el.clear();
        resetWasCompleteFlag();
      },
    }),
    withHandlers({
      handleChange: ({
        complete,
        wasCompleteFlag,
        setCompleteTo,
        setWasCompleteFlag,
      }) => change => {
        const completeValue = change.complete;
        if (complete !== completeValue) {
          setCompleteTo(completeValue);
        }
        if (completeValue && !wasCompleteFlag) {
          setWasCompleteFlag();
        }
      },
      handleSubmit: ({
        valid,
        stripe,
        addPayment,
        modalGoBack,
        clearForm,
        cardholderName,
        setCardholderNameError,
        showSpinner,
        hideSpinner,
      }) => e => {
        e.preventDefault();
        if (valid) {
          // Within the context of `Elements`, this call to createToken knows which Element to
          // tokenize, since there's only one in this group.
          showSpinner();
          stripe
            .createToken({ name: cardholderName })
            .then(({ token }) => {
              hideSpinner();
              if (token) {
                addPayment({ token: token.id });
                clearForm();
                modalGoBack();
              }
            })
            .catch(() => {
              hideSpinner();
            });
          // However, this line of code will do the same thing:
          // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
        } else if (!cardholderName) {
          setCardholderNameError("Please enter the cardholder's name");
        }
      },
    }),
  )(WrappedComponent);
  const Wrapper = props => (
    <Elements>
      <Hocced {...props} />
    </Elements>
  );
  Wrapper.displayName = 'PaymentFormHOC';
  return Wrapper;
};

const connectedWithScriptLoaded = compose(
  connect(null, { onLoad: paymentsActions.setStripeLoaded }),
  withScriptLoaded({
    url: 'https://js.stripe.com/v3/',
  }),
);

export default connectedWithScriptLoaded(PaymentFormHOC(PaymentForm));
