import React from 'react';
import { compose, defaultProps, withProps } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DataContainerFirstLine, Item, Name } from 'components/Card';

import { media } from 'utils/mediaQueries';
import { getFixedPrice } from 'utils/currencyHelpers';
import SoldOut from 'components/SoldOut';

const JustNameGroupComponent = ({ options }) =>
  options.map(option => (
    <CheckboxItem key={option.id} disabled={option.disabled || option.soldOut}>
      <DataContainer>
        <DataContainerFirstLine>
          <CheckBoxName disabled={option.disabled || option.soldOut}>
            {option.name}
          </CheckBoxName>
          {
            {
              [true]: null,
              [!!option.price]: (
                <Price> + ${getFixedPrice(option.price)} </Price>
              ),
              [option.soldOut]: <SoldOut />,
            }.true
          }
        </DataContainerFirstLine>
      </DataContainer>
    </CheckboxItem>
  ));

JustNameGroupComponent.propTypes = {
  value: PropTypes.objectOf(PropTypes.number),
  handleClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
};

const JustNameGroupHOC = compose(
  defaultProps({
    value: {},
  }),
  withProps(({ data: { options }, value }) => ({
    options: options.filter(({ id }) => id in value),
  })),
);

export const JustNameGroup = JustNameGroupHOC(JustNameGroupComponent);

const CheckBoxName = styled(Name)`
  font-weight: 400;
`;

const CheckboxItem = Item.extend`
  align-items: flex-start;
  ${media.desktop`${props => !props.disabled && 'cursor: pointer'}`};
`;

const Price = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 400;
  white-space: nowrap;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
