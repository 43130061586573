import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isUndefined from 'lodash/isUndefined';

import editIcon from 'assets/edit.png';

const SettingsCard = ({ children, title, showError, edit }) => (
  <Wrapper>
    <Header>
      <Title>{title}</Title>
      {!isUndefined(showError) && (
        <Required color={showError ? 'red' : 'gray'}> Required </Required>
      )}
      {edit && <Edit>EDIT</Edit>}
    </Header>
    {children}
  </Wrapper>
);

SettingsCard.propTypes = {
  title: PropTypes.string,
  showError: PropTypes.bool,
  children: PropTypes.node,
  edit: PropTypes.bool,
};

const Wrapper = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  padding: 20px 15px;
  background: #e7e7e7;
  position: relative;
`;

const Title = styled.div`
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;
  font-weight: 300;
`;

export const Required = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  background: ${({ color }) => (color === 'red' ? '#c93c32' : '#939393')};
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 300;
  letter-spacing: 0.2px;
  padding: 3px 5px;
`;

const Edit = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -15px;
    background: url(${editIcon}) no-repeat center / contain;
  }
`;

export default SettingsCard;
