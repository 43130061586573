import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardElement } from 'react-stripe-elements';

const PaymentForm = ({
  valid,
  wasCompleteFlag,
  handleSubmit,
  handleChange,
  onRef,
  cardholderName,
  updateCardholderName,
  cardholderNameError,
}) => (
  <Wrapper>
    <form onSubmit={handleSubmit}>
      <PaymentWrapper>
        <Payment>
          <PaymentInputWrapper>
            <CardElement
              onChange={handleChange}
              onReady={onRef}
              style={{
                base: {
                  fontFamily: '"Roboto", sans-serif',
                },
              }}
            />
          </PaymentInputWrapper>
        </Payment>
      </PaymentWrapper>
      <HideBox wasCompleteFlag={wasCompleteFlag}>
        <InputWrapper>
          <Input
            type="text"
            value={cardholderName}
            placeholder="Cardholder's Name"
            onChange={e => updateCardholderName(e.target.value)}
            error={cardholderNameError}
          />
        </InputWrapper>
        {cardholderNameError && <Error>{cardholderNameError}</Error>}
        <ButtonWrapper onClick={handleSubmit}>
          <Button disabled={!valid}>
            <Label>SAVE</Label>
          </Button>
        </ButtonWrapper>
      </HideBox>
    </form>
  </Wrapper>
);

PaymentForm.propTypes = {
  valid: PropTypes.bool.isRequired,
  wasCompleteFlag: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  cardholderName: PropTypes.string.isRequired,
  updateCardholderName: PropTypes.func.isRequired,
  cardholderNameError: PropTypes.string.isRequired,
};

const Wrapper = styled.div``;

const PaymentWrapper = styled.div``;

const HideBox = styled.div`
  width: 100%;
  margin: 15px 0 16px;
  display: ${p => (p.wasCompleteFlag ? 'inherit' : 'none')};
  max-height: ${p => (p.wasCompleteFlag ? '300px' : '1px')};
  transition: max-height 0.25s ease-in;
`;

const ButtonWrapper = styled.button`
  cursor: pointer;
`;

const Button = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  background-color: ${p => (p.disabled ? '#d2d3d3' : '#54a300')};
  text-align: center;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  position: relative;
  padding: 25px 15px;
`;

const Label = styled.div`
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-align: center;
  text-shadow: 1px 1px 1px #595959;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PaymentInputWrapper = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  outline: none;
  border: none;
  width: 100%;
`;

const InputWrapper = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  outline: none;
  border: none;
  width: 100%;
  margin-bottom: 16px;
  :focus-within {
    border: 1px solid #54a300;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  border: ${p => (p.error ? '2px solid red' : 'none')};
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  outline: none;
  min-height: 50px;
  appearance: none;
  color: #424648;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    font-weight: 300;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    font-weight: 300;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    font-weight: 300;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    font-weight: 300;
  }

  &:not(:first-child) {
    border-top: 1px solid #eeefef;
  }
`;

const Error = styled.p`
  color: red;
  margin: 16px 0;
`;

const Payment = styled.div`
  background: #fff;
  padding: 12px 15px;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  height: 50px;
  align-items: center;
`;

export default PaymentForm;
