import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';

export const DeliveryTypePicker = ({
  onPickupClick,
  onDeliveryClick,
  canPickup,
  canDelivery,
  deliveryType,
  deliveryTypeError,
  dineInEnabled,
}) => (
  <Buttons>
    <Button
      onClick={onPickupClick}
      active={deliveryType === 'PICKUP'}
      disabled={!canPickup}
      error={deliveryType === 'PICKUP' && !canPickup}
      deliveryTypeError={deliveryTypeError}
    >
      {dineInEnabled ? 'DINE-IN' : 'PICKUP'}
      <CheckWrapper active={deliveryType === 'PICKUP' && canPickup}>
        <Icon name="icon-check-small" size="26px" />
      </CheckWrapper>
    </Button>
    <Button
      onClick={onDeliveryClick}
      active={deliveryType === 'DELIVERY'}
      disabled={!canDelivery}
      error={deliveryType === 'DELIVERY' && !canDelivery}
      deliveryTypeError={deliveryTypeError}
    >
      DELIVERY
      <CheckWrapper active={deliveryType === 'DELIVERY' && canDelivery}>
        <Icon name="icon-check-small" size="26px" />
      </CheckWrapper>
    </Button>
  </Buttons>
);

DeliveryTypePicker.propTypes = {
  canPickup: PropTypes.bool.isRequired,
  canDelivery: PropTypes.bool.isRequired,
  onPickupClick: PropTypes.func.isRequired,
  onDeliveryClick: PropTypes.func.isRequired,
  deliveryType: PropTypes.oneOf(['PICKUP', 'DELIVERY']),
  deliveryTypeError: PropTypes.string,
  dineInEnabled: PropTypes.bool.isRequired,
};

DeliveryTypePicker.defaultProps = {
  deliveryType: null,
  deliveryTypeError: null,
};

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const CheckWrapper = styled.div`
  position: absolute;
  width: 21px;
  height: 16px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 0.2s ease;
  top: 47%;
  transform: translateY(-50%);
  right: 15px;
  color: #54a30a;
`;

const Button = styled.button`
  border-radius: 2px;
  background-color: ${p => (p.disabled ? '#f8f9f9' : 'rgb(255, 255, 255)')};
  padding: 13px 20px;
  width: 50%;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: ${p =>
    ({
      [true]: '#595E61',
      [!!p.disabled]: 'rgba(88,93,95,0.5)',
      [!!p.active]: '#000',
    }.true)};
  font-weight: ${({ active }) => (active ? '600' : '300')};
  letter-spacing: 0.5px;
  transition: background 0.2s ease, color 0.2s ease;
  position: relative;
  cursor: pointer;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${p =>
    ({
      [true]: 'transparent',
      [p.active]: '#54A300',
      [p.error]: '#F40A15',
    }.true)};
  border: ${p => p.deliveryTypeError && '2px solid #F40A15'};

  &:not(:first-child) {
    border-left: 1px solid #eceded;
  }
`;
