import React from 'react';
import PropTypes from 'prop-types';
import Navigation, { NavigationHeader } from 'components/Navigation';
import ScrollableContent from 'components/ScrollableContent';
import FavoritesContainer from './containers/FavoritesContainer';

const FavoritesPage = props => (
  <Navigation headerHeight={props.modal ? '0' : '50px'}>
    {!props.modal && <NavigationHeader> Favorites </NavigationHeader>}
    <ScrollableContent>
      <FavoritesContainer />
    </ScrollableContent>
  </Navigation>
);

FavoritesPage.propTypes = {
  modal: PropTypes.bool,
};

export default FavoritesPage;
