import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navigation, { NavigationHeader } from 'components/Navigation';

import ScrollableContent from 'components/ScrollableContent';
import { PromotionsHOC } from 'routes/Modals/containers/Promotions';
import Promotion from './components/Promotion';

const Promotions = ({ modal, promotions }) => (
  <Navigation headerHeight={modal ? '0' : '50px'}>
    {!modal && <NavigationHeader>Promotions</NavigationHeader>}
    <ScrollableContent>
      <Content>
        <Inner>
          <div>
            {promotions.map(promotion => (
              <PromoWrapper key={promotion.couponName}>
                <Promotion
                  code={promotion.couponName}
                  expires={promotion.expirationDate}
                  text={promotion.description}
                  // alreadyUsed
                />
              </PromoWrapper>
            ))}
          </div>
        </Inner>
      </Content>
    </ScrollableContent>
  </Navigation>
);

Promotions.propTypes = {
  modal: PropTypes.bool,
  loyaltyEnabled: PropTypes.bool,
  promotions: PropTypes.array,
  points: PropTypes.number,
};

export default PromotionsHOC(Promotions);

const Content = styled.div`
  padding: 15px;
`;

const PromoWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
`;
