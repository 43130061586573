import get from 'lodash/get';
import ExtendableError from './ExtendableError';

const defaultFormatter = predictions =>
  predictions.map(prediction => prediction.description);

class GeolocationError extends ExtendableError {
  static formatMessage = status =>
    `Error was oquiered when request location. Error code: ${status}`;
  constructor(status) {
    super(GeolocationError.formatMessage(status));
  }
}
const AutocompleteService = get(
  window,
  'google.maps.places.AutocompleteService',
  null,
);

export default class PlaceAutocompleteService {
  static autocompleteService = AutocompleteService && new AutocompleteService();

  static predict(
    placeString,
    { formatter = defaultFormatter, byZipcode = false },
  ) {
    return new Promise((resolve, reject) => {
      if (!PlaceAutocompleteService.autocompleteService) {
        window.location.reload(true);
        reject(
          new Error(
            "Can't find google.maps.places.AutocompleteService class. Check your internet connection.",
          ),
        );
      }
      PlaceAutocompleteService.autocompleteService.getPlacePredictions(
        {
          input: placeString,
          ...(byZipcode && { types: ['(regions)'] }),
        },
        (predictions, status) => {
          if (status === 'ZERO_RESULTS') {
            resolve([]);
          } else if (status !== 'OK') {
            reject(new GeolocationError(status));
          } else resolve(formatter(predictions));
        },
      );
    });
  }
}
