import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import DesktopCart from './containers/DesktopCart';
import DesktopMenu from './containers/DesktopMenu';

const DesktopHeader = ({
  showModalFavorites,
  showModalLogin,
  showModalRegister,
  itemsCount,
  isUserLoggedIn,
  restaurantInfo,
  favoritesButtonActive,
  firstName,
  menu,
  isMenuOpened,
  isCartOpened,
  toggleMenu,
  toggleCart,
  closeCart,
}) => (
  <Wrapper>
    <DesktopMenu opened={isMenuOpened} toggleHandler={toggleMenu} />
    <DesktopCart
      opened={isCartOpened}
      toggleHandler={toggleCart}
      closeCart={closeCart}
    />
    <Container>
      <Left>
        <MenuWrapper
          onClick={toggleMenu}
          aria-label={isMenuOpened ? 'Close Navigation' : 'Open Navigation'}
        >
          <Icon name="icon-menu-small" size="34px" />
        </MenuWrapper>
        <Link to="/menu">
          <Logo src={restaurantInfo.logo} alt={restaurantInfo.name} />
        </Link>
        <Name>
          <IconWrapper />
          <Label aria-hidden>{restaurantInfo.name}</Label>
        </Name>
      </Left>
      {menu ? (
        <Right>
          {isUserLoggedIn ? (
            <React.Fragment>
              {firstName && <div>Hello, {firstName}</div>}
              <FavoriteIconWrapper
                onClick={showModalFavorites}
                visible={favoritesButtonActive}
              >
                <Icon name="icon-favorite-small" size="40px" />
              </FavoriteIconWrapper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <SignIn onClick={showModalLogin}>SIGN IN</SignIn>
              <Register onClick={showModalRegister}>REGISTER</Register>
            </React.Fragment>
          )}
          <Cart
            onClick={toggleCart}
            active={itemsCount > 0}
            aria-label="Review your order"
          >
            <Icon name="icon-bag-small" size="58px" />
            <Counter>{itemsCount}</Counter>
          </Cart>
        </Right>
      ) : (
        <Right>
          {isUserLoggedIn && firstName && <div>Hello, {firstName}</div>}
        </Right>
      )}
    </Container>
  </Wrapper>
);

DesktopHeader.propTypes = {
  itemsCount: PropTypes.number,
  isUserLoggedIn: PropTypes.bool,
  favoritesButtonActive: PropTypes.bool.isRequired,
  showModalFavorites: PropTypes.func.isRequired,
  showModalLogin: PropTypes.func.isRequired,
  showModalRegister: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  restaurantInfo: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  menu: PropTypes.bool,
  isMenuOpened: PropTypes.bool.isRequired,
  isCartOpened: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  toggleCart: PropTypes.func.isRequired,
  closeCart: PropTypes.func.isRequired,
};

DesktopHeader.defaultProps = {
  itemsCount: 0,
  isUserLoggedIn: false,
  menu: false,
};

const Wrapper = styled.div`
  background: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  padding: 16px 30px;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const MenuWrapper = styled.button`
  width: 25px;
  height: 17px;
  color: #545859;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #65ac19;
  }
`;

const Logo = styled.div`
  height: 45px;
  width: 160px;
  background: url(${p => p.src}) no-repeat center / contain;
  margin-left: 40px;
  cursor: pointer;
`;

const Name = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 70px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 12px;
  color: #6e7172;
`;

const FavoriteIconWrapper = styled.div`
  width: 15px;
  height: 12px;
  color: #6e7172;
  margin-left: 35px;
  cursor: pointer;
  visibility: ${p => (p.visible ? 'inherit' : 'hidden')};
`;

const SignIn = styled.button`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: rgb(84, 163, 0);
  padding: 10px;
  min-width: 100px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #67c70f;
  }
`;

const Register = styled.button`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.19;
  text-align: center;
  border-radius: 2px;
  background-color: rgb(241, 241, 241);
  padding: 10px;
  min-width: 100px;
  margin-left: 20px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #cacaca;
  }
`;

const Label = styled.div`
  margin-left: 10px;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.19;
  font-weight: 300;
`;

const Right = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`;

const Cart = styled.button`
  width: 30px;
  height: 45px;
  position: relative;
  text-align: right;
  margin-left: 35px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#67c70f' : '#898b8c')};
  transition: color 0.2s ease;
`;

const Counter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  color: #fff;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
`;

export default DesktopHeader;
