import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  landingUrlSelector,
  restaurantInfoSelector,
} from 'redux/modules/general';
import ModalComponents from './ModalComponents';

const PrivacyPolicy = ({ restaurantInfo: { name }, landingUrl }) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>Privacy Policy</ModalComponents.Title>
      <ModalComponents.WallOfText>
        <p>
          Foodie Order ("{name}", "us" or "we") respects the privacy of visitors
          to our Sites and registrants of our Services. This Privacy Policy is
          intended to inform you of our policies and practices regarding the
          collection, use and disclosure of any Personal Information you submit
          to use through our Sites. Any capitalized term used but not defined in
          this Privacy Policy shall have the meaning given in the {name}
        </p>
        <h4>User Agreement.</h4>
        <p>
          {name} provides certain websites, including the websites available at
          the domain name {landingUrl} (each, a "Site") and certain services
          made available to registered users of the Sites ("Services"). By
          submitting any information through a Site or registering for any
          Services, you acknowledge and agree that
          {name} may process (i.e., collect, use, etc.) your Personal
          Information as described in this Privacy Policy.
        </p>
        <p>
          You expressly consent to the processing of your Personal Data
          according to this Privacy Policy. Personal Data may be processed by
          {name} in the country where it was collected as well as other
          countries (including the United States) where laws regarding
          processing of Personal Data may be less stringent.
        </p>

        <h3>THE TYPES OF INFORMATION WE COLLECT</h3>

        <p>
          {name} collects Personal Information and Anonymous Information from
          you when you visit a Site, use the Services, and/or send us
          information or communications. "Personal Information" means
          information that allows someone to identify or contact you, including,
          for example, your name, address, telephone number, e-mail address, as
          well as any information about you that is associated with or linked to
          any of the foregoing information. "Anonymous Information" means
          information that is not associated with or linked to your Personal
          Information, including any feedback you may provide; Anonymous
          Information does not permit the identification of individual persons.
          We collect and use Personal Information and Anonymous Information as
          described in this Privacy Policy.
        </p>

        <h4>Personal Information You Provide to Us</h4>
        <p>
          We may collect Personal Information that you submit to us voluntarily
          through your access and/or use of a Site, including your user name,
          password, billing name and address, contact information such as phone
          numbers or e-mail addresses, and credit card information.
        </p>

        <h4>Personal Information Collected Via Technology</h4>
        <p>
          As you access and/or use the Site and/or the Services, we may collect
          certain information, such as unique identifiers for your wireless
          communication device, your product purchase preferences, and the
          locations from which you order the Products, and details involving the
          time, place, cost, number of items and other features of your orders.
        </p>
        <p>
          In addition, to make the Sites more useful to you, we use
          technological means to collect Personal Information from you,
          including the Uniform Resource Locator (URL) you just came from, the
          URL you go to next, your browser type, and your Internet Protocol (IP)
          address. We may also use cookies to collect Personal Information from
          you about your use of our Sites. You expressly consent to our use of
          cookies when you use our Sites.
        </p>
        <p>
          An "IP Address" is a number that is automatically assigned to your
          computer when you use the Internet. In some cases your IP address
          stays the same from browser session to browser session; but if you use
          a consumer internet access provider, your IP address probably varies
          from session to session. We track IP addresses solely in conjunction
          with session cookies to analyze our web page flow.
        </p>
        <p>
          "Cookies" are small pieces of information that a web site sends to
          your computer's hard drive while you are viewing a web site.
          {name} may use both session cookies (which expire once you close your
          web browser) and persistent cookies (which stay on your computer until
          you delete them) to provide you with a more personal and interactive
          experience on the Sites. Persistent cookies can be removed by
          following Internet browser help file directions. If you choose to
          disable cookies, some areas of the Site and the Services may not work
          properly.
        </p>

        <h3>HOW WE USE YOUR INFORMATION</h3>

        <p>
          In general, we use your Personal Information to respond to your
          requests as submitted through the Services and to charge your credit
          card the cost and transaction fee associated with the Products you
          request. In addition, we may use your Personal Information to
          determine your purchase preferences, to better understand how the
          Sites and Services are used; to gauge traffic patterns and determine
          what types of content and services are most popular with users; and to
          contact you with information about {name}'s products, services, news
          and events. We will share your Personal Information with third parties
          to fulfill your requests for Products, and we may share your Personal
          Information with third parties who perform services for us or on our
          behalf. You expressly consent to the sharing of your Personal
          Information with these third parties.
        </p>
        <p>
          We may create Anonymous Information records from Personal Information
          by excluding information (such as your name) that make the information
          personally identifiable to you. We may use this Anonymous Information
          to analyze request patterns and usage patterns so that we may enhance
          our products and services. {name} reserves the right to use and
          disclose Anonymous Information to third parties in its discretion.
        </p>
        <p>
          We may also use your Personal Information to send information on
          products, services, special offers, and promotions. Out of respect for
          your privacy, we give you the option not to receive these types of
          communications. If you would like to cease receiving any such
          communications, you may unsubscribe by sending an email to support
          [at] foodieorder.com. You may not opt out of non-promotional
          communications from {name}, including confirmation messages regarding
          successful order requests.
        </p>
        <p>
          We may share some or all of your Personal Information with
          “affiliates” (parent company, subsidiaries, joint ventures, or other
          companies under common control), in which case we will require our
          affiliates to honor this Privacy Policy. If our company or our assets
          are acquired by another company, that company will possess the
          Personal Information collected by us and it will assume the rights and
          obligations regarding your Personal Information as described in this
          Privacy Policy.
        </p>
        <p>
          We may disclose your Personal Information if we believe in good faith
          that such disclosure is necessary (a) to comply with relevant laws or
          to respond to subpoenas or warrants served on {name}; or (b) to
          protect and defend the rights or property of {name}
          or users of our website.
        </p>
        <h4>Feedback</h4>
        <p>
          If you provide feedback on any of our products or services to us, we
          may use such feedback for any purpose, provided we will not associate
          such feedback with your Personal Information. {name} will collect any
          information contained in such communication and will treat the
          Personal Information in such communication in accordance with this
          Privacy Policy.
        </p>
        <h4>Regarding Children</h4>
        <p>
          We do not intentionally gather Personal Information about visitors who
          are under the age of 13. Furthermore, we do not intentionally allow
          visitors under the age of 18 to register for the Services.
        </p>
        <h4>Links to Other Sites</h4>
        <p>
          Our provision of a link to any other website or location is for your
          convenience and does not signify our endorsement of such other website
          or location or its contents. We have no control over, do not review,
          and cannot be responsible for, these outside websites or their
          content. Please be aware that the terms of our Privacy Policy do not
          apply to these outside websites.
        </p>

        <h3>HOW WE PROTECT YOUR INFORMATION</h3>

        <p>
          We use reasonable measures to maintain the security of your Personal
          Information. {name} makes use of Secure Sockets Layer (SSL) technology
          and keeps sensitive details stored in an encrypted format in the{' '}
          {name} database.
        </p>
        <p>
          {name} has implemented other industry-standard electronic means of
          protecting your Personal Information. We store Personal Information
          behind a computer firewall, a barrier designed to prevent outsiders
          from accessing our servers. In addition, {name}
          protects your Personal Information from unauthorized physical access
          by storing your Personal Information in a controlled facility. Except
          as provided elsewhere in this Privacy Policy, {name} limits access to
          Personal Information in electronic databases to those persons
          (including employees and contractors) in {name}'s organization who
          have a business need for such access.
        </p>
        <p>
          Even though {name} has taken significant steps to ensure that your
          Personal Information is not intercepted, accessed, used, or disclosed
          by unauthorized persons, you should know that
          {name} cannot eliminate security risks associated with Personal
          Information.
        </p>

        <h3>ADVERTISING</h3>

        <p>
          {name} displays retargeted ads to our site prospects across the
          Internet. We collect non-personally identifiable information about
          your visits to our Website, through the use of a pixel tag, which is
          industry standard technology used by most major web sites. No
          Personally Identifiable Information is collected during in this
          process. If you do not want {name} to collect this information, you
          may opt out of this service. Opting out will not in any ways affect
          your use of our site. To opt-out of retargeting, please click here
        </p>

        <h3>PRIVACY POLICY UPDATES</h3>

        <p>
          This Privacy Policy is subject to revision, and if we make any
          substantial changes in the way we collect, use, or disclose your
          Personal Information, we will post a notice on this page. If you
          object to any such changes, you must cease using the Sites. Continued
          use of our Sites following notice of any such changes shall indicate
          your acknowledgement of such changes and agreement to be bound by the
          terms and conditions of such changes.
        </p>

        <h3>CONTACT US</h3>

        <p>
          You may contact the Privacy Officer to access, correct or delete your
          Personal Information. If necessary, the Privacy Officer will contact
          another employee to assist in completing your requested task.
        </p>
        <p>
          If you have any questions or complaints, please contact the Privacy
          Officer. The Privacy Officer can be reached by telephone at: (305)
          285-8402; by email at: support [at] foodieorder.com; or by mail at:
        </p>
        <p>Foodie Order</p>

        <p>8004 NW 154th St #162</p>
        <p>Miami Lakes, FL 33016</p>
        <p>Attn.: Privacy Officer</p>

        <p>We will address your concern and attempt to resolve any problem.</p>
      </ModalComponents.WallOfText>
    </ModalComponents.ContentContainer>
  </ModalComponents.ModalContent>
);

PrivacyPolicy.propTypes = {
  restaurantInfo: PropTypes.shape({ name: PropTypes.string }),
  landingUrl: PropTypes.string.isRequired,
};

PrivacyPolicy.defaultProps = {
  restaurantInfo: { name: 'restaurant' },
};

const privacyPolicyHOC = connect(state => ({
  restaurantName: restaurantInfoSelector(state),
  landingUrl: landingUrlSelector(state),
}));

export default privacyPolicyHOC(PrivacyPolicy);
