import get from 'lodash/get';
import map from 'lodash/map';
import ExtendableError from './ExtendableError';

class DistanceError extends ExtendableError {
  static formatMessage = status =>
    `Error was oquiered when compute distance. Error code: ${status}`;
  constructor(status) {
    super(DistanceError.formatMessage(status));
  }
}

const oneRowformatter = distanceMatrix => {
  if (!distanceMatrix.rows) {
    console.error('Matrix has no rows');
    return [];
  }
  return map(
    get(distanceMatrix, ['rows', 0, 'elements'], []),
    ({ distance }) => distance,
  );
};

const DistanceMatrixService = get(window, 'google.maps.DistanceMatrixService');

export default class DistanceService {
  static distanceMatrix = DistanceMatrixService && new DistanceMatrixService();

  static compute(origins, destinations) {
    return new Promise((resolve, reject) => {
      if (!DistanceService.distanceMatrix) {
        reject(
          new Error(
            "Can't find google.maps.DistanceMatrixService class. Check your internet connection.",
          ),
        );
      }
      const payload = {
        origins: Array.isArray(origins) ? origins : [origins],
        destinations: Array.isArray(destinations)
          ? destinations
          : [destinations],
        travelMode: 'DRIVING',
        unitSystem: get(window, 'google.maps.UnitSystem.IMPERIAL', 'IMPERIAL'),
      };
      DistanceService.distanceMatrix.getDistanceMatrix(
        payload,
        (result, status) => {
          if (status !== 'OK') {
            reject(new DistanceError(status));
          }
          resolve(oneRowformatter(result));
        },
      );
    });
  }
}
