import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';

import Button from 'components/Button';

import Modal from 'components/Modal';
import TouchHandler from 'components/TouchHandler';

import CounterItem from './CounterItem';

const Flavors = ({
  description,
  title,
  options,
  maxCount,
  show,
  closeModal,
  variation,
  variationOptions,
  items,
  itemsCount,
  increaseOpt,
  decreaseOpt,
  valid,
  onAcceptClick,
}) => (
  <Modal
    onCloseClick={closeModal}
    show={show}
    title={`Choose ${variation.max} flavors`}
  >
    <ScrollableContentWrapper>
      <TouchHandler>
        <Content>
          <Inner>
            <Information>
              <Top>
                <Title>{variation.comboText}</Title>
                {valid && (
                  <CheckIcon>
                    <Icon name="icon-check-small" size="28px" />
                  </CheckIcon>
                )}
              </Top>
              <Bottom>
                <Description>{variation.description}</Description>
                <Counter active={valid}>
                  {itemsCount}/{variation.max}
                </Counter>
              </Bottom>
            </Information>
            <Counters>
              {items.map(({ id, price, value, name, soldOut }) => (
                <CounterItem
                  value={value}
                  maxValue={variation.max}
                  allItemsCount={itemsCount}
                  onIncClick={increaseOpt(id)}
                  onDecClick={decreaseOpt(id)}
                  key={id}
                  name={name}
                  soldOut={soldOut}
                  price={price}
                />
              ))}
            </Counters>
          </Inner>
          <ButtonWrapper mobile>
            <Button disabled={!valid} onClick={onAcceptClick}>
              ACCEPT
            </Button>
          </ButtonWrapper>
        </Content>
      </TouchHandler>
    </ScrollableContentWrapper>
    <ButtonWrapper>
      <Button disabled={!valid} onClick={onAcceptClick}>
        ACCEPT
      </Button>
    </ButtonWrapper>
  </Modal>
);

Flavors.propTypes = {
  maxCount: PropTypes.number,
  options: PropTypes.object,
  // initialValue: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  onAcceptClick: PropTypes.func,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  variation: PropTypes.object,
  variationOptions: PropTypes.array,
  items: PropTypes.array,
  itemsCount: PropTypes.number,
  increaseOpt: PropTypes.func,
  decreaseOpt: PropTypes.func,
  valid: PropTypes.bool,
};

const Inner = styled.div`
  background: #fff;
  box-shadow: 1px 1px 3px 0 #e7e7e7;
  border-radius: 2px;
`;

const Information = styled.div`
  padding: 15px;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const CheckIcon = styled.div`
  width: 20px;
  height: 15px;
  color: #54a300;
`;

const Counter = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: ${({ active }) => (active ? '#54a300' : 'rgb(116, 119, 120)')};
  font-weight: 300;
  letter-spacing: 0.2px;
`;

const Title = styled.div`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 25px;
  padding-right: 10px;
  flex: 1 1 auto;
  padding-right: 10px;
`;

const Bottom = styled.div`
  line-height: 20px;
  margin-top: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const Description = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: rgb(116, 119, 120);
  font-weight: 300;
  letter-spacing: 0.2px;
  padding-right: 10px;
`;

const Content = styled.div`
  padding: 22px 15px 15px 15px;
`;

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: calc(100% - 74px);

  ${media.tablet`
    height: 100%;
  `};
`;

const ButtonWrapper = styled.div`
  background: #fff;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  display: ${({ mobile }) => (mobile ? 'none' : 'block')};

  ${media.tablet`
    display: ${({ mobile }) => (!mobile ? 'none' : 'block')};
    background: transparent;
    box-shadow: none;
    position: initial;
    padding: 15px 0;
    margin-top: 15px;
  `};
`;

const Counters = styled.div``;

export default Flavors;
