import React from 'react';
import { compose, defaultProps, withProps, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getFixedPrice } from 'utils/currencyHelpers';
import GhostButton from 'components/Button/GhostButton';

const ComboGroup = ({ comboItems, onClick, chooseButtonLabel }) => (
  <ComboGroupStyled>
    {!!comboItems.length && (
      <Text>
        {comboItems.map(({ amount, name, price }) => (
          <ComboItem key={name}>
            <Amount>{amount}</Amount>
            <Name>{name}</Name>
            <Price>+ ${getFixedPrice(price)}</Price>
          </ComboItem>
        ))}
      </Text>
    )}
    <GhostButton onClick={onClick} withArrow>
      {chooseButtonLabel}
    </GhostButton>
  </ComboGroupStyled>
);

ComboGroup.propTypes = {
  comboItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
      price: PropTypes.number,
    }),
  ),
  onClick: PropTypes.func.isRequired,
  chooseButtonLabel: PropTypes.string.isRequired,
};

ComboGroup.defaultProps = {
  comboItems: [],
};

const ComboGroupHOC = compose(
  defaultProps({
    value: {},
  }),
  withProps(({ data, value }) => {
    const flavorIds = Object.entries(value);
    const selectedFlavors = flavorIds.map(([selectedId, val]) => ({
      ...data.options.find(({ id }) => +selectedId === id),
      val,
    }));
    const comboItems = selectedFlavors.map(({ name, val, price }) => ({
      amount: val,
      name,
      price,
    }));
    return { comboItems };
  }),
  withProps(({ value }) => ({
    chooseButtonLabel: Object.keys(value).length ? 'Modify' : 'Choose',
  })),
  withHandlers({
    onClick: ({ data: { id }, onMultiplePickerClick }) => () => {
      onMultiplePickerClick({ variationId: id });
    },
  }),
);

const ComboGroupStyled = styled.div`
  padding: 15px;
  background-color: #fff;
`;

const Text = styled.div`
  display: block;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  background: #fff;
  flex: 1 1 auto;
  font-weight: 300;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  margin-bottom: 15px;
`;

const ComboItem = styled.p`
  line-height: 1.8;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Amount = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const Name = styled.span`
  flex: 1;
`;

const Price = styled.span``;

export default ComboGroupHOC(ComboGroup);
