/* eslint-disable react/require-default-props */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Item, SettingsCard as BaseCard } from 'components/Card';
import PopUp from 'components/PopUp';

import Icon from 'components/Icon';

import FadeInAnimation from 'components/FadeInAnimation';
import { TransitionGroup } from 'react-transition-group';

import { media } from 'utils/mediaQueries';

// ---
// CONSTANTS
// ---
const ANIMATION_DURATION = 200;

const NAME = 'NAME';
const EMAIL = 'EMAIL';
const MOBILE_PHONE = 'MOBILE_PHONE';
const PASSWORD = 'PASSWORD';

export const iconTypes = {
  NAME,
  EMAIL,
  MOBILE_PHONE,
  PASSWORD,
};

const icons = {
  [NAME]: 'icon-my-account-small',
  [EMAIL]: 'icon-email-small',
  [MOBILE_PHONE]: 'icon-mobile-number-small',
  [PASSWORD]: 'icon-sign-in-and-security-small',
};

const titles = {
  [NAME]: 'Name',
  [EMAIL]: 'Email',
  [MOBILE_PHONE]: 'Mobile Number',
  [PASSWORD]: 'Password',
};

// ---
// Controls components
// ---

const ShowModeControls = ({ onClick }) => (
  <Edit onClick={onClick}>
    <EditIcon>
      <Icon name="icon-edit-small" size="24px" />
    </EditIcon>
    <EditLabel> Edit </EditLabel>
  </Edit>
);

ShowModeControls.propTypes = {
  onClick: PropTypes.func,
};

const EditModeControls = ({ onSaveClick, onCancelClick, isValid, dirty }) => (
  <div>
    <CancelLabel onClick={onCancelClick}> Cancel </CancelLabel>
    <SaveLabel onClick={onSaveClick} isValid={isValid || (!isValid && !dirty)}>
      {' '}
      Save{' '}
    </SaveLabel>
  </div>
);

EditModeControls.propTypes = {
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

const Controls = ({
  mode,
  onEditClick,
  onSaveClick,
  onCancelClick,
  isValid,
  dirty,
}) => {
  switch (mode) {
    case 'SHOW':
      return <ShowModeControls onClick={onEditClick} />;
    case 'EDIT':
      return (
        <EditModeControls
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
          isValid={isValid}
          dirty={dirty}
        />
      );
    default:
      return null;
  }
};

Controls.propTypes = {
  mode: PropTypes.oneOf(['SHOW', 'EDIT']),
  onEditClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

// ---
// ANIMATED COMPONENTS
// ---
const AnimatedContent = ({ children, ...props }) => {
  const ENTER_TIMEOUT = 10;
  const EXIT_TIMEOUT = 200;
  return (
    <FadeInAnimation
      {...props}
      exit={false} // Not show animation on unmount
      unmountOnExit
      duration={ANIMATION_DURATION}
      timeout={{ enter: ENTER_TIMEOUT, exit: EXIT_TIMEOUT }}
    >
      {style => <div style={style}>{children}</div>}
    </FadeInAnimation>
  );
};

AnimatedContent.propTypes = {
  children: PropTypes.node,
};

export const ShowModeContent = ({ children, ...props }) => (
  <AnimatedContent {...props}>{children}</AnimatedContent>
);
ShowModeContent.propTypes = { children: PropTypes.node };

export const EditModeContent = ({ children, ...props }) => (
  <AnimatedContent {...props}>{children}</AnimatedContent>
);
EditModeContent.propTypes = { children: PropTypes.node };

class SecurityCard extends Component {
  state = {
    mode: 'SHOW',
  };

  // ---
  // HANDLERS
  // ---
  handleSaveClick = () => {
    const { onCancel, onSave, isValid, dirty } = this.props;
    if (isValid && onSave) {
      this.setState({ mode: 'SHOW' });
      onSave();
    } else if (!dirty && onCancel) {
      this.setState({ mode: 'SHOW' });
      onCancel();
    }
  };

  handleCancelClick = () => {
    const { onCancel } = this.props;
    this.setState({ mode: 'SHOW' });
    if (onCancel) {
      onCancel();
    }
  };

  handleEditClick = () => {
    this.setState({ mode: 'EDIT' });
  };

  render() {
    const { mode } = this.state;
    const {
      type,
      children,
      editModeHeight,
      showModeHeight,
      isValid,
      dirty,
    } = this.props;

    const childrensArray = React.Children.toArray(children);
    const showModeContent = childrensArray.filter(
      c => c.type === ShowModeContent,
    );
    const editModeContent = childrensArray.filter(
      c => c.type === EditModeContent,
    );
    const cardStyle = {
      zIndex: mode === 'SHOW' ? 1 : 10000,
      height: mode === 'SHOW' ? showModeHeight : editModeHeight,
    };
    return (
      <PopUp show={mode === 'EDIT'}>
        <Card style={cardStyle}>
          <Item
            style={{ borderBottom: mode === 'EDIT' && '1px solid #eeefef' }}
          >
            <Header>
              <IconWrapper>
                <Icon
                  name={icons[type]}
                  size={type === 'EMAIL' ? '22px' : '26px'}
                />
              </IconWrapper>{' '}
              <Title>{titles[type]}</Title>
              {editModeContent && !!editModeContent.length && (
                <Controls
                  mode={mode}
                  onSaveClick={this.handleSaveClick}
                  onEditClick={this.handleEditClick}
                  onCancelClick={this.handleCancelClick}
                  isValid={isValid}
                  dirty={dirty}
                />
              )}
            </Header>
          </Item>
          <TransitionGroup>
            {mode === 'SHOW' && showModeContent}
            {mode === 'EDIT' && editModeContent}
          </TransitionGroup>
        </Card>
      </PopUp>
    );
  }
}

SecurityCard.propTypes = {
  editModeHeight: PropTypes.number,
  showModeHeight: PropTypes.number,
  children: PropTypes.node,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.oneOf([NAME, EMAIL, MOBILE_PHONE, PASSWORD]),
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

SecurityCard.defaultProps = {
  editModeHeight: 148,
  showModeHeight: 72,
};

export default SecurityCard;

const Card = BaseCard.extend`
  position: relative;
  background: white;
  overflow: hidden;
  transition: ${ANIMATION_DURATION}ms;
  max-height: ${props => props.maxHeight};
  will-change: max-height;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #5f6263;
`;

const Title = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Header = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const Edit = styled.button`
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-self: flex-start;
  align-items: center;

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Label = styled.button`
  width: auto;
  display: inline;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;

  ${media.desktop`
    cursor: pointer;
  `};
`;

const EditLabel = Label.extend`
  color: rgb(66, 70, 72);
`;

const SaveLabel = Label.extend`
  color: ${p => (p.isValid ? 'rgb(84, 163, 0)' : 'rgb(116, 119, 120)')};
  cursor: ${p => (p.isValid ? 'inherit' : 'default')};
`;

const CancelLabel = Label.extend`
  color: rgb(116, 119, 120);
  margin-right: 15px;
`;

const EditIcon = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  color: #5f6263;
`;

export const Descritption = styled.div`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: rgb(116, 119, 120);
  margin-top: -5px;
  margin-left: 40px;
  padding-bottom: 15px;
`;

export const Input = styled.input`
  flex: 1 1 auto;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  border: 0;
  color: rgb(94, 98, 99);
  outline: none;
  font-weight: 300;
`;
