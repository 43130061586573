import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { commaSplit } from 'utils/commaSplit';
import { formatCents } from 'utils/formatCents';
import IconClean from 'components/IconClean';
import { redeemPointsHOC } from 'routes/Checkout/redeemPointsHOC';

const RedeemPointsStyled = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-bottom: 15px;
  align-items: stretch;
`;

const Info = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  flex: 1;
  margin: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Points = styled.div``;

const Currency = styled.div`
  margin-top: 12px;
  font-size: 30px;
`;

const ButtonContainer = styled.button`
  width: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-width: 120px;
`;

const RedeemButton = styled.div`
  background-color: #54a300;
  color: white;
  padding: 0 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const RedeemCancelButton = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  flex: 1;

  ${IconClean} {
    margin: -12px -28px;
    color: #54a300;
  }
`;

const RedeemPointsComponent = ({
  loyalty: {
    consumer: { points },
  },
  maxApplicablePoints,
  redeemCurrency,
  toggleRedeemCurrency,
}) => (
  <RedeemPointsStyled>
    <Info tabIndex={0}>
      <Points>{commaSplit(points)} available points</Points>
      <Currency>{formatCents(maxApplicablePoints)}</Currency>
    </Info>
    <ButtonContainer onClick={toggleRedeemCurrency} aria-label="Redeem points">
      {redeemCurrency ? (
        <RedeemCancelButton>
          <div>
            <IconClean name="icon-thank-you-small" size="96px" />
          </div>
          <div>REMOVE</div>
        </RedeemCancelButton>
      ) : (
        <RedeemButton>REDEEM</RedeemButton>
      )}
    </ButtonContainer>
  </RedeemPointsStyled>
);

RedeemPointsComponent.propTypes = {
  loyalty: PropTypes.shape({
    points: PropTypes.number,
    pointsInCurrency: PropTypes.number,
  }).isRequired,
  redeemCurrency: PropTypes.number.isRequired,
  maxApplicablePoints: PropTypes.number.isRequired,
  toggleRedeemCurrency: PropTypes.func.isRequired,
};

export const RedeemPoints = redeemPointsHOC(RedeemPointsComponent);
