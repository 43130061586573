import React from 'react';
import { compose, defaultProps, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';

import PizzaPartItem from './PizzaPartItem';

export const PizzaPartGroup = ({ options, value, parts, handleChange }) =>
  options.map(option => (
    <PizzaPartItem
      key={option.id}
      option={option}
      value={value[option.id]}
      parts={parts}
      onChange={handleChange}
      soldOut={option.soldOut}
    />
  ));

PizzaPartGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.objectOf(PropTypes.number),
  onChange: PropTypes.func,
  parts: PropTypes.arrayOf(PropTypes.integer),
};

const PizzaPartGroupHOC = compose(
  defaultProps({
    parts: [-0.5, 0.5, 1],
    value: {},
  }),
  withHandlers({
    handleChange: ({ value, onChange }) => (optionId, sideValue) => {
      onChange(
        pickBy({
          ...value,
          [optionId]: sideValue,
        }),
      );
    },
  }),
  withProps(({ data: { options, max }, value }) => ({
    options:
      max > 0 && Object.keys(value).length >= max
        ? options.map(o => ({
            ...o,
            ...(!(o.id in value) && { disabled: true }),
          }))
        : options,
  })),
);

export default PizzaPartGroupHOC(PizzaPartGroup);
