import React from 'react';
import { compose, defaultProps, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';
import pickBy from 'lodash/pickBy';

import SoldOut from 'components/SoldOut';
import {
  DataContainer,
  DataContainerFirstLine,
  Description,
  ItemButton,
  Name,
} from 'components/Card';
import { getFixedPrice } from 'utils/currencyHelpers';

const RadioGroupContent = ({
  data: { options, priceOverwrite },
  value,
  onChange,
}) =>
  options.map(option => (
    <ItemStyled
      key={option.id}
      onClick={() => onChange(option)}
      disabled={option.soldOut}
    >
      <Circle
        active={Object.keys(value)
          .map(o => +o)
          .includes(+option.id)}
      />
      <DataContainer>
        <DataContainerFirstLine>
          <RadioGroupName
            active={Object.keys(value)
              .map(o => +o)
              .includes(+option.id)}
          >
            {option.name}
          </RadioGroupName>
          {
            {
              [true]: null,
              [!!option.price]: (
                <Price
                  active={Object.keys(value)
                    .map(o => +o)
                    .includes(+option.id)}
                >
                  {!priceOverwrite && '+ '}${getFixedPrice(option.price)}
                </Price>
              ),
              [option.soldOut]: <SoldOut />,
            }.true
          }
        </DataContainerFirstLine>
        {option.description && <Description>{option.description}</Description>}
      </DataContainer>
    </ItemStyled>
  ));

RadioGroupContent.propTypes = {
  data: PropTypes.shape({
    options: PropTypes.array,
    priceOverwrite: PropTypes.bool,
  }).isRequired,
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

const RadioGroupContentHOC = compose(
  defaultProps({
    value: {},
  }),
  withHandlers({
    onChange: ({ onChange, value }) => ({ id, soldOut }) => {
      if (!soldOut) {
        onChange(pickBy({ [id]: value[id] ? undefined : 1 }));
      }
    },
  }),
);

const ItemStyled = styled(ItemButton)`
  align-items: flex-start;
`;

const RadioGroupName = styled(Name)`
  font-weight: ${({ active }) => (active ? '600' : '400')};
`;

const Price = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: ${({ active }) => (active ? '600' : '400')};
`;

const Circle = styled.div`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  border: ${({ active }) => (active ? 'none' : '2px solid #929292')};
  background: ${({ active }) => (active ? '#54a300' : 'transparent')};

  ${media.desktop`
    cursor: pointer;
  `};
`;

export default RadioGroupContentHOC(RadioGroupContent);
