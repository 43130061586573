import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import isEmail from 'utils/isEmail';

const AccountDropdown = ({ value, options, onChange }) => (
  <InputWrapper>
    <ReactSelectStyled>
      <ReactSelect
        name="form-field-name"
        value={value}
        onChange={onChange}
        options={options}
        searchable={false}
      />
    </ReactSelectStyled>
  </InputWrapper>
);

AccountDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

const accountDropdownHOC = compose(
  withProps(({ value, options }) => ({
    options: [
      ...options.map(option => ({
        value: option,
        label: (
          <Label>
            <i className="icon-my-account-small" />
            <span>{option}</span>
            <i className="icon-dropdown-small" />
          </Label>
        ),
      })),
      {
        value: 'changelogin',
        label: `Change ${isEmail(value) ? 'email' : 'number'}`,
      },
    ],
  })),
  withHandlers({
    onChange: ({ onLoginChange }) => ({ value }) => {
      if (value === 'changelogin') {
        if (onLoginChange) {
          onLoginChange();
        }
      }
    },
  }),
);

const InputWrapper = styled.div`
  border-top: 1px solid #eeefef;
  margin-bottom: 24px;
  max-width: 100%;
`;

const Label = styled.div`
  color: #5e6263;
  display: flex;
  align-items: center;
  height: 45px;
  margin: auto 3px auto 8px;
  text-overflow: ellipsis;
  max-width: 100%;
  i {
    &.icon-my-account-small {
      font-size: 24px;
      margin-right: 6px;
    }
    &.icon-dropdown-small {
      font-size: 14px;
      margin: auto 12px;
    }
  }
`;

const ReactSelectStyled = styled.div`
  .Select-control {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    color: #424648;
    font-weight: 400;
    outline: none;
    text-align: left;
    width: 100%;
    border: none;
    border-radius: 2px;
    background: white;
  }

  .Select {
    input {
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      width: 150px;
      border: none;
      margin: 0;
      padding: 0;
      outline: none;
      background: transparent;
    }
  }

  .Select-clear {
    display: none;
  }

  .Select-menu-outer {
    position: relative;

    .Select-menu {
      position: absolute;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      background: #fff;
      z-index: 999;
      padding: 0;
      margin: 0;
    }

    .Select-menu > .Select-option {
      text-align: center;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(66, 70, 72);
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      border-radius: 2px;

      &:not(:first-child) {
        border-top: 1px solid #e1e1e1;
      }

      &:last-child {
        color: #ffffff;
        background-color: #4e4e4e;
      }
      &.is-selected {
        display: none;
      }
    }

    .is-disabled {
      opacity: 0.6;
      font-style: italic;
    }
  }

  .Select-input {
    position: absolute;
  }
`;

export default accountDropdownHOC(AccountDropdown);
