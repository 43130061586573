import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const RootPortal = ({ children }) =>
  createPortal(children, document.getElementById('root-portal'));

RootPortal.propTypes = {
  children: PropTypes.node,
};

export default RootPortal;
