import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import {
  promotionItemsSelector,
  actions as promotionActions,
} from 'redux/modules/promotions';
import { actions as modalActions } from 'components/ReduxModal';

export const PromotionsHOC = compose(
  connect(
    state => ({
      promotions: promotionItemsSelector(state),
    }),
    {
      closeModal: modalActions.closeModal,
      getPromotions: promotionActions.getPromotions,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { promotions, getPromotions } = this.props;
      if (!promotions.length) {
        getPromotions();
      }
    },
  }),
);

export default PromotionsHOC;
