import { compose, defaultProps, withProps, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import addMinutes from 'date-fns/add_minutes';

import {
  actions as deliveryActions,
  deliveryTimeTypes,
  deliverySelector,
} from 'redux/modules/delivery';
import canSoonestSelector from 'selectors/canSoonestSelector';
import canScheduleSelector from 'selectors/canScheduleSelector';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';
import { getNowTime } from 'utils/timeHelpers';

const withOrderDeliveryTimeTypePicker = compose(
  withRouter,
  connect(
    state => ({
      delivery: deliverySelector(state),
      canSoonest: canSoonestSelector(state),
      canSchedule: canScheduleSelector(state),
      asapPreparationTime: asapPreparationTimeSelector(state),
    }),
    {
      setDeliveryTimeType: deliveryActions.setDeliveryTimeType,
      setSoonestTime: deliveryActions.setSoonestTime,
    },
  ),
  defaultProps({
    canSoonest: true,
    canSchedule: true,
    soonestButtonEnabled: false,
    scheduleButtonEnabled: false,
  }),
  withProps(({ delivery: { type, deliveryTimeType, scheduleTime } }) => ({
    type,
    deliveryTimeType,
    scheduleTime,
  })),
  withProps(({ type, canSoonest, canSchedule }) => ({
    soonestButtonEnabled: Boolean(!!type && canSoonest),
    scheduleButtonEnabled: Boolean(!!type && canSchedule),
  })),
  withHandlers({
    onSoonestClick: ({
      asapPreparationTime,
      setSoonestTime,
      history,
    }) => () => {
      setSoonestTime(
        addMinutes(getNowTime(), asapPreparationTime).toISOString(),
        history,
      );
    },
    onScheduleClick: ({ setDeliveryTimeType }) => () => {
      setDeliveryTimeType(deliveryTimeTypes.SCHEDULE);
    },
  }),
  withProps(({ scheduleTime, deliveryTimeType, type }) => ({
    isValid: Boolean(
      !!type &&
        deliveryTimeType &&
        (deliveryTimeType !== 'SCHEDULE' || !!scheduleTime),
    ),
  })),
);

export default withOrderDeliveryTimeTypePicker;
