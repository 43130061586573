import React from 'react';
import PropTypes from 'prop-types';
import registerStepTwoHOC from 'HOC/registerStepTwoHOC';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import TextField from '@material-ui/core/TextField';
import InvisibleSubmit from 'components/InvisibleSubmit';
import ModalComponents from './ModalComponents';

const RegisterStepTwo = ({
  title,
  values: { password1Field, password2Field },
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <TextField
          label="Password"
          placeholder="At least 8 characters"
          value={password1Field}
          name="password1Field"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            touched.password1Field &&
            errors.password1Field &&
            errors.password1Field.length &&
            errors.password1Field.map(err => <p>{err}</p>)
          }
          error={
            touched.password1Field &&
            errors.password1Field &&
            errors.password1Field.length
          }
          fullWidth
        />
        <TextField
          label="Repeat password"
          value={password2Field}
          name="password2Field"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.password2Field && errors.password2Field}
          error={touched.password2Field && errors.password2Field}
          fullWidth
        />
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Next
          </Button>
        </ModalComponents.FormButton>
        <InvisibleSubmit />
      </ModalComponents.Form>
      <ModalComponents.Separator />
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

RegisterStepTwo.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.shape({
    emailField: PropTypes.string,
    password1Field: PropTypes.string,
    password2Field: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    emailField: PropTypes.string,
    password1Field: PropTypes.string,
    password2Field: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    emailField: PropTypes.bool,
    password1Field: PropTypes.bool,
    password2Field: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default registerStepTwoHOC(RegisterStepTwo);
