import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'components/OrderScheduleDateTimePicker/Select';

import Icon from 'components/Icon';
import FixedNumber from 'components/FixedNumber';
import { SpecialInstructions } from 'components/OrderDishesList';
import VariationItem from 'components/VariationItem';

const CartItem = ({
  count,
  name,
  price,
  variations,
  onRemoveClick,
  onEditClick,
  closeCart,
  updateDish,
  counterType,
  specialInstructions,
  readOnly,
  quantityOptions,
}) => (
  <Wrapper
    onClick={() => {
      if (closeCart) {
        closeCart();
      }
      onEditClick();
    }}
    tabIndex={-1}
  >
    {counterType === 'SELECT' && (
      <SelectWrapper onClick={event => event.stopPropagation()} aria-hidden>
        <Select
          onChange={value => {
            updateDish({ count: value.value });
          }}
          iconSmall
          options={quantityOptions}
          value={count}
          placeholder="1"
          searchable={false}
        />
      </SelectWrapper>
    )}
    {counterType === 'COUNTER' && (
      <CounterWrapper>
        <Counter>{count}</Counter>
      </CounterWrapper>
    )}
    <Information>
      <Title
        onClick={event => {
          event.stopPropagation();
          if (closeCart) {
            closeCart();
          }
          onEditClick();
        }}
      >
        {name}
      </Title>
      <Options>
        {variations.map(variation => (
          <VariationItem
            key={`${variation.id}-${JSON.stringify(variation.value)}`}
            variationId={variation.id}
            type={variation.type}
            value={variation.value}
          />
        ))}
      </Options>
      {specialInstructions && (
        <SpecialInstructions>{specialInstructions}</SpecialInstructions>
      )}
    </Information>
    <Actions>
      <Price>
        $<FixedNumber>{price}</FixedNumber>
      </Price>
      {!readOnly && (
        <Remove
          onClick={event => {
            event.stopPropagation();
            onRemoveClick();
          }}
          aria-label="Remove"
        >
          <Icon name="icon-cross-small" size="24px" />
        </Remove>
      )}
    </Actions>
  </Wrapper>
);

CartItem.propTypes = {
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  variations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  closeCart: PropTypes.func,
  updateDish: PropTypes.func.isRequired,
  specialInstructions: PropTypes.string.isRequired,
  counterType: PropTypes.string,
  readOnly: PropTypes.bool,
  quantityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.number,
      value: PropTypes.number,
    }),
  ).isRequired,
};

CartItem.defaultProps = {
  counterType: 'SELECT',
  closeCart: undefined,
  readOnly: false,
};

const Wrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  padding: 20px;
  cursor: pointer;
  min-height: 100px;

  &:hover {
    background: #f7f8f8;
  }
`;

const SelectWrapper = styled.div`
  border: 1px solid #e2e2e2;
  min-width: 47px;
  max-height: 22px;

  .Select-placeholder {
    left: 12px;
    font-size: 12px;
  }
`;

const Information = styled.div`
  flex: 1 1 auto;
  margin-left: 18px;
  margin-top: 4px;
`;

const Title = styled.button`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Options = styled.div`
  margin-top: 5px;
`;

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: flex-start;
`;

const Price = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  margin-right: 14px;
  font-weight: 300;
`;

const Remove = styled.button`
  color: #545859;
  width: 15px;
  height: 15px;
`;

const Counter = styled.div`
  background: #54a30a;
  padding: 3px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 14px;
  font-weight: lighter;
  color: #fff;
  display: inline-block;
  font-weight: 300;
  width: 20px;
  text-align: center;
`;

const CounterWrapper = styled.div``;

export default CartItem;
