import React, { Fragment } from 'react';
import { compose, withStateHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BaseModal from 'components/Modal/BaseModal';
import { ModalHeader } from 'components/Modal';

import orderContentHOC from 'routes/Orders/containers/orderContentHOC';
import UpcomingOrdersContainer from '../../Orders/containers/UpcomingOrdersContainer';
import PastOrdersContainer from '../../Orders/containers/PastOrdersContainer';

const OrderTimeSwitcherItem = ({ children, active, onClick }) => (
  <Item>
    <OrderLink active={active} onClick={onClick}>
      {children}
    </OrderLink>
  </Item>
);

OrderTimeSwitcherItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

const OrdersTimeSwitcher = ({ activeCategory, onCategoryClick }) => (
  <SwitcherContent>
    <OrderTimeSwitcherItem
      active={activeCategory === 'past'}
      onClick={() => onCategoryClick('past')}
    >
      Past Orders
    </OrderTimeSwitcherItem>
    <OrderTimeSwitcherItem
      active={activeCategory === 'upcoming'}
      onClick={() => onCategoryClick('upcoming')}
    >
      Upcoming
    </OrderTimeSwitcherItem>
  </SwitcherContent>
);

OrdersTimeSwitcher.propTypes = {
  activeCategory: PropTypes.oneOf(['past', 'upcoming']),
  onCategoryClick: PropTypes.func,
};

const SwitcherContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const OrderLink = styled.span`
  color: ${props => (props.active ? 'rgb(84, 163, 0)' : 'rgb(116, 121, 122)')};
  font-weight: 300;
  text-decoration: none;
  padding: 0 15px;
  letter-spacing: 0.3px;
  cursor: pointer;
`;

const Item = styled.div`
  position: relative;

  &:last-child {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background: #eee;
    }
  }
`;

const OrderContent = ({ type, ...rest }) => {
  switch (type) {
    case 'past':
      return <PastOrdersContainer {...rest} type={type} />;
    case 'upcoming':
      return <UpcomingOrdersContainer {...rest} type={type} />;
    default:
      return null;
  }
};

OrderContent.propTypes = {
  type: PropTypes.oneOf(['past', 'upcoming']),
  closeModal: PropTypes.func,
};

const OrdersModalContent = ({
  closeModal,
  activeCategory,
  handleCategoryClick,
  ...rest
}) => (
  <Fragment>
    <ModalHeader onCloseClick={closeModal}>
      <OrdersTimeSwitcher
        activeCategory={activeCategory}
        onCategoryClick={handleCategoryClick}
      />
    </ModalHeader>
    <ScrollableContent>
      <Content>
        <OrderContent type={activeCategory} {...rest} closeModal={closeModal} />
      </Content>
    </ScrollableContent>
  </Fragment>
);

OrdersModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
  activeCategory: PropTypes.string.isRequired,
};

const OrdersModalContentHOC = compose(
  orderContentHOC,
  withStateHandlers(
    {
      activeCategory: undefined,
    },
    {
      handleCategoryClick: () => category => ({ activeCategory: category }),
    },
  ),
  withProps(({ activeCategory, upcomingOrders }) => ({
    activeCategory:
      activeCategory || (upcomingOrders.length ? 'upcoming' : 'past'),
  })),
);

const OrdersModalContentWrapped = OrdersModalContentHOC(OrdersModalContent);

const OrdersModal = ({ closeModal, show }) => (
  <BaseModal show={show} title="Orders" onCloseClick={closeModal}>
    {show && <OrdersModalContentWrapped closeModal={closeModal} />}
  </BaseModal>
);

OrdersModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
};

const ScrollableContent = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

const Content = styled.div`
  padding: 0 5px 45px 5px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
`;

export default OrdersModal;
