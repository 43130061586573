import { createSelector } from 'reselect';

import { tipSelector } from 'redux/modules/cart/selectors';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';

const tipAmountSelector = createSelector(
  [tipSelector, cartSubtotalSelector],
  (tip, cartSubtotal) => (tip < 0.01 ? cartSubtotal * 100 * tip : tip) || 0,
);

export default tipAmountSelector;
