import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, Route } from 'react-router-dom';

const DoesMatchRoute = ({ children, path }) => {
  const renderFunc = props => children(!!props.match); // eslint-disable-line
  return <Route path={path}>{renderFunc}</Route>;
};

DoesMatchRoute.propTypes = {
  children: PropTypes.func,
  path: PropTypes.string,
};

const OrdersTimeSwitcher = ({ match }) => (
  <Content>
    <Item>
      <DoesMatchRoute path={`${match.url}/past`}>
        {isMatch => (
          <OrderLink replace active={isMatch} to={`${match.url}/past`}>
            Past Orders
          </OrderLink>
        )}
      </DoesMatchRoute>
    </Item>
    <Item>
      <DoesMatchRoute path={`${match.url}/upcoming`}>
        {isMatch => (
          <OrderLink replace active={isMatch} to={`${match.url}/upcoming`}>
            Upcoming
          </OrderLink>
        )}
      </DoesMatchRoute>
    </Item>
  </Content>
);

OrdersTimeSwitcher.propTypes = {
  match: PropTypes.object,
};

export default OrdersTimeSwitcher;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const OrderLink = styled(Link)`
  color: ${props => (props.active ? 'rgb(84, 163, 0)' : 'rgb(116, 121, 122)')};
  font-weight: 300;
  text-decoration: none;
  padding: 0 15px;
  letter-spacing: 0.3px;
`;

const Item = styled.div`
  position: relative;

  &:last-child {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background: #eee;
    }
  }
`;
