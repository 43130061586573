import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';

import { media } from 'utils/mediaQueries';
import OrderScheduleDateTimePicker from 'components/OrderScheduleDateTimePicker';

const ScheduleOrder = ({ active, onClick, filled }) => (
  <React.Fragment>
    <Wrapper>
      <Top>
        <CheckWrapper active={active && filled}>
          <Icon name="icon-check-small" size="26px" />
        </CheckWrapper>
        <IconWrapper>
          <Icon name="icon-schedule-small" size="26px" />
        </IconWrapper>
        <Label active={active} onClick={onClick}>
          Schedule an order
        </Label>
      </Top>
      {active && <OrderScheduleDateTimePicker />}
    </Wrapper>
    <div id="calendar-container" />
  </React.Fragment>
);

ScheduleOrder.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  filled: PropTypes.bool.isRequired,
};

const Wrapper = styled.div`
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  padding: 13px 15px;
`;

const CheckWrapper = styled.div`
  position: absolute;
  width: 21px;
  height: 16px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 0.2s ease;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #54a30a;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Label = styled.button`
  flex: 1 1 auto;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  padding-left: 10px;
  outline: none;
  font-weight: ${({ active }) => (active ? '400' : '300')};
`;

export default ScheduleOrder;
