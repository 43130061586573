import {
  compose,
  defaultProps,
  withHandlers,
  withProps,
  branch,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import EnterVerificationCode from 'routes/Modals/EnterVerificationCode';
import { actions as userActions } from 'redux/modules/user';
import cleanPhoneNumber from 'utils/cleanPhoneNumber';
import RegisterStepTwo from 'routes/Modals/RegisterStepTwo';
import RegisterStepThree from 'routes/Modals/RegisterStepThree';

const registerHOC = compose(
  defaultProps({ title: 'Register', action: 'account_create' }),
  withRouter,
  connect(null, {
    validateEmail: userActions.validateEmail,
  }),
  withFormik({
    mapPropsToValues: ({
      match: {
        params: { phoneNumber },
      },
    }) => ({
      phoneNumberField: phoneNumber || '',
      phoneNumber: '',
      token: '',
      password: '',
    }),
    validate: ({ phoneNumberField }) => {
      const errors = {};
      const cleanedPhoneNumber = cleanPhoneNumber(phoneNumberField);
      if (!phoneNumberField) {
        errors.phoneNumberField = 'Required';
      }
      if (cleanedPhoneNumber.length < 10) {
        errors.phoneNumberField = 'Enter 10 digits';
      }
      return errors;
    },
    handleSubmit: (
      { phoneNumberField },
      { setFieldValue, setFieldError, props: { validateEmail } },
    ) => {
      validateEmail(
        { email: phoneNumberField },
        () => {
          setFieldError('phoneNumberField', 'Phone number already taken');
        },
        () => {
          setFieldValue('phoneNumber', phoneNumberField);
        },
      );
    },
  }),
  withHandlers({
    onCodeValidated: ({ setFieldValue }) => ({ token }) => {
      setFieldValue('token', token);
    },
    setPassword: ({ setFieldValue }) => password => {
      setFieldValue('password', password);
    },
    onTryAgainClick: ({ resetForm }) => () => {
      resetForm();
    },
  }),
  withProps(({ values: { phoneNumber, token, password } }) => ({
    phoneNumber,
    token,
    password,
  })),
  branch(
    ({ phoneNumber, token, password }) => phoneNumber && token && password,
    renderComponent(RegisterStepThree),
  ),
  branch(
    ({ phoneNumber, token }) => phoneNumber && token,
    renderComponent(RegisterStepTwo),
  ),
  branch(
    ({ phoneNumber }) => !!phoneNumber,
    renderComponent(EnterVerificationCode),
  ),
);

export default registerHOC;
