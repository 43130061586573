import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actions as modalActions } from 'components/ReduxModal';
import Payments from '../components/Payments';

const PaymentsHOC = compose(
  connect(null, {
    closeModal: modalActions.modalGoBack,
  }),
);

export default PaymentsHOC(Payments);
