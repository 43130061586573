import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import deliveryEstimateTextSelector from 'selectors/deliveryEstimatesTextSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { actions as modalActions } from 'components/ReduxModal';
import CartHOC from 'HOC/Cart';

import DesktopCart from '../components/DesktopCart';

export const DesktopCartHOC = compose(
  CartHOC,
  withRouter,
  connect(
    state => ({
      deliveryTimeText: deliveryEstimateTextSelector(state),
      deliveryType: getDeliveryType(state),
    }),
    {
      showModal: modalActions.showModal,
    },
  ),
  withHandlers({
    handleCheckoutClick: ({
      userLoggedIn,
      checkoutButtonEnabled,
      history,
    }) => () => {
      if (checkoutButtonEnabled) {
        if (!userLoggedIn) {
          history.push({
            pathname: '/sign-in',
            state: {
              modal: true,
              afterSignInDo: [{ action: 'historyPush', args: ['/checkout'] }],
            },
          });
        } else {
          history.push('/checkout');
        }
      }
    },
    handleEditTimeClick: ({ deliveryType, closeCart, showModal }) => () => {
      if (deliveryType) {
        showModal('edit-time');
      } else {
        closeCart();
      }
    },
  }),
);

export default DesktopCartHOC(DesktopCart);
