import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import userIsAuthenticatedHOC from 'HOC/userIsAuthenticatedHOC';
import logOutAction from 'redux/modules/user/logout';
import styled from 'styled-components';

import Navigation, { NavigationHeader } from 'components/Navigation';

import Icon from 'components/Icon';
import { Link } from 'react-router-dom';

import ScrollableContent from 'components/ScrollableContent';
import { isUserLoggedInSelector } from 'redux/modules/user';
import { loyaltyEnabledSelector } from 'redux/modules/general';
import SubFooter from 'components/SubFooter';

const MyAccount = ({ isUserLoggedIn, logOut, loyaltyEnabled }) => (
  <Navigation>
    <NavigationHeader>My Account</NavigationHeader>
    <ScrollableContent>
      <Content>
        <Inner>
          {isUserLoggedIn && (
            <Fragment>
              <LinkItem to="/orders">
                <IconWrapper>
                  <Icon name="icon-orders-small" size="28px" />
                </IconWrapper>
                <Label>Orders</Label>
              </LinkItem>
              <LinkItem to="/my-account/adresses">
                <IconWrapper>
                  <Icon name="icon-address-small" size="28px" />
                </IconWrapper>
                <Label>My Addresses</Label>
              </LinkItem>
            </Fragment>
          )}
          <LinkItem to="/promotions">
            <IconWrapper>
              <Icon name="icon-promotions-small" size="28px" />
            </IconWrapper>
            <Label>Promotions</Label>
          </LinkItem>
          {isUserLoggedIn && (
            <Fragment>
              {loyaltyEnabled && (
                <LinkItem to="/myrewards">
                  <IconWrapper>
                    <Icon name="icon-promotions-small" size="28px" />
                  </IconWrapper>
                  <Label>My Rewards</Label>
                </LinkItem>
              )}
              <LinkItem to="/my-account/payments">
                <IconWrapper>
                  <Icon name="icon-payments-small" size="28px" />
                </IconWrapper>
                <Label>Payments</Label>
              </LinkItem>
              <LinkItem to="/my-account/security">
                <IconWrapper>
                  <Icon name="icon-sign-in-and-security-small" size="28px" />
                </IconWrapper>
                <Label>Sign in & Security</Label>
              </LinkItem>
              <Item onClick={logOut}>
                <IconWrapper>
                  <Icon name="icon-logout--small" size="32px" />
                </IconWrapper>
                <Label>Sign out</Label>
              </Item>
            </Fragment>
          )}
        </Inner>
        <SubFooter />
      </Content>
    </ScrollableContent>
  </Navigation>
);

MyAccount.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  loyaltyEnabled: PropTypes.bool.isRequired,
};

const MyAccountHOC = compose(
  withRouter,
  userIsAuthenticatedHOC,
  connect(
    state => ({
      isUserLoggedIn: isUserLoggedInSelector(state),
      loyaltyEnabled: loyaltyEnabledSelector(state),
    }),
    {
      logOut: logOutAction,
    },
  ),
);

export default MyAccountHOC(MyAccount);

const Content = styled.div`
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LinkItem = styled(Link)`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const Item = styled.button`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;

  :not(:first-child) {
    margin-top: 15px;
  }
`;

const IconWrapper = styled.div`
  min-width: 15px;
  min-height: 15px;
  width: 15px;
  height: 15px;
  color: #5e6263;
`;

const Label = styled.div`
  margin-left: 10px;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  flex: 1;
`;
