import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Favorites from '../components/Favorites';
import { closeModal } from 'components/ReduxModal';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeModal: () => closeModal('favorites'),
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Favorites);
