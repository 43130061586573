import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import format from 'date-fns/format';
import isSameMinute from 'date-fns/is_same_minute';
import addMinutes from 'date-fns/add_minutes';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { showIntervalsSelector } from 'selectors/showIntervalsSelector';

import 'rc-time-picker/assets/index.css';

import Select from './Select';

const FORMAT_STRING = 'hh:mma';

const TimePicker = ({
  selectValue,
  onSelectChange,
  selectOptions,
  hideSpacer,
  showSpacer,
}) => (
  <SelectWrapper>
    <Select
      value={selectValue}
      searchable={false}
      options={selectOptions}
      onChange={onSelectChange}
      placeholder="Select Time"
      // onClose={hideSpacer}
      // onOpen={showSpacer}
    />
  </SelectWrapper>
);

TimePicker.propTypes = {
  selectValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  onSelectChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  hideSpacer: PropTypes.func.isRequired,
  showSpacer: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
  selectValue: undefined,
};

const TimePickerHOC = compose(
  connect((state, { deliveryType }) => ({
    minutesStepSize: timeIntervalSelector(state, { deliveryType }),
    showIntervals: showIntervalsSelector(state, { deliveryType }),
  })),
  withProps(({ options, minutesStepSize, showIntervals }) => ({
    selectOptions: [].concat(
      ...options.map(timeEntry => ({
        value: timeEntry.toISOString(),
        label: showIntervals
          ? `${format(timeEntry, FORMAT_STRING)} - ${format(
              addMinutes(timeEntry, minutesStepSize),
              FORMAT_STRING,
            )}`
          : format(timeEntry, FORMAT_STRING),
      })),
    ),
  })),
  withProps(({ value, selectOptions }) => ({
    selectValue: selectOptions.find(option =>
      isSameMinute(option.value, value),
    ),
  })),
  withHandlers({
    onSelectChange: ({ onChange }) => option => {
      onChange(option.value);
    },
  }),
);

const SelectWrapper = styled.div`
  height: 45px;

  .Select-placeholder {
    font-style: italic;
  }
`;

export default TimePickerHOC(TimePicker);
