import { findDOMNode } from 'react-dom';
import { withRouter } from 'react-router-dom';
import {
  compose,
  withProps,
  withHandlers,
  withStateHandlers,
  lifecycle,
  branch,
} from 'recompose';
import { connect } from 'react-redux';

import keys from 'lodash/keys';

import { selectedPaymentSelector } from 'redux/modules/payments';
import {
  promocodeSelector,
  cartItemsSelector,
  backendCartProductsSelector,
  tipSelector,
  specialInstructionsSelector,
  paymentMethodSelector,
  autoPromocodeSelector,
  redeemCurrencySelector,
  actions as cartActions,
} from 'redux/modules/cart';
import withPromocodeValidation from 'HOC/withPromocodeValidation';
import withPromocodeRevalidator from 'HOC/withPromocodeRevalidator';
import { actions as orderActions } from 'redux/modules/orders';
import { actions as modalActions } from 'components/ReduxModal';
import placeIdSelector from 'selectors/placeIdSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import {
  actions as deliveryActions,
  deliverySelector,
} from 'redux/modules/delivery';
import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { selectedUserAddressSelector } from 'selectors/selectedUserAddressSelector';
import {
  scheduleTimeSelector,
  backendScheduleTimeSelector,
} from 'selectors/scheduleTimeSelector';
import destinationAddressSelector from 'selectors/destinationAddressSelector';
import tipsAllowedSelector from 'selectors/tipsAllowedSelector';
import tipAmountSelector from 'selectors/tipAmountSelector';
import tipTypeSelector from 'selectors/tipTypeSelector';
import paymentValidSelector from 'selectors/paymentValidSelector';
import totalPriceSelector from 'selectors/totalPriceSelector';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';
import Checkout from './Checkout';

export const CheckoutHOC = compose(
  withRouter,
  connect(
    state => ({
      type: getDeliveryType(state),
      promocode: promocodeSelector(state),
      dishes: keys(cartItemsSelector(state)),
      cartSubtotal: cartSubtotalSelector(state),
      selectedUserAddress: selectedUserAddressSelector(state),
      scheduleTime: scheduleTimeSelector(state),
      backendScheduleTime: backendScheduleTimeSelector(state),
      deliveryTimeType: deliveryTimeTypeSelector(state),
      backendCartProducts: backendCartProductsSelector(state),
      specialInstructions: specialInstructionsSelector(state),
      tipsAllowed: tipsAllowedSelector(state),
      tipType: tipTypeSelector(state),
      tipAmount: tipAmountSelector(state),
      tipValue: tipSelector(state),
      paymentMethod: paymentMethodSelector(state),
      selectedPayment: selectedPaymentSelector(state),
      destinationAddress: destinationAddressSelector(state),
      paymentValid: paymentValidSelector(state),
      price: totalPriceSelector(state),
      placeId: placeIdSelector(state),
      autoPromocode: autoPromocodeSelector(state),
      redeemCurrency: redeemCurrencySelector(state),
      dineInEnabled: dineInEnabledSelector(state),
      dineInTable: deliverySelector(state)?.dineInTable,
      dineInName: deliverySelector(state)?.dineInName,
      dineInPhone: deliverySelector(state)?.dineInPhone,
    }),
    {
      showModal: modalActions.showModal,
      closeModal: modalActions.closeModal,
      createOrder: orderActions.createOrder,
      setSpecialInstructions: cartActions.setSpecialInstructions,
      setDineInTable: deliveryActions.setDineInTable,
      setDineInName: deliveryActions.setDineInName,
      setDineInPhone: deliveryActions.setDineInPhone,
    },
  ),
  withStateHandlers(
    {
      placeOrderButtonTouched: false,
    },
    {
      touchPlaceOrderButton: () => () => ({ placeOrderButtonTouched: true }),
    },
  ),
  withHandlers({
    setDineInTable: ({ setDineInTable }) => e => {
      setDineInTable(e.target.value);
    },
    setDineInName: ({ setDineInName }) => e => {
      setDineInName(e.target.value);
    },
    setDineInPhone: ({ setDineInPhone }) => e => {
      setDineInPhone(e.target.value);
    },
  }),
  withProps(
    ({
      placeOrderButtonTouched,
      paymentValid,
      cartSubtotal,
      dineInEnabled,
      dineInTable,
      dineInName,
      dineInPhone,
    }) => ({
      orderValid: Boolean(
        paymentValid &&
          cartSubtotal &&
          (!dineInEnabled ||
            (dineInTable &&
              dineInName &&
              dineInPhone &&
              dineInPhone.length === 17)),
      ),
      tableNumberInvalid: Boolean(
        dineInEnabled && placeOrderButtonTouched && !dineInTable,
      ),
      tableNameInvalid: Boolean(
        dineInEnabled && placeOrderButtonTouched && !dineInName,
      ),
      tablePhoneInvalid: Boolean(
        dineInEnabled &&
          placeOrderButtonTouched &&
          (!dineInPhone || dineInPhone.length < 17),
      ),
      paymentInvalid: Boolean(placeOrderButtonTouched && !paymentValid),
    }),
  ),
  withHandlers(() => {
    let scrollableNode = null;
    let paymentsNode = null;
    let tableNumberInputNode = null;
    return {
      onScrollableNodeRef: () => ref => {
        scrollableNode = ref;
      },
      onPaymentsNodeRef: () => ref => {
        paymentsNode = ref;
      },
      onTableNumberInputNodeRef: () => ref => {
        tableNumberInputNode = ref;
      },
      scrollToInvalidCard: ({ tableNumberInvalid, paymentInvalid }) => () => {
        if (tableNumberInvalid || paymentInvalid) {
          // eslint-disable-next-line react/no-find-dom-node
          const invalidDOMNode = findDOMNode(
            (tableNumberInvalid && tableNumberInputNode) ||
              (paymentInvalid && paymentsNode),
          );
          // eslint-disable-next-line react/no-find-dom-node
          const container = findDOMNode(scrollableNode);
          const offset = invalidDOMNode.offsetTop;
          container.scrollTop = offset - 200;
        }
      },
    };
  }),
  withHandlers({
    showModalPromocode: ({ showModal }) => () => showModal('promocode'),
    onSpecialInstructionsChange: ({ setSpecialInstructions }) => value => {
      setSpecialInstructions(value);
    },
    onPlaceOrderClick: ({
      cartSubtotal,
      type,
      deliveryTimeType,
      selectedUserAddress,
      scheduleTime,
      backendScheduleTime,
      backendCartProducts,
      promocode,
      specialInstructions,
      tipsAllowed,
      tipType,
      tipAmount,
      tipValue,
      paymentMethod,
      selectedPayment,
      destinationAddress,
      createOrder,
      orderValid,
      touchPlaceOrderButton,
      scrollToInvalidCard,
      placeId,
      redeemCurrency,
      dineInTable,
      dineInName,
      dineInPhone,
      dineInEnabled,
    }) => () => {
      touchPlaceOrderButton();
      setTimeout(() => {
        if (orderValid) {
          const address = selectedUserAddress && {
            place: selectedUserAddress.address,
            addressDetails: selectedUserAddress.aptSuiteFloor,
            notes: selectedUserAddress.specialInstructions,
            businessName: selectedUserAddress.businessName,
            placeId,
            ...(selectedUserAddress.name && { name: selectedUserAddress.name }),
          };
          const method = {
            type: type.toLowerCase(),
            deliveryType: deliveryTimeType.toLowerCase(),
            ...(address && { address }),
            // HACK: timezone is incorrect. But backend requires it this way.
            date: backendScheduleTime,
          };
          const body = {
            cart: {
              products: backendCartProducts,
            },
            method,
            ...(tipAmount &&
              tipsAllowed && {
                tip: {
                  type: tipType,
                  value:
                    tipType === 'amount'
                      ? tipAmount
                      : Math.round(tipValue * 10000),
                },
              }),
            ...(promocode && { coupons: [promocode] }),
            payment: {
              [paymentMethod === 'CASH']: 'cash',
              [paymentMethod === 'CARD']: 'card',
            }.true,
            ...(paymentMethod === 'CARD' && { selectedCc: selectedPayment.id }),
            cartTotal: cartSubtotal,
            ...(redeemCurrency && { redeemCurrency: redeemCurrency / 100 }),
            specialInstructions,
            dineInTable,
            dineInName,
            dineInPhone,
          };
          const myMethod = {
            type,
            deliveryTimeType,
            destinationAddress,
            scheduleTime,
          };
          createOrder(body, myMethod, dineInEnabled);
        } else {
          scrollToInvalidCard();
        }
      }, 0);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        show,
        match: { url },
        history,
        dishes,
      } = this.props;
      if ((url.includes('/checkout') || show) && dishes.length === 0) {
        history.replace('/menu');
      }
    },
  }),
  branch(
    ({ show, match: { url }, autoPromocode }) =>
      (url.includes('/checkout') || show) && autoPromocode,
    compose(
      withPromocodeValidation(),
      lifecycle({
        componentDidMount() {
          const { autoPromocode, validate } = this.props;
          this.timeout = setTimeout(() => validate(autoPromocode), 250);
        },
        componentWillUnmount() {
          // HACK: prevents requests duplication due to component remounting for an unknown reason
          clearTimeout(this.timeout);
        },
      }),
    ),
  ),
  withPromocodeRevalidator,
);

export default CheckoutHOC(Checkout);
