import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Navigation, { NavigationHeader } from 'components/Navigation';

import { media } from 'utils/mediaQueries';
import ScrollableContent from 'components/ScrollableContent';
import UserSecurityCard from './components/UserSecurityCard';
import EmailSecurityCard from './components/EmailSecurityCard';
import PasswordSecurityCard from './components/PasswordSecurityCard';
import MobilePhoneSecurityCard from './components/MobilePhoneSecurityCard';

class Security extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      values: { email },
      phone,
      errors,
      setFieldValue,
      submitForm,
      resetForm,
      modal,
      isValid,
      dirty,
    } = this.props;
    return (
      <Navigation headerHeight={!modal ? '50px' : '0'}>
        {!modal && <NavigationHeader>Sign in & Security</NavigationHeader>}
        <ScrollableContent>
          <Content>
            <ItemCardWrapper>
              <UserSecurityCard />
            </ItemCardWrapper>
            <ItemCardWrapper>
              <EmailSecurityCard
                onCancel={resetForm}
                onSave={submitForm}
                email={email}
                onChange={value => setFieldValue('email', value)}
                isValid={isValid}
                error={errors.email}
                dirty={dirty}
              />
            </ItemCardWrapper>
            <ItemCardWrapper>
              <MobilePhoneSecurityCard phone={phone} />
            </ItemCardWrapper>
            <ItemCardWrapper>
              <PasswordSecurityCard />
            </ItemCardWrapper>
          </Content>
        </ScrollableContent>
      </Navigation>
    );
  }
}

Security.propTypes = {
  values: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  phone: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  modal: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

Security.defaultProps = {
  modal: false,
};

export default Security;

const Content = styled.div`
  padding: 15px;
  width: 100%;

  ${media.tablet`
    padding: 20px;
  `};
`;

const ItemCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
`;
