import { compose, lifecycle, defaultProps } from 'recompose';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import isEmail from 'utils/isEmail';
import { actions as userActions, userSelector } from 'redux/modules/user';
import Security from '../components/Security';

const SecurityHOC = compose(
  connect(
    state => ({
      ...pick(userSelector(state), ['email', 'phone']),
    }),
    {
      updateUser: userActions.updateUser,
      userInfo: userActions.userInfo,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { userInfo } = this.props;
      userInfo();
    },
  }),
  defaultProps({
    email: '',
    phone: '',
  }),
  withFormik({
    mapPropsToValues: ({ email }) => ({
      email,
    }),
    enableReinitialize: true,
    validate: ({ email }) => {
      const errors = {};
      if (!email) {
        errors.email = 'Required';
      } else if (!isEmail(email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
    handleSubmit: (
      { email, ...values },
      { props: { email: oldEmail, updateUser } },
    ) => {
      const valuesToSubmit = {
        ...values,
        ...(email !== oldEmail && { email }),
      };
      updateUser(valuesToSubmit);
    },
  }),
);

export default SecurityHOC(Security);
