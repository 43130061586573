import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import Button from 'components/Button';
import ModalComponents from 'routes/Modals/ModalComponents';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import InvisibleSubmit from 'components/InvisibleSubmit';
import SubFooter from 'components/SubFooter';
import recoverPasswordHOC from 'HOC/recoverPasswordHOC';

const Recover = ({
  values: { password1, password2 },
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>Reset your password</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <TextField
              label="Enter your new Password"
              placeholder="At least 8 characters"
              value={password1}
              name="password1"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.password1 &&
                errors.password1 &&
                errors.password1.length &&
                errors.password1.map(err => <p>{err}</p>)
              }
              error={
                touched.password1 && errors.password1 && errors.password1.length
              }
              autofocus={autoFocus}
              fullWidth
            />
          )}
        </AutoFocusIfNonTouchDevice>
        <TextField
          label="Repeat your password"
          value={password2}
          name="password2"
          type="password"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.password2 && errors.password2}
          error={touched.password2 && errors.password2}
          fullWidth
        />
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            RESET PASSWORD
          </Button>
        </ModalComponents.FormButton>
        <InvisibleSubmit />
      </ModalComponents.Form>
      <ModalComponents.Separator />
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

Recover.propTypes = {
  values: PropTypes.shape({
    password1: PropTypes.string,
    password2: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    password1: PropTypes.string,
    password2: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    password1: PropTypes.bool,
    password2: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default recoverPasswordHOC(Recover);
