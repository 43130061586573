import { createSelector } from 'reselect';
import deliveryTypeSelector from 'selectors/deliveryTypeSelector';
import mapValues from 'lodash/mapValues';
import schedulesByWeekdaySelector from 'selectors/schedulesByWeekdaySelector';

export const deliveryAvailabilitySchedulesByWeekdaySelector = createSelector(
  [
    schedulesByWeekdaySelector,
    deliveryTypeSelector,
    state => state.delivery.availability,
    (_, props) => props,
  ],
  (schedulesByWeekday, deliveryType, availability, props) => {
    if (props) {
      if (
        props.deliveryType === 'DELIVERY' &&
        props.deliveryConditions &&
        props.deliveryConditions.availability
      ) {
        return mapValues(props.deliveryConditions.availability, v =>
          v.filter(a => a && a.length > 0),
        );
      }
      return schedulesByWeekday;
    }
    if (deliveryType === 'DELIVERY' && availability) {
      return mapValues(availability, v => v.filter(a => a && a.length > 0));
    }
    return schedulesByWeekday;
  },
);
