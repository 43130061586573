import isFinite from 'lodash/isFinite';
import zip from 'lodash/zip';

const SEQUENCE_LENGTH = 4;
const IDENTICAL_CHAR_SEQUENCE_LENGTH = 3;

const sequences = [
  '01234567890',
  '09876543210',
  'qwertyuiop',
  'poiuytrewq',
  'asdfghjkl',
  'lkjhgfdsa',
  'zxcvbnm',
  'mnbvcxz',
  'abcdefghijklmnopqrstuvwxyz',
  'zyxwvutsrqponmlkjihgfedcba',
];

const getSequenceChunks = n =>
  [].concat(
    ...sequences.map(sequence =>
      zip(
        ...Array(n)
          .fill([...sequence])
          .map((x, i) => x.slice(i)),
      )
        .map(arr => arr.join(''))
        .filter(chunk => chunk.length === n),
    ),
  );

const chunkedSequences = getSequenceChunks(SEQUENCE_LENGTH);

// const containsSpecialChars = str => {
//   const pattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
//   return pattern.test(str);
// };

const containsIdenticalChars = str =>
  zip(
    ...Array(IDENTICAL_CHAR_SEQUENCE_LENGTH)
      .fill([...str])
      .map((x, i) => x.slice(i)),
  )
    .map(arr => arr.join(''))
    .filter(chunk => chunk.length === IDENTICAL_CHAR_SEQUENCE_LENGTH)
    .some(chunk => [...new Set(chunk)].length === 1);

export const validatePasswordAllChecks = password => {
  if (!password) {
    return ['Required'];
  }
  return [
    ...(password.length < 8 ? ['At least 8 characters'] : []),
    ...(![...password].some(a => isFinite(+a)) ? ['At least 1 Number'] : []),
    ...(password === password.toLowerCase()
      ? ['At least 1 Capitalized Letter']
      : []),
    ...(password === password.toUpperCase()
      ? ['At least 1 Lowercased Letter']
      : []),
    // ...(!containsSpecialChars(password)
    //   ? ['At least 1 Special Characters (@, #, *, $, etc.)']
    //   : []),
    ...(containsIdenticalChars(password)
      ? [
          'Cannot contain 3 or more identical characters (i.e., "111111" or "aaaaaa")',
        ]
      : []),
    ...(chunkedSequences.some(chunk => password.toLowerCase().includes(chunk))
      ? ['Cannot contain consecutive characters']
      : []),
  ];
};

const validatePassword = password => {
  const errors = validatePasswordAllChecks(password);
  return !!errors.length && errors[0];
};

export default validatePassword;
