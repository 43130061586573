import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import { variationsSelector } from 'redux/modules/variations';
import { optionsSelector } from 'selectors/optionsSelector';

export const expandedVariationsSelector = createSelector(
  variationsSelector,
  optionsSelector,
  (variations, options) =>
    mapValues(variations, variation => ({
      ...variation,
      options: mapValues(variation.options, option => ({
        ...option,
        ...options[`${option.id}`],
      })),
    })),
);
