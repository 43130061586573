import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';

import AddressItem from 'routes/Delivery/components/AddressItem';

const MyAddresses = ({ addresses, onAddAddressClick, onAddressSelect }) => (
  <Content>
    <InputWrapper>
      <AddressesButton onClick={onAddAddressClick}>
        <AdressesIcon>
          <Icon name="icon-address-small" size="30px" />
        </AdressesIcon>{' '}
        <span>Enter a new address</span>
      </AddressesButton>
    </InputWrapper>

    <ItemsWrapper>
      {addresses.map(address => (
        <InputWrapper key={address.id}>
          <AddressItem
            onClick={() => onAddressSelect && onAddressSelect(address.id)}
            name={address.name}
            address={address.address}
            deliveryNotes={address.specialInstructions}
            addressDetails={address.aptSuiteFloor || address.businessName}
          />
        </InputWrapper>
      ))}
    </ItemsWrapper>
  </Content>
);

MyAddresses.propTypes = {
  addresses: PropTypes.array,
  onAddAddressClick: PropTypes.func,
  onAddressSelect: PropTypes.func,
};

export default MyAddresses;

const InputWrapper = styled.div`
  margin-top: 15px;

  :focus-within {
    border: 1px solid #54a300;
  }

  ${media.desktop`
    cursor: pointer;
    
    &:first-child {
      margin-top: 0;
    }
  `};
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const ItemsWrapper = styled.div`
  margin-top: 15px;
`;

const AddressesButton = styled.button`
  padding: 15px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

  span {
    vertical-align: middle;
    margin-left: 10px;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

const AdressesIcon = styled.div`
  display: inline-block;
  width: 14px;
  height: 16px;
  vertical-align: middle;
  color: #5f6263;
`;
