import { createSelector } from 'reselect/lib/index';

import startOfDay from 'date-fns/start_of_day';
import isWithinRange from 'date-fns/is_within_range';
import setHours from 'date-fns/set_hours';
import setMinutes from 'date-fns/set_minutes';

import { getNowTime, strRangeArrayToBusinessHours } from 'utils/timeHelpers';
import { restaurantInfoSelector } from 'redux/modules/general';
import format from 'date-fns/format';

import schedulesByWeekdaySelector from 'selectors/schedulesByWeekdaySelector';

const isAppWorkingSelector = createSelector(
  [schedulesByWeekdaySelector, restaurantInfoSelector],
  (schedulesByWeekday, { appStartingTime, appEndingTime }) => {
    const now = getNowTime();
    const today = startOfDay(now);
    if (!appStartingTime) {
      return true;
    }
    const [appStartHH, appStartMM] = appStartingTime.split(':');
    const appStartToday = setHours(setMinutes(today, +appStartMM), +appStartHH);
    if (appEndingTime) {
      const [appEndHH, appEndMM] = appEndingTime.split(':');
      const appEndToday = setHours(setMinutes(today, +appEndMM), +appEndHH);
      return isWithinRange(now, appStartToday, appEndToday);
    }
    const dayFormatted = format(today, 'YYYY-MM-DD');
    const times = schedulesByWeekday[dayFormatted] || [];
    // eslint-disable-next-line no-unused-vars
    const [_, restaurantsLastMenuEnd] = strRangeArrayToBusinessHours(
      today,
      times,
    );
    return isWithinRange(now, appStartToday, restaurantsLastMenuEnd);
  },
);

// App_starting_time represents the first time in the day the user can start an order.
// If user is Outside ordering time range (BEFORE app_starting_time OR AFTER last menu schedule ends) show this screen:
// app_ending_time (if presents) indicates when consumers are no longer able to use the system.

export default isAppWorkingSelector;
