import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { PersistGate } from 'redux-persist/integration/react';
import { StripeProvider } from 'react-stripe-elements';

import { mediaQueries } from 'utils/mediaQueries';
import { setMode, modes } from 'HOC/ModeManager';
import Routes, { history } from 'routes';
import store, { persistor } from 'redux/store';
import { STRIPE_API_KEY } from 'utils/constants';
import { stripeLoadedSelector } from 'redux/modules/payments';
import { ErrorBoundary } from 'utils/ErrorBoundary';

const DEFAULT_MODE = modes.MOBILE_MODE;

// To load Stripe.js on demand
const ConnectedStripeProvider = connect(state => ({
  stripe: stripeLoadedSelector(state) ? window.Stripe(STRIPE_API_KEY) : null,
}))(StripeProvider);

// window.historyDebug = history;
//
const theme = createMuiTheme({
  palette: {
    primary: { main: '#54A300' },
  },
});

class App extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResizeThrottled);
    this.handleResize();
  }

  handleResize = () => {
    const { mode } = store.getState();
    if (
      window.matchMedia(mediaQueries.tablet).matches &&
      mode !== modes.desktop
    ) {
      store.dispatch(setMode(modes.DESKTOP_MODE));
    } else if (
      !window.matchMedia(mediaQueries.tablet).matches &&
      mode !== DEFAULT_MODE
    ) {
      store.dispatch(setMode(DEFAULT_MODE));
    }
  };

  handleResizeThrottled = throttle(this.handleResize, 300);

  copmonentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeThrottled);
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedStripeProvider>
            <MuiThemeProvider theme={theme}>
              <Router history={history}>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </Router>
            </MuiThemeProvider>
          </ConnectedStripeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
