import React from 'react';
import { compose, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { actions as deliveryActions } from 'redux/modules/delivery/reducer';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Asap from 'components/Asap';
import ScheduleOrderContainer from 'routes/Delivery/components/DeliveryPage/containers/ScheduleOrderContainer';
import Button from 'components/Button';
import withOrderDeliveryTimeTypePicker from 'HOC/withOrderDeliveryTimeTypePicker';
// import Overlay from 'utils/Overlay';
import Icon from 'components/Icon';

const EditTime = ({
  soonestButtonEnabled,
  onSoonestClick,
  deliveryTimeType,
  onScheduleClick,
  scheduleButtonEnabled,
  scheduleTime,
  isValid,
  close,
  apply,
}) => (
  <React.Fragment>
    <Container>
      <Header>
        <Title>Edit Time</Title>
        <CloseButton onClick={close}>
          <Icon name="icon-cross-small" size="24px" />
        </CloseButton>
      </Header>
      {soonestButtonEnabled && (
        <InputWrapper>
          <Asap
            onClick={onSoonestClick}
            active={deliveryTimeType === 'SOONEST'}
          />
        </InputWrapper>
      )}

      {scheduleButtonEnabled && (
        <InputWrapper>
          <ScheduleOrderContainer
            onClick={onScheduleClick}
            active={deliveryTimeType === 'SCHEDULE'}
            scheduleTime={scheduleTime}
          />
        </InputWrapper>
      )}
      {isValid && (
        <ButtonWrapper>
          <Button onClick={apply}>APPLY</Button>
        </ButtonWrapper>
      )}
    </Container>
    <Overlay onClick={close} />
  </React.Fragment>
);

EditTime.propTypes = {
  soonestButtonEnabled: PropTypes.bool.isRequired,
  onSoonestClick: PropTypes.func.isRequired,
  deliveryTimeType: PropTypes.string,
  onScheduleClick: PropTypes.func.isRequired,
  scheduleButtonEnabled: PropTypes.bool.isRequired,
  scheduleTime: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
};

EditTime.defaultProps = {
  scheduleTime: undefined,
  deliveryTimeType: null,
};

const InputWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 16px;
  z-index: 1020;
  position: relative;
  background-color: #f3f4f5;
`;

const Header = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-right: 8px;
`;

const Title = styled.div`
  color: #424648;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 300;
`;

const CloseButton = styled.div``;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  content: '';
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
`;

const editTimeHOC = compose(
  connect(null, {
    snapshotDeliverySettings: deliveryActions.snapshotDeliverySettings,
    rollbackDeliverySettings: deliveryActions.rollbackDeliverySettings,
  }),
  withOrderDeliveryTimeTypePicker,
  lifecycle({
    componentDidMount() {
      const { snapshotDeliverySettings } = this.props;
      snapshotDeliverySettings();
    },
  }),
  withHandlers({
    close: ({ rollbackDeliverySettings, disableEditMode }) => () => {
      rollbackDeliverySettings();
      disableEditMode();
    },
    apply: ({ disableEditMode }) => () => {
      disableEditMode();
    },
  }),
);

export default editTimeHOC(EditTime);
