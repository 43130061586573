import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withPromocodeValidation from 'HOC/withPromocodeValidation';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { promocodeSelector } from 'redux/modules/cart';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { backendScheduleTimeSelector } from 'selectors/scheduleTimeSelector';

const withPromocodeRevalidator = compose(
  withRouter,
  connect(state => ({
    promocode: promocodeSelector(state),
  })),
  compose(
    connect(state => ({
      type: getDeliveryType(state),
      cartSubtotal: cartSubtotalSelector(state),
      backendScheduleTime: backendScheduleTimeSelector(state),
    })),
    withPromocodeValidation({ noSpinner: true }),
    lifecycle({
      componentDidUpdate(prevProps) {
        const { type, cartSubtotal, backendScheduleTime } = this.props;
        if (
          type !== prevProps.type ||
          cartSubtotal !== prevProps.cartSubtotal ||
          backendScheduleTime !== prevProps.backendScheduleTime
        ) {
          const { validate, promocode } = this.props;
          if (promocode) {
            validate(promocode);
          }
        }
      },
    }),
  ),
);

export default withPromocodeRevalidator;
