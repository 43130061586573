import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { actions as modalActions } from 'components/ReduxModal';

export const ThankyouDineInHOC = compose(
  connect(
    state => ({}),
    {
      closeModal: modalActions.closeModal,
    },
  ),
  lifecycle({
    componentDidMount() {
    },
  }),
);

export default ThankyouDineInHOC;
