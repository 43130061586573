import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link, Route, withRouter } from 'react-router-dom';

import Icon from 'components/Icon';

const RouterTabBarItem = ({ icon, children, to, onClick }) => (
  <Route path={to}>
    {({ match }) => (
      <TabBarItemLink to={to} onClick={onClick}>
        <IconWrapper
          active={!!match && (match.path === '/' ? match.isExact : true)}
        >
          <Icon name={icon} size="34px" />
        </IconWrapper>
        <Label active={!!match && (match.path === '/' ? match.isExact : true)}>
          {children}
        </Label>
      </TabBarItemLink>
    )}
  </Route>
);

RouterTabBarItem.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

const TabBarItemLink = styled(Link)`
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 5px 10px;
  text-decoration: none;
`;

const Label = styled.div`
  text-align: center;
  font-size: 11px;
  font-family: 'Roboto';
  color: ${({ active }) => (active ? '#54a300' : 'rgba(66, 70, 72, 0.549)')};
  margin-top: 6px;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: ${({ active }) => (active ? '#54a300' : 'rgba(66, 70, 72, 0.549)')};
`;

export default withRouter(RouterTabBarItem);
