import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Icon from 'components/Icon';
import AddressItem from 'routes/Delivery/components/AddressItem';

import { media } from 'utils/mediaQueries';
import deliveryAddressPickerHOC from './deliveryAddressPickerHOC';

const DeliveryAddressPicker = ({
  userAddresses,
  showDeliveryAddressModal,
  handleAddressClick,
  deliveryValid,
  changeAddressButtonVisible,
  onChangeAddressClick,
  addressError,
  value,
}) => (
  <Item>
    <ErrorFrame error={addressError}>
      <TitleWrapper>
        <Title>Tell us your Address</Title>
        {changeAddressButtonVisible && (
          <ChangeAddressButtonWrapper>
            <Icon name="icon-edit-small" size="22px" />
            <ChangeAddressButton onClick={onChangeAddressClick}>
              Change address
            </ChangeAddressButton>
          </ChangeAddressButtonWrapper>
        )}
        {addressError && <ErrorPill>Required</ErrorPill>}
      </TitleWrapper>
      <InputWrapper>
        <AddressesButton onClick={showDeliveryAddressModal}>
          <AdressesIcon>
            <Icon name="icon-address-small" size="28px" />
          </AdressesIcon>{' '}
          <span>Enter a new address</span>
        </AddressesButton>
      </InputWrapper>
      <TransitionGroup>
        {userAddresses.map(
          address =>
            (!deliveryValid || address.id === value) && (
              <CSSTransition
                key={address.id}
                timeout={500}
                classNames="collapse"
              >
                <InputWrapper key={address.id}>
                  <AddressItem
                    onClick={() => handleAddressClick(address.id)}
                    active={value === address.id}
                    name={address.name}
                    address={address.address}
                    deliveryNotes={address.specialInstructions}
                    addressDetails={
                      address.aptSuiteFloor || address.businessName
                    }
                  />
                </InputWrapper>
              </CSSTransition>
            ),
        )}
      </TransitionGroup>
    </ErrorFrame>
  </Item>
);

DeliveryAddressPicker.propTypes = {
  userAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      aptSuiteFloor: PropTypes.string,
      businessName: PropTypes.string,
      id: PropTypes.string,
      placeId: PropTypes.string,
      name: PropTypes.string,
      selected: PropTypes.bool,
      specialInstructions: PropTypes.string,
    }),
  ).isRequired,
  showDeliveryAddressModal: PropTypes.func.isRequired,
  handleAddressClick: PropTypes.func.isRequired,
  onChangeAddressClick: PropTypes.func.isRequired,
  deliveryValid: PropTypes.bool.isRequired,
  changeAddressButtonVisible: PropTypes.bool.isRequired,
  addressError: PropTypes.string,
  value: PropTypes.string,
};

DeliveryAddressPicker.defaultProps = {
  addressError: undefined,
  value: undefined,
};

const AddressesButton = styled.button`
  padding: 15px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

  span {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

const Item = styled.div`
  padding: 8px 4px 8px;

  &:not(:first-child) {
    border-top: 1px solid #e8e8e8;
  }

  &:first-child {
    margin-top: 10px;
  }

  ${media.tablet`
    &:first-child {
      margin-top: 65px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  `} ${media.desktop`
    
    &:first-child {
      margin-top: 65px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  `};
`;

const ErrorFrame = styled.div`
  border: ${p => p.error && '2px solid red'};
  padding: 16px 11px 12px;
`;

const AdressesIcon = styled.div`
  display: inline-block;
  width: 14px;
  height: 16px;
  vertical-align: middle;
  color: #5f6263;
`;

const InputWrapper = styled.div`
  :focus-within {
    border: 1px solid #54a300;
  }
  cursor: pointer;
  margin-top: 15px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  letter-spacing: 0.2px;
`;

const ChangeAddressButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > :first-child {
    margin-right: 16px;
    margin-bottom: 2px;
  }
`;

const ErrorPill = styled.div`
  background-color: #f40a15;
  border-radius: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  padding: 5px;
`;

const ChangeAddressButton = styled.button`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
`;

export default deliveryAddressPickerHOC(DeliveryAddressPicker);
