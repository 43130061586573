import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import Icon from 'components/Icon';

const DATE_FORMAT = 'hh:mm A';
const CLOSURES_DATE_FORMAT = 'MMM, D hh:mm A';

const SERVICES = {
  delivery: 'Delivery',
  pickup: 'Pickup',
  both: 'Pickup & Delivery',
};

const DeliveryFallbackSchedule = ({
  businessHours,
  restaurantClosedMessage,
  temporaryClosures,
}) => (
  <div>
    <WarningBox>
      <IconContainer>
        <Icon name="icon-menu-availability-small" size="28px" />
      </IconContainer>
      <WarningText>{restaurantClosedMessage}</WarningText>
    </WarningBox>
    <Title>Business Hours</Title>
    <Schedule>
      {businessHours.map(schedule => (
        <Weekday key={schedule.weekday}>
          <WeekdayName>{schedule.weekday}</WeekdayName>
          <Hours>
            {schedule.hours.map(({ start, end }) => (
              <div key={`${start}-${end}`}>
                {format(start, DATE_FORMAT)} - {format(end, DATE_FORMAT)}
              </div>
            ))}
          </Hours>
        </Weekday>
      ))}
    </Schedule>
    {temporaryClosures &&
      !!temporaryClosures.length &&
      temporaryClosures.map(({ from, to, services }) => (
        <React.Fragment key={`${from}-${to}`}>
          <Title>Temporary Closures</Title>
          <Closures services={services}>
            <FromUntil>
              <div>
                <WeekdayName>From</WeekdayName>
                <Hours>{format(from, CLOSURES_DATE_FORMAT)}</Hours>
              </div>
              <div>
                <WeekdayName>Until</WeekdayName>
                <Hours>{format(to, CLOSURES_DATE_FORMAT)}</Hours>
              </div>
            </FromUntil>
            <ClosureType>{SERVICES[services]}</ClosureType>
          </Closures>
        </React.Fragment>
      ))}
  </div>
);

DeliveryFallbackSchedule.propTypes = {
  businessHours: PropTypes.arrayOf(
    PropTypes.shape({
      weekday: PropTypes.string,
      hours: PropTypes.array,
    }),
  ).isRequired,
  restaurantClosedMessage: PropTypes.string.isRequired,
  temporaryClosures: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      services: PropTypes.string,
    }),
  ).isRequired,
};

const WarningBox = styled.div`
  display: flex;
  font: 18px 'Montserrat', sans-serif;
  color: #424648;
  font-weight: 300;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  padding: 10px 20px 8px 8px;
  color: gray;
`;

const WarningText = styled.div``;

const Schedule = styled.div`
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Weekday = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  color: #595e61;
  :nth-child(2n) {
    background-color: #f8f8f8;
  }
`;

const WeekdayName = styled.div`
  font: 17px 'Roboto', sans-serif;
  font-weight: 500;
`;

const Hours = styled.div`
  font: 15px 'Roboto', sans-serif;
  font-weight: 300;
`;

const Title = styled.div`
  font: 18px 'Montserrat', sans-serif;
  color: #424648;
  margin-top: 32px;
  margin-bottom: 20px;
  font-weight: 300;
`;

const Closures = styled.div`
  background-color: ${p =>
    ({
      [true]: '#FFFFFF',
      [p.services === 'both']: '#FFFAE7',
    }.true)};
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 16px;
`;

const FromUntil = styled.div`
  flex: 1;
  padding: 16px 20px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #595e61;
  }
`;

const ClosureType = styled.div`
  border-left: 1px solid #eceded;
  width: 120px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #595e61;
`;

export default DeliveryFallbackSchedule;
