import React from 'react';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card, { Header, Title as CardTitle } from 'components/Card';
import Icon from 'components/Icon';

const SpecialInstructionsCard = ({ value, onChange, placeholder }) => (
  <Card>
    <Header>
      <CardTitle> Special Instructions </CardTitle>
    </Header>
    <Wrapper>
      <IconWrapper>
        <Icon name="icon-note-small" size="25px" />
      </IconWrapper>
      <Textarea value={value} onChange={onChange} placeholder={placeholder} />
    </Wrapper>
  </Card>
);

SpecialInstructionsCard.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

SpecialInstructionsCard.defaultProps = {
  placeholder: 'Add note (extra sauce, no pickles). Charges may apply.',
};

const SpecialInstructionsCardHOC = compose(
  withHandlers({
    onChange: ({ onChange }) => e => {
      onChange(e.target.value);
    },
  }),
);

export default SpecialInstructionsCardHOC(SpecialInstructionsCard);

const Textarea = styled.textarea`
  background: #fff;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  resize: none;
  display: block;
  outline: none;
  width: 100%;
  padding: 15px 15px 15px 40px;
  min-height: 100px;
  border: none;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

const Wrapper = styled.div`
  position: relative;
  :focus-within {
    outline: 1px solid #54a300;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  color: #5e6263;
`;
