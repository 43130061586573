import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { isUserLoggedInSelector } from 'redux/modules/user';

const userIsAuthenticatedHOC = connectedRouterRedirect({
  // The url to redirect auth to if they fail
  redirectPath: '/sign-in',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains auth data
  authenticatedSelector: state => isUserLoggedInSelector(state),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
});

export default userIsAuthenticatedHOC;
