import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getFixedPrice } from 'utils/currencyHelpers';

const Highlight = ({ children }) =>
  children.map(([s, shouldHighlight]) =>
    shouldHighlight ? <b key={s}>{s}</b> : s,
  );

const SearchResult = ({ filteredCategories, onDishClickHandlerCreator }) => (
  <CategoryList>
    {filteredCategories.map(cat => (
      <Category key={cat.id}>
        <CategoryTitle>{cat.name}</CategoryTitle>
        <CategoryItems>
          {cat.items.map(item => (
            <Item
              key={item.id}
              onClick={onDishClickHandlerCreator(item.id)}
              aria-label={item.title}
            >
              <Text>
                <Title>
                  <Highlight>{item.titleHighlitten}</Highlight>
                </Title>
                <Description>
                  <Highlight>{item.descriptionHighlitten}</Highlight>
                </Description>
                <Price>{`$${getFixedPrice(item.price)}`}</Price>
              </Text>
              {item.image && (
                <Image>
                  <img src={item.image} alt={item.title} />
                </Image>
              )}
            </Item>
          ))}
        </CategoryItems>
      </Category>
    ))}
  </CategoryList>
);

SearchResult.propTypes = {
  filteredCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          titleHighlitten: PropTypes.array,
          descriptionHighlitten: PropTypes.array,
        }),
      ),
    }),
  ).isRequired,
  onDishClickHandlerCreator: PropTypes.func.isRequired,
};

const CategoryList = styled.div`
  position: fixed;
  top: 50px;
  z-index: 200;
  margin: 0 15px;
  padding: 15px 0;
  width: calc(100% - 30px);
  max-height: calc(100% - 65px);
  overflow-y: auto;
`;

const Category = styled.div`
  margin-bottom: 18px;
  border-radius: 2px;
`;

const CategoryTitle = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #424648;
  background-color: #e9e9e9;
  padding: 18px 16px;
`;

const CategoryItems = styled.div`
  background-color: #ffffff;
  color: #424648;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
`;

const Item = styled.button`
  display: flex;
  border-top: 1px solid #e9e9e9;
  justify-content: space-between;
  align-items: stretch;
  :first-child {
    border-top: none;
  }
`;

const Text = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
`;

const Image = styled.div`
  height: auto;
  img {
    max-width: 130px;
    display: block;
  }
`;

const Title = styled.div`
  b {
    font-weight: 800;
  }
`;

const Description = styled.div`
  color: #747778;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  b {
    font-weight: 600;
  }
  flex: 1;
  margin-top: 8px;
`;

const Price = styled.div`
  margin-top: 16px;
`;

export default SearchResult;
