import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Item } from 'components/Card';

export const OrderSummaryItem = ({ name, price, isSubtotal }) => (
  <PriceItem isSubtotal={isSubtotal}>
    <Name>{name}</Name>
    <Price>${price.toFixed(2)}</Price>
  </PriceItem>
);

OrderSummaryItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  isSubtotal: PropTypes.bool,
};

export const OrderSummaryDiscountItem = ({ name, price, removePromocode }) => (
  <DiscountItem>
    <Name>{name}</Name>
    {removePromocode && (
      <Remove onClick={removePromocode} aria-label="Remove">
        REMOVE
      </Remove>
    )}
    <Price>
      -$
      {price.toFixed(2)}
    </Price>
  </DiscountItem>
);

OrderSummaryDiscountItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  removePromocode: PropTypes.func,
};

const OrderSummary = ({
  prices,
  total,
  discount,
  redeemCurrency,
  removePromocode,
}) => (
  <Wrapper>
    {prices.map((price, index) => (
      <OrderSummaryItem
        key={index}
        name={price.name}
        price={price.price}
        isSubtotal={price.isSubtotal}
      />
    ))}
    {discount && (
      <OrderSummaryDiscountItem
        name={discount.name}
        price={discount.price}
        removePromocode={removePromocode}
      />
    )}
    {redeemCurrency && (
      <OrderSummaryDiscountItem
        name={redeemCurrency.name}
        price={redeemCurrency.price}
      />
    )}
    <TotalItem>
      <Name>Total</Name>
      <Price>${total.toFixed(2)} </Price>
    </TotalItem>
  </Wrapper>
);

OrderSummary.propTypes = {
  prices: PropTypes.array,
  total: PropTypes.number,
  discount: PropTypes.object,
  redeemCurrency: PropTypes.object,
  removePromocode: PropTypes.func,
};

const Wrapper = styled.div`
  background: #fff;
  border-top: 1px solid #eeefef;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const PriceItem = styled(Item)`
  justify-content: space-between;
  color: rgb(66, 70, 72);
  font-weight: ${p => (p.isSubtotal ? '400' : '300')};
`;

const DiscountItem = styled(Item)`
  justify-content: space-between;
  color: rgb(79, 154, 0);
  font-weight: 400;
`;

const TotalItem = styled(Item)`
  justify-content: space-between;
  font-weight: 400;
  background-color: #f7f7f7;
`;

const Name = styled.div``;

const Price = styled.div``;

const Remove = styled.div`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 24px;
  cursor: pointer;
`;

export default OrderSummary;
