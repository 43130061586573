import { createAction, handleActions } from 'redux-actions';

// ---
// ACTIONS
// ---
export const SHOW_MODAL = 'redux-modal/SHOW_MODAL';
export const CLOSE_MODAL = 'redux-modal/CLOSE_MODAL';
export const MODAL_GO_BACK = 'redux-modal/MODAL_GO_BACK';

// ---
// ACTION CREATORS
// ---
export const showModal = createAction(SHOW_MODAL, (name, payload) => ({
  name,
  payload,
}));
export const closeModal = createAction(CLOSE_MODAL, (name, payload) => ({
  name,
  payload,
}));

const modalGoBack = createAction(MODAL_GO_BACK);

// ---
// INITIAL STATE
// ---
const initialState = {
  name: null,
  payload: null,
  modalHistory: [],
  payloadHistory: [],
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [SHOW_MODAL]: (state, action) => ({
      name: action.payload.name,
      payload: action.payload.payload,
      modalHistory: [...state.modalHistory, action.payload.name],
      payloadHistory: [...state.payloadHistory, action.payload.payload],
    }),
    [CLOSE_MODAL]: () => ({
      ...initialState,
    }),
    [MODAL_GO_BACK]: state => ({
      name: state.modalHistory[state.modalHistory.length - 2] || null,
      payload: state.payloadHistory[state.payloadHistory.length - 2] || null,
      modalHistory: state.modalHistory.slice(0, -1),
      payloadHistory: state.payloadHistory.slice(0, -1),
    }),
  },
  initialState,
);

export const actions = {
  showModal,
  closeModal,
  modalGoBack,
};
