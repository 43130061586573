import { handleActions } from 'redux-actions';
import { actionTypes as generalActionTypes } from 'redux/modules/general';
import pickBy from 'lodash/pickBy';

const initialState = {
  entities: {},
  order: [],
};

export default handleActions(
  {
    [generalActionTypes.GET_GENERAL_SUCCESS]: (state, action) => ({
      ...state,
      ...pickBy({
        entities: action.payload.entities.items,
        order: action.payload.result.items,
      }),
    }),
  },
  initialState,
);

export const actions = {};
