import React from 'react';
import PropTypes from 'prop-types';
import signInHOC from 'HOC/signInHOC';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import PhoneNumberField from 'components/PhoneNumberField';
import ModalComponents from './ModalComponents';

const SignIn = ({
  history,
  location,
  values: { loginField },
  errors,
  touched,
  onLoginFieldChange,
  handleBlur,
  handleSubmit,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>Sign In</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <PhoneNumberField
              label="Enter your Mobile Number"
              value={loginField}
              name="loginField"
              type="tel"
              onChange={onLoginFieldChange}
              onBlur={handleBlur}
              helperText={touched.loginField && errors.loginField}
              error={!!(touched.loginField && errors.loginField)}
              fullWidth
              autoFocus={autoFocus}
            />
          )}
        </AutoFocusIfNonTouchDevice>
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Next
          </Button>
        </ModalComponents.FormButton>
      </ModalComponents.Form>
      <ModalComponents.Separator />
      <ModalComponents.FormButton>
        <Button
          primary={false}
          onClick={() =>
            history.push({
              pathname: '/register',
              state: {
                ...location.state,
                modal: true,
              },
            })
          }
          fullWidth
        >
          Create an account
        </Button>
      </ModalComponents.FormButton>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

SignIn.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.object }).isRequired,
  values: PropTypes.shape({
    loginField: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({ loginField: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ loginField: PropTypes.bool }).isRequired,
  onLoginFieldChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default signInHOC(SignIn);
