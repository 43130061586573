import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import startsWith from 'lodash/startsWith';

import DesktopHeader from 'components/DesktopHeader';
import { actions as modalActions } from 'components/ReduxModal';
import { getDishesCount } from 'redux/modules/cart';
import { firstNameSelector, isUserLoggedInSelector } from 'redux/modules/user';
import { restaurantInfoSelector } from 'redux/modules/general';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import logOutAction from 'redux/modules/user/logout';

const DesktopHeaderHOC = compose(
  withRouter,
  connect(
    state => ({
      itemsCount: getDishesCount(state),
      isUserLoggedIn: isUserLoggedInSelector(state),
      firstName: firstNameSelector(state),
      restaurantInfo: restaurantInfoSelector(state),
      scheduleTime: scheduleTimeSelector(state),
    }),
    {
      showModal: modalActions.showModal,
      logOut: logOutAction,
    },
  ),
  withProps(({ isUserLoggedIn, scheduleTime, location: { pathname } }) => ({
    favoritesButtonActive:
      !!(isUserLoggedIn && scheduleTime) && startsWith(pathname, '/menu'),
  })),
  withStateHandlers(
    ({
      match: {
        params: { show },
      },
    }) => ({
      isMenuOpened: false,
      isCartOpened: show === 'cart' || false,
    }),
    {
      toggleMenu: ({ isMenuOpened }) => () => ({ isMenuOpened: !isMenuOpened }),
      toggleCart: ({ isCartOpened }) => () => ({ isCartOpened: !isCartOpened }),
      closeCart: () => () => ({ isCartOpened: false }),
    },
  ),
  withHandlers({
    showModalLogin: ({ history }) => () => {
      history.push({
        pathname: '/sign-in',
        state: {
          modal: true,
        },
      });
    },
    showModalRegister: ({ history }) => () => {
      history.push({
        pathname: '/register',
        state: {
          modal: true,
        },
      });
    },
    showModalFavorites: ({
      history,
      showModal,
      favoritesButtonActive,
    }) => () => {
      if (favoritesButtonActive) {
        showModal('favorites');
      } else {
        history.push({
          pathname: '/sign-in',
          state: {
            modal: true,
          },
        });
      }
    },
  }),
);

export default DesktopHeaderHOC(DesktopHeader);
