import {
  compose,
  withProps,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import isSameDay from 'date-fns/is_same_day';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/start_of_day';

import { actions as deliveryActions } from 'redux/modules/delivery';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import daysAndTimestampsSelector from 'selectors/daysAndTimestampsSelector';

import OrderScheduleDateTimePicker from './OrderScheduleDateTimePicker';

export const OrderScheduleDateTimePickerHOC = compose(
  withRouter,
  connect(
    state => ({
      daysAndTimestamps: daysAndTimestampsSelector(state),
      scheduleTime: scheduleTimeSelector(state),
    }),
    {
      setScheduleTime: deliveryActions.setScheduleTime,
    },
  ),
  withStateHandlers(
    { calendarVisible: false, spacerVisible: false },
    {
      showCalendar: () => () => {
        setTimeout(() => {
          document.getElementById('calendar-container').scrollIntoView();
        }, 10);
        return {
          calendarVisible: true,
          spacerVisible: false,
        };
      },
      hideCalendar: () => () => ({
        calendarVisible: false,
      }),
      showSpacer: () => () => ({
        spacerVisible: true,
        calendarVisible: false,
      }),
      hideSpacer: () => () => ({
        spacerVisible: false,
      }),
    },
  ),
  withProps(({ scheduleTime }) => ({
    timePickerValue: scheduleTime && parse(scheduleTime),
  })),
  withHandlers({
    setTimePickerValue: ({ setScheduleTime, history }) => value => {
      setScheduleTime(value, history);
    },
  }),
  withStateHandlers(
    ({ daysAndTimestamps, timePickerValue }) => ({
      dayPickerValue:
        (timePickerValue && startOfDay(timePickerValue)) ||
        daysAndTimestamps[0].day,
    }),
    {
      setDayPickerValue: (_, { setTimePickerValue }) => value => {
        setTimePickerValue();
        return { dayPickerValue: value };
      },
    },
  ),
  withPropsOnChange(
    ['daysAndTimestamps', 'dayPickerValue'],
    ({ daysAndTimestamps, dayPickerValue }) => ({
      dayPickerOptions: daysAndTimestamps,
      timePickerOptions:
        !isEmpty(daysAndTimestamps) &&
        daysAndTimestamps.find(({ day }) => isSameDay(day, dayPickerValue))
          .timestamps,
    }),
  ),
  withPropsOnChange(['scheduleTime'], ({ scheduleTime }) => ({
    filled: scheduleTime,
  })),
);

export default OrderScheduleDateTimePickerHOC(OrderScheduleDateTimePicker);
