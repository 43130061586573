import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Security from 'routes/MyAccount/routes/Security';

import Modal from 'components/Modal';
import ScrollableContent from 'components/ScrollableContent';

import { media } from 'utils/mediaQueries';

const SecurityModal = ({ closeModal, show }) => (
  <Modal show={show} onCloseClick={closeModal} title="Sign in & Security">
    <ScrollableContentWrapper>
      <ScrollableContent>
        <Content>
          <Security modal />
        </Content>
      </ScrollableContent>
    </ScrollableContentWrapper>
  </Modal>
);

SecurityModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  padding: 15px 15px 15px 15px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  ${media.tablet`
    padding: 0;
  `};
`;

export default SecurityModal;
