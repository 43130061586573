import fromPairs from 'lodash/fromPairs';
import { SUBDOMAIN } from 'utils/hostname';

export const requiredEnvs = [
  'REACT_APP_GOOGLE_MAPS_API_KEY',
  'REACT_APP_STRIPE_API_KEY',
];

const STRIPE_API_KEY_OVERRIDES = process.env.REACT_APP_STRIPE_API_KEY_OVERRIDES;

const STRIPE_API_KEY_OVERRIDES_PARSED =
  (STRIPE_API_KEY_OVERRIDES &&
    fromPairs(STRIPE_API_KEY_OVERRIDES.split(',').map(p => p.split(':')))) ||
  {};
const OVERRIDDEN_STRIPE_API_KEY = STRIPE_API_KEY_OVERRIDES_PARSED[SUBDOMAIN];

export const STRIPE_API_KEY =
  OVERRIDDEN_STRIPE_API_KEY || process.env.REACT_APP_STRIPE_API_KEY;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const PREPARATION_TIME_TOLERANCE = 10;

export const IDLE_TIMEOUT = 1000 * 60 * 60; // 60 minutes in ms

export const ORDERS_PER_PAGE = 20;

export const SMS_TIMEOUT = 20 * 1000;

export const BUILD_DATE = process.env.REACT_APP_BUILD_DATE || 'dev';

export const SMARTLOOK_KEY = '6b6319d9d78042460b7949c2e2116a56ce398868';

export const SMARTLOOK_BLACKLISTED_DOMAINS = [
  'qa-location1',
  'dev-location1',
  'dev-location6',
];
