import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import isNumber from 'lodash/isNumber';
import { actions as cartActions, tipSelector } from 'redux/modules/cart';
import { orderingSettingsSelector } from 'redux/modules/general';
import { getDeliveryType } from 'selectors/getDeliveryType';
import tipAmountSelector from 'selectors/tipAmountSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import Tip from '../components/Tip';

export const TipHOC = compose(
  connect(
    state => ({
      orderingSettings: orderingSettingsSelector(state),
      tip: tipSelector(state),
      deliveryType: getDeliveryType(state),
      tipAmount: tipAmountSelector(state),
      cartSubtotal: cartSubtotalSelector(state),
    }),
    {
      setTip: cartActions.setTip,
    },
  ),
  withProps(
    ({
      deliveryType,
      orderingSettings: {
        tipEnabled,
        enforceTipUpTo,
        allowPickupTips,
        allowDeliveryTips,
        quickTipPercent,
      },
    }) => ({
      tipEnabled:
        tipEnabled &&
        ((deliveryType === 'DELIVERY' && allowDeliveryTips) ||
          (deliveryType === 'PICKUP' && allowPickupTips)),
      enforceTipUpTo,
      tipChoices: quickTipPercent.map(v => +v / 10000),
    }),
  ),
  withProps(({ cartSubtotal, enforceTipUpTo }) => ({
    maxTipAmount: Number(((cartSubtotal / 100) * enforceTipUpTo).toFixed(2)),
  })),
  withProps(({ tip, tipChoices, tipAmount, maxTipAmount }) => ({
    tipValue: tip,
    selectedTipIndex: !isUndefined(tip)
      ? tipChoices.findIndex(v => tip === v)
      : undefined,
    valid: !!tipAmount && tipAmount <= maxTipAmount,
  })),
  withProps(({ selectedTipIndex, tipValue, tipAmount }) => ({
    displayTipValue: isNumber(selectedTipIndex),
    tipInputEnabled: selectedTipIndex === -1,
    inputTipAmount: tipValue < 0.01 ? tipAmount.toFixed(2) : tipAmount,
  })),
  withProps(({ tipInputEnabled, maxTipAmount, tipValue }) => ({
    maxTipAmountReached: !!(
      tipInputEnabled &&
      tipValue &&
      maxTipAmount === tipValue
    ),
  })),
  withHandlers(() => {
    let element = null;
    return {
      onTipInputRef: () => ref => {
        element = ref;
      },
      getTipInput: () => () => element.refsInput,
    };
  }),
  withHandlers({
    onInputChange: ({ setTip, maxTipAmount }) => (e, value) => {
      const v = +value;
      if (v <= maxTipAmount) {
        setTip(v || 0);
      }
    },
    onTipClick: ({ setTip, tip, tipInputEnabled, getTipInput }) => v => () => {
      if (v ? v === tip : tipInputEnabled) {
        setTip(undefined);
      } else {
        setTip(v || 0);
        if (!v) {
          // focus?
          console.log(getTipInput().focus);
          setTimeout(() => getTipInput().focus(), 200);
        }
      }
    },
    onTipInputFocus: () => e => {
      e.target.setSelectionRange(0, 0);
    },
    onTipInputClick: () => e => {
      e.target.setSelectionRange(0, 0);
      e.target.setSelectionRange(0, 10);
    },
  }),
);

export default TipHOC(Tip);
