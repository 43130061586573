import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';

import { media } from 'utils/mediaQueries';

const PizzaPartSwitcher = ({
  parts,
  onLeftClick,
  onRightClick,
  onFullClick,
  value,
}) => (
  <PizzaPartSwitcherWrapper>
    {parts.includes(-0.5) && (
      <Left
        active={value === -0.5}
        onClick={onLeftClick}
        aria-label="Left Half"
      >
        <Icon name="icon-pizza-left-small" size="50px" />
      </Left>
    )}
    {parts.includes(0.5) && (
      <Right
        active={value === 0.5}
        onClick={onRightClick}
        aria-label="Right Half"
      >
        <Icon name="icon-pizza-right-small" size="50px" />
      </Right>
    )}
    {parts.includes(1) && (
      <Whole active={value === 1} onClick={onFullClick} aria-label="Whole">
        <Icon name="icon-pizza-full-small" size="50px" />
      </Whole>
    )}
  </PizzaPartSwitcherWrapper>
);

PizzaPartSwitcher.propTypes = {
  parts: PropTypes.array,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  onFullClick: PropTypes.func,
  value: PropTypes.number,
};

const PizzaPartSwitcherWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Circle = styled.button`
  min-width: 20px;
  min-height: 22px;
  width: 23px;
  height: 22px;
  color: ${({ active }) => (active ? '#54A30A' : '#95999E')};
  transition: color 0.1s ease;

  &:last-child {
    margin-right: 0;
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

export const Left = styled(Circle)``;

export const Right = styled(Circle)`
  margin: 0 13px;
  ${({ nullMarginRight }) =>
    nullMarginRight ? 'margin-right: 0;' : ''} box-sizing: content-box;
`;

export const Whole = styled(Circle)``;

export default PizzaPartSwitcher;
