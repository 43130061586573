import { compose, withProps, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import { availableOnAwareScheduleFilteredCategoriesSelector } from 'redux/modules/categories';
import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';
import { cartItemsSelector } from 'redux/modules/cart';
import { actions as modalActions } from 'components/ReduxModal';
import searchAndHighlightSubstring from 'utils/searchAndHighlightSubstring';

const menuSearchHOC = compose(
  connect(
    state => ({
      categories: availableOnAwareScheduleFilteredCategoriesSelector(state),
      items: availableAtDateItemsSelector(state),
      basketDishes: cartItemsSelector(state),
    }),
    {
      showModal: modalActions.showModal,
    },
  ),
  withStateHandlers(
    {
      searchInput: '',
    },
    { handleSearchInputChange: () => e => ({ searchInput: e.target.value }) },
  ),
  withProps(({ categories, items, searchInput }) => {
    const searchInputLower = searchInput.toLowerCase();
    if (searchInput.length <= 1) {
      return {
        filteredCategories: [],
      };
    }
    const filteredItems = filter(
      items,
      ({ title, description }) =>
        title.toLowerCase().includes(searchInputLower) ||
        description.toLowerCase().includes(searchInputLower),
    );
    const filteredCategories = categories
      .map(c => ({
        ...c,
        hasItemsWithSearchString: Boolean(
          intersection(
            c.items,
            filteredItems.map(({ id }) => id),
          ).length,
        ),
      }))
      .filter(({ hasItemsWithSearchString }) => hasItemsWithSearchString)
      .map(({ items: itemIDs, ...rest }) => ({
        ...rest,
        items: itemIDs
          .filter(item => filteredItems.map(({ id }) => id).includes(item))
          .map(id => items[id])
          .map(({ title, description, ...restItemProps }) => ({
            ...restItemProps,
            title,
            description,
            titleHighlitten: searchAndHighlightSubstring(title, searchInput),
            descriptionHighlitten: searchAndHighlightSubstring(
              description,
              searchInput,
            ),
          })),
      }));
    return {
      filteredCategories,
      filteredItems,
    };
  }),
  withHandlers({
    onDishClickHandlerCreator: ({ showModal }) => id => () => {
      showModal('dish-details', { dishId: id });
    },
  }),
);

export default menuSearchHOC;
