import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import keys from 'lodash/keys';

import { getDishesCount, cartItemsSelector } from 'redux/modules/cart';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import deliveryEstimateTextSelector from 'selectors/deliveryEstimatesTextSelector';
import { isUserLoggedInSelector } from 'redux/modules/user';
import minimumOrderSelector from 'selectors/minimumOrderSelector';

const CartHOC = compose(
  connect(state => ({
    itemsCount: getDishesCount(state),
    subtotal: cartSubtotalSelector(state),
    dishes: keys(cartItemsSelector(state)),
    userLoggedIn: isUserLoggedInSelector(state),
    minimumOrder: minimumOrderSelector(state),
    deliveryTimeText: deliveryEstimateTextSelector(state),
  })),
  withPropsOnChange(
    ['dishes', 'subtotal'],
    ({ dishes, subtotal, minimumOrder }) => ({
      checkoutButtonEnabled: dishes.length > 0 && subtotal >= minimumOrder,
      minimumOrderWarningDiff:
        (dishes.length > 0 && Math.max(minimumOrder - subtotal, 0)) || 0,
    }),
  ),
);

export default CartHOC;
