import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';
import mergeWith from 'lodash/mergeWith';
import { menusEntitiesSelector } from 'redux/modules/menus';

const schedulesByWeekdaySelector = createSelector(
  [menusEntitiesSelector],
  menus => {
    const schedules = Object.values(menus).map(v => v.schedule);
    const zipped = mergeWith({}, ...schedules, (first, second) =>
      first ? [...first, second] : [second],
    );
    const flatSchedule = mapValues(zipped, v => flatten(v));
    const flatScheduleFiltered = mapValues(flatSchedule, v =>
      v.filter(a => a && a.length > 0),
    );
    return flatScheduleFiltered;
  },
);

export default schedulesByWeekdaySelector;
