import { createAction, handleActions } from 'redux-actions';

// ---
// CONSTANTS
// ---
export const FETCH_RESTAURANTS = 'organisation/FETCH_RESTAURANTS';
export const SET_CURRENT_RESTAURANT_ID =
  'organisation/SET_CURRENT_RESTAURANT_ID';
export const FETCH_RESTAURANT_DETAILS = 'organisation/FETCH_RESTAURANT_DETAILS';
export const FETCH_ORGANISATION = 'organisation/FETCH_ORGANISATION';

// ---
// ACTION_CREATORS
// ---
export const fetchRestaurants = createAction(FETCH_RESTAURANTS);
export const setCurrrentRestaurantId = createAction(SET_CURRENT_RESTAURANT_ID);
export const fetchRestaurantDetails = createAction(FETCH_RESTAURANT_DETAILS);
export const fetchOrganisation = '';

// ---
// INITIAL_STATE
// ---
const initialState = {
  name: 'Polo Norte',
  restaurantsById: {
    1: {
      id: '1',
      name: 'Kendall',
      address: '13901 SW 42nd St. Miami, FL 33175',
      deliveryFrom: 10,
      afternoonMenuFrom: '13:00',
    },
    2: {
      id: '2',
      name: 'Hialeah',
      address: '668 NW 5th St., Miami, FL 33128-1622',
      deliveryFrom: 0,
      afternoonMenuFrom: '15:00',
    },
    3: {
      id: '3',
      name: 'Palm Springs North',
      address: '13901 SW 42nd St. Miami, FL 33175',
      deliveryFrom: 0,
      afternoonMenuFrom: '12:00',
    },
  },
  currentRestaurantId: null,
  menusById: null,
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [FETCH_RESTAURANTS]: (state, action) => ({
      ...state,
      restaurantsById: action.payload,
    }),
    [SET_CURRENT_RESTAURANT_ID]: (state, action) => ({
      ...state,
      currentRestaurantId: action.payload,
    }),
    [FETCH_RESTAURANT_DETAILS]: (state, action) => ({
      ...state,
      restaurantsById: {
        ...state.restaurantsById,
        [action.payload.id]: {
          ...state.restaurantsById[action.payload.id],
          ...action.payload,
        },
      },
    }),
  },
  initialState,
);

export const actions = {
  fetchRestaurants,
  setCurrrentRestaurantId,
  fetchRestaurantDetails,
};
