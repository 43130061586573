import { createSelector } from 'reselect';

import sum from 'lodash/sumBy';
import get from 'lodash/get';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';

import { cartItemsSelector } from 'redux/modules/cart/selectors';
import { expandedVariationsSelector } from 'selectors/expandedVariationsSelector';
import itemsWithOverwrittenPriceSelector from 'selectors/itemsWithOverwrittenPriceSelector';
import { taxPercentageSelector } from 'redux/modules/general';

// Keep this logic in sync with configuredDishVariationsSelector
const cartItemsWithPriceAndTaxesSelector = createSelector(
  [
    cartItemsSelector,
    expandedVariationsSelector,
    itemsWithOverwrittenPriceSelector,
    taxPercentageSelector,
  ],
  (cartItems, expandedVariations, items, taxPercentage) =>
    mapValues(cartItems, item => {
      let itemTotal = 0;
      let taxTotal = 0;
      try {
        const { count, dishId, variations } = item;
        const dish = get(items, dishId);
        const variationsPrice = sum(
          Object.values(variations).map(({ id, value }) => {
            const { options, type, chargeMoreExpensive } = get(
              expandedVariations,
              id,
            );
            const optionPrices = Object.entries(value).map(
              ([optionId, optionValue]) => {
                const option = find(options, opt => opt.id === +optionId);
                return +option.partialPrice && Math.abs(optionValue) === 0.5
                  ? +option.partialPrice
                  : Math.max(optionValue, 1) * +option.price;
              },
            );
            return chargeMoreExpensive && type === 'mix_and_match'
              ? Math.max(...optionPrices)
              : sum(optionPrices);
          }),
        );
        itemTotal = count * (+dish.price + variationsPrice);
        const { taxable } = dish;
        if (taxable) {
          taxTotal = (itemTotal * taxPercentage) / 100;
        }
      } catch (e) {
        console.log('cartItemsWithPriceAndTaxesSelector error', e);
      }
      return {
        ...item,
        itemTotal,
        taxTotal,
      };
    }),
);

export default cartItemsWithPriceAndTaxesSelector;
