import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import styled from 'styled-components';

const PopupStyled = styled.div`
  pointer-events: all;
  cursor: default;
  text-align: center;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px;
  padding: 40px 24px 24px;
  width: calc(100% - 30px);
  max-width: 400px;
`;

const HeaderStyled = styled.div`
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 0.91;
`;

const MessageStyled = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.322;
  font-weight: 300;
  margin: 20px 30px 40px;
`;

const ActionsStyled = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  flex: 1 0 auto;
`;

const ButtonStyled = styled.button`
  width: auto;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  margin: 0 20px 0;
`;

const SpacerStyled = styled.div`
  width: 24px;
`;

const InformPopup = ({
  title,
  message,
  buttonChoice,
  showCancelButton,
  onCloseClick,
  cancelButtonText,
  onButtonClick,
  buttonText,
}) => (
  <PopupStyled>
    <HeaderStyled>{title || ''}</HeaderStyled>
    <MessageStyled>{message}</MessageStyled>
    {buttonChoice ? (
      <ActionsStyled>
        <ButtonWrapper>
          {showCancelButton && (
            <Button onClick={onCloseClick} primary={false}>
              {cancelButtonText}
            </Button>
          )}
        </ButtonWrapper>
        <SpacerStyled />
        <ButtonWrapper>
          <Button onClick={onButtonClick}>{buttonText}</Button>
        </ButtonWrapper>
      </ActionsStyled>
    ) : (
      <div>
        {showCancelButton && (
          <ButtonStyled onClick={onCloseClick}>{cancelButtonText}</ButtonStyled>
        )}
        <ButtonStyled onClick={onButtonClick}>{buttonText}</ButtonStyled>
      </div>
    )}
  </PopupStyled>
);

InformPopup.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonChoice: PropTypes.bool.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default InformPopup;
