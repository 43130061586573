import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeInAnimation from 'components/FadeInAnimation';

const Aux = props => props.children;

class PopUp extends Component {
  render() {
    const { children, errorPopup, onOutsideClick, show = true } = this.props;
    return (
      <Aux>
        <FadeInAnimation
          unmountOnExit
          appear
          in={show}
          duration={350}
          timeout={{ enter: 10, exit: 350 }}
        >
          {style => (
            <PopUpOverlay
              errorPopup={errorPopup}
              style={style}
              onClick={onOutsideClick}
            />
          )}
        </FadeInAnimation>
        {children}
      </Aux>
    );
  }
}

PopUp.propTypes = {
  children: PropTypes.node,
  onOutsideClick: PropTypes.func,
  show: PropTypes.bool,
  errorPopup: PropTypes.bool,
};

PopUp.defaultProps = {
  show: true,
};

export default PopUp;

const PopUpOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${({ errorPopup }) => (errorPopup ? '50' : '30')};
  transform: translate3d(0, 0, 0);
`;
