import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';
import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';

export const favoritesSelector = state => state.favorites;
export const favoriteEntitiesSelector = createSelector(
  favoritesSelector,
  favorites => favorites.entities,
);

export const favoriteItemsSelector = createSelector(
  [favoriteEntitiesSelector, availableAtDateItemsSelector],
  (favorites, items) => pickBy(items, item => favorites.includes(item.id)),
);
