import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card, { Header, Title, Edit, Item, Label } from 'components/Card';

import PopUp from 'components/PopUp';

import Icon from 'components/Icon';
import IconClean from 'components/IconClean';

export const ThankYouPageDeliveryTimeInfo = ({
  deliveryDay,
  deliveryTime,
  deliveryType,
  dineInEnabled,
}) => (
  <Item>
    <Clock>
      <Icon name="icon-soonest-available-small" size="30px" />
    </Clock>
    <ThankYouPageText tabIndex={0}>
      {
        {
          [true]: 'Not set',
          [deliveryType === 'DELIVERY']: 'Delivery',
          [deliveryType === 'PICKUP']: 'Pickup',
          [deliveryType === 'PICKUP' && dineInEnabled]: 'Dine-in',
        }.true
      }
      : {deliveryDay} <span>{deliveryTime}</span>
    </ThankYouPageText>
  </Item>
);

ThankYouPageDeliveryTimeInfo.propTypes = {
  deliveryDay: PropTypes.string.isRequired,
  deliveryTime: PropTypes.string.isRequired,
  deliveryType: PropTypes.string.isRequired,
  dineInEnabled: PropTypes.bool.isRequired,
};

export const DeliveryTimeInfo = ({
  deliveryEstimateText,
  onDeliveryTimeClick,
}) => (
  <Item>
    <Clock>
      <Icon name="icon-soonest-available-small" size="30px" />
    </Clock>
    <Text>
      <span onClick={onDeliveryTimeClick}>{deliveryEstimateText}</span>
    </Text>
  </Item>
);

DeliveryTimeInfo.propTypes = {
  deliveryEstimateText: PropTypes.string.isRequired,
  onDeliveryTimeClick: PropTypes.func.isRequired,
};

class DishesList extends Component {
  wrapInPopUp = content => {
    const { onEditClick } = this.props;
    return (
      <PopUp onOutsideClick={onEditClick}>
        <PopUpContent>{content}</PopUpContent>
      </PopUp>
    );
  };

  renderContent = () => {
    const { subtotal, onEditClick, children, type, readOnly } = this.props;
    return (
      <Card>
        <Header>
          <Title> Item List </Title>
          {!readOnly && type === 'REMOVE' && (
            <Edit onClick={onEditClick}>
              <Inner>
                <IconClean name="icon-edit-small" size="22px" />
                <span>EDIT</span>
              </Inner>
            </Edit>
          )}
          {!readOnly && type === 'EDIT' && (
            <Label onClick={onEditClick}> DONE </Label>
          )}
        </Header>
        {children}
        <Footer>
          <Txt>Subtotal</Txt>
          <Amount>${subtotal.toFixed(2)}</Amount>
        </Footer>
      </Card>
    );
  };

  render() {
    const { type } = this.props;
    return type === 'EDIT'
      ? this.wrapInPopUp(this.renderContent())
      : this.renderContent();
  }
}

DishesList.propTypes = {
  children: PropTypes.node,
  subtotal: PropTypes.number,
  onEditClick: PropTypes.func,
  type: PropTypes.oneOf(['REMOVE', 'EDIT']),
  readOnly: PropTypes.bool,
};

DishesList.defaultProps = {
  subtotal: 0,
  readOnly: false,
};

const Text = styled.div`
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
`;

const ThankYouPageText = styled.div`
  margin-left: 5px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: rgb(66, 70, 72);
`;

const Clock = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  color: #5f6263;
`;

const Footer = styled.div`
  border-top: 1px solid #eeefef;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #fbfbfb;
`;

const Txt = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Amount = styled.div`
  font-size: 17px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
`;

const PopUpContent = styled.div`
  position: relative;
  z-index: 10000;
`;

const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export default DishesList;
