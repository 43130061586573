import { lifecycle, renderNothing } from 'recompose';

const DocumentElementStylesHelper = lifecycle({
  componentDidMount() {
    document.documentElement.classList.add('no-scroll');
  },
  componentWillUnmount() {
    document.documentElement.classList.remove('no-scroll');
  },
})(renderNothing());

export default DocumentElementStylesHelper;
