import mapValues from 'lodash/mapValues';
import { css } from 'styled-components';

export const mediaQueries = {
  mobile: '(min-width: 320px)',
  tablet: '(min-width: 820px), (orientation: portrait) and (min-width: 768px)',
  desktop: '(min-width: 1155px)',
  350: '(min-width: 350px)',
  400: '(min-width: 400px)',
};

export const media = mapValues(mediaQueries, v => (...args) => css`
  @media ${v} {
    ${css(...args)};
  }
`);
