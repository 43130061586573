import { createSelector } from 'reselect/lib/index';
import { deliveryAvailabilitySchedulesByWeekdaySelector } from 'selectors/deliveryAvailabilitySchedulesByWeekdaySelector';
import getPreparationPlusDeliveryMinutesForTimeFunc from 'selectors/getPreparationPlusDeliveryMinutesForTimeFunc';
import getPreparationMinutesForTimeFunc from 'selectors/getPreparationMinutesForTimeFunc';

import range from 'lodash/range';
import last from 'lodash/last';
import uniqWith from 'lodash/uniqWith';

import startOfDay from 'date-fns/start_of_day';
import addDays from 'date-fns/add_days';
import setMinutes from 'date-fns/set_minutes';
import addHours from 'date-fns/add_hours';
import addMinutes from 'date-fns/add_minutes';
import isBefore from 'date-fns/is_before';
import compareAsc from 'date-fns/compare_asc';
import isEqual from 'date-fns/is_equal';
import isWithinRange from 'date-fns/is_within_range';
import format from 'date-fns/format';

import {
  getNowTime,
  strRangeArrayToBusinessHours,
  strRangeToDatetimes,
} from 'utils/timeHelpers';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { orderAheadInfoSelector } from 'redux/modules/general';
import { PREPARATION_TIME_TOLERANCE } from 'utils/constants';

const daysAndTimestampsSelector = createSelector(
  [
    getPreparationMinutesForTimeFunc,
    deliveryAvailabilitySchedulesByWeekdaySelector,
    orderAheadInfoSelector,
    timeIntervalSelector,
    getPreparationPlusDeliveryMinutesForTimeFunc,
  ],
  (
    getPreparationMinutesForTime,
    schedulesByWeekday,
    orderAheadInfo,
    minutesStepSize,
    getPreparationPlusDeliveryMinutesForTime,
  ) => {
    // const orderAheadTime = +orderAheadInfo.orderAheadTime || 0
    const maxDaysInAdvance = +orderAheadInfo.maxDaysInAdvance || 0;
    const minDaysInAdvance = +orderAheadInfo.minDaysInAdvance || 0;
    const now = getNowTime();
    const today = startOfDay(now);
    const preparationTimeValue = getPreparationPlusDeliveryMinutesForTime(now);
    const afterPreparationTimeForNow = addMinutes(
      now,
      preparationTimeValue + PREPARATION_TIME_TOLERANCE,
    );
    const daysRange = range(minDaysInAdvance, maxDaysInAdvance + 1);
    const daysAndTimestamps = daysRange.map(dayAhead => {
      const day = addDays(today, dayAhead);
      const dayFormatted = format(day, 'YYYY-MM-DD');
      const times = schedulesByWeekday[dayFormatted];
      const timeRanges =
        (Array.isArray(times) &&
          times.map(strRange => strRangeToDatetimes(day, strRange))) ||
        [];
      const businessHoursRange = strRangeArrayToBusinessHours(day, times);
      const [businessHoursStart, businessHoursEnd] = businessHoursRange;
      const validTimes = [].concat(
        ...timeRanges.map(({ start }) => {
          const possibleClosest = [
            setMinutes(start, 0),
            setMinutes(start, 15),
            setMinutes(start, 30),
            setMinutes(start, 45),
            setMinutes(addHours(start, 1), 0),
          ];
          const firstAvailableTimestamp = possibleClosest.find(
            v => !isBefore(v, start),
          );
          const result = [firstAvailableTimestamp];
          while (true) {
            const nextTime = addMinutes(last(result), minutesStepSize);
            if (
              timeRanges.some(({ start: rangeStart, end: rangeEnd }) =>
                isWithinRange(nextTime, rangeStart, rangeEnd),
              )
            ) {
              result.push(nextTime);
            } else {
              break;
            }
          }
          const withoutTooLate = result.filter(
            v => v >= afterPreparationTimeForNow,
          );
          const withinWorkingHoursAfterPreparation = withoutTooLate.filter(
            v => {
              try {
                return isWithinRange(
                  v,
                  addMinutes(
                    businessHoursStart,
                    getPreparationPlusDeliveryMinutesForTime(v) -
                      getPreparationMinutesForTime(v),
                  ),
                  businessHoursEnd,
                );
              } catch (err) {
                return false;
              }
            },
          );
          return withinWorkingHoursAfterPreparation;
        }),
      );
      const validTimesSorted = validTimes.sort(compareAsc);
      const validTimesFiltered = uniqWith(validTimesSorted, isEqual);
      return { day, timeRanges, timestamps: validTimesFiltered };
    });
    return daysAndTimestamps.filter(d => d.timestamps.length);
  },
);

export default daysAndTimestampsSelector;
