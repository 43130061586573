import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import { isUserLoggedInSelector } from 'redux/modules/user';
import { actions as modalActions } from 'components/ReduxModal';

const NavigationHeaderHOC = compose(
  withRouter,
  connect(
    state => ({
      isUserLoggedIn: isUserLoggedInSelector(state),
      scheduleTime: scheduleTimeSelector(state),
    }),
    {
      showModal: modalActions.showModal,
    },
  ),
  withProps(({ isUserLoggedIn, scheduleTime, backPath }) => ({
    favoritesButtonActive: !!(isUserLoggedIn && scheduleTime),
    backButtonVisible: backPath !== '/',
  })),
  withHandlers({
    showLoginModal: ({ history }) => () => {
      history.push({
        pathname: '/sign-in',
        state: {
          modal: true,
        },
      });
    },
    backButtonHandler: ({ onBackClick, backPath, history }) => () => {
      if (onBackClick) {
        onBackClick();
      }
      if (!backPath) {
        history.goBack();
      } else {
        history.replace(backPath);
      }
    },
  }),
  withStateHandlers(
    { searchBarVisible: false },
    {
      showSearchBar: () => () => ({ searchBarVisible: true }),
      hideSearchBar: () => () => ({ searchBarVisible: false }),
    },
  ),
);

export default NavigationHeaderHOC;
