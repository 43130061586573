import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';

import Modal from 'components/Modal';
import ScrollableContent from 'components/ScrollableContent';

import { media } from 'utils/mediaQueries';
import InputFocusWrapper from 'components/InputFocusWrapper';

const PromoPopup = ({
  show,
  modalGoBack,
  value,
  handleChange,
  handleApplyClick,
}) => (
  <Modal show={show} onCloseClick={modalGoBack} title="Promotions">
    <ScrollableContentWrapper>
      <ScrollableContent>
        <ItemWrapper>
          <InputFocusWrapper>
            <Promo>
              <Input
                value={value}
                onChange={handleChange}
                placeholder="Enter Promo code"
              />
            </Promo>
          </InputFocusWrapper>
          {value && (
            <ButtonWrapper>
              <Button onClick={handleApplyClick}>APPLY</Button>
            </ButtonWrapper>
          )}
        </ItemWrapper>
      </ScrollableContent>
    </ScrollableContentWrapper>
  </Modal>
);

PromoPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  modalGoBack: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleApplyClick: PropTypes.func.isRequired,
};

export default PromoPopup;

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ItemWrapper = styled.div`
  padding: 10px 15px;
  padding-top: 30px;
  min-height: calc(100vh - 55px);

  ${media.tablet`
    min-height: initial;
    padding: 20px;
  `};
`;

const Promo = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 12px 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Input = styled.input`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  padding-left: 10px;
  outline: none;
  font-weight: 300;
  border: none;
  text-transform: uppercase;
  display: block;
  width: 100%;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    text-transform: capitalize;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    text-transform: capitalize;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    text-transform: capitalize;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
    text-transform: capitalize;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
