import { createAction, handleActions } from 'redux-actions';
import without from 'lodash/without';
import { decamelizeKeys } from 'humps';
import wretches from 'wretches';
import { actions as spinnerActions } from 'redux/modules/spinner/reducer';
import { isUserLoggedInSelector } from 'redux/modules/user';

// ---
// CONSTANTS
// ---
export const GET_FAVORITES_SUCCESS = 'favorites/GET_FAVORITES_SUCCESS';
export const REMOVE_FAVORITE_SUCCESS = 'favorites/REMOVE_FAVORITE_SUCCESS';
export const ADD_FAVORITE_SUCCESS = 'favorites/ADD_FAVORITE_SUCCESS';
// ---
// ACTION CREATORS
// ---
export const getFavoritesSuccess = createAction(GET_FAVORITES_SUCCESS);
export const removeFavoriteSuccess = createAction(REMOVE_FAVORITE_SUCCESS);
export const addFavoriteSuccess = createAction(ADD_FAVORITE_SUCCESS);
// ---
// INITIAL_STATE
// ---
const initialState = {
  entities: [],
  loading: true,
  fulfilled: false,
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [GET_FAVORITES_SUCCESS]: (state, action) => ({
      ...state,
      entities: action.payload,
      loading: false,
      fulfilled: true,
    }),
    [REMOVE_FAVORITE_SUCCESS]: (state, action) => ({
      ...state,
      entities: without(state.entities, action.payload),
    }),
    [ADD_FAVORITE_SUCCESS]: (state, action) => ({
      ...state,
      entities: [...state.entities, action.payload],
    }),
  },
  initialState,
);

export const addFavorite = productId => dispatch => {
  dispatch(addFavoriteSuccess(productId));
  wretches.favorites
    .json(decamelizeKeys({ productId }))
    .post()
    .json(() => {})
    .catch(() => {
      dispatch(removeFavoriteSuccess(productId));
    });
};

export const removeFavorite = productId => dispatch => {
  dispatch(removeFavoriteSuccess(productId));
  wretches.favorites
    .url(`/${productId}`)
    .delete()
    .json(() => {})
    .catch(() => {
      dispatch(addFavoriteSuccess(productId));
    });
};

export const getFavorites = (spinner = true) => (dispatch, getState) => {
  if (isUserLoggedInSelector(getState())) {
    if (spinner) {
      dispatch(spinnerActions.showSpinner());
      wretches.favorites
        .get()
        .json(data => {
          dispatch(spinnerActions.hideSpinner());
          dispatch(getFavoritesSuccess(data));
        })
        .catch(() => {
          dispatch(spinnerActions.hideSpinner());
        });
    } else {
      wretches.favorites.get().json(data => {
        dispatch(getFavoritesSuccess(data));
      });
    }
  }
};

export const actions = {
  getFavoritesSuccess,
  removeFavoriteSuccess,
  addFavoriteSuccess,
  addFavorite,
  removeFavorite,
  getFavorites,
};
