import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';

import RouterTabBar from './RouterTabBar';
import RouterTabItem from './RouterTabItem';
import { TransitionGroup } from 'react-transition-group';

import FadeInAnimation from 'components/FadeInAnimation';
import AppLayout from 'layouts/AppLayout';

const createRoute = (path, route) => {
  path = `${trimEnd(path, '/')}/`;
  route = trimStart(route, '/');
  return path + route;
};

const RouterTabView = ({ children, defaultPath, match, location }) => {
  const tabItems = React.Children.toArray(children).filter(
    children => children.type === RouterTabItem,
  );
  const tabBar = React.Children.toArray(children).find(
    children => children.type === RouterTabBar,
  );
  const { url } = match;

  return (
    <Wrapper>
      <Content id="router-tab-view-content">
        <TransitionGroup>
          <FadeInAnimation
            key={location.pathname.split('/')[1]}
            appear
            unmountOnExit
            duration={300}
            timeout={{ enter: 10, exit: 300 }}
            exit={false}
          >
            {style => (
              <AppLayout style={style}>
                <Switch>
                  {[
                    ...tabItems.map(tabItem => (
                      <Route
                        key={tabItem.props.path}
                        path={createRoute(url, tabItem.props.path)}
                        render={props => React.cloneElement(tabItem, props)}
                        exact={tabItem.props.path === '/'}
                      />
                    )),
                  ]}
                  <Redirect to="/" />,
                </Switch>
              </AppLayout>
            )}
          </FadeInAnimation>
        </TransitionGroup>
      </Content>
      {tabBar}
    </Wrapper>
  );
};

RouterTabView.propTypes = {
  defaultPath: PropTypes.string,
  children: PropTypes.node,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(RouterTabView);

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;

  > div {
    height: 100%;
  }
`;
