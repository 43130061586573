import { compose, defaultProps, withHandlers, withProps } from 'recompose';
import pickBy from 'lodash/pickBy';
import { PizzaPartGroup } from './PizzaPartGroup';

const SmallPizzaPartGroup = compose(
  defaultProps({
    value: {},
    parts: [1],
  }),
  withHandlers({
    handleChange: ({ value, onChange }) => (optionId, sideValue) => {
      onChange(
        pickBy({
          ...value,
          [optionId]: sideValue,
        }),
      );
    },
  }),
  withProps(({ data: { options, max }, value }) => ({
    options:
      max > 0 && Object.keys(value).length >= max
        ? options.map(o => ({
            ...o,
            ...(!(o.id in value) && { disabled: true }),
          }))
        : options,
  })),
);

export default SmallPizzaPartGroup(PizzaPartGroup);
