import { createSelector } from 'reselect';
import { orderingSettingsSelector } from 'redux/modules/general';
import { deliveryTypes, deliverySelector } from 'redux/modules/delivery';
import { getDeliveryType } from 'selectors/getDeliveryType';

const minimumOrderSelector = createSelector(
  [getDeliveryType, orderingSettingsSelector, deliverySelector],
  (deliveryType, { minimumAmountOrder }, { minimumOrder }) =>
    deliveryType === deliveryTypes.DELIVERY && minimumOrder
      ? +minimumOrder
      : +minimumAmountOrder,
);

export default minimumOrderSelector;
