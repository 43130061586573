import { createSelector } from 'reselect';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import mapValues from 'lodash/mapValues';
import isBefore from 'date-fns/is_before';
import { optionEntitiesSelector } from 'redux/modules/options';

export const optionsSelector = createSelector(
  [optionEntitiesSelector, scheduleTimeSelector],
  (options, date) =>
    mapValues(options, option => ({
      ...option,
      soldOut: isBefore(date, option.temporarySoldout),
    })),
);
