// Search substrings in string. Preserves case. Groups result.

// Example:
// searchAndHighlightSubstring('asdfQwerasdfQWqwerasdfqwer', 'qw')
// Output:
// [
//   [ 'asdf', false ],
//   [ 'Qw', true ],
//   [ 'erasdf', false ],
//   [ 'QWqw', true ],
//   [ 'erasdf', false ],
//   [ 'qw', true ],
//   [ 'er', false ]
// ]

import findLastIndex from 'lodash/findLastIndex';

const searchAndHighlightSubstring = (s, substring) =>
  [...s]
    .reduce((acc, char, i, arr) => {
      const lowercased = arr.join('').toLowerCase();
      const lastTrue = findLastIndex(acc, ([_, d]) => d);
      const indexOf = lowercased.indexOf(substring.toLowerCase(), i);
      return [
        ...acc,
        [
          char,
          i === indexOf,
          lastTrue !== -1 && i < lastTrue + substring.length,
        ],
      ];
    }, [])
    .map(([c, f1, f2]) => [c, f1 || f2])
    .reduce((acc, [char, shouldHighlight]) => {
      const [lastString, lastShouldHighlight] = [...acc].pop(-1) || [
        undefined,
        undefined,
      ];
      return lastShouldHighlight === shouldHighlight
        ? [...acc.slice(null, -1), [lastString + char, shouldHighlight]]
        : [...acc, [char, shouldHighlight]];
    }, []);

export default searchAndHighlightSubstring;
