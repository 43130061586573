import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { actions as modalActions } from 'components/ReduxModal';
import withAddNewAddress from 'HOC/withAddNewAddress';
import AddNewAddress from '../components/AddNewAddress';

const AddNewAddressHOC = compose(
  withAddNewAddress,
  connect(null, {
    closeModal: modalActions.closeModal,
  }),
  withHandlers({
    handleAddressClick: ({ closeModal }) => {
      closeModal();
    },
  }),
);

export default AddNewAddressHOC(AddNewAddress);
