import { createSelector } from 'reselect';
import addMinutes from 'date-fns/add_minutes';

import getPreparationPlusDeliveryMinutesForTimeFunc from 'selectors/getPreparationPlusDeliveryMinutesForTimeFunc';
import { PREPARATION_TIME_TOLERANCE } from 'utils/constants';

const getToleranceAndPreparationAwareDeliveryTimeForTimeFunc = createSelector(
  [getPreparationPlusDeliveryMinutesForTimeFunc],
  getPreparationPlusDeliveryMinutesForTime => time =>
    addMinutes(
      time,
      +getPreparationPlusDeliveryMinutesForTime(time) +
        PREPARATION_TIME_TOLERANCE,
    ),
);

export default getToleranceAndPreparationAwareDeliveryTimeForTimeFunc;
