import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/Icon';

import arrowIcon from './assets/arrow-down.png';
import TouchHandler from 'components/TouchHandler';

class FitViewHeight extends Component {
  state = { maxHeight: 0 };

  componentDidMount() {
    if (this.node.parentNode) {
      const deviceHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0,
      );
      const { top } = this.node.parentNode.getBoundingClientRect();
      const maxHeight = deviceHeight - top - 55;
      const height = this.node.parentNode.clientHeight;
      this.setState({
        maxHeight,
        height,
        hintOpacity: height > maxHeight ? 1 : 0,
      });
    }
  }

  handleScroll = event => {
    this.setState({ hintOpacity: this.node.scrollTop === 0 ? 1 : 0 });
  };

  render() {
    const { children } = this.props;
    const { maxHeight, height } = this.state;
    const style = maxHeight > 0 ? { maxHeight, height } : {};
    return (
      <TouchHandler
        zeroHeight
        style={style}
        onScroll={this.handleScroll}
        ref={node => (this.node = node)}
      >
        <div>
          {children}
          {height > maxHeight && (
            <Opacity style={{ opacity: this.state.hintOpacity }}>
              <Arrow />
            </Opacity>
          )}
        </div>
      </TouchHandler>
    );
  }
}

FitViewHeight.propTypes = {
  children: PropTypes.node,
};

class Categories extends Component {
  state = {
    catsLength: this.props.categories.length,
    hintOpacity: 1,
  };

  handleCategoryClick = index => {
    const { onCategoryClick } = this.props;
    if (onCategoryClick) {
      onCategoryClick(index);
    }
  };

  render() {
    const { categories, deliveryTimeText } = this.props;

    return (
      <Wrapper>
        <DeliveryTime>
          <IconWrapper>
            <Icon name="icon-soonest-available-small" size="29px" />
          </IconWrapper>
          <Label>{deliveryTimeText}</Label>
        </DeliveryTime>
        <Dropdown>
          <FitViewHeight>
            {categories.map((item, index) => (
              <DropdownItem
                key={item.id}
                onClick={() => this.handleCategoryClick(item)}
              >
                <Name>{item.name}</Name>
                <Count>{item.items.length || '0'}</Count>
              </DropdownItem>
            ))}
          </FitViewHeight>
        </Dropdown>
      </Wrapper>
    );
  }
}

Categories.propTypes = {
  categories: PropTypes.array,
  onCategoryClick: PropTypes.func,
  deliveryTimeText: PropTypes.string,
};

const Wrapper = styled.div`
  position: fixed;
`;

const Dropdown = styled.div`
  background: #fff;
  z-index: 10000;
  position: relative;
  margin-top: 18px;
  width: 255px;
  border-radius: 2px;
  overflow: auto;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px 18px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.2s ease;

  &:not(:first-child) {
    border-top: 1px solid #e8e8e8;
  }

  &:hover {
    background: #f7f8f8;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const Count = styled.div`
  font-size: 16px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const Opacity = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transition: opacity 150ms;
  pointer-events: none;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const Arrow = styled.div`
  width: 20px;
  height: 15px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(${arrowIcon}) no-repeat center / contain;
`;

const DeliveryTime = styled.div`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Label = styled.div`
  margin-left: 7px;
  font-size: 15px;
  font-weight: 300;
`;

export default Categories;
