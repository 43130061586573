import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from 'utils/mediaQueries';

const Button = ({
  children,
  disabled,
  onClick,
  primary,
  fullWidth,
  clickableWhenDisabled,
}) => (
  <ButtonWrapper
    disabled={disabled}
    onClick={event => {
      (clickableWhenDisabled || !disabled) && onClick(event);
    }}
    primary={primary}
    fullWidth={fullWidth}
  >
    {children}
  </ButtonWrapper>
);

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clickableWhenDisabled: PropTypes.bool,
};

const LabelButton = ({
  children,
  disabled,
  onClick,
  primary = true,
  fullWidth = false,
  clickableWhenDisabled,
  ...props
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    primary={primary}
    fullWidth={fullWidth}
    clickableWhenDisabled={clickableWhenDisabled}
  >
    <Label disabled={disabled}>{children}</Label>
  </Button>
);

LabelButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clickableWhenDisabled: PropTypes.bool,
};

export default LabelButton;

export const Label = styled.div`
  text-align: center;
  color: ${({ disabled }) => (disabled ? 'rgb(66, 70, 72)' : '#fff')};
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`;

const ButtonWrapper = styled.button`
  position: relative;
  background: #54a300;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background-color: ${p =>
    ({
      [true]: 'rgb(84, 163, 0)',
      [!p.primary]: '#808384',
      [!!p.disabled]: '#d2d3d3',
    }.true)};
  padding: 19px 10px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  transition: background 0.2s ease;
  height: 52px;
  width: ${p => p.fullWidth && '100%'};

  ${media.desktop`
    cursor: pointer;
    &:hover {
      background: ${props => (props.disabled ? '#d2d3d3' : '#67c70f')};;
    }
  `};
`;
