import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnimationForward from 'components/AnimationForward';
import AnimationBackward from 'components/AnimationBackward';
import FadeInAnimation from 'components/FadeInAnimation';
import AppLayout from 'layouts/AppLayout';

import ModeManagerHOC, { modes } from 'HOC/ModeManager';
import values from 'lodash/values';

const ANIMATION_DURATION = 300;

const defaultStyle = {
  top: '10px',
  right: '0',
  bottom: '0',
  left: '0',
  width: 'auto',
  position: 'fixed',
  overflow: 'hidden',
  height: '100vh',
};

export class FadeInAnimationContent extends Component {
  state = {
    style: defaultStyle,
  };
  componentDidMount() {
    this.timeout = setTimeout(
      () =>
        this.setState({
          style: { position: 'relative', height: '100%', minHeight: '100vh' },
        }),
      800,
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show && !nextProps.show) {
      this.setState({ style: defaultStyle });
    }
  }

  render() {
    const { show, children } = this.props;
    return (
      <FadeInAnimation
        in={show}
        unmountOnExit
        duration={ANIMATION_DURATION}
        timeout={{ enter: 10, exit: ANIMATION_DURATION }}
        exit={false}
      >
        {style => (
          <AppLayout
            style={{ ...style, /* ...this.state.style, */ zIndex: 200 }}
          >
            {children}
          </AppLayout>
        )}
      </FadeInAnimation>
    );
  }
}

FadeInAnimationContent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

export const ForwardAnimationContent = ({ show, children }) => (
  <AnimationForward
    in={show}
    unmountOnExit
    duration={ANIMATION_DURATION}
    timeout={{ enter: 10, exit: ANIMATION_DURATION }}
  >
    {style => (
      <AppLayout style={{ ...style, zIndex: 20000 }}>{children}</AppLayout>
    )}
  </AnimationForward>
);

ForwardAnimationContent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

export const BackwardAnimationContent = ({ show, children }) => (
  <AnimationBackward
    in={show}
    unmountOnExit
    duration={ANIMATION_DURATION}
    timeout={{ enter: 10, exit: ANIMATION_DURATION }}
  >
    {style => <AppLayout style={{ ...style }}>{children}</AppLayout>}
  </AnimationBackward>
);

export const BackwardFadeInAnimationContent = ({ show, children }) => (
  <FadeInAnimation
    in={show}
    unmountOnExit
    duration={ANIMATION_DURATION}
    timeout={{ enter: 10, exit: ANIMATION_DURATION }}
    exit={false}
  >
    {style => <AppLayout style={{ ...style }}>{children}</AppLayout>}
  </FadeInAnimation>
);

BackwardFadeInAnimationContent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

BackwardAnimationContent.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

const cordovaAnimatedContents = {
  FORWARD: ForwardAnimationContent,
  BACKWARD: BackwardAnimationContent,
};

const mobileAnimatedContents = {
  FORWARD: FadeInAnimationContent,
  BACKWARD: BackwardFadeInAnimationContent,
};

const AnimatedRoute = ({ mode, in: show, direction, children }) => {
  const AnimatedContent =
    mode === modes.CORDOVA_MODE
      ? cordovaAnimatedContents[direction]
      : mobileAnimatedContents[direction];
  return <AnimatedContent show={show}>{children}</AnimatedContent>;
};

AnimatedRoute.propTypes = {
  in: PropTypes.bool,
  direction: PropTypes.oneOf(['FORWARD', 'BACKWARD']),
  children: PropTypes.node,
  mode: PropTypes.oneOf(values(modes)),
};

export default ModeManagerHOC(AnimatedRoute);
