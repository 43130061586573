import { compose, withHandlers, withProps, defaultProps } from 'recompose';
import { connect } from 'react-redux';

import { removeFavorite } from 'redux/modules/favorites';

import { showModal } from 'components/ReduxModal';
import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';

import Product from '../components/Product';

const ProductHOC = compose(
  connect(
    state => ({
      items: availableAtDateItemsSelector(state),
    }),
    {
      showModal,
      removeFavorite,
    },
  ),
  withProps(({ items, dishId }) => ({
    item: items[dishId],
  })),
  defaultProps({
    item: {},
  }),
  withHandlers({
    onClick: ({ id, showModal }) => () => {
      showModal('dish-details', { dishId: id });
    },
    onFavoriteClick: ({ id, removeFavorite }) => () => removeFavorite(id),
  }),
);

export default ProductHOC(Product);
