import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import ModalComponents from './ModalComponents';

const NoAccountFound = ({ onTryAgainClick }) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>No account found</ModalComponents.Title>
      <ModalComponents.SubTitle>
        There is no account with the information you provided.
      </ModalComponents.SubTitle>
      <ModalComponents.FormButton>
        <Button fullWidth onClick={onTryAgainClick}>
          Try again
        </Button>
      </ModalComponents.FormButton>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

NoAccountFound.propTypes = {
  onTryAgainClick: PropTypes.func.isRequired,
};

export default NoAccountFound;
