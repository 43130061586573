import { createSelector } from 'reselect';
import { globalMinimumQuantitySelector } from 'redux/modules/general';
import mapValues from 'lodash/mapValues';

export const itemEntitiesSelector = state => state.items.entities;

export const itemsSelector = createSelector(
  [itemEntitiesSelector, globalMinimumQuantitySelector],
  (items, globalMinimumQuantity) =>
    mapValues(items, item => ({
      ...item,
      minCount: item.minimumQuantity || globalMinimumQuantity || 1,
    })),
);
