import uuidv4 from 'uuid/v4';
import keyBy from 'lodash/keyBy';

const legacyLineItemsToCart = lineItems =>
  keyBy(
    lineItems.map(lineItem => ({
      id: uuidv4(),
      dishId: lineItem.productId,
      count: lineItem.quantity,
      specialInstructions: '',
      variations: {},
      lineItem,
    })),
    'id',
  );

export default legacyLineItemsToCart;
