import { createSelector } from 'reselect';
import { orderingSettingsSelector } from 'redux/modules/general';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { paymentMethodSelector } from 'redux/modules/cart';

const tipsAllowedSelector = createSelector(
  [orderingSettingsSelector, getDeliveryType, paymentMethodSelector],
  (
    { tipEnabled, allowPickupTips, allowDeliveryTips },
    deliveryType,
    paymentMethod,
  ) =>
    Boolean(
      tipEnabled &&
        paymentMethod === 'CARD' &&
        ((deliveryType === 'PICKUP' && allowPickupTips) ||
          (deliveryType === 'DELIVERY' && allowDeliveryTips)),
    ),
);

export default tipsAllowedSelector;
