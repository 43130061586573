import React from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

const Spinner = ({ spinnerVisible }) =>
  spinnerVisible &&
  createPortal(
    <SpinnerContainerStyled>
      <SpinnerStyled>
        <div />
        <div />
        <div />
        <div />
      </SpinnerStyled>
    </SpinnerContainerStyled>,
    document.getElementById('spinner'),
  );

const rotation = keyframes`
  0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const SpinnerStyled = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #54a300;
    border-radius: 50%;
    animation: ${rotation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #54a300 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const SpinnerContainerStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Spinner;
