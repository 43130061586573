import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import withLoyalty from 'HOC/withLoyalty';
import { connect } from 'react-redux';
import { loyaltySelector } from 'redux/modules/promotions';

const STAMPS_COUNT = 10;

export const myRewardsHOC = compose(
  withRouter,
  withLoyalty,
  connect(state => ({
    loyalty: loyaltySelector(state),
  })),
  withProps(
    ({
      loyalty: {
        restaurantSettings: { nthOrderGiftRules },
        consumer: { ordersCount },
      },
    }) => ({
      stampsCount:
        nthOrderGiftRules && Object.keys(nthOrderGiftRules).length
          ? STAMPS_COUNT
          : 0,
      stampsStart: Math.floor(ordersCount / 10) * 10,
      nextReward: Math.min(
        ...(nthOrderGiftRules
          ? Object.keys(nthOrderGiftRules)
              .map(v => +v)
              .filter(v => v >= ordersCount)
          : []),
      ),
    }),
  ),
  withProps(
    ({
      stampsCount,
      stampsStart,
      nextReward,
      loyalty: {
        restaurantSettings: { nthOrderGiftRules },
        consumer: { lastOrders, ordersCount },
      },
    }) => ({
      ordersTillNextReward: nextReward ? nextReward - ordersCount + 1 : 0,
      stamps: Array(stampsCount)
        .fill(undefined)
        .map((_, i) => stampsStart + i)
        .map(i => ({
          orderNumber: i,
          ...(i < ordersCount
            ? { isOrder: true, order: lastOrders[i] }
            : { isOrder: false }),
          ...(i in nthOrderGiftRules
            ? { isReward: true, reward: nthOrderGiftRules[i] }
            : { isReward: false }),
        })),
    }),
  ),
);
