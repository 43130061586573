import {
  compose,
  withProps,
  defaultProps,
  withHandlers,
  withStateHandlers,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import withConditionalCDM from 'HOC/withConditionalCDM';

import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';

import { actions as cartActions, cartItemsSelector } from 'redux/modules/cart';
import { actions as modalActions } from 'components/ReduxModal';
import { actions as spinnerActions } from 'redux/modules/spinner';
import {
  actions as deliveryActions,
  deliverySelector,
  deliveryTimeTypes,
  deliveryTypes,
} from 'redux/modules/delivery';
import { tempUserAddressSelector } from 'selectors/tempUserAddressSelector';
import { userAddressesSelector } from 'selectors/userAddressesSelector';
import { userAddressIdSelector } from 'selectors/userAddressIdSelector';
import {
  canDeliverySelector,
  canPickupSelector,
  cantinaInfoSelector,
  orderAheadInfoSelector,
  orderingSettingsSelector,
  restaurantInfoSelector,
} from 'redux/modules/general';
import {
  actions as userActions,
  defaultAddressSelector,
} from 'redux/modules/user';
import availableItemIdsSelector from 'selectors/availableItemIdsSelector';
import canSoonestSelector from 'selectors/canSoonestSelector';
import canScheduleSelector from 'selectors/canScheduleSelector';
import { canTodaySelector } from 'selectors/canTodaySelector';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';
import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';
import itemsWithOverwrittenPriceSelector from 'selectors/itemsWithOverwrittenPriceSelector';
import {
  scheduleTimeSelector,
  backendScheduleTimeSelector,
} from 'selectors/scheduleTimeSelector';
import { menusEntitiesSelector } from 'redux/modules/menus';
import scheduleFilteredMenuSelector from 'selectors/scheduleFilteredMenuSelector';
import fallbackScheduleVisibleSelector from 'selectors/fallbackScheduleVisibleSelector';
import daysAndTimestampsSelector from 'selectors/daysAndTimestampsSelector';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';

import addMinutes from 'date-fns/add_minutes';
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';

import debounce from 'lodash/debounce';

import inform from 'utils/inform';
import { getNowTime } from 'utils/timeHelpers';
import backendProductsToCart from 'utils/backendProductsToCart';

import uuidv4 from 'uuid/v4';
import wretches from 'wretches';
import { decamelizeKeys } from 'humps';

const formatTime = (time, deliveryType) =>
  deliveryType === 'SOONEST'
    ? 'Soonest Available'
    : `${isToday(time) ? 'Today' : format(time, 'MM-DD-YYYY')} at ${format(
        time,
        'hh:mm A',
      )}`;

export const withDeliverySettings = compose(
  withRouter,
  connect(
    state => ({
      delivery: deliverySelector(state),
      orderingSettings: orderingSettingsSelector(state),
      canPickup: canPickupSelector(state),
      canDelivery: canDeliverySelector(state),
      canSchedule: canScheduleSelector(state),
      asapPreparationTime: asapPreparationTimeSelector(state),
      cartItems: cartItemsSelector(state),
      itemsWithOverwrittenPrice: itemsWithOverwrittenPriceSelector(state),
      menusEntities: menusEntitiesSelector(state),
      scheduleTime: scheduleTimeSelector(state),
      restaurantInfo: restaurantInfoSelector(state),
      userAddresses: keyBy(userAddressesSelector(state), 'id'),
      tempUserAddress: tempUserAddressSelector(state),
      defaultAddress: defaultAddressSelector(state),
      userAddressId: userAddressIdSelector(state),
      orderAheadInfo: orderAheadInfoSelector(state),
      fallbackScheduleVisible: fallbackScheduleVisibleSelector(state),
      cantinaEnabled: Boolean(cantinaInfoSelector(state)?.enabled),
      dineInEnabled: dineInEnabledSelector(state),
    }),
    {
      setUserAddressId: deliveryActions.setUserAddressId,
      setTempUserAddress: deliveryActions.setTempUserAddress,
      addDeliveryAddress: userActions.addDeliveryAddress,
      createAddress: userActions.createAddress,
      setDeliverySettings: deliveryActions.setDeliverySettings,
      setDeliveryType: deliveryActions.setDeliveryType,
      setDeliveryTimeType: deliveryActions.setDeliveryTimeType,
      setSoonestTime: deliveryActions.setSoonestTime,
      clearCart: cartActions.clearCart,
      setDeliveryConditions: deliveryActions.setDeliveryConditions,
      cancelReorderMode: deliveryActions.cancelReorderMode,
      appendCartItems: cartActions.appendCartItems,
      showSpinner: spinnerActions.showSpinner,
      hideSpinner: spinnerActions.hideSpinner,
      setReorderMode: deliveryActions.setReorderMode,
      closeModal: modalActions.closeModal,
      setAutoContinue: deliveryActions.setAutoContinue,
    },
  ),
  withProps(
    ({ delivery: { type, deliveryTimeType, scheduleTime, reorderId } }) => ({
      deliveryType: type,
      deliveryTimeType,
      scheduleTime,
      reorderId,
    }),
  ),
  defaultProps({
    canSoonest: true,
    canSchedule: true,
    soonestButtonEnabled: false,
    scheduleButtonEnabled: false,
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({
      deliveryType,
      deliveryTimeType,
      scheduleTime,
      tempUserAddress,
      userAddressId,
      delivery: { availability, minimumOrder, deliveryCharge, deliveryTime },
    }) => ({
      deliveryType,
      ...{
        [true]: {
          pickupTimeType: null,
          deliveryTimeType: null,
          pickupTime: null,
          scheduleTime: null,
        },
        [deliveryType === deliveryTypes.PICKUP]: {
          pickupTimeType: deliveryTimeType,
          deliveryTimeType: null,
          pickupTime: scheduleTime,
          scheduleTime: null,
        },
        [deliveryType === deliveryTypes.DELIVERY]: {
          pickupTimeType: null,
          deliveryTimeType,
          pickupTime: null,
          scheduleTime,
        },
      }.true,
      tempUserAddress,
      deliveryAddress: userAddressId,
      deliveryConditions: {
        availability,
        minimumOrder,
        deliveryCharge,
        deliveryTime,
      },
      deliveryAddressError: null,
      deliveryAddressAutoSelector: null,
    }),
    isInitialValid: true,
    validate: (
      {
        deliveryType,
        pickupTimeType,
        deliveryTimeType,
        pickupTime,
        scheduleTime,
        deliveryAddress,
        deliveryAddressError,
      },
      { canPickup, canDelivery },
    ) => {
      const errors = {};
      if (!deliveryType) {
        errors.deliveryType = 'Required';
      }
      if (deliveryType === deliveryTypes.PICKUP && !canPickup) {
        errors.deliveryType =
          'Sorry, we only accept delivery orders at this time.';
      }
      if (deliveryType === deliveryTypes.DELIVERY) {
        if (!canDelivery) {
          errors.deliveryType =
            'Sorry, we only accept pickup orders at this time.';
        }
        if (!deliveryAddress) {
          errors.deliveryAddress = 'Required';
        } else if (deliveryAddressError) {
          errors.deliveryAddress = deliveryAddressError;
        }
      }
      if (
        (deliveryType === deliveryTypes.PICKUP && !pickupTimeType) ||
        (deliveryType === deliveryTypes.DELIVERY && !deliveryTimeType)
      ) {
        errors.deliveryTimeType = 'Required';
      }
      if (
        (deliveryType === deliveryTypes.PICKUP &&
          pickupTimeType === 'SCHEDULE' &&
          !pickupTime) ||
        (deliveryType === deliveryTypes.DELIVERY &&
          deliveryTimeType === 'SCHEDULE' &&
          !scheduleTime)
      ) {
        errors.scheduleTime = 'Please select your scheduled time.';
      }
      return errors;
    },
    handleSubmit: (
      {
        deliveryType,
        pickupTimeType,
        deliveryTimeType,
        pickupTime,
        scheduleTime,
        deliveryAddress,
        tempUserAddress,
        deliveryConditions,
      },
      {
        props: {
          setDeliverySettings,
          history,
          setUserAddressId,
          setTempUserAddress,
          setDeliveryConditions,
        },
      },
    ) => {
      setDeliverySettings({
        type: deliveryType,
        deliveryTimeType:
          deliveryType === deliveryTypes.PICKUP
            ? pickupTimeType
            : deliveryTimeType,
        scheduleTime:
          deliveryType === deliveryTypes.PICKUP ? pickupTime : scheduleTime,
      });
      setUserAddressId(
        deliveryType === deliveryTypes.DELIVERY ? deliveryAddress : null,
      );
      setDeliveryConditions(
        deliveryType === deliveryTypes.DELIVERY ? deliveryConditions : {},
      );
      if (deliveryType === deliveryTypes.DELIVERY && tempUserAddress) {
        setTempUserAddress(tempUserAddress);
      }
      const scrollable =
        document.querySelector('#app-scroll') ||
        document.querySelector('#router-tab-view-content');
      if (scrollable) {
        setTimeout(() => {
          scrollable.scrollTo(0, 0);
        }, 0);
      }

      history.push(`/menu`);
    },
  }),
  withHandlers({
    setDeliveryTimeType: ({ setFieldValue, values: { deliveryType } }) => v => {
      setFieldValue(
        deliveryType === deliveryTypes.PICKUP
          ? 'pickupTimeType'
          : 'deliveryTimeType',
        v,
      );
    },
    setScheduleTime: ({ setFieldValue, values: { deliveryType } }) => v => {
      setFieldValue(
        deliveryType === deliveryTypes.PICKUP ? 'pickupTime' : 'scheduleTime',
        v,
      );
    },
  }),
  withProps(
    ({
      values: {
        deliveryType,
        pickupTimeType,
        deliveryTimeType,
        pickupTime,
        scheduleTime,
        deliveryAddress,
        tempUserAddress,
      },
      userAddresses,
    }) => ({
      deliveryTimeTypeFormikValue:
        deliveryType === deliveryTypes.PICKUP
          ? pickupTimeType
          : deliveryTimeType,
      scheduleTimeFormikValue:
        deliveryType === deliveryTypes.PICKUP ? pickupTime : scheduleTime,
      selectedAddress:
        deliveryAddress === 'temp'
          ? tempUserAddress
          : userAddresses[deliveryAddress],
    }),
  ),
  connect(
    (
      state,
      { scheduleTimeFormikValue, values: { deliveryConditions, deliveryType } },
    ) => ({
      daysAndTimestamps: daysAndTimestampsSelector(state, {
        scheduleTime: scheduleTimeFormikValue,
        deliveryType,
        deliveryConditions,
      }),
      canSoonest: canSoonestSelector(state, {
        scheduleTime: scheduleTimeFormikValue,
        deliveryType,
        deliveryConditions,
      }),
      canToday: canTodaySelector(state, {
        scheduleTime: scheduleTimeFormikValue,
        deliveryType,
        deliveryConditions,
      }),
      backendScheduleTime: backendScheduleTimeSelector(state, {
        scheduleTime: scheduleTimeFormikValue,
      }),
    }),
  ),
  withProps(
    ({
      values: {
        deliveryAddress,
        deliveryAddressError,
        deliveryType,
        deliveryConditions: { minimumOrder, deliveryCharge } = {},
      },
      orderingSettings: { minimumAmountOrder } = {},
    }) => {
      const labels = [
        ...(deliveryType === deliveryTypes.PICKUP && minimumAmountOrder
          ? [`Minimum order: $${minimumAmountOrder}`]
          : []),
        ...(deliveryType === deliveryTypes.DELIVERY && minimumOrder
          ? [`Minimum order: $${minimumOrder}`]
          : []),
        ...(deliveryType === deliveryTypes.DELIVERY &&
        Number.isInteger(+deliveryCharge)
          ? [
              deliveryCharge
                ? `Delivery Fee: $${deliveryCharge}`
                : 'Free delivery',
            ]
          : []),
      ];
      // Insert | splitter between items
      const deliveryConditionsLabels = []
        .concat(...labels.map(v => [v, '|']))
        .slice(null, -1);
      return (
        deliveryAddress && !deliveryAddressError && { deliveryConditionsLabels }
      );
    },
  ),
  withProps(
    ({
      values: {
        deliveryAddress,
        deliveryAddressError,
        tempUserAddress,
        deliveryType,
      },
      errors,
      userAddresses,
      orderAheadInfo: { minDaysInAdvance, maxDaysInAdvance },
      canPickup,
      canSoonest,
      canToday,
    }) => ({
      changeAddressButtonVisible: !!(
        deliveryAddress &&
        Object.values({
          ...userAddresses,
          ...(tempUserAddress && { temp: tempUserAddress }),
        }).length > 1
      ),
      deliveryValid: !!(
        deliveryType === deliveryTypes.DELIVERY &&
        deliveryAddress &&
        [...Object.keys(userAddresses), 'temp'].includes(deliveryAddress) &&
        !errors.deliveryConditions
      ),
      deliveryAddressErrorResolutionSuggestion: Boolean(
        +minDaysInAdvance === 0 &&
          deliveryType === deliveryTypes.DELIVERY &&
          deliveryAddress &&
          [...Object.keys(userAddresses), 'temp'].includes(deliveryAddress) &&
          ((errors.deliveryAddress && errors.deliveryAddress !== 'Required') ||
            !(canSoonest || canToday)),
      ) && [
        ...(canPickup ? ['Switch to pickup'] : []),
        'Change your delivery address',
        ...(+maxDaysInAdvance > 0 && !deliveryAddressError
          ? [
              `Schedule a delivery time up to ${maxDaysInAdvance} days in advance`,
            ]
          : []),
      ],
    }),
  ),
  withHandlers({
    reorderButtonHandler: ({
      reorderId,
      values: { deliveryType },
      deliveryTimeTypeFormikValue: deliveryTimeType,
      backendScheduleTime,
      selectedAddress,
      history,
      appendCartItems,
      submitForm,
      showSpinner,
      hideSpinner,
      closeModal,
      setReorderMode,
      cancelReorderMode,
    }) => () => {
      if (deliveryType && deliveryTimeType && backendScheduleTime) {
        showSpinner();
        wretches.reorder
          .json(
            decamelizeKeys({
              id: reorderId,
              orderMethod: deliveryType.toLowerCase(),
              orderType: deliveryTimeType.toLowerCase(),
              orderDate: backendScheduleTime,
              placeId: selectedAddress && selectedAddress.placeId,
            }),
          )
          .post()
          .json(json => {
            const {
              error,
              cart: { products },
            } = json;
            hideSpinner();
            if (!isEmpty(error)) {
              inform({
                title: error.title,
                message: error.body,
                buttonText: 'OK',
              });
            }
            if (products.length) {
              appendCartItems(backendProductsToCart(products));
              closeModal();
              submitForm();
              // history.push('/menu/cart');
              cancelReorderMode();
            }
          })
          .catch(err => {
            hideSpinner();
            inform({
              title: 'Oops...',
              message: err.json && err.json.message,
              buttonText: 'OK',
            });
          });
      } else {
        // set reorder mode
        setReorderMode(reorderId);
        history.push('/');
        closeModal();
        // go to delivery page
      }
    },
    reorderBackButtonHandler: ({ cancelReorderMode, history }) => () => {
      cancelReorderMode();
      history.push('/orders');
    },
    validateAndSetScheduleTime: ({
      setDeliveryTimeType,
      setScheduleTime,
      cartItems,
      deliveryTimeType: lastDeliveryTimeType,
      scheduleTime: lastScheduleTime,
      clearCart,
      itemsWithOverwrittenPrice,
      menusEntities,
    }) => (scheduleTime, deliveryTimeType = deliveryTimeTypes.SCHEDULE) => {
      const cartItemIds = map(cartItems, 'dishId');
      if (scheduleTime) {
        if (cartItemIds && cartItemIds.length) {
          const scheduleFilteredMenu = scheduleFilteredMenuSelector.resultFunc(
            scheduleTime,
            menusEntities,
          );
          const availableAtDateItems = availableAtDateItemsSelector.resultFunc(
            itemsWithOverwrittenPrice,
            scheduleTime,
            scheduleFilteredMenu,
          );
          const availableItemIds = availableItemIdsSelector.resultFunc(
            availableAtDateItems,
          );
          const invalidItems = cartItemIds.filter(
            cartItemId => !availableItemIds.includes(cartItemId),
          );
          if (invalidItems.length) {
            const onBeforeButtonClick = () => {
              clearCart();
              setDeliveryTimeType(deliveryTimeType);
              setScheduleTime(scheduleTime);
            };
            inform({
              title: 'Start New Order?',
              message: `You have an active order for ${formatTime(
                lastScheduleTime,
                lastDeliveryTimeType,
              )}. Would you like to clear order and Schedule your order for ${formatTime(
                scheduleTime,
                deliveryTimeType,
              )} instead?`,
              buttonText: 'New Order',
              showCancelButton: true,
              buttonChoice: true,
              onBeforeButtonClick,
            });
            return;
          }
        }
      }
      setScheduleTime(scheduleTime);
      setDeliveryTimeType(deliveryTimeType);
    },
  }),
  withHandlers({
    onSoonestClick: ({
      asapPreparationTime,
      validateAndSetScheduleTime,
    }) => () => {
      validateAndSetScheduleTime(
        addMinutes(getNowTime(), asapPreparationTime).toISOString(),
        deliveryTimeTypes.SOONEST,
      );
    },
    onScheduleClick: ({
      validateAndSetScheduleTime,
      cantinaEnabled,
      daysAndTimestamps,
    }) => () => {
      if (cantinaEnabled) {
        validateAndSetScheduleTime(
          daysAndTimestamps[0].timestamps[0],
          deliveryTimeTypes.SCHEDULE,
        );
      } else {
        validateAndSetScheduleTime(null, deliveryTimeTypes.SCHEDULE);
      }
    },
  }),
  withHandlers({
    setDefaultDeliveryTimeType: ({
      deliveryTimeTypeFormikValue,
      canSoonest,
      canSchedule,
      onSoonestClick,
      onScheduleClick,
    }) => (force = false) => {
      if (!deliveryTimeTypeFormikValue || force) {
        if (canSoonest) {
          onSoonestClick();
        } else if (canSchedule) {
          onScheduleClick();
        }
      }
    },
    onChangeAddressClick: ({
      setFieldValue,
      setFieldTouched,
      setDeliveryTimeType,
      setScheduleTime,
    }) => () => {
      setFieldValue('deliveryAddress', null);
      setFieldValue('deliveryConditions', undefined);
      setFieldValue('deliveryAddressError', null);
      setFieldTouched('deliveryAddressAutoSelector');
      setDeliveryTimeType(null);
      setScheduleTime(null);
    },
    submitForm: ({ submitForm }) => debounce(submitForm, 500),
  }),
  withHandlers({
    setAddress: ({
      userAddresses,
      values: { tempUserAddress },
      setFieldValue,
      setDefaultDeliveryTimeType,
    }) => deliveryAddress => {
      const address =
        deliveryAddress === 'temp'
          ? tempUserAddress
          : userAddresses[deliveryAddress];
      const { placeId } = address;
      if (placeId) {
        setFieldValue('deliveryAddress', deliveryAddress);
        setFieldValue('deliveryAddressError', null);
        wretches.addressValidate
          .query(decamelizeKeys({ placeId }))
          .get()
          .json(response => {
            const availabilityValues = flattenDeep(
              Object.values(response.availability),
            );
            if (!availabilityValues.length) {
              setFieldValue('deliveryAddressError', 'No availability');
            } else {
              setFieldValue('deliveryConditions', response);
              setTimeout(() => {
                setDefaultDeliveryTimeType();
              }, 0);
            }
          })
          .catch(err => {
            setFieldValue('deliveryAddressError', err.json.message);
          });
      }
    },
    setNewAddress: ({
      setFieldValue,
      createAddress,
      addDeliveryAddress,
      onChangeAddressClick,
      setDefaultDeliveryTimeType,
    }) => addressInfo => {
      onChangeAddressClick();
      const data = { name: addressInfo.saveAs, ...addressInfo };
      if (addressInfo.saveAs) {
        const id = uuidv4();
        createAddress({ ...data, id });
        setFieldValue('deliveryAddress', id);
        addDeliveryAddress({ ...data, id });
      } else {
        setFieldValue('deliveryAddress', 'temp');
        setFieldValue('tempUserAddress', { ...addressInfo, id: 'temp' });
      }
      setFieldValue('deliveryConditions', addressInfo.place);
      setTimeout(() => {
        setDefaultDeliveryTimeType();
      }, 0);
    },
    onPickupClick: ({
      canPickup,
      setFieldValue,
      setFieldTouched,
      setDefaultDeliveryTimeType,
    }) => () => {
      setFieldValue('deliveryType', deliveryTypes.PICKUP);
      if (canPickup) {
        setTimeout(() => {
          setDefaultDeliveryTimeType();
        }, 0);
      }
      setFieldTouched('deliveryType');
    },
    onDeliveryClick: ({ setFieldValue, setFieldTouched }) => () => {
      setFieldValue('deliveryType', deliveryTypes.DELIVERY);
      setFieldTouched('deliveryType');
    },
    continueButtonHandler: ({ submitForm, showSpinner, hideSpinner }) => () => {
      showSpinner();
      setTimeout(submitForm, 0);
      setTimeout(() => {
        hideSpinner();
      }, 1000);
    },
  }),
  withHandlers({
    setDefaultDeliveryType: ({
      canPickup,
      canDelivery,
      onPickupClick,
      onDeliveryClick,
    }) => () => {
      if (canPickup) {
        onPickupClick();
      } else if (canDelivery) {
        onDeliveryClick();
      }
    },
  }),
  withProps(
    ({ values: { deliveryType, deliveryTimeType, deliveryAddress } }) => ({
      deliveryTimeTypePickerKey: `${deliveryType}-${deliveryTimeType}-${deliveryAddress}`,
    }),
  ),
  withProps(
    ({
      values: { deliveryType, deliveryAddress },
      errors,
      daysAndTimestamps,
    }) => ({
      deliveryTimePickerVisible: Boolean(
        (deliveryType !== 'DELIVERY' ||
          (deliveryAddress && !errors.deliveryAddress)) &&
          daysAndTimestamps.length,
      ),
    }),
  ),
  withStateHandlers(
    {
      deliveryAddressModalVisible: false,
    },
    {
      handleAddressClick: (
        _,
        { isAddressSelected, showModal, setAddress },
      ) => id => {
        if (isAddressSelected) {
          showModal('add-new-address', { addressId: id });
        } else {
          setAddress(id);
        }
        return { deliveryAddressModalVisible: false };
      },
      hideDeliveryAddressModal: () => () => ({
        deliveryAddressModalVisible: false,
      }),
      showDeliveryAddressModal: () => () => ({
        deliveryAddressModalVisible: true,
      }),
    },
  ),
  withConditionalCDM(
    ({ deliveryType }) => !deliveryType,
    ({ setDefaultDeliveryType }) => {
      setTimeout(() => {
        setDefaultDeliveryType();
      }, 0);
    },
  ),
  withConditionalCDM(
    ({
      userAddresses,
      defaultAddress,
      touched,
      values: { deliveryType, deliveryAddress },
    }) =>
      deliveryType === deliveryTypes.DELIVERY &&
      !deliveryAddress &&
      !touched.deliveryAddressAutoSelector &&
      defaultAddress &&
      Object.values(userAddresses).length,
    ({
      defaultAddress: defaultAddressName,
      userAddresses,
      setAddress,
      setFieldTouched,
    }) => {
      const defaultAddress = Object.values(userAddresses).find(
        ({ name }) => name === defaultAddressName,
      );
      if (defaultAddress) {
        setAddress(defaultAddress.id);
        setFieldTouched('deliveryAddressAutoSelector');
      }
    },
  ),
  withConditionalCDM(
    ({ fallbackScheduleVisible, dineInEnabled }) =>
      dineInEnabled && !fallbackScheduleVisible,
    ({ setAutoContinue, continueButtonHandler }) =>
      setTimeout(() => {
        setAutoContinue(false);
        continueButtonHandler();
      }, 500),
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        values: { deliveryType, deliveryAddress },
      } = this.props;
      if (
        deliveryType === deliveryTypes.DELIVERY &&
        !prevProps.values.deliveryAddress &&
        deliveryAddress
      ) {
        const scrollable =
          document.querySelector('#app-scroll') ||
          document.querySelector('#router-tab-view-content');
        if (scrollable) {
          setTimeout(() => {
            scrollable.scrollTo(0, scrollable.scrollHeight);
          }, 0);
        }
      }
    },
  }),
);
