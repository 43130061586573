import { compose, setDisplayName, renderNothing } from 'recompose';
import withConditionalCDM from 'HOC/withConditionalCDM';
import smartlookClient from 'smartlook-client';
import { SMARTLOOK_KEY, SMARTLOOK_BLACKLISTED_DOMAINS } from 'utils/constants';
import { SUBDOMAIN } from 'utils/hostname';

const SmartlookHelper = compose(
  setDisplayName('SmartlookHelper'),
  withConditionalCDM(
    () =>
      !SMARTLOOK_BLACKLISTED_DOMAINS.includes(SUBDOMAIN) && !window.smartlook,
    () => {
      smartlookClient.init(SMARTLOOK_KEY);
    },
  ),
  renderNothing,
)();

export default SmartlookHelper;
