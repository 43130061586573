import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import PopUpAnimation from 'components/PopUpAnimation';
import PopUp from 'components/PopUp';

const ANIMATION_DURATION = 350;

class BaseModal extends Component {
  render() {
    const { children, style, desktop } = this.props;
    return (
      <PopupWrapper style={style} desktop={desktop}>
        <ModalContent desktop={desktop}>{children}</ModalContent>
      </PopupWrapper>
    );
  }
}

BaseModal.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  desktop: PropTypes.bool,
};

const AnimatedModal = ({ show, children, title, onCloseClick, desktop }) => (
  <PopUp show={show} onOutsideClick={onCloseClick}>
    <PopUpAnimation
      unmountOnExit
      appear
      in={show}
      duration={ANIMATION_DURATION}
      timeout={{ enter: 10, exit: ANIMATION_DURATION }}
    >
      {style => (
        <BaseModal
          style={style}
          title={title}
          onCloseClick={onCloseClick}
          desktop={desktop}
        >
          {children}
        </BaseModal>
      )}
    </PopUpAnimation>
  </PopUp>
);

const modalPropTypes = {
  isFavorite: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  onCloseClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  desktop: PropTypes.bool,
};

AnimatedModal.propTypes = {
  ...modalPropTypes,
};

export default AnimatedModal;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const PopupWrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  position: fixed;
  overflow: hidden;
  z-index: 40;
  background: #f3f4f5;

  ${media.tablet`
    width: 440px;
    right: initial;
    left: calc(50% - 220px);
    top: 115px;
    bottom: 70px;
  `};

  ${({ desktop }) =>
    desktop &&
    media.tablet`
      width: 440px;
      right: initial;
      top: 115px;
      height: 530px;
      bottom: initial !important;
  `};
`;
