import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import enterPasswordHOC from 'HOC/enterPasswordHOC';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import Button from 'components/Button';
import SubFooter from 'components/SubFooter';
import AccountDropdown from 'components/AccountDropdown';
import ModalComponents from './ModalComponents';

const EnterPassword = ({
  login,
  values: { password },
  errors,
  touched,
  handleBlur,
  handleSubmit,
  onPasswordFieldChange,
  onLoginChange,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>Welcome back</ModalComponents.Title>
      <ModalComponents.Form onSubmit={handleSubmit}>
        <AccountDropdown
          value={login}
          options={[login]}
          onLoginChange={onLoginChange}
        />
        <AutoFocusIfNonTouchDevice>
          {autoFocus => (
            <TextField
              label="Enter your password"
              value={password}
              type="password"
              name="password"
              onChange={onPasswordFieldChange}
              onBlur={handleBlur}
              helperText={touched.password && errors.password}
              error={touched.password && errors.password}
              fullWidth
              autoFocus={autoFocus}
            />
          )}
        </AutoFocusIfNonTouchDevice>
        <ModalComponents.FormLink
          to={{
            pathname: `/forgot-password`,
            state: { login, modal: true },
          }}
        >
          Forgot your password?
        </ModalComponents.FormLink>
        <ModalComponents.FormButton>
          <Button fullWidth onClick={handleSubmit}>
            Next
          </Button>
        </ModalComponents.FormButton>
      </ModalComponents.Form>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

EnterPassword.propTypes = {
  login: PropTypes.string.isRequired,
  values: PropTypes.shape({ password: PropTypes.string }).isRequired,
  errors: PropTypes.shape({ password: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ password: PropTypes.bool }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onPasswordFieldChange: PropTypes.func.isRequired,
  onLoginChange: PropTypes.func.isRequired,
};

export default enterPasswordHOC(EnterPassword);
