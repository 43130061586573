import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconClean from 'components/IconClean';
import SearchResult from 'components/MenuSearch/SearchResult';
import Overlay from 'utils/Overlay';
import menuSearchHOC from './menuSearchHOC';

const MenuSearch = ({
  hideSearchBar,
  handleSearchInputChange,
  searchInput,
  filteredCategories,
  onDishClickHandlerCreator,
}) => (
  <>
    <Overlay onClick={hideSearchBar} zIndex={25} inPlace>
      {!!filteredCategories.length && (
        <SearchResult
          filteredCategories={filteredCategories}
          onDishClickHandlerCreator={onDishClickHandlerCreator}
        />
      )}
    </Overlay>
    <SearchBar>
      <IconClean name="icon-search" size="20px" />
      <Input
        type="text"
        value={searchInput}
        onChange={handleSearchInputChange}
        autoFocus
      />
      <IconButton onClick={hideSearchBar} aria-label="close">
        <IconClean name="icon-cross-thin-small" size="36px" />
      </IconButton>
    </SearchBar>
  </>
);

MenuSearch.propTypes = {
  searchInput: PropTypes.string.isRequired,
  handleSearchInputChange: PropTypes.func.isRequired,
  hideSearchBar: PropTypes.func.isRequired,
  filteredCategories: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.array,
    }),
  ).isRequired,
  onDishClickHandlerCreator: PropTypes.func.isRequired,
};

const SearchBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0 15px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 30;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const Input = styled.input`
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  border: none;
  outline: none;
  margin-left: 16px;
`;

const IconButton = styled.button`
  width: auto;
`;

export default menuSearchHOC(MenuSearch);
