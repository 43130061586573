import { createSelector } from 'reselect';
import values from 'lodash/values';
import { addressesSelector } from 'selectors/addressesSelector';
import { userAddressIdSelector } from 'selectors/userAddressIdSelector';
import { tempUserAddressSelector } from 'selectors/tempUserAddressSelector';

export const userAddressesSelector = createSelector(
  [addressesSelector, userAddressIdSelector, tempUserAddressSelector],
  (addresses, selectedAddress, tempUserAddress) =>
    values({ ...addresses, tempUserAddress })
      .map(address => ({
        ...address,
        selected: selectedAddress && address && selectedAddress === address.id,
      }))
      .filter(({ placeId }) => placeId),
);
