import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';

import { ThankYouPageDeliveryTimeInfo } from 'components/DishesList';
import MapAddress, { styles as mapAddressStyles } from 'components/MapAddress';
import { Item } from 'components/Card';

const ThankYou = ({
  orderId,
  history,
  deliveryDay,
  deliveryTime,
  deliveryType,
  address,
  dineInEnabled,
}) => (
  <Content>
    <Container>
      <Greetings>
        <IconWrapper>
          <Icon name="icon-thank-you-small" size="145px" />
        </IconWrapper>
        <Text tabIndex={0}>Thank you!</Text>
      </Greetings>

      <Message>
        <SmallIconWrapper>
          <Icon name="icon-orders-small" size="28px" />
        </SmallIconWrapper>
        <Label tabIndex={0}>Order #{orderId} has been processed</Label>
      </Message>

      <ThankYouPageDeliveryTimeInfo
        deliveryDay={deliveryDay}
        deliveryTime={deliveryTime}
        deliveryType={deliveryType}
        dineInEnabled={dineInEnabled}
      />
      {!!Object.keys(address).length && (
        <MapItem>
          <MapAddressWrapper>
            <MapAddress address={address} withIcon />
          </MapAddressWrapper>
        </MapItem>
      )}
    </Container>
    <ButtonWrapper>
      <Button onClick={() => history.push(`/menu`)}>START A NEW ORDER</Button>
    </ButtonWrapper>
  </Content>
);

ThankYou.propTypes = {
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  deliveryType: PropTypes.string,
  deliveryDay: PropTypes.string,
  deliveryTime: PropTypes.string,
  history: PropTypes.object,
  address: PropTypes.shape({
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    id: PropTypes.number,
    placeId: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }),
  dineInEnabled: PropTypes.bool.isRequired,
};

export default ThankYou;

const Content = styled.div`
  padding: 15px;
  width: 100%;
  max-width: 440px;
  margin: 15px auto 0 auto;

  ${media.tablet`
    margin-top: 100px;
  `};
`;

const MapAddressWrapper = styled.div`
  flex: 1 1 auto;
  font-weight: 300;

  ${mapAddressStyles.Name} {
    margin-bottom: 6px;
  }

  ${mapAddressStyles.Description} {
    font-size: 14px;

    > p {
      margin-bottom: 4px;
    }
  }

  ${mapAddressStyles.Information} {
    padding: 15px 0;
  }
`;

const Container = styled.div`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Greetings = styled.div`
  padding: 35px 15px;
  border-bottom: 1px solid #eeefef;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto;
  color: #54a30a;
`;

const Text = styled.div`
  font-size: 23px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  text-align: center;
  margin-top: 25px;
`;

const Message = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 18px 15px;
`;

const SmallIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #97999b;
`;

const MapItem = styled(Item)`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 0 0 0 15px;
`;

const Label = styled.div`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  margin-left: 15px;
`;

const Button = styled.button`
  text-transform: uppercase;
  background: #54a300;
  color: #fff;
  border-radius: 2px;
  background-color: rgb(84, 163, 0);
  text-shadow: 1px 1px 1px #4a860c;
  padding: 17px 10px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px;
  text-align: center;
  transition: background 0.2s ease;

  ${media.tablet`
    cursor: pointer;
  `} ${media.desktop`
    &:hover {
      background: #67c70f;
    }
  `};
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
`;
