// import isFinite from 'lodash/isFinite'
// import isString from 'lodash/isString'

export const getFixedPrice = (price, precision = 2) =>
  parseFloat(Math.round(price * 100) / 100).toFixed(precision);
// const priceInt = isString(price) ? +price : price
// if (isFinite(priceInt)) {
//   return priceInt.toFixed(precision)
// }
// console.error(`Price value: ${priceInt} is not a number`)
// return priceInt
