import { createSelector } from 'reselect';
import isSameDay from 'date-fns/is_same_day';
import { generalSelector } from 'redux/modules/general';
import daysAndTimestampsSelector from 'selectors/daysAndTimestampsSelector';
import { getNowTime } from 'utils/timeHelpers';

export const canTodaySelector = createSelector(
  [daysAndTimestampsSelector, generalSelector],
  (daysAndTimestamps, { orderAheadInfo: { orderAheadEnabled } }) =>
    orderAheadEnabled &&
    daysAndTimestamps.some(({ day }) => isSameDay(day, getNowTime())),
);
