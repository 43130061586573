import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Icon from 'components/Icon';

import ReactSpinner from 'react-spinner';
import 'react-spinner/react-spinner.css';
import './react-spinner.css';

const LocationInput = ({
  onGeolocationClick,
  onClearClick,
  geologationInProgress,
  ...inputProps
}) => (
  <Wrapper>
    {geologationInProgress ? (
      <SpinnerWrapper>
        <ReactSpinner />
      </SpinnerWrapper>
    ) : (
      <LocationIcon onClick={onGeolocationClick}>
        <Icon name="icon-address-small" size="30px" />
      </LocationIcon>
    )}
    <Input type="number" pattern="\d*" {...inputProps} />
    <ClearIcon onClick={onClearClick}>
      <Icon name="icon-cross-thin-small" size="30px" />
    </ClearIcon>
  </Wrapper>
);

LocationInput.propTypes = {
  onGeolocationClick: PropTypes.func,
  onClearClick: PropTypes.func,
  geologationInProgress: PropTypes.bool,
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 15px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

const LocationIcon = styled.button`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Input = styled.input`
  flex: 1 1 auto;
  font-size: 17px;
  font-size: 'Roboto', sans-serif;
  padding: 0 10px;
  margin: 0;
  border: 0;
  color: rgb(94, 98, 99);
  outline: none;
  font-weight: 300;
`;

const ClearIcon = styled.button`
  width: 15px;
  height: 15px;
  opacity: 0.5;
  cursor: pointer;
`;

export default LocationInput;
