import { handleActions, createAction } from 'redux-actions';
import { SET_USER_POSITION } from 'redux/modules/user';

// ---
// CONSTANTS
// ---
export const CLEAR_LOCATION = 'locations/CLEAR_LOCATION';
export const SET_LOCATION_PREDICTIONS = 'locations/SET_LOCATION_PREDICTIONS';
export const CLEAR_LOCATOIN_PREDICTIONS =
  'locations/CLEAR_LOCATOIN_PREDICTIONS';
export const COMPUTE_DISTANCES = 'locations/COMPUTE_DISTANCES';

// ---
//  ACTION CREATORS
// ---
export const clearLocation = createAction(CLEAR_LOCATION);
export const setLocationPredictions = createAction(SET_LOCATION_PREDICTIONS);
export const clearLocationPredictions = createAction(
  CLEAR_LOCATOIN_PREDICTIONS,
);
// ---
// INITIAL STATE
// ---
const initialState = {
  location: '',
  locationPredictions: [],
  distances: [],
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [SET_USER_POSITION]: (state, action) => ({
      ...state,
      location: action.payload.address,
    }),
    [CLEAR_LOCATION]: state => ({
      ...state,
      location: '',
    }),
    [SET_LOCATION_PREDICTIONS]: (state, action) => ({
      ...state,
      locationPredictions: action.payload,
    }),
    [CLEAR_LOCATOIN_PREDICTIONS]: state => ({
      ...state,
      locationPredictions: [],
    }),
    [COMPUTE_DISTANCES]: (state, action) => ({
      ...state,
      distances: action.payload,
    }),
  },
  initialState,
);

export const actions = {
  clearLocation,
  setLocationPredictions,
  clearLocationPredictions,
};
