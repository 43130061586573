import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PopUpAnimation from 'components/PopUpAnimation';
import PopUp from 'components/PopUp';
import DocumentElementStylesHelper from 'components/DocumentElementStylesHelper';

const ANIMATION_DURATION = 350;

const Modal = ({ children, style }) => (
  <PopupWrapper style={style}>{children}</PopupWrapper>
);

Modal.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

const AnimatedModal = ({ show, children, onCloseClick }) => (
  <PopUp show={show} onOutsideClick={onCloseClick}>
    {show && <DocumentElementStylesHelper />}
    <PopUpAnimation
      unmountOnExit
      appear
      in={show}
      duration={ANIMATION_DURATION}
      timeout={{ enter: 10, exit: ANIMATION_DURATION }}
    >
      {style => (
        <Modal style={style} onCloseClick={onCloseClick}>
          {children}
        </Modal>
      )}
    </PopUpAnimation>
  </PopUp>
);

AnimatedModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
};

export default AnimatedModal;

const PopupWrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  position: fixed;
  overflow: hidden;
  pointer-events: none;
  z-index: 30;

  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
