import ExtendableError from './ExtendableError';

class GeolocationError extends ExtendableError {
  constructor() {
    super('Geolocation is not supported by your browser');
  }
}

export default class GeolocationService {
  static getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        const onSuccess = position => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        };
        navigator.geolocation.getCurrentPosition(onSuccess, reject);
      } else {
        reject(new GeolocationError());
      }
    });
  }
}
