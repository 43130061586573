import { createSelector } from 'reselect';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import { getNowTime } from 'utils/timeHelpers';
import isSameDay from 'date-fns/is_same_day';
import addDays from 'date-fns/add_days';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';

const deliveryDaySelector = createSelector(
  scheduleTimeSelector,
  scheduleTime =>
    ({
      [true]: format(scheduleTime, 'MMM Do'),
      [differenceInCalendarDays(scheduleTime, getNowTime()) < 7]: format(
        scheduleTime,
        'ddd Do',
      ),
      [isSameDay(scheduleTime, addDays(getNowTime(), 1))]: 'Tomorrow',
      [isSameDay(scheduleTime, getNowTime())]: 'Today',
    }.true),
);

export default deliveryDaySelector;
