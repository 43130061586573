import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';

const withScriptLoaded = ({ url, attributes }) => WrappedComponent => {
  class Wrapper extends React.Component {
    constructor() {
      super();
      this.state = { scriptLoaded: false };
    }

    setScriptLoaded = () => {
      const { onLoad } = this.props;
      if (onLoad) {
        onLoad();
      }
      this.setState({ scriptLoaded: true });
    };

    render() {
      const { scriptLoaded } = this.state;
      return (
        <Fragment>
          <Script
            url={url}
            // onCreate={this.handleScriptCreate.bind(this)}
            // onError={this.handleScriptError.bind(this)}
            onLoad={this.setScriptLoaded}
            attributes={attributes}
          />
          {scriptLoaded && <WrappedComponent {...this.props} />}
        </Fragment>
      );
    }
  }

  Wrapper.propTypes = {
    onLoad: PropTypes.func,
  };

  Wrapper.defaultProps = {
    onLoad: undefined,
  };

  Wrapper.displayName = 'withScriptLoaded';

  return Wrapper;
};

export default withScriptLoaded;
