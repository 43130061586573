import { createSelector } from 'reselect';

import sum from 'lodash/sumBy';

import cartItemsPriceSelector from './cartItemsPrice';

const cartSubtotalSelector = createSelector(
  [cartItemsPriceSelector],
  cartItemsPrice => sum(Object.values(cartItemsPrice)),
);

export default cartSubtotalSelector;
