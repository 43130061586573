import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { actions as userActions } from 'redux/modules/user';
import inform from 'utils/inform';
import isEmail from 'utils/isEmail';

const registerStepThreeHOC = compose(
  withRouter,
  connect(null, {
    createUser: userActions.createUser,
  }),
  withProps(({ values: { firstStepValues } }) => ({ firstStepValues })),
  withFormik({
    mapPropsToValues: () => ({
      firstName: '',
      lastName: '',
      email: '',
    }),
    validate: ({ firstName, lastName, email }) => {
      const errors = {};
      if (!firstName) {
        errors.firstName = 'Required';
      }
      if (!lastName) {
        errors.lastName = 'Required';
      }
      if (!email) {
        errors.email = 'Required';
      }
      if (!isEmail(email)) {
        errors.email = 'Invalid email';
      }
      return errors;
    },
    handleSubmit: (
      { email, firstName, lastName },
      { props: { token, password, createUser, history } },
    ) => {
      createUser(
        { token, email, password, firstName, lastName },
        () => {
          history.push('/');
        },
        err => {
          inform({
            title: 'Oops...',
            message: err.json.message,
            buttonText: 'OK',
          });
        },
      );
    },
  }),
  withProps(({ values: { email } }) => ({ email })),
  withHandlers({
    handleEmailFieldBlur: ({
      values: { email },
      errors,
      setFieldError,
      handleBlur,
      validateEmail,
    }) => e => {
      handleBlur(e);
      if (email && !errors.email) {
        validateEmail(
          { email },
          () => {
            setFieldError('email', 'Email already taken');
          },
          () => null,
        );
      }
    },
  }),
);

export default registerStepThreeHOC;
