import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';
import IconClean from 'components/IconClean';

const ReviewOrderButton = ({ orderPrice, count, handleReviewOrderClick }) =>
  orderPrice > 0 && (
    <ButtonWrapper onClick={handleReviewOrderClick}>
      <ReviewOrderWrapper>
        <CounterWrapper>
          <Bag name="icon-bag-small" size="48px" />
          <Counter>{count}</Counter>
        </CounterWrapper>
        <Label>Review Order</Label>
        <Amount>${Number(orderPrice).toFixed(2)}</Amount>
      </ReviewOrderWrapper>
    </ButtonWrapper>
  );

ReviewOrderButton.propTypes = {
  handleReviewOrderClick: PropTypes.func.isRequired,
};

const ButtonWrapper = styled.button`
  background: #fff;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 30;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  ${media.tablet`
    display: none;
  `};
`;

const ReviewOrderWrapper = styled.div`
  height: 52px;
  background: #54a300;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 8px;
`;

const CounterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 16px;
`;

const Counter = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;

  font-size: 15px;
  letter-spacing: 0;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  font-weight: lighter;
`;

const Bag = styled(IconClean)`
  color: #3f7806;
`;

const Label = styled.div`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
`;

const Amount = styled.div`
  font-size: 14px;
  opacity: 0.8;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
`;

export default ReviewOrderButton;
