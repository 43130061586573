import React from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';
import styled from 'styled-components';

import logo from 'assets/logo-footer.svg';
import { BUILD_DATE } from 'utils/constants';

const Footer = ({ clicks, handleLabelClick }) => (
  <Wrapper aria-hidden>
    <Top>
      <Label onClick={handleLabelClick}>
        {clicks >= 5 ? BUILD_DATE : 'POWERED BY'}
      </Label>
      <Logo />
    </Top>
  </Wrapper>
);

Footer.propTypes = {
  clicks: PropTypes.number.isRequired,
  handleLabelClick: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  background: #303337;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: #fff;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 20px 0;
`;

const Label = styled.div`
  font-size: 9px;
  margin-right: 20px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(243, 243, 243);
  line-height: 1.547;
  letter-spacing: 1.7px;
  font-weight: 300;
`;

const Logo = styled.div`
  width: 70px;
  height: 25px;
  background: url('${logo}') no-repeat center / contain;
`;

const footerHOC = compose(
  withStateHandlers(
    {
      clicks: 0,
    },
    {
      handleLabelClick: ({ clicks }) => () => ({
        clicks: clicks <= 4 ? clicks + 1 : clicks,
      }),
    },
  ),
);

export default footerHOC(Footer);
