import { handleActions, createAction } from 'redux-actions';

// ---
// CONSTANTS
// ---
const SHOW_SPINNER = 'spinner/SHOW_SPINNER';
const HIDE_SPINNER = 'spinner/HIDE_SPINNER';

// ---
// ACTION CREATORS
// ---
const showSpinner = createAction(SHOW_SPINNER);
const hideSpinner = createAction(HIDE_SPINNER);

// ---
// INITIAL STATE
// ---

const initialState = {
  spinnersCounter: 0,
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [SHOW_SPINNER]: state => ({
      ...state,
      spinnersCounter: state.spinnersCounter + 1,
    }),
    [HIDE_SPINNER]: state => ({
      ...state,
      spinnersCounter: Math.max(state.spinnersCounter - 1, 0),
    }),
  },
  initialState,
);

export const actions = {
  showSpinner,
  hideSpinner,
};
