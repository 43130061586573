import {
  compose,
  branch,
  renderNothing,
  withProps,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  actions as cartActions,
  redeemCurrencySelector,
} from 'redux/modules/cart';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import withLoyalty from 'HOC/withLoyalty';

export const redeemPointsHOC = compose(
  withLoyalty,
  branch(({ loyaltyEnabled }) => !loyaltyEnabled, renderNothing),
  connect(
    state => ({
      redeemCurrency: redeemCurrencySelector(state),
      cartSubtotal: cartSubtotalSelector(state),
    }),
    {
      setRedeemCurrency: cartActions.setRedeemCurrency,
    },
  ),
  withProps(
    ({
      loyalty: {
        consumer: { pointsInCurrency },
      },
      cartSubtotal,
    }) => ({
      maxApplicablePoints: Math.min(pointsInCurrency, cartSubtotal * 100),
    }),
  ),
  withHandlers({
    toggleRedeemCurrency: ({
      maxApplicablePoints,
      redeemCurrency,
      setRedeemCurrency,
    }) => () => {
      setRedeemCurrency(redeemCurrency ? null : maxApplicablePoints);
    },
  }),
);
