import { compose, lifecycle, mapProps } from 'recompose';
import omit from 'lodash/fp/omit';
import { connect } from 'react-redux';

const dataFetcher = (shouldFetchSelector, actionToDispatch) =>
  compose(
    connect(
      (state, props) => ({ shouldFetch: shouldFetchSelector(state, props) }),
      {
        action: actionToDispatch,
      },
    ),
    lifecycle({
      componentDidMount() {
        const { shouldFetch, action } = this.props;
        if (shouldFetch) {
          action();
        }
      },
    }),
    mapProps(omit(['shouldFetch', 'action'])),
  );

export default dataFetcher;
