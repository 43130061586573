import { createSelector } from 'reselect/lib/index';

import { generalSelector } from 'redux/modules/general';
import daysAndTimestampsSelector from 'selectors/daysAndTimestampsSelector';

const canScheduleSelector = createSelector(
  [daysAndTimestampsSelector, generalSelector],
  (daysAndTimestamps, { orderAheadInfo: { orderAheadEnabled } }) =>
    orderAheadEnabled &&
    daysAndTimestamps.some(({ timestamps }) => timestamps && timestamps.length),
);

export default canScheduleSelector;
