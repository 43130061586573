import {
  compose,
  withProps,
  defaultProps,
  branch,
  renderNothing,
} from 'recompose';
import has from 'lodash/has';
import { connect } from 'react-redux';
import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';
import { cartItemsSelector } from 'redux/modules/cart';
import DishCard from '../components/ProductCard';

const DishCardHOC = compose(
  connect(state => ({
    items: availableAtDateItemsSelector(state),
    basketDishes: cartItemsSelector(state),
  })),
  defaultProps({
    items: {},
    basketDishes: {},
  }),
  withProps(({ id, items, basketDishes }) => ({
    item: {
      ...items[id],
      count: Object.values(basketDishes)
        .filter(item => item.dishId === id)
        .reduce((sum, orderItem) => sum + orderItem.count, 0),
    },
  })),
  withProps(({ item }) => ({
    item: { ...item, price: item.overwrittenPriceRange || item.price },
  })),
  defaultProps({
    item: {},
  }),
  branch(({ item }) => !has(item, 'id'), renderNothing),
);

export default DishCardHOC(DishCard);
