import { GOOGLE_MAPS_API_KEY } from 'utils/constants';

const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';

export default class StaticMapService {
  constructor(width, height) {
    this.width = Math.round(width);
    this.height = Math.round(height);
    this.scale = 1;
  }
  getPicture(address) {
    return (
      address &&
      `${BASE_URL}?size=${this.width}x${this.height}&markers=${address
        .replace('<', '')
        .replace(/\s/g, '')}&key=${GOOGLE_MAPS_API_KEY}`
    );
  }
}
