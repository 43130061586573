import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/Icon';
import NumericInput from 'react-numeric-input';

const BaseTipOption = ({ id, children, checked, onClick }) => (
  <Button key={id} checked={checked} onClick={onClick}>
    {children}
  </Button>
);

BaseTipOption.propTypes = {
  id: PropTypes.number,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

BaseTipOption.defaultProps = {
  id: -1,
};

const PercentTipOption = ({ id, value, checked, onClick }) => (
  <BaseTipOption id={id} checked={checked} onClick={onClick}>
    {(value * 10000).toFixed()} %
  </BaseTipOption>
);

PercentTipOption.propTypes = {
  id: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.node.isRequired,
};

const CurrencyTipOption = ({ checked, onClick }) => (
  <BaseTipOption checked={checked} onClick={onClick}>
    Other
  </BaseTipOption>
);

CurrencyTipOption.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Tip = ({
  tipChoices,
  displayTipValue,
  onTipInputClick,
  onTipClick,
  selectedTipIndex,
  onInputChange,
  valid,
  tipValue,
  inputTipAmount,
  maxTipAmount,
  tipInputEnabled,
  onTipInputFocus,
  maxTipAmountReached,
  onTipInputRef,
}) => (
  <Wrapper>
    <Top>
      {tipChoices.map((tipChoice, index) => (
        <PercentTipOption
          key={tipChoice}
          id={tipChoice}
          checked={selectedTipIndex === index}
          value={tipChoice}
          onClick={onTipClick(tipChoice)}
        />
      ))}
      <CurrencyTipOption checked={tipInputEnabled} onClick={onTipClick()} />
    </Top>
    <Bottom>
      <Label>Your tip:</Label>
      <Value>
        <InputWrapper displayTipValue={displayTipValue}>
          $
          <InputStyled
            pattern="[0-9]+([,\.][0-9]+)?"
            inputMode="numeric"
            value={tipInputEnabled ? +tipValue || null : inputTipAmount}
            onChange={onInputChange}
            onClick={onTipInputClick}
            onFocus={onTipInputFocus}
            innerRef={onTipInputRef}
            min={0}
            max={maxTipAmount}
            step={0.1}
            readOnly={!tipInputEnabled}
            placeholder="0.00"
            precision={2}
            // eslint-disable-next-line react/style-prop-object
            style={false}
            snap
            strict
          />
        </InputWrapper>
        <MaxTipReached visible={maxTipAmountReached}>
          Max. Tip Reached
        </MaxTipReached>
        {valid && (
          <Checked>
            <Icon name="icon-check-small" size="36px" />
          </Checked>
        )}
      </Value>
    </Bottom>
  </Wrapper>
);

Tip.propTypes = {
  tipValue: PropTypes.number,
  valid: PropTypes.bool.isRequired,
  tipChoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  displayTipValue: PropTypes.bool.isRequired,
  inputTipAmount: PropTypes.number.isRequired,
  maxTipAmount: PropTypes.number.isRequired,
  selectedTipIndex: PropTypes.number,
  onInputChange: PropTypes.func.isRequired,
  onTipClick: PropTypes.func.isRequired,
  tipInputEnabled: PropTypes.bool.isRequired,
  onTipInputFocus: PropTypes.func.isRequired,
  maxTipAmountReached: PropTypes.bool.isRequired,
  onTipInputRef: PropTypes.func.isRequired,
  onTipInputClick: PropTypes.func.isRequired,
};

Tip.defaultProps = {
  tipValue: 0,
  selectedTipIndex: undefined,
};

const Wrapper = styled.div`
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Button = styled.button`
  width: 20%;
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: ${p => (p.checked ? '#fff' : 'rgb(66, 70, 72)')};
  background: ${p => (p.checked ? '#54a300' : 'inherit')};

  padding: 15px 5px;
  white-space: nowrap;

  :not(:first-child) {
    border-left: 1px solid #eeefef;
  }
`;

const Bottom = styled.div`
  border-top: 1px solid #eeefef;
  display: flex;
  flex-flow: row nowrap;
`;

const Label = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  padding: 15px;
  border-right: 1px solid #eeefef;
  min-width: 98px;
`;

const Value = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  :focus-within {
    border: 1px solid #54a300;
  }
`;

const InputWrapper = styled.div`
  flex: 1 1 auto;
  padding: 12px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 80px;
  display: ${p => (p.displayTipValue ? 'inherit' : 'none')};
`;

const InputStyled = styled(NumericInput)`
  border: none;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  outline: none;
  max-width: 100px;
  -webkit-text-fill-color: rgb(66, 70, 72);
  opacity: 1;
  background: transparent !important;

  ::-webkit-inner-spin-button, 
  ::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance:textfield !important;
`;

const Checked = styled.div`
  flex: 1 1 auto;
  min-width: 20px;
  min-height: 15px;
  width: 20px;
  height: 15px;
  color: #55a30b;
`;

const MaxTipReached = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  visibility: ${p => (p.visible ? 'inherit' : 'hidden')};
`;

export default Tip;
