import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class ClickOutside extends Component {
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = event => {
    if (!ReactDOM.findDOMNode(this).contains(event.target)) {
      this.props.onOutsideClick();
    }
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

ClickOutside.propTypes = {
  children: PropTypes.node,
  onOutsideClick: PropTypes.func,
};

ClickOutside.defaultProps = {
  onOutsideClick: () => null,
};

export default ClickOutside;
