import React from 'react';
import { withDeliverySettings } from 'HOC/withDeliverySettings';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import Footer from 'components/Footer';
import DesktopHeader from 'containers/DesktopHeader';

import Navigation, {
  EmptyHeader,
  NavigationHeader,
} from 'components/Navigation';
import Button from 'components/Button';

import ScrollableContent from 'components/ScrollableContent';
import DeliveryFallbackSchedule from 'components/DeliveryFallbackSchedule';
import DeliveryAddressPicker from 'components/DeliveryAddressPicker';
import { DeliveryTypePicker } from 'components/DeliveryTypePicker';
import { DeliveryTimeTypePicker } from 'components/DeliveryTimeTypePicker';
import SelectDeliveryAddress from 'routes/Delivery/components/SelectDeliveryAddress';

const FooterWrapper = styled.div``;

const Content = styled.div`
  flex: 1 1 auto;
`;

const Item = styled.div`
  padding: 25px 15px 28px 15px;

  &:not(:first-child) {
    border-top: 1px solid #e8e8e8;
  }

  ${media.tablet`
    &:first-child {
      margin-top: 65px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  `} ${media.desktop`
    
    &:first-child {
      margin-top: 65px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  `};
`;

const TitleHeader = styled.div`
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  letter-spacing: 0.2px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-weight: 300;
  letter-spacing: 0.2px;

  b {
    font-weight: bold;
  }
`;

const ErrorPill = styled.div`
  background-color: #f40a15;
  border-radius: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  padding: 5px;
`;

const DeliveryMethodWrapper = styled.div`
  margin-top: 20px;
`;

const Name = styled.div`
  display: inline-block;
  font-size: 16px;
  font-family: 'Montserrat';
  color: #000;
  text-align: center;
  font-weight: normal;
`;

const ButtonWrapper = styled.div`
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 30px;
    ${media.tablet`
    margin-top: 50px;
  `};
  }
`;

const HeaderWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 1px 1px 2px #e8e9e9;

  ${media.tablet`
    display: block;
  `} ${media.desktop`
    display: block;
  `};
`;

const MobileHeader = styled.div`
  ${media.tablet`
    display: none;
  `} ${media.desktop`
    display: none;
  `};
`;

const DeliveryConditionsLabels = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  color: #474b4d;
  text-align: center;
  margin: 0 0 16px 0;
`;

const Label = styled.span`
  margin: 0 8px;
`;

const DeliveryTypeError = styled.div`
  border-radius: 2px;
  border: 1px solid #f40a15;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  color: #424648;
  font-weight: 300;
  margin: 0 15px;
  padding: 20px 24px 16px;
`;

const DeliveryAddressError = styled.div`
  border-radius: 2px;
  border: 1px solid #f40a15;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  color: #424648;
  font-weight: 200;
  margin: 0 15px;
  padding: 20px 24px 16px;
  line-height: 1.4;
  ul {
    margin-top: 12px;
    margin-left: 18px;
    list-style: disc;
  }
`;

const DeliverySettingsComponent = ({
  onSoonestClick,
  onScheduleClick,
  restaurantInfo,
  reorderBackButtonHandler,
  deliveryConditionsLabels,
  setNewAddress,
  fallbackScheduleVisible,
  changeAddressButtonVisible,
  deliveryValid,
  onChangeAddressClick,
  isValid,
  deliveryAddressModalVisible,
  handleAddressClick,
  hideDeliveryAddressModal,
  showDeliveryAddressModal,
  continueButtonHandler,
  reorderButtonHandler,
  reorderId,
  canSoonest,
  canSchedule,
  onPickupClick,
  onDeliveryClick,
  canPickup,
  canDelivery,
  validateAndSetScheduleTime,
  deliveryAddressErrorResolutionSuggestion,
  errors,
  touched,
  deliveryTimeTypeFormikValue,
  scheduleTimeFormikValue,
  values: {
    deliveryType,
    deliveryAddress,
    tempUserAddress,
    deliveryConditions,
  },
  deliveryTimePickerVisible,
  deliveryTimeTypePickerKey,
  dineInEnabled,
}) => (
  <Navigation delivery>
    <EmptyHeader>
      <MobileHeader>
        <NavigationHeader
          backPath={reorderId ? '/orders' : '/'}
          onBackClick={reorderId && reorderBackButtonHandler}
        >
          <TitleHeader>
            <Name>{restaurantInfo.name}</Name>
          </TitleHeader>
        </NavigationHeader>
      </MobileHeader>
    </EmptyHeader>
    <HeaderWrapper>
      <DesktopHeader menu />
    </HeaderWrapper>
    <ScrollableContent>
      <SelectDeliveryAddress
        show={deliveryAddressModalVisible}
        onCloseClick={hideDeliveryAddressModal}
        onDoneClick={setNewAddress}
      />
      <Content>
        {dineInEnabled && (
          <Container>
            <Item>
              <TitleWrapper>
                <Title>Loading Menu...</Title>
              </TitleWrapper>
            </Item>
          </Container>
        )}
        {fallbackScheduleVisible ? (
          <Container>
            <Item>
              <DeliveryFallbackSchedule />
            </Item>
          </Container>
        ) : (
          <Container opacity={dineInEnabled ? '0' : '1'}>
            <Item>
              <TitleWrapper>
                {reorderId ? (
                  <Title>
                    How would you like to <b>reorder</b>?
                  </Title>
                ) : (
                  <Title>How would you like to order?</Title>
                )}
                {!deliveryType &&
                  touched.deliveryType &&
                  errors.deliveryType &&
                  errors.deliveryType === 'Required' && (
                    <ErrorPill>Required</ErrorPill>
                  )}
              </TitleWrapper>
              <DeliveryMethodWrapper>
                <DeliveryTypePicker
                  onPickupClick={onPickupClick}
                  onDeliveryClick={onDeliveryClick}
                  canPickup={canPickup}
                  canDelivery={canDelivery}
                  deliveryType={deliveryType}
                  deliveryTypeError={
                    touched.deliveryType &&
                    errors.deliveryType &&
                    errors.deliveryType === 'Required'
                  }
                  dineInEnabled={dineInEnabled}
                />
              </DeliveryMethodWrapper>
            </Item>
            {deliveryType && touched.deliveryType && errors.deliveryType ? (
              <DeliveryTypeError>{errors.deliveryType}</DeliveryTypeError>
            ) : (
              <>
                {deliveryType === 'DELIVERY' && (
                  <DeliveryAddressPicker
                    value={deliveryAddress}
                    tempUserAddress={tempUserAddress}
                    showDeliveryAddressModal={showDeliveryAddressModal}
                    handleAddressClick={handleAddressClick}
                    deliveryValid={deliveryValid}
                    addressError={
                      touched.deliveryAddress &&
                      errors.deliveryAddress &&
                      errors.deliveryAddress === 'Required'
                    }
                    changeAddressButtonVisible={changeAddressButtonVisible}
                    onChangeAddressClick={onChangeAddressClick}
                  />
                )}
                {Boolean(
                  deliveryConditionsLabels && deliveryConditionsLabels.length,
                ) && (
                  <DeliveryConditionsLabels>
                    {deliveryConditionsLabels.map(label => (
                      <Label key={label}>{label}</Label>
                    ))}
                  </DeliveryConditionsLabels>
                )}
                {deliveryAddressErrorResolutionSuggestion && (
                  <DeliveryAddressError>
                    <p>
                      Delivery to this address is no longer available for today.
                      You can:
                    </p>
                    <ul>
                      {deliveryAddressErrorResolutionSuggestion.map(error => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </DeliveryAddressError>
                )}
                <Item>
                  {deliveryTimePickerVisible && (
                    <>
                      <Title>
                        {
                          {
                            [true]: 'Select Time',
                            [deliveryType ===
                            'DELIVERY']: 'Select Delivery Time',
                            [deliveryType === 'PICKUP']: 'Select Pickup Time',
                            [deliveryType === 'PICKUP' &&
                            dineInEnabled]: 'Select Dine-in Time',
                          }.true
                        }
                      </Title>

                      <DeliveryTimeTypePicker
                        key={deliveryTimeTypePickerKey}
                        deliveryType={deliveryType}
                        soonestButtonEnabled={canSoonest}
                        onSoonestClick={onSoonestClick}
                        deliveryTimeType={deliveryTimeTypeFormikValue}
                        scheduleButtonEnabled={canSchedule}
                        onScheduleClick={onScheduleClick}
                        scheduleTime={scheduleTimeFormikValue}
                        setScheduleTime={scheduleTime => {
                          validateAndSetScheduleTime(scheduleTime);
                        }}
                        scheduleTimeError={
                          touched.scheduleTime && errors.scheduleTime
                        }
                        deliveryConditions={deliveryConditions}
                      />
                    </>
                  )}
                  <ButtonWrapper>
                    {reorderId ? (
                      <Button
                        onClick={reorderButtonHandler}
                        disabled={!isValid}
                        clickableWhenDisabled
                      >
                        Review your order
                      </Button>
                    ) : (
                      <Button
                        onClick={continueButtonHandler}
                        disabled={!isValid}
                        clickableWhenDisabled
                      >
                        Continue
                      </Button>
                    )}
                  </ButtonWrapper>
                </Item>
              </>
            )}
          </Container>
        )}
      </Content>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ScrollableContent>
  </Navigation>
);

DeliverySettingsComponent.propTypes = {
  onSoonestClick: PropTypes.func.isRequired,
  onScheduleClick: PropTypes.func.isRequired,
  restaurantInfo: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  reorderBackButtonHandler: PropTypes.func.isRequired,
  fallbackScheduleVisible: PropTypes.bool.isRequired,
  deliveryConditionsLabels: PropTypes.arrayOf(PropTypes.string),
  onChangeAddressClick: PropTypes.func.isRequired,
  changeAddressButtonVisible: PropTypes.bool.isRequired,
  deliveryValid: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  deliveryAddressModalVisible: PropTypes.bool.isRequired,
  handleAddressClick: PropTypes.func.isRequired,
  hideDeliveryAddressModal: PropTypes.func.isRequired,
  showDeliveryAddressModal: PropTypes.func.isRequired,
  continueButtonHandler: PropTypes.func.isRequired,
  reorderButtonHandler: PropTypes.func.isRequired,
  reorderId: PropTypes.number,
  deliveryAddressErrorResolutionSuggestion: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  scheduleTimeFormikValue: PropTypes.string,
  deliveryTimeTypeFormikValue: PropTypes.oneOf(['SCHEDULE', 'SOONEST']),
  values: PropTypes.shape({
    deliveryType: PropTypes.oneOf(['PICKUP', 'DELIVERY']),
    deliveryAddress: PropTypes.string,
    tempUserAddress: PropTypes.object,
    deliveryConditions: PropTypes.object,
  }).isRequired,
  setNewAddress: PropTypes.func.isRequired,
  canSoonest: PropTypes.bool.isRequired,
  canSchedule: PropTypes.bool.isRequired,
  onPickupClick: PropTypes.func.isRequired,
  onDeliveryClick: PropTypes.func.isRequired,
  canPickup: PropTypes.bool.isRequired,
  canDelivery: PropTypes.bool.isRequired,
  validateAndSetScheduleTime: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  deliveryTimePickerVisible: PropTypes.bool.isRequired,
  deliveryTimeTypePickerKey: PropTypes.string.isRequired,
  dineInEnabled: PropTypes.bool.isRequired,
};

DeliverySettingsComponent.defaultProps = {
  reorderId: undefined,
  deliveryAddressErrorResolutionSuggestion: undefined,
  scheduleTimeFormikValue: null,
  deliveryTimeTypeFormikValue: null,
  deliveryConditionsLabels: undefined,
};

const Container = styled.div`
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  padding-bottom: 20px;
  opacity: ${({ opacity }) => opacity};
`;

export const DeliverySettings = withDeliverySettings(DeliverySettingsComponent);
