import { compose, withPropsOnChange } from 'recompose';

import ScheduleOrder from '../components/ScheduleOrder';

const ScheduleOrderHOC = compose(
  withPropsOnChange(['scheduleTime'], ({ scheduleTime }) => ({
    filled: !!scheduleTime,
  })),
);

export default ScheduleOrderHOC(ScheduleOrder);
