import { createSelector } from 'reselect';

import subMinutes from 'date-fns/sub_minutes';

import { deliverySelector } from 'redux/modules/delivery/selectors';

export const scheduleTimeSelector = createSelector(
  [deliverySelector, (_, props) => props],
  (delivery, { scheduleTime } = {}) => scheduleTime || delivery.scheduleTime,
);

export const backendScheduleTimeSelector = createSelector(
  scheduleTimeSelector,
  scheduleTime =>
    scheduleTime &&
    subMinutes(
      scheduleTime,
      new Date(scheduleTime).getTimezoneOffset(),
    ).toISOString(),
);

export default scheduleTimeSelector;

// TODO: consider deleting this (duplicates the same selector in separate file)
// export const scheduleTimeSelector = createSelector(
//   deliverySelector,
//   delivery => delivery.scheduleTime
// );
