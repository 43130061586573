import { compose, withProps, withHandlers, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import get from 'lodash/get';
import sum from 'lodash/sum';
import pickBy from 'lodash/pickBy';
import keyBy from 'lodash/keyBy';
import {
  actions as dishConfiguratorActions,
  configuredDishIdSelector,
  configuredDishSelector,
  dishConfiguratorSelector,
  dishVariationsWithOptionsSelector,
} from 'redux/modules/dishConfigurator';
import { modalSelector, actions as modalActions } from 'components/ReduxModal';
import { optionsSelector } from 'selectors/optionsSelector';

import Flavors from '../components/Flavors';

const FlavorsHOC = compose(
  connect(
    state => ({
      modal: modalSelector(state),
      dishId: configuredDishIdSelector(state),
      dish: configuredDishSelector(state),
      variations: dishVariationsWithOptionsSelector(state),
      configuration: dishConfiguratorSelector(state),
      options: optionsSelector(state),
    }),
    {
      modalGoBack: modalActions.modalGoBack,
      updateVariation: dishConfiguratorActions.updateVariation,
    },
  ),
  defaultProps({
    variations: {},
    configuration: {},
  }),
  withProps(({ modal }) => ({
    variationId: modal.payload && modal.payload.variationId,
  })),
  withProps(({ variationId, variations }) => ({
    variation: variations.find(v => v.id === variationId) || { options: [] },
  })),
  withProps(({ variation, options, configuration, variationId }) => ({
    variationOptions: variation.options.map(({ id, ...rest }) => ({
      ...rest,
      id,
      ...get(options, id),
    })),
    variationConfiguration: get(
      configuration,
      `variations.${variationId}.value`,
      {},
    ),
  })),
  withProps(({ variationOptions, variationConfiguration }) => ({
    items: variationOptions.map(opt => ({
      ...opt,
      value: variationConfiguration[opt.id] || 0,
    })),
  })),
  withProps(({ options, variationConfiguration }) => {
    const flavorIds = Object.entries(variationConfiguration);
    const selectedFlavors = flavorIds.map(([selectedId, val]) => ({
      ...options[selectedId],
      val,
    }));
    const comboItems = selectedFlavors.map(({ name, val, price }) => ({
      amount: val,
      name,
      price,
    }));
    return { comboItems };
  }),
  withProps(({ variationConfiguration }) => ({
    itemsCount: sum(Object.values(variationConfiguration)),
  })),
  withProps(({ variation: { min, max }, itemsCount }) => ({
    valid: (!min || itemsCount >= min) && (max <= 0 || itemsCount <= max),
  })),
  withHandlers({
    onAcceptClick: ({ modalGoBack }) => () => {
      modalGoBack();
    },
    handleChange: ({
      variation,
      variationConfiguration,
      variationId,
      updateVariation,
    }) => (optionId, optionValue) => {
      updateVariation({
        id: variationId,
        type: variation.type,
        value: pickBy({
          ...variationConfiguration,
          [optionId]: optionValue,
        }),
      });
    },
  }),
  withHandlers({
    increaseOpt: ({ items, handleChange }) => optionId => () => {
      handleChange(optionId, keyBy(items, 'id')[optionId].value + 1);
    },
    decreaseOpt: ({ items, handleChange }) => optionId => () => {
      handleChange(optionId, keyBy(items, 'id')[optionId].value - 1);
    },
  }),
  withHandlers({
    closeModal: ({
      onAcceptClick,
      variation,
      variationId,
      updateVariation,
    }) => () => {
      updateVariation({
        ...variation,
        id: variationId,
        value: {},
      });
      onAcceptClick();
    },
  }),
);

export default FlavorsHOC(Flavors);
