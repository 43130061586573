import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withHandlers,
  renderNothing,
  branch,
} from 'recompose';
import { connect } from 'react-redux';
import { availableOnAwareScheduleFilteredCategoriesSelector } from 'redux/modules/categories';
import styled from 'styled-components';
import Scrollspy from 'react-scrollspy';

const CategoriesScrollSpy = ({
  categoryAnchors,
  categories,
  onCategoryUpdate,
  createOnCategoryClick,
  scrollSpyKey,
}) => (
  <Container>
    <Scrollspy
      key={scrollSpyKey}
      rootEl="#router-tab-view-content"
      items={categoryAnchors}
      currentClassName="current"
      onUpdate={onCategoryUpdate}
      componentTag="div"
      offset={-100}
    >
      {categories.map(({ id, name }) => (
        <Category
          key={id}
          id={`category-${id}-button`}
          onClick={createOnCategoryClick(id)}
        >
          <span className="anchor-link">{name}</span>
        </Category>
      ))}
    </Scrollspy>
  </Container>
);

CategoriesScrollSpy.propTypes = {
  categoryAnchors: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  scrollSpyKey: PropTypes.string.isRequired,
  onCategoryUpdate: PropTypes.func.isRequired,
  createOnCategoryClick: PropTypes.func.isRequired,
};

function easeInOutQuad(currentTime, start, change, duration) {
  const newCurrentTime = currentTime / (duration / 2);
  if (newCurrentTime < 1)
    return (change / 2) * newCurrentTime * newCurrentTime + start;
  const newCurrentTime2 = newCurrentTime - 1;
  return (-change / 2) * (newCurrentTime2 * (newCurrentTime2 - 2) - 1) + start;
}

const smoothScrollLeft = ({ element, left }) => {
  if ('scrollBehavior' in document.documentElement.style) {
    element.scroll({
      left,
      behavior: 'smooth',
    });
    return;
  }
  const duration = 200;
  const start = element && element.scrollLeft;

  const change = left - start;

  const increment = 20;
  let currentTime = 0;

  function animateScroll() {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scroll({
      left: val,
    });
    if (currentTime < duration) {
      window.setTimeout(animateScroll, increment);
    }
  }
  animateScroll();
};

const CategoriesScrollSpyHOC = compose(
  connect(state => ({
    categories: availableOnAwareScheduleFilteredCategoriesSelector(state),
  })),
  withProps(({ categories }) => ({
    categoryAnchors: categories.map(({ id }) => `category-${id}-container`),
  })),
  withProps(({ categoryAnchors }) => ({
    // It forces re-rendering preventing scrollspy bugs if menu changed
    scrollSpyKey: categoryAnchors.join('+'),
  })),
  withHandlers({
    onCategoryUpdate: () => ({ id: elementId } = {}) => {
      if (elementId) {
        const [, id] = elementId.split('-');
        const child = id && document.querySelector(`#category-${id}-button`);
        if (id && child) {
          const parent = child.parentElement;
          smoothScrollLeft({ element: parent, left: child.offsetLeft - 15 });
        }
      }
    },
    createOnCategoryClick: () => id => () => {
      const scrollable = document.querySelector('#router-tab-view-content');
      const category = document.querySelector(`#category-${id}`);
      scrollable.scroll({
        top: category.offsetTop,
        behavior: 'smooth',
      });
    },
  }),
  branch(({ categories }) => !categories.length, renderNothing),
);

const Container = styled.div`
  > div {
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 12px 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    transition: margin-right ease;
    display: flex;
    flex-wrap: nowrap;
  }
`;

const Category = styled.button`
  white-space: nowrap;
  padding: 8px;
  border-radius: 2px;
  position: relative;
  transition: background-color 0.5s ease;
  font-weight: 300;

  .anchor-link {
    color: #424648;
    transition: color 0.2s ease;
    text-decoration: none;
    z-index: 10;
    position: relative;
  }
  &.current {
    font-weight: 400;
    background: #424648;
    .anchor-link {
      color: white;
    }
  }
`;

export default CategoriesScrollSpyHOC(CategoriesScrollSpy);
