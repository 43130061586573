import {
  compose,
  withHandlers,
  branch,
  renderComponent,
  defaultProps,
} from 'recompose';
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import EnterFirstLastName from 'routes/Modals/EnterFirstLastName';

const forgotEmailHOC = compose(
  defaultProps({ title: 'Find your Email', action: 'find_email' }),
  withRouter,
  withFormik({
    mapPropsToValues: ({ location: { state: { login } = {} } }) => ({
      phoneNumberField: login || '',
      phoneNumber: login || '',
    }),
    handleSubmit: ({ phoneNumberField }, { setFieldValue }) => {
      setFieldValue('phoneNumber', phoneNumberField);
    },
  }),
  withHandlers({
    onPhoneNumberFieldChange: ({ setFieldValue }) => e =>
      setFieldValue('phoneNumberField', e.target.value),
    resetNumberForm: ({ resetForm }) => () => {
      resetForm();
    },
    onCodeValidated: ({ history }) => ({ userEmail }) => {
      history.push({
        pathname: '/forgot-password',
        state: {
          email: userEmail,
        },
      });
    },
  }),
  branch(
    ({ values: { phoneNumber } }) => !!phoneNumber,
    renderComponent(EnterFirstLastName),
  ),
);

export default forgotEmailHOC;
