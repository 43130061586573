import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';
import values from 'lodash/values';

import AppLayout from 'layouts/AppLayout';
import ModeManagerHOC, { modes } from 'HOC/ModeManager';
import NavigationHeader from './NavigationHeader';
import { BaseNavigationHeader } from './BaseNavigationHeader';

export const EmptyHeader = ({ children }) => children;

const headerComponents = [EmptyHeader, NavigationHeader, BaseNavigationHeader];

const Navigation = ({
  children,
  headerHeight,
  addresses,
  delivery,
  signIn,
  menu,
  mode,
  ...props
}) => {
  const navigationHeader = React.Children.toArray(children).find(
    item => headerComponents.indexOf(item.type) !== -1,
  );
  const content = React.Children.toArray(children).filter(
    item => headerComponents.indexOf(item.type) === -1,
  );

  return (
    <Wrapper {...props} delivery={delivery}>
      {navigationHeader}
      <ContentWrapper
        mode={mode}
        top={headerHeight}
        addresses={addresses}
        delivery={delivery}
        signIn={signIn}
        menu={menu}
      >
        {content}
      </ContentWrapper>
    </Wrapper>
  );
};

Navigation.propTypes = {
  mode: PropTypes.oneOf(values(modes)),
  children: PropTypes.node,
  headerHeight: PropTypes.string,
  addresses: PropTypes.bool,
  delivery: PropTypes.bool,
  signIn: PropTypes.bool,
  menu: PropTypes.bool,
};

Navigation.defaultProps = {
  headerHeight: '50px',
};

export default ModeManagerHOC(Navigation);

const Wrapper = styled(AppLayout)`
  ${media.tablet`
    position: relative;
    top: initial;
    left: initial;
    bottom: initial;
    right: initial;
    height: ${({ delivery }) => (delivery ? '100vh' : '100%')};
  `};
  ${media.desktop`
    position: relative;
    top: initial;
    left: initial;
    bottom: initial;
    right: initial;
    height: 100%;
  `};
  background: #f3f4f5;
  /* backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0); */
`;

const ContentWrapper = styled(AppLayout)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${props =>
    props.mode === modes.MOBILE_MODE
      ? `padding-top: ${props.top}`
      : `top: ${props.top}`};
  /* position: absolute; */
  /* left: 0;
  right: 0;
  bottom: 0; */
  background: #f3f4f5;

  ${media.tablet`
    overflow-y: auto;
    position: relative;
    bottom: initial;
    height: 100%;
    top: 0;
    padding-top: ${({ delivery, menu, signIn }) =>
      delivery || menu || signIn ? '50px' : '0'};
    ${({ delivery }) => delivery && 'display: flex; flex-flow: column nowrap;'}
  `};

  ${media.desktop`
    position: relative;
    bottom: initial;
    height: 100%;
  `};
`;
