import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';
import { deliveryTimeTypeAsapHOC } from 'HOC/deliveryTimeTypeAsapHOC';

const DeliveryTimeTypeAsapComponent = ({ active, onClick, estimateText }) => (
  <Wrapper onClick={onClick}>
    <IconWrapper>
      <Icon name="icon-soonest-available-small" size="34px" />
    </IconWrapper>
    <CheckWrapper active={active}>
      <Icon name="icon-check-small" size="26px" />
    </CheckWrapper>
    <Texts>
      <Title active={active}>Soonest available</Title>
      <Description>{estimateText}</Description>
    </Texts>
  </Wrapper>
);

DeliveryTimeTypeAsapComponent.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  estimateText: PropTypes.string.isRequired,
};

DeliveryTimeTypeAsapComponent.defaultProps = {
  active: undefined,
};

const Wrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;

  ${media.desktop`
    cursor: pointer;
  `};
`;

const CheckWrapper = styled.div`
  position: absolute;
  width: 21px;
  height: 16px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 0.2s ease;
  top: 25%;
  transform: translateY(-25%);
  right: 15px;
  color: #54a30a;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: #5f6263;
`;

const Texts = styled.div`
  margin-left: 10px;
`;

const Title = styled.div`
  font-size: 17px;
  font-family: 'Roboto';
  color: #000;
  font-weight: ${({ active }) => (active ? '400' : '300')};
`;

const Description = styled.div`
  font-size: 14px;
  font-family: 'Roboto';
  color: rgb(116, 119, 120);
  margin-top: 10px;
  font-weight: 300;
`;

export const DeliveryTimeTypeAsap = deliveryTimeTypeAsapHOC(
  DeliveryTimeTypeAsapComponent,
);
