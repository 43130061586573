import React from 'react';
import ModalComponents from './ModalComponents';

const TermsOfUse = () => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>Terms of Use</ModalComponents.Title>
      <ModalComponents.WallOfText>
        <h2>Terms and Conditions</h2>
        <p>
          By using this website, you signify your agreement to these terms of
          use. If you do not accept all of the terms and conditions contained in
          this agreement, please do not use the site.
        </p>

        <h3>Modification of Services</h3>

        <p>
          The website reserves the right, in its sole discretion, to modify the
          services from time to time and without notice, including by removing,
          adding, or modifying Products and/or third-party vendors from the
          Product Catalog. The website shall have no liability to you for any
          modification or discontinuation of the services. If you object to any
          such changes, your sole recourse shall be to cease using the services.
        </p>

        <h3>Limitations on Your Use of Services</h3>

        <p>
          You may not attempt, or authorize, encourage, or support others’
          attempts, to circumvent, reverse engineer, decrypt, break or otherwise
          alter or interfere with the service. You acknowledge and agree that
          you may not copy, distribute, sell, resell, or exploit for any
          commercial purposes any portion of the services, or any products
          accessible through the services.
        </p>

        <h3>Modification of the Agreement</h3>

        <p>
          The website may amend, supplement, or modify this agreement from time
          to time by posting the amended agreement on the site, and you agree to
          be bound by any such amendment or modification. If you object to any
          such changes, your sole recourse shall be to cease using the services.
        </p>

        <h3>Termination</h3>

        <p>
          If you materially breach the terms of this agreement, the website may
          suspend your ability to use the services or may terminate this
          agreement effective immediately and without notice to you.
        </p>

        <h3>Payment and Billing</h3>

        <p>
          You acknowledge and agree that for each order you place through the
          service, the full cost of Products you order will be assessed against
          your credit card, charge card, restaurant-specific gift card, or
          restaurant-specific cash card. You acknowledge and agree that a
          command originating from your account constitutes an authorization for
          the service to charge this amount and you assume all liability for and
          shall promptly pay any and all charges. Without limiting the
          foregoing, you acknowledge and agree that you will pay assessed
          charges for any and all orders placed from your account. The service
          will provide you with reasonable notice of any material modification
          in the fees charged for the services, which notice may be provided by
          posting the new fee schedule on the website.
        </p>

        <h3>Refund Policy</h3>

        <p>
          You acknowledge that Foodie Order is not responsible for full or
          partial refunds on purchases of Products or related order fulfillment
          charges. Addressing customer requests for refunds on purchases of
          Products will be the sole responsibility of the restaurant at which
          the order was placed in accordance with such restaurant’s refund
          policies. Contact the restaurant directly with the phone number
          provided as soon as possible to request a full or partial refund.
        </p>

        <h3>Disclaimer of Warranties</h3>

        <p>
          YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR OWN RISK. THE
          SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE
          WEBSITE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR
          PURPOSE, AND NON-INFRINGEMENT. THE WEBSITE MAKES NO WARRANTY THAT THE
          SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. YOU UNDERSTAND AND AGREE
          THAT ANY PRODUCTS YOU OBTAIN THROUGH USE OF THE SERVICES IS DONE AT
          YOUR OWN RISK, AND THE WEBSITE MAKES NO WARRANTY REGARDING ANY
          DEALINGS WITH OR TRANSACTIONS ENTERED INTO WITH ANY OTHER PARTIES
          THROUGH THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR
          WRITTEN, OBTAINED BY YOU FROM THE WEBSITE OR THROUGH THE SERVICES
          SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
        </p>

        <h3>Limitation of Liability</h3>

        <p>
          YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN
          NO EVENT WILL THE WEBSITE OR ITS OFFICERS, DIRECTORS, EMPLOYEES,
          AFFILIATES, AGENTS OR LICENSORS BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF SUCH
          PARTIES WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT
          OF OR RELATED TO YOUR USE OF THE SERVICES OR PRODUCTS, REGARDLESS OF
          WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT OR OTHERWISE. THE
          PARTIES ACKNOWLEDGE THAT THE TERMS OF THIS PARAGRAPH REFLECT THE
          ALLOCATION OF RISK SET FORTH IN THIS AGREEMENT AND THAT THE PARTIES
          WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS OF
          LIABILITY. THE AGGREGATE LIABILITY OF THE WEBSITE TO YOU FOR ALL
          CLAIMS ARISING FROM OR RELATED TO THE SERVICES OR PRODUCTS IS LIMITED
          TO ONE HUNDRED DOLLARS. Some jurisdictions do not allow the exclusion
          of certain warranties or the limitation or exclusion of liability for
          incidental or consequential damages. Accordingly, some of the above
          limitations and disclaimers may not apply to you. To the extent that
          we may not, as a matter of applicable law, disclaim any implied
          warranty or limit its liabilities, the scope and duration of such
          warranty and the extent of our liability shall be the minimum
          permitted under such applicable law.
        </p>

        <h3>Privacy</h3>

        <p>
          The website has an unrestricted right to collect and use any personal
          information you provide in connection with the services, provided that
          any such use shall be in accordance with our Privacy Policy available
          publicly on the website. If you use the services, you are accepting
          the terms and conditions of our Privacy Policy.
        </p>

        <h3>Indemnification</h3>

        <p>
          You agree to indemnify, defend and hold harmless the website, its
          agents, employees, representatives, licensors, affiliates, officers
          and directors, from and against any and all claims, liabilities,
          damages, losses, costs, expenses, fees (including reasonable
          attorneys’ fees and court costs) resulting or arising from any
          third-party claim in connection with (a) any information you (or
          anyone accessing the services using your password) submit or transmit
          through the services, (b) your use of or access to the services, (c)
          your violation of this agreement, (d) your violation of any rights of
          any third party, or (e) any viruses, trojan horses, worms, time bombs,
          cancelbots, spyware, or other similar harmful or deleterious
          programming routines input by you into the services.
        </p>

        <h3>Intellectual Property</h3>

        <p>
          The services contain copyrighted and/or proprietary subject matter
          that is owned by Foodie Order and/or its licensors. All such material
          is protected by relevant intellectual property laws, including
          copyright, trademark, patent and/or trade secret laws. Such material
          may not be modified, reproduced, transmitted, sold, offered for sale,
          publicly displayed, or redistributed in any way without our prior
          written permission and the prior written permission of any other
          applicable rights licensor. All trademarks, slogans and other indicia
          of origin (“Marks”) that appear on or in connection with the services
          are the property of Foodie Order and/or its affiliates, licensors
          and/or licensees. You are not authorized to use any such Marks.
        </p>

        <h3>Miscellaneous</h3>

        <p>
          Any legal action, suit, or proceeding arising out of or relating to
          this agreement, or your use of the services, shall be instituted
          exclusively in the federal or state courts located in Miami, Florida.
          This agreement shall be governed by Florida law, without regard to
          conflict of law principles. You agree that regardless of any statute
          or law to the contrary, any claim or cause of action arising out of or
          related to use of the services or this agreement must be filed within
          one year after such claim or cause of action arose or be forever
          barred. If any provision of this agreement is found by a court of
          competent jurisdiction to be unenforceable, the parties agree that the
          court should endeavor to give effect to the parties’ intentions as
          reflected in the provision, and that the enforceable provisions of
          this agreement shall remain in full force and effect.
        </p>
      </ModalComponents.WallOfText>
    </ModalComponents.ContentContainer>
  </ModalComponents.ModalContent>
);

TermsOfUse.propTypes = {};

export default TermsOfUse;
