import {
  compose,
  withHandlers,
  branch,
  renderComponent,
  defaultProps,
  withProps,
} from 'recompose';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions as userActions } from 'redux/modules/user';
import isEmail from 'utils/isEmail';
import inform from 'utils/inform';
import EmailSent from 'routes/Modals/EmailSent';

const forgotPasswordHOC = compose(
  defaultProps({ title: 'Reset your password', action: 'reset_password' }),
  connect(null, {
    resetPassword: userActions.resetPassword,
  }),
  withRouter,
  withProps(({ location: { state: { login, email } = {} } }) => ({
    login,
    email,
  })),
  withFormik({
    mapPropsToValues: ({ email }) => ({
      emailOrNumberField: email || '',
      email: '',
    }),
    enableReinitialize: true,
    validate: values => {
      const errors = {};
      if (!values.emailOrNumberField) {
        errors.emailOrNumberField = 'Required';
      }
      if (!isEmail(values.emailOrNumberField)) {
        errors.emailOrNumberField = 'Please enter valid email';
      }
      return errors;
    },
    handleSubmit: (
      { emailOrNumberField },
      { props: { resetPassword }, setFieldValue },
    ) => {
      const onSuccess = () => {
        setFieldValue('email', emailOrNumberField);
      };
      resetPassword({ email: emailOrNumberField, onSuccess });
    },
  }),
  withHandlers({
    onEmailOrNumberFieldChange: ({ setFieldValue }) => e =>
      setFieldValue('emailOrNumberField', e.target.value),
    resendEmail: ({ resetPassword, values: { email } }) => () => {
      resetPassword({
        email,
        onSuccess: () => {
          inform({
            message: 'Email has been resent',
            buttonText: 'OK',
          });
        },
      });
    },
  }),
  branch(({ values: { email } }) => !!email, renderComponent(EmailSent)),
);

export default forgotPasswordHOC;
