/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createExpirationTransform from 'redux-persist-transform-expire';
import reduxReset from 'redux-reset';

// ---
// REDUCERS
// ---
import { reduxModalReducer } from 'components/ReduxModal';
import { modeManagerReducer } from 'HOC/ModeManager';
import cart from './modules/cart';
import categories from './modules/categories';
import delivery from './modules/delivery';
import dishConfigurator from './modules/dishConfigurator';
import favorites from './modules/favorites';
import general, { actions as generalActions } from './modules/general';
import items from './modules/items';
import locations from './modules/locations';
import menus from './modules/menus';
import options from './modules/options';
import orders from './modules/orders';
import organisation from './modules/organisation';
import payments from './modules/payments';
import promotions from './modules/promotions';
import spinner from './modules/spinner';
import user from './modules/user';
import variations from './modules/variations';

const middlewares = [thunk];

const enhancers =
  !!window.chrome && window.__REDUX_DEVTOOLS_EXTENSION__
    ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
    : [];

const rootReducer = combineReducers({
  cart,
  categories,
  delivery,
  dishConfigurator,
  favorites,
  general,
  items,
  locations,
  menus,
  options,
  orders,
  organisation,
  payments,
  promotions,
  spinner,
  user,
  variations,
  reduxModal: reduxModalReducer,
  mode: modeManagerReducer,
});

const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt',
  defaultState: {},
});

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['delivery', 'cart'],
  transforms: [expireTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares), reduxReset(), ...enhancers),
);

export const persistor = persistStore(store);

store.dispatch(generalActions.getGeneral());

window.store = store;
window.persistor = persistor;

export default store;
