import uuidv4 from 'uuid/v4';
import keyBy from 'lodash/keyBy';
import { camelizeKeys } from 'humps';
import backendVariationValueToCart from 'utils/backendVariationValueToCart';

const backendProductsToCart = products =>
  keyBy(
    camelizeKeys(products).map(product => ({
      dishId: product.id,
      specialInstructions: product.specialInstructions,
      count: product.quantity,
      variations: keyBy(
        product.variations &&
          product.variations.map(variation => ({
            id: variation.id,
            type: variation.type,
            value: backendVariationValueToCart(variation),
          })),
        'id',
      ),
      id: uuidv4(),
    })),
    'id',
  );

export default backendProductsToCart;
