import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dropdownIcon from 'assets/dropdown.png';
import ReactSelect from 'react-select';

const Select = ({
  iconSmall,
  disabled,
  onSelectRef,
  onClick,
  onDropdownChange,
  ...rest
}) => (
  <>
    <DayPickerWrapper
      iconSmall={iconSmall}
      disabled={disabled}
      onClick={onClick}
      aria-hidden
    >
      <ReactSelect
        iconSmall={iconSmall}
        disabled={disabled}
        {...rest}
        ref={onSelectRef}
      />
    </DayPickerWrapper>
    <NativeSelect value={rest?.value?.value} onChange={onDropdownChange}>
      {rest.options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </NativeSelect>
  </>
);

Select.propTypes = {
  disabled: PropTypes.bool,
  iconSmall: PropTypes.bool,
  onSelectRef: PropTypes.func,
  onClick: PropTypes.func,
  onDropdownChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  disabled: undefined,
  iconSmall: undefined,
  onSelectRef: undefined,
  onClick: undefined,
};

const selectHOC = compose(
  withProps(({ value, options }) => ({
    valueIndex: options.findIndex(
      option => option && value && option.value === value.value,
    ),
  })),
  withHandlers(() => {
    let selectRef = null;
    return {
      onSelectRef: () => ref => {
        selectRef = ref;
      },
      scrollToNextOptions: ({ valueIndex }) => () => {
        const { menuContainer, menu } = selectRef;
        if (
          menuContainer &&
          menu &&
          menu.children &&
          menu.children[valueIndex + 1]
        ) {
          menuContainer.scrollTop = menu.children[valueIndex + 1].offsetTop + 1;
        }
      },
    };
  }),
  withHandlers({
    onOpen: ({ scrollToNextOptions, onOpen }) => () => {
      if (onOpen) {
        onOpen();
      }
      setTimeout(() => {
        scrollToNextOptions();
      }, 0);
    },
    onDropdownChange: ({ options, onChange }) => e => {
      const dropdownValue = e.target.value;
      onChange(options.find(({ value }) => value === dropdownValue));
    },
  }),
);

export default selectHOC(Select);

const DayPickerWrapper = styled.button`
  position: relative;
  height: 100%;
  cursor: ${p => p.disabled && 'default'};

  * {
    pointer-events: ${p => p.onClick && 'none'};
  }

  &::after {
    display: ${p => p.disabled && 'none'};
    position: absolute;
    content: '';
    width: ${({ iconSmall }) => (iconSmall ? '7px' : '10px')};
    height: 7px;
    background: url(${dropdownIcon}) no-repeat center / contain;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ iconSmall }) => (iconSmall ? '7px' : '15px')};
    pointer-events: none;
  }

  .Select-control {
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    color: rgb(66, 70, 72);
    font-weight: lighter;
    position: relative;
    outline: none;
    text-align: left;
    width: 100%;
    border: none;
    border-radius: 0;
    background: transparent;
    appearance: none;
    height: 100%;
  }

  .Select {
    height: 100%;

    input {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      width: 150px;
      border: none;
      appearance: none;
      margin: 0;
      padding: 0;
      outline: none;
      background: transparent;
    }
  }

  .Select-arrow {
    display: none;
  }

  .Select-clear {
    display: none;
  }

  .Select-menu-outer {
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    max-height: 200px;
    overflow: auto;
    transform: translateY(100%);
    background: #fff;
    z-index: 999;
    padding: 0;
    margin: 0;

    div > div {
      padding: 15px;
      color: rgb(66, 70, 72);
      font-size: 17px;
      font-family: 'Roboto';
      font-weight: 300;

      &:not(:first-child) {
        border-top: 1px solid #e1e1e1;
      }
    }

    .is-disabled {
      opacity: 0.6;
      font-style: italic;
    }
  }

  .Select-value-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    z-index: 5;
  }

  .Select-placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    z-index: 5;
  }
`;

const NativeSelect = styled.select`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
