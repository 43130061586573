import React from 'react';
import { compose, lifecycle, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { actions as userActions } from 'redux/modules/user';
import addMilliseconds from 'date-fns/add_milliseconds';
import inform from 'utils/inform';
import { getNowTime } from 'utils/timeHelpers';
import { SMS_TIMEOUT } from 'utils/constants';

const enterVerificationCodeHOC = compose(
  connect(null, {
    validateSMSCode: userActions.validateSMSCode,
    requestSMSCode: userActions.requestSMSCode,
  }),
  withStateHandlers(
    {
      retryTimerTill: null,
      secondSMSsent: false,
    },
    {
      resetRetryTimer: () => () => ({
        retryTimerTill: addMilliseconds(getNowTime(), SMS_TIMEOUT),
      }),
      setSecondSMSSent: () => () => ({
        secondSMSsent: true,
      }),
    },
  ),
  withHandlers({
    requestCode: ({
      firstName,
      lastName,
      requestSMSCode,
      phoneNumber,
      action,
      resetRetryTimer,
      onRequestCodeFail,
    }) => (call = false) => {
      requestSMSCode(
        {
          phoneNumber,
          action,
          type: call ? 'call' : 'sms',
          firstName: firstName || '',
          lastName: lastName || '',
        },
        response => {
          resetRetryTimer();
          console.log(response);
        },
        message => {
          if (onRequestCodeFail) {
            onRequestCodeFail();
          } else {
            inform({
              title: 'Oops...',
              message:
                message ||
                (call ? "We couldn't make a call" : "We couldn't sent an SMS"),
              buttonText: 'OK',
            });
          }
        },
      );
    },
  }),
  withHandlers({
    callMe: ({ requestCode }) => () => {
      requestCode(true);
    },
    smsTryAgain: ({ requestCode, setSecondSMSSent }) => () => {
      setSecondSMSSent();
      requestCode();
    },
  }),
  withHandlers({
    requestCodeRetryClick: ({ secondSMSsent, callMe, smsTryAgain }) => () => {
      if (secondSMSsent) {
        inform({
          title: 'Resend Code',
          message:
            "If you didn't get recieve your verification code, we'll send it to you through an automated phone call.",
          buttonText: (
            <span>
              <i className="icon-phone" /> RESEND VIA PHONE CALL
            </span>
          ),
          showCancelButton: true,
          buttonChoice: true,
          onBeforeButtonClick: callMe,
          alternativeStyles: true,
        });
      } else {
        inform({
          title: 'Resend Code',
          message:
            "If you didn't get receive your verification code, we'll resend it to you via SMS.",
          buttonText: (
            <span>
              <i className="icon-email" /> RESEND VIA SMS
            </span>
          ),
          showCancelButton: true,
          buttonChoice: true,
          onBeforeButtonClick: smsTryAgain,
          alternativeStyles: true,
        });
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { requestCode } = this.props;
      requestCode();
    },
  }),
  withStateHandlers(
    { codeError: '' },
    { setCodeError: () => error => ({ codeError: error }) },
  ),
  withHandlers({
    submitCode: ({
      validateSMSCode,
      phoneNumber,
      onCodeValidated,
      setCodeError,
    }) => code => {
      validateSMSCode(
        {
          phoneNumber,
          smsCode: code,
        },
        data => {
          onCodeValidated(data);
          setCodeError('');
        },
        () => {
          setCodeError('Incorrect verification code');
        },
      );
    },
  }),
);

export default enterVerificationCodeHOC;
