import React from 'react';
import { compose, defaultProps, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pickBy from 'lodash/pickBy';

import {
  DataContainer,
  DataContainerFirstLine,
  Description,
  ItemButton,
  Name,
} from 'components/Card';

import { media } from 'utils/mediaQueries';
import { getFixedPrice } from 'utils/currencyHelpers';
import SoldOut from 'components/SoldOut';

const CheckboxGroup = ({ options, handleClick, value }) =>
  options.map(option => (
    <CheckboxItem
      key={option.id}
      disabled={option.disabled || option.soldOut}
      onClick={() => {
        handleClick(option);
      }}
    >
      <Box
        active={Object.keys(value)
          .map(o => +o)
          .includes(+option.id)}
      />
      <DataContainer>
        <DataContainerFirstLine>
          <CheckBoxName
            disabled={option.disabled || option.soldOut}
            active={Object.keys(value)
              .map(o => +o)
              .includes(+option.id)}
          >
            {option.name}
          </CheckBoxName>
          {
            {
              [true]: null,
              [!!option.price]: (
                <Price
                  active={Object.keys(value)
                    .map(o => +o)
                    .includes(+option.id)}
                >
                  {' '}
                  + ${getFixedPrice(option.price)}{' '}
                </Price>
              ),
              [option.soldOut]: <SoldOut />,
            }.true
          }
        </DataContainerFirstLine>
        {option.description && <Description>{option.description}</Description>}
      </DataContainer>
    </CheckboxItem>
  ));

CheckboxGroup.propTypes = {
  value: PropTypes.objectOf(PropTypes.number),
  handleClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
};

const CheckboxGroupHOC = compose(
  defaultProps({
    value: {},
  }),
  withHandlers({
    handleClick: ({ onChange, value }) => ({ id, soldOut, disabled }) => {
      if (!soldOut && !disabled) {
        onChange(
          pickBy({
            ...value,
            [id]: value[id] ? undefined : 1,
          }),
        );
      }
    },
  }),
  withProps(({ data: { options, max }, value }) => ({
    options:
      max > 0 && Object.keys(value).length >= max
        ? options.map(o => ({
            ...o,
            ...(!(o.id in value) && { disabled: true }),
          }))
        : options,
  })),
);

export default CheckboxGroupHOC(CheckboxGroup);

const CheckBoxName = styled(Name)`
  font-weight: ${({ active }) => (active ? '600' : '400')};
`;

const CheckboxItem = ItemButton.extend`
  align-items: flex-start;
  ${media.desktop`${props => !props.disabled && 'cursor: pointer'}`};
`;

const Box = styled.div`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: ${({ active }) => (active ? 'none' : '2px solid #929292')};
  background: ${({ active }) => (active ? '#54a300' : 'transparent')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 4px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const Price = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: ${({ active }) => (active ? '600' : '400')};
  white-space: nowrap;
`;
