import { createSelector } from 'reselect';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { selectedUserAddressSelector } from 'selectors/selectedUserAddressSelector';
import { restaurantAddressSelector } from 'redux/modules/general';

const destinationAddressSelector = createSelector(
  [getDeliveryType, selectedUserAddressSelector, restaurantAddressSelector],
  (method, currentAddress, restaurantAddress) =>
    ({
      [true]: {},
      [method === 'PICKUP']: {
        ...restaurantAddress,
        fullAddress: `${restaurantAddress.address}, ${restaurantAddress.city}, ${restaurantAddress.zipcode}`,
      },
      [method === 'DELIVERY']: currentAddress,
    }.true),
);

export default destinationAddressSelector;
