import {
  compose,
  withProps,
  withHandlers,
  branch,
  renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import parse from 'date-fns/parse';
import { actions as ordersActions, ordersSelector } from 'redux/modules/orders';
import { actions as modalActions } from 'components/ReduxModal';
import dataFetcher from 'HOC/dataFetcher';
import lineItemsToCart from 'utils/lineItemsToCart';
import legacyLineItemsToCart from 'utils/legacyLineItemsToCart';
import { ORDERS_PER_PAGE } from 'utils/constants';

const orderContentHOC = compose(
  connect(
    state => ({
      orders: ordersSelector(state),
      getOrdersList: ordersActions.getOrdersList,
      ordersFulfilled: state.orders.fulfilled,
    }),
    {
      closeModal: modalActions.closeModal,
      getOrdersList: ordersActions.getOrdersList,
    },
  ),
  dataFetcher(state => !state.orders.fulfilled, ordersActions.getOrdersList),
  branch(({ ordersFulfilled }) => !ordersFulfilled, renderNothing),
  withProps(({ orders }) => ({
    orders: mapValues(orders, order => {
      const { orderDate } = order.data;
      return {
        ...order,
        data: {
          ...order.data,
          orderDate: parse(orderDate),
        },
        cart: order.data.legacy
          ? legacyLineItemsToCart(order.data.lineItems)
          : lineItemsToCart(order.data.lineItems),
      };
    }),
  })),
  withProps(({ orders }) => ({
    pastOrders: sortBy(
      values(orders).filter(({ isComplete }) => isComplete),
      order => parse(order.data.orderDate).getTime(),
    ).reverse(),
    upcomingOrders: sortBy(
      values(orders).filter(({ isComplete }) => !isComplete),
      order => parse(order.data.orderDate).getTime(),
    ),
  })),
  withHandlers({
    onLoadMore: ({ orders, getOrdersList }) => (visible, index) => {
      console.log('onLoadMore');
      const ordersCount = Object.keys(orders).length;
      // TODO: check for X-Total
      if (visible && index >= ordersCount - ORDERS_PER_PAGE) {
        const nextPage = Math.floor(ordersCount / ORDERS_PER_PAGE);
        getOrdersList(nextPage);
      }
    },
  }),
);

export default orderContentHOC;
