import {
  compose,
  withProps,
  withHandlers,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { cantinaInfoSelector } from 'redux/modules/general';
import { cantinaWeekSelector } from 'selectors/cantinaWeekSelector';

import isSameDay from 'date-fns/is_same_day';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/start_of_day';

import daysAndTimestampsSelector from 'selectors/daysAndTimestampsSelector';

export const deliveryTimeTypeTimePickerHOC = compose(
  withRouter,
  connect((state, { scheduleTime, deliveryType, deliveryConditions }) => ({
    cantinaEnabled: Boolean(cantinaInfoSelector(state)?.enabled),
    cantinaWeek: cantinaWeekSelector(state, {
      scheduleTime,
      deliveryType,
      deliveryConditions,
    }),
    daysAndTimestamps: daysAndTimestampsSelector(state, {
      scheduleTime,
      deliveryType,
      deliveryConditions,
    }),
  })),
  withStateHandlers(
    { calendarVisible: false, spacerVisible: false },
    {
      showCalendar: () => () => {
        setTimeout(() => {
          document.getElementById('calendar-container').scrollIntoView();
        }, 10);
        return {
          calendarVisible: true,
          spacerVisible: false,
        };
      },
      hideCalendar: () => () => ({
        calendarVisible: false,
      }),
      showSpacer: () => () => ({
        spacerVisible: true,
        calendarVisible: false,
      }),
      hideSpacer: () => () => ({
        spacerVisible: false,
      }),
    },
  ),
  withProps(({ scheduleTime }) => ({
    timePickerValue: scheduleTime && parse(scheduleTime),
  })),
  withHandlers({
    setTimePickerValue: ({ setScheduleTime, history }) => value => {
      setScheduleTime(value || null, history);
    },
  }),
  withStateHandlers(
    ({ daysAndTimestamps, timePickerValue }) => ({
      dayPickerValue:
        (timePickerValue && startOfDay(timePickerValue)) ||
        (daysAndTimestamps[0] && daysAndTimestamps[0].day),
    }),
    {
      setDayPickerValue: (_, { setTimePickerValue }) => value => {
        setTimePickerValue();
        return { dayPickerValue: value };
      },
    },
  ),
  withPropsOnChange(
    ['daysAndTimestamps', 'dayPickerValue'],
    ({ daysAndTimestamps, dayPickerValue }) => {
      let timePickerOptions = [];
      try {
        timePickerOptions = daysAndTimestamps.find(({ day }) =>
          isSameDay(day, dayPickerValue),
        ).timestamps;
      } catch (e) {}
      return {
        dayPickerOptions: daysAndTimestamps,
        timePickerOptions,
      };
    },
  ),
  withPropsOnChange(['scheduleTime'], ({ scheduleTime }) => ({
    filled: scheduleTime,
  })),
);
