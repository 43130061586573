import { compose, branch, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import withConditionalCDM from 'HOC/withConditionalCDM';

import {
  hideLoyaltyQrcodeSelector,
  loyaltyEnabledSelector,
} from 'redux/modules/general';

import {
  loyaltySelector,
  actions as promotionActions,
} from 'redux/modules/promotions';
import withUserInfo from 'HOC/withUserInfo';

const withLoyalty = compose(
  connect(state => ({
    loyaltyEnabled: loyaltyEnabledSelector(state),
    hideLoyaltyQrcode: hideLoyaltyQrcodeSelector(state),
  })),
  branch(
    ({ loyaltyEnabled }) => loyaltyEnabled,
    compose(
      withUserInfo,
      connect(
        state => ({
          loyalty: loyaltySelector(state),
        }),
        {
          getLoyaltyInfo: promotionActions.getLoyaltyInfo,
        },
      ),
      withHandlers({
        getLoyalty: ({ getLoyaltyInfo, user: { foce } }) => () => {
          if (foce) {
            getLoyaltyInfo(foce);
          }
        },
      }),
      withConditionalCDM(
        ({ user: { foce } }) => foce,
        ({ getLoyalty }) => {
          getLoyalty();
        },
      ),
    ),
  ),
);

export default withLoyalty;
