import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TransitionGroup } from 'react-transition-group';
import AnimatedRoute from 'components/AnimatedRoute';

import PaymentsManagement from 'HOC/PaymentsManagement';
import MyAccount from './components/MyAccount';
import Favorites from './routes/Favorites';
import Security from './routes/Security';
import Addresses from './routes/Addresses';

class MyAccountRoute extends Component {
  render() {
    const { location } = this.props;
    const basePath = this.props.match.url;
    const routes = {
      myAccount: basePath,
      addresses: `${basePath}/adresses`,
      favorites: `${basePath}/favorites`,
      payments: `${basePath}/payments`,
      security: `${basePath}/security`,
    };

    return (
      <TransitionGroup>
        {location.pathname === routes.myAccount && (
          <AnimatedRoute key={routes.myAccount} direction="BACKWARD">
            <MyAccount />
          </AnimatedRoute>
        )}
        {location.pathname === routes.addresses && (
          <AnimatedRoute key={routes.addresses} direction="FORWARD">
            <Addresses />
          </AnimatedRoute>
        )}
        {location.pathname === routes.favorites && (
          <AnimatedRoute key={routes.favorites} direction="FORWARD">
            <Favorites />
          </AnimatedRoute>
        )}
        {location.pathname === routes.payments && (
          <AnimatedRoute key={routes.payments} direction="FORWARD">
            <PaymentsManagement />
          </AnimatedRoute>
        )}
        {location.pathname === routes.security && (
          <AnimatedRoute key={routes.security} direction="FORWARD">
            <Security />
          </AnimatedRoute>
        )}
      </TransitionGroup>
    );
  }
}
MyAccountRoute.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default MyAccountRoute;
