import uuidv4 from 'uuid/v4';
import fromPairs from 'lodash/fromPairs';
import pickBy from 'lodash/pickBy';
import countBy from 'lodash/countBy';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';

const lineItemsToCart = lineItems =>
  fromPairs(
    lineItems.map(lineItem => {
      const cartItemId = uuidv4();
      const lineItemVariations =
        lineItem.metaData[1] && lineItem.metaData[1].value;
      const availableVariations =
        isArray(lineItemVariations) && keyBy(lineItemVariations, 'id');
      const variations = pickBy(
        fromPairs(
          lineItem.metaData[0].variations.map(
            ({ id, type, options = [], left = [], right = [], full = [] }) => {
              const variation =
                availableVariations && availableVariations[`${id}`];
              if (!variation) {
                return [];
              }
              const availableOptions = fromPairs(
                variation.options.map(({ id: optionId, ...rest }) => [
                  optionId,
                  { id: optionId, ...rest },
                ]),
              );
              const value = pickBy(
                fromPairs(
                  options.map(option =>
                    option in availableOptions
                      ? [
                          option,
                          {
                            [true]: 1,
                            [[
                              'pizza_toppings',
                              'pizza_toppings_small',
                            ].includes(type) && full.includes(option)]: 1,
                            [['pizza_toppings', 'mix_and_match'].includes(
                              type,
                            ) && left.includes(option)]: -0.5,
                            [['pizza_toppings', 'mix_and_match'].includes(
                              type,
                            ) && right.includes(option)]: 0.5,
                            [type === 'combo']: countBy(options)[`${option}`],
                          }.true,
                        ]
                      : [],
                  ),
                ),
              );
              const variationValue = {
                id,
                type,
                value,
                options: availableOptions,
              };
              return [id, variationValue];
            },
          ),
        ),
      );
      const cartItem = {
        id: cartItemId,
        dishId: lineItem.productId,
        count: lineItem.quantity,
        specialInstructions: '',
        variations,
        lineItem,
      };
      return [cartItemId, cartItem];
    }),
  );

export default lineItemsToCart;
