import PropTypes from 'prop-types';
import { getFixedPrice } from 'utils/currencyHelpers';

const FixedNumber = ({ children, precision }) =>
  getFixedPrice(children, precision) || null;

FixedNumber.propTypes = {
  children: PropTypes.number,
  precision: PropTypes.number,
};

FixedNumber.defaultProps = {
  precision: 2,
};

export default FixedNumber;
