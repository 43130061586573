import { createSelector } from 'reselect/lib/index';

import startOfDay from 'date-fns/start_of_day';
import isWithinRange from 'date-fns/is_within_range';
import format from 'date-fns/format';
import addMinutes from 'date-fns/add_minutes';

import { deliveryAvailabilitySchedulesByWeekdaySelector } from 'selectors/deliveryAvailabilitySchedulesByWeekdaySelector';
import {
  getNowTime,
  strRangeArrayToBusinessHours,
  strRangeToDatetimes,
} from 'utils/timeHelpers';
import getToleranceAndPreparationAwareDeliveryTimeForTimeFunc from 'selectors/getToleranceAndPreparationAwareDeliveryTimeForTimeFunc';
import { generalSelector } from 'redux/modules/general';
import getPreparationMinutesForTimeFunc from 'selectors/getPreparationMinutesForTimeFunc';

const canSoonestSelector = createSelector(
  [
    getPreparationMinutesForTimeFunc,
    deliveryAvailabilitySchedulesByWeekdaySelector,
    getToleranceAndPreparationAwareDeliveryTimeForTimeFunc,
    generalSelector,
  ],
  (
    getPreparationMinutesForTime,
    schedulesByWeekday,
    getToleranceAndPreparationAwareDeliveryTimeForTime,
    { asapInfo: { enabledAsap } },
  ) => {
    if (!enabledAsap) {
      return false;
    }
    const now = getNowTime();
    const today = startOfDay(now);
    const toleranceAndPreparationAwareDeliveryTime = getToleranceAndPreparationAwareDeliveryTimeForTime(
      now,
    );
    const dayFormatted = format(today, 'YYYY-MM-DD');
    const times = schedulesByWeekday[dayFormatted] || [];
    const businessHoursRange = strRangeArrayToBusinessHours(today, times);
    return times.some(strRange => {
      const { start, end } = strRangeToDatetimes(today, strRange);
      const nowPlusPreparationTime = addMinutes(
        now,
        getPreparationMinutesForTime(now),
      );
      return (
        isWithinRange(toleranceAndPreparationAwareDeliveryTime, start, end) &&
        isWithinRange(nowPlusPreparationTime, ...businessHoursRange)
      );
    });
  },
);

export default canSoonestSelector;
