import { branch, lifecycle } from 'recompose';

const withConditionalCDM = (condition, cdm) =>
  branch(
    condition,
    lifecycle({
      componentDidMount() {
        cdm(this.props);
      },
    }),
  );

export default withConditionalCDM;
