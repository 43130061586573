import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  promocodeSelector,
  redeemCurrencySelector,
  removePromocode,
} from 'redux/modules/cart';

import { centsToDollars } from 'utils/formatCents';
import { getDeliveryType } from 'selectors/getDeliveryType';
import tipAmountSelector from 'selectors/tipAmountSelector';
import tipsAllowedSelector from 'selectors/tipsAllowedSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import promocodeDiscountSelector from 'selectors/promocodeDiscountSelector';
import taxesTotalSelector from 'selectors/taxesTotalSelector';
import { serviceFeeSelector } from 'redux/modules/general';
import serviceFeeTotalSelector from 'selectors/serviceFeeTotalSelector';
import deliveryCostSelector from 'selectors/deliveryCostSelector';
import totalPriceSelector from 'selectors/totalPriceSelector';
import OrderSummary from '../components/OrderSummary';

const mapStateToProps = state => {
  const cartSubtotal = cartSubtotalSelector(state);
  const discountPrice = promocodeDiscountSelector(state);
  const deliveryCost = deliveryCostSelector(state);
  const deliveryType = getDeliveryType(state);
  const tipPrice = tipAmountSelector(state);
  const tipsAllowed = tipsAllowedSelector(state);
  const taxesTotal = taxesTotalSelector(state);
  const total = totalPriceSelector(state);
  const serviceFeeTotal = serviceFeeTotalSelector(state);
  const serviceFee = serviceFeeSelector(state);
  const promocode = promocodeSelector(state);
  const redeemCurrency = redeemCurrencySelector(state);
  return {
    prices: [
      { name: 'Subtotal', price: cartSubtotal, isSubtotal: true },
      { name: 'Tax', price: taxesTotal },
      ...(serviceFeeTotal
        ? [{ name: serviceFee?.label || 'Service fee', price: serviceFeeTotal }]
        : []),
      ...(deliveryType === 'DELIVERY'
        ? [{ name: 'Delivery', price: deliveryCost }]
        : []),
      ...(tipsAllowed && tipPrice ? [{ name: 'Tip', price: tipPrice }] : []),
    ],
    ...(redeemCurrency && {
      redeemCurrency: {
        name: `Rewards`,
        price: centsToDollars(redeemCurrency),
      },
    }),
    ...(promocode && {
      discount: {
        name: `Promocode: ${promocode.toUpperCase()}`,
        price: discountPrice,
      },
    }),
    total,
  };
};

export const OrderSummaryHOC = compose(
  connect(mapStateToProps, {
    removePromocode,
  }),
);

export default OrderSummaryHOC(OrderSummary);
