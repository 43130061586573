import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Promotion extends Component {
  render() {
    const { code, expires, text, disabled } = this.props;

    return (
      <Wrapper disabled={disabled} tabIndex={0}>
        <Top>
          <Code>
            CODE: <span>{code}</span>
          </Code>
          {expires && (
            <Expires active={expires === 'today'}>EXPIRES: {expires}</Expires>
          )}
        </Top>
        <Description>{text}</Description>
      </Wrapper>
    );
  }
}

Promotion.propTypes = {
  code: PropTypes.string,
  expires: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Promotion;

const Wrapper = styled.div`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`;

const Top = styled.div`
  padding: 13px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeefef;
`;

const Code = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.3px;

  span {
    font-weight: bold;
  }
`;

const Expires = styled.div`
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: ${({ active }) => (active ? '#fff' : 'rgb(66, 70, 72)')};
  text-transform: uppercase;
  background: ${({ active }) => (active ? '#f1a500' : 'transparent')};
  padding: ${({ active }) => (active ? '5px' : '0')};
  font-weight: 300;
  letter-spacing: 0.3px;
`;

const Description = styled.div`
  padding: 15px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;
