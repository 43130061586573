import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import ModalComponents from 'routes/Modals/ModalComponents';
import QRCode from 'qrcode.react';
import { myRewardsHOC } from 'HOC/myRewardsHOC';
import { formatCents } from 'utils/formatCents';
import IconClean from 'components/IconClean';
import { commaSplit } from 'utils/commaSplit';
import { Stamp } from 'routes/Modals/Stamp';

const QRCodeWrapper = styled(QRCode)`
  height: 100% !important;
  width: 100% !important;
  padding: 16px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 32px;
  background-color: white;
  border-bottom: 1px solid hsla(0, 0%, 0%, 10%);
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  font-weight: 400;
`;

const PointsAvailable = styled.div`
  font-weight: 600;
`;

const StampsInfo = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const StampsTilNextReward = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 300;
  padding: 24px 0;
  text-align: center;
  b {
    font-weight: 400;
  }
`;

const StampsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 8px;
`;

const MyRewardsComponent = ({
  hideLoyaltyQrcode,
  loyalty: {
    consumer: { points, pointsInCurrency },
  },
  loyaltyEnabled,
  user: { foce } = {},
  ordersTillNextReward,
  stamps,
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer noPadding>
      <Heading>
        <PointsAvailable>
          <IconClean name="icon-favorite-address-small" size="20px" />
          {commaSplit(points)}
        </PointsAvailable>
        <div>{formatCents(pointsInCurrency)}</div>
      </Heading>
      <div>
        {loyaltyEnabled && foce && !hideLoyaltyQrcode && (
          <QRCodeWrapper
            value={foce}
            size={640}
            aria-label="QR Code"
            tabIndex={0}
          />
        )}
      </div>
      <StampsInfo>
        {!!ordersTillNextReward && ordersTillNextReward !== Infinity && (
          <StampsTilNextReward>
            Stamps required til next reward: <b>{ordersTillNextReward}</b>
          </StampsTilNextReward>
        )}
        <div>
          <StampsRow>
            {stamps.slice(0, 5).map(({ orderNumber, ...stamp }) => (
              <Stamp key={orderNumber} {...{ orderNumber, ...stamp }} />
            ))}
          </StampsRow>
          <StampsRow>
            {stamps.slice(5).map(({ orderNumber, ...stamp }) => (
              <Stamp key={orderNumber} {...{ orderNumber, ...stamp }} />
            ))}
          </StampsRow>
        </div>
      </StampsInfo>
    </ModalComponents.ContentContainer>
  </ModalComponents.ModalContent>
);

MyRewardsComponent.propTypes = {
  loyalty: PropTypes.shape({
    points: PropTypes.number,
  }).isRequired,
  ordersTillNextReward: PropTypes.number.isRequired,
  stamps: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.number,
    }),
  ).isRequired,
};

export const MyRewards = myRewardsHOC(MyRewardsComponent);
