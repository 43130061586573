import { createSelector } from 'reselect/lib/index';

import getISODay from 'date-fns/get_iso_day';
import isWithinRange from 'date-fns/is_within_range';

import { dashSplittedTimeRangeToDatetimes } from 'utils/timeHelpers';
import { orderingSettingsSelector } from 'redux/modules/general';

const getPreparationMinutesForTimeFunc = createSelector(
  [orderingSettingsSelector],
  orderingSettings => time => {
    const { preparationTime, generalPreparationTime } = orderingSettings;
    if (preparationTime && preparationTime.length) {
      const preparationTimeDay = preparationTime[getISODay(time) - 1];
      const value = Object.entries(preparationTimeDay).find(([key]) => {
        const { start, end } = dashSplittedTimeRangeToDatetimes(time, key);
        return isWithinRange(time, start, end);
      });
      if (value) {
        return +value[1];
      }
    }
    return +generalPreparationTime;
  },
);

export default getPreparationMinutesForTimeFunc;
