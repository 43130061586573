import setHours from 'date-fns/set_hours';
import setMinutes from 'date-fns/set_minutes';
import setSeconds from 'date-fns/set_seconds';
import min from 'date-fns/min';
import max from 'date-fns/max';
import format from 'date-fns/format';

import zip from 'lodash/zip';

export const getNowTime = () => new Date();

export const getTime = date => format(date, 'hh:mm A');

export const strRangeToDatetimes = (day, range) => {
  if (!range.length) {
    return {};
  }
  const [[startHH, startMM], [endHH, endMM]] = range.map(t => t.split(':'));
  const start = setHours(setMinutes(day, +startMM), +startHH);
  const end = setHours(setMinutes(setSeconds(day, 59), +endMM), +endHH);
  return { start, end };
};

export const dashSplittedTimeRangeToDatetimes = (day, range) => {
  const [[startHH, startMM], [endHH, endMM]] = range
    .split('-')
    .map(t => t.split(':'));
  const start = setHours(setMinutes(day, +startMM), +startHH);
  const end = setHours(setMinutes(day, +endMM), +endHH);
  return { start, end };
};

export const strRangeArrayToBusinessHours = (day, rangeArray = []) => {
  const [starts, ends] = zip(
    ...rangeArray.map(strRange => {
      const { start, end } = strRangeToDatetimes(day, strRange);
      return [start, end];
    }),
  );
  return starts && ends ? [min(...starts), max(...ends)] : [];
};
