import { createAction, handleActions } from 'redux-actions';
import { camelizeKeys, decamelizeKeys } from 'humps';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { history } from 'routes';
import wretches from 'wretches';
import inform from 'utils/inform';
import { actions as modalActions } from 'components/ReduxModal';
import { actions as spinnerActions } from 'redux/modules/spinner';
import { actions as cartActions } from 'redux/modules/cart';
import { actions as deliveryActions } from 'redux/modules/delivery/reducer';
import backendProductsToCart from 'utils/backendProductsToCart';

// ---
// CONSTANTS
// ---
export const GET_ORDERS = 'orders/GET_ORDERS';
export const ADD_ORDER = 'orders/ADD_ORDER';

// ---
// ACTION CREATORS
// ---
const getOrders = createAction(GET_ORDERS);
export const addOrder = createAction(ADD_ORDER);

// ---
// INITIAL STATE
// ---
const initialState = {
  entities: {},
  fulfilled: false,
};

// ---
// REDUCER
// ---

export default handleActions(
  {
    [ADD_ORDER]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        [action.payload.clientMeta.id]: action.payload,
      },
    }),
    [GET_ORDERS]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        ...mapValues(action.payload, (order, key) => ({
          ...order,
          clientMeta: get(state.entities, [key, 'clientMeta']),
        })),
      },
      fulfilled: true,
    }),
  },
  initialState,
);

export const getOrdersList = (page = 0) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.ordersList
    .query({
      page,
    })
    .get()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      const camelcased = camelizeKeys(data);
      const normalized = keyBy(camelcased, 'orderId');
      dispatch(getOrders(normalized));
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      inform({
        title: 'Oops...',
        message: err.json.message,
        buttonText: 'OK',
      });
    });
};

const createOrder = (body, method, ignoreAuth) => dispatch => {
  /* setTimeout(() => dispatch(cartActions.finishOrder()), 10);
  const redirectTo = ignoreAuth ? '/thankyouDineIn' : `/orders/thank-you/`;
  history.push(redirectTo);return;
  */ dispatch(
    spinnerActions.showSpinner(),
  );
  const checkoutWretch = ignoreAuth
    ? wretches.checkoutDineIn
    : wretches.checkout;
  checkoutWretch
    .json(decamelizeKeys(body))
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      const res = camelizeKeys(json);
      if (res.result) {
        dispatch(modalActions.closeModal());
        setTimeout(() => dispatch(cartActions.finishOrder()), 10);
        const redirectTo = ignoreAuth
          ? '/thankyouDineIn'
          : `/orders/thank-you/${res.orderInformation.orderId}`;
        history.push(redirectTo);
      }
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());

      inform({
        title: 'Oops...',
        message: err.json.message,
        buttonText: 'OK',
      });
    });
};

const reorder = ({
  orderId,
  type,
  deliveryTimeType,
  backendScheduleTime,
  placeId,
}) => dispatch => {
  if (type && deliveryTimeType && backendScheduleTime) {
    dispatch(spinnerActions.showSpinner());
    wretches.reorder
      .json(
        decamelizeKeys({
          id: orderId,
          orderMethod: type.toLowerCase(),
          orderType: deliveryTimeType.toLowerCase(),
          orderDate: backendScheduleTime,
          placeId,
        }),
      )
      .post()
      .json(json => {
        const {
          error,
          cart: { products },
        } = json;
        dispatch(spinnerActions.hideSpinner());
        if (!isEmpty(error)) {
          inform({
            title: error.title,
            message: error.body,
            buttonText: 'OK',
          });
        }
        if (products.length) {
          dispatch(
            cartActions.appendCartItems(backendProductsToCart(products)),
          );
          dispatch(modalActions.closeModal());
          history.push('/');
          history.push('/menu/cart');
          dispatch(deliveryActions.cancelReorderMode());
        }
      })
      .catch(err => {
        dispatch(spinnerActions.hideSpinner());

        inform({
          title: 'Oops...',
          message: err.json && err.json.message,
          buttonText: 'OK',
        });
      });
  } else {
    // set reorder mode
    dispatch(deliveryActions.setReorderMode(orderId));
    history.push('/');
    dispatch(modalActions.closeModal());
    // go to delivery page
  }

  //
  // Response:
  // {
  //      "cart": cart object
  //      "method":
  //      "error" array with errors (invalid products, variations, options)
  // }
};

export const actions = {
  addOrder,
  createOrder,
  getOrdersList,
  reorder,
};
