import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import cardAmericanExpress from 'assets/cards/card-american-express.svg';
import cardDinersClub from 'assets/cards/card-diners-club.svg';
import cardDiscover from 'assets/cards/card-discover.svg';
import cardJcb from 'assets/cards/card-jcb.svg';
import cardMasterCard from 'assets/cards/card-master-card.svg';
import cardUnionpay from 'assets/cards/card-unionpay.svg';
import cardVisa from 'assets/cards/card-visa.svg';

const CardIcon = ({ brand = 'Visa' }) => <Icon brand={brand} />;

CardIcon.propTypes = {
  brand: PropTypes.string.isRequired,
};

const Icon = styled.div`
  min-width: 56px;
  min-height: 32px;
  background: url(${p =>
      ({
        [true]: cardVisa,
        [p.brand === 'American Express']: cardAmericanExpress,
        [p.brand === 'Diners club']: cardDinersClub,
        [p.brand === 'Discover']: cardDiscover,
        [p.brand === 'JCB']: cardJcb,
        [p.brand === 'MasterCard']: cardMasterCard,
        [p.brand === 'Unionpay']: cardUnionpay,
        [p.brand === 'Visa']: cardVisa,
      }.true)})
    no-repeat center / contain;
`;

export default CardIcon;
