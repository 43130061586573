import { schema } from 'normalizr';

const categories = new schema.Entity('categories');
const menus = new schema.Entity('menus');
const items = new schema.Entity('items');
const variations = new schema.Entity('variations');
const options = new schema.Entity('options');

export default {
  categories: [categories],
  menus: [menus],
  items: [items],
  variations: [variations],
  options: [options],
};
