import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconClean from 'components/IconClean';

const GhostButton = ({ children, withArrow, ...rest }) => (
  <GhostButtonStyled {...rest}>
    {withArrow && <div />}
    {children}
    {withArrow && (
      <span>
        <IconClean name="icon-arrow-right-small" size="36px" />
      </span>
    )}
  </GhostButtonStyled>
);

GhostButton.propTypes = {
  children: PropTypes.node.isRequired,
  withArrow: PropTypes.bool,
};

GhostButton.defaultProps = {
  withArrow: false,
};

const GhostButtonStyled = styled.button`
  color: #54a300;
  border: 1px solid #54a300;
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
`;

export default GhostButton;
