import { createSelector } from 'reselect';
import { restaurantInfoSelector } from 'redux/modules/general';
import { getNowTime, strRangeToDatetimes } from 'utils/timeHelpers';
import setDay from 'date-fns/set_day';

const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const businessHoursSelector = createSelector(
  restaurantInfoSelector,
  restaurantInfo => {
    if (restaurantInfo && restaurantInfo.businessHours) {
      return (
        restaurantInfo.businessHours &&
        restaurantInfo.businessHours.map((weekdayHours, index) => {
          const today = getNowTime();
          const dayOfWeekIndex = index === 6 ? 0 : index + 1;
          const day = setDay(today, dayOfWeekIndex, {
            weekStartsOn: 1,
          });
          return {
            weekday: WEEKDAYS[index],
            hours: weekdayHours.map(businessHoursRange =>
              strRangeToDatetimes(day, businessHoursRange),
            ),
          };
        })
      );
    }
    return [
      {
        weekday: undefined,
        hours: [],
      },
    ];
  },
);

export default businessHoursSelector;
