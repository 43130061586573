import React from 'react';
import PropTypes from 'prop-types';

import { TransitionGroup } from 'react-transition-group';
import AnimatedRoute from 'components/AnimatedRoute';

import { DeliverySettings } from 'routes/DeliverySettings';
import Menu from '../Menu';

AnimatedRoute.propTypes = {
  in: PropTypes.bool,
  direction: PropTypes.oneOf(['FORWARD', 'BACKWARD']),
  children: PropTypes.node,
};

const isDeliveryRoute = path => path === '/';

const isMenuRoute = path => path.indexOf('/menu') !== -1;

const LocationsRoute = ({ match, location }) => {
  const basePath = match.url;
  const routes = {
    delivery: `${basePath}`,
    menu: `${basePath}/menu`,
  };
  const { pathname } = location;
  return (
    <TransitionGroup>
      {(isDeliveryRoute(pathname) || isMenuRoute(pathname)) && (
        <AnimatedRoute key="MenuAndDelivery" direction="FORWARD">
          <TransitionGroup>
            {isDeliveryRoute(pathname) && (
              <AnimatedRoute key={routes.delivery} direction="BACKWARD">
                <DeliverySettings />
              </AnimatedRoute>
            )}
            {isMenuRoute(pathname) && (
              <AnimatedRoute name="menu" key={routes.menu} direction="FORWARD">
                <Menu />
              </AnimatedRoute>
            )}
          </TransitionGroup>
        </AnimatedRoute>
      )}
    </TransitionGroup>
  );
};

LocationsRoute.propTypes = {
  match: PropTypes.shape({}),
  location: PropTypes.shape({}),
};

LocationsRoute.defaultProps = {
  match: undefined,
  location: undefined,
};

export default LocationsRoute;
