import { createSelector } from 'reselect';
import { cantinaInfoSelector } from 'redux/modules/general';
import daysAndTimestampsSelector from 'selectors/daysAndTimestampsSelector';

import format from 'date-fns/format';
import addDays from 'date-fns/add_days';

export const cantinaWeekSelector = createSelector(
  [cantinaInfoSelector, daysAndTimestampsSelector],
  ({ enabled, dateRangeInDays }, daysAndTimestamps) =>
    enabled
      ? {
          start: format(daysAndTimestamps[0]?.day, 'MMM Do'),
          stop: format(
            addDays(daysAndTimestamps[0]?.day, dateRangeInDays),
            'MMM Do',
          ),
        }
      : null,
);
