// This component sets up things defined in url. Like auto applying coupon.

import { compose, withProps, setDisplayName, renderNothing } from 'recompose';
import withConditionalCDM from 'HOC/withConditionalCDM';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import pick from 'lodash/pick';
import qs from 'query-string';
import parse from 'url-parse';
import { camelizeKeys } from 'humps';
import { landingUrlSelector } from 'redux/modules/general';
import { actions as cartActions } from 'redux/modules/cart';
import { actions as deliveryActions } from 'redux/modules/delivery';

const UrlSetupHelper = compose(
  setDisplayName('UrlSetupHelper'),
  withRouter,
  connect(
    state => ({
      landingUrl: landingUrlSelector(state),
    }),
    {
      setAutoPromocode: cartActions.setAutoPromocode,
      setAutoContinue: deliveryActions.setAutoContinue,
    },
  ),
  withProps(({ location: { search } }) => ({
    ...pick(camelizeKeys(qs.parse(search, { decode: true })), [
      'appSrcUrl',
      'coupon',
      'dineIn',
    ]),
  })),
  withConditionalCDM(
    ({ appSrcUrl, landingUrl }) =>
      appSrcUrl && landingUrl && parse(landingUrl).host !== appSrcUrl,
    ({ landingUrl }) => {
      window.location.href = landingUrl;
    },
  ),
  withConditionalCDM(
    ({ coupon }) => coupon,
    ({ coupon, setAutoPromocode }) => {
      setAutoPromocode(coupon);
    },
  ),
  withConditionalCDM(
    ({ dineIn }) => dineIn,
    ({ setAutoContinue }) => {
      setAutoContinue(true);
    },
  ),
  renderNothing,
)();

export default UrlSetupHelper;
