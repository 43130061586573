import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import RootPortal from 'components/RootPortal';
import FocusLock from 'react-focus-lock';

// ---
// COMPONENT
// ---
class ReduxModalProvider extends Component {
  render() {
    const { children, activeModal, options } = this.props;
    return (
      <RootPortal>
        <FocusLock disabled={!activeModal}>
          {children(activeModal, options)}
        </FocusLock>
      </RootPortal>
    );
  }
}

ReduxModalProvider.propTypes = {
  children: PropTypes.func,
  activeModal: PropTypes.string,
  options: PropTypes.object,
};

// ---
// CONTAINER
// ---
const mapStateToProps = state => ({
  activeModal: state.reduxModal.name,
  options: state.reduxModal.options,
});

export default connect(mapStateToProps)(ReduxModalProvider);
