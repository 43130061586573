import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';
import addMinutes from 'date-fns/add_minutes';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import parse from 'date-fns/parse';

import { ordersSelector } from 'redux/modules/orders';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { showIntervalsSelector } from 'selectors/showIntervalsSelector';
import { cantinaWeekSelector } from 'selectors/cantinaWeekSelector';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';

import dataFetcher from 'HOC/dataFetcher';
import { actions as ordersActions } from 'redux/modules/orders/reducer';
import ThankYou from '../components/ThankYou';

const TIME_FORMAT = 'hh:mma';

const ThankYouHOC = compose(
  connect(state => ({
    orders: ordersSelector(state),
    cantinaWeek: cantinaWeekSelector(state),
    dineInEnabled: dineInEnabledSelector(state),
  })),
  dataFetcher(
    (
      state,
      {
        match: {
          params: { orderId },
        },
      },
    ) => !hasIn(state, ['orders', 'entities', orderId, 'orderId']),
    ordersActions.getOrdersList,
  ),
  withProps(
    ({
      cantinaWeek,
      orders,
      match: {
        params: { orderId },
      },
    }) => {
      const orderInfo = get(orders, orderId);
      const orderDate = get(orderInfo, ['data', 'orderDate']);
      const deliveryDate = orderDate && parse(orderDate).toISOString();
      const deliveryType = get(orderInfo, ['data', 'method'], '').toUpperCase();
      const deliveryDay = cantinaWeek
        ? cantinaWeek?.start
        : deliveryDate &&
          (isToday(deliveryDate)
            ? 'Today'
            : format(deliveryDate, 'MM-DD-YYYY'));
      const address = get(
        orderInfo,
        ['clientMeta', 'method', 'destinationAddress'],
        {},
      );
      return {
        orderId,
        deliveryDay,
        deliveryDate,
        deliveryType,
        address,
      };
    },
  ),
  connect((state, { deliveryType }) => ({
    orders: ordersSelector(state),
    minutesStepSize: timeIntervalSelector(state, { deliveryType }),
    showIntervals: showIntervalsSelector(state, { deliveryType }),
  })),
  withProps(
    ({ cantinaWeek, deliveryDate, showIntervals, minutesStepSize }) => ({
      deliveryTime: cantinaWeek
        ? `- ${cantinaWeek?.stop}`
        : deliveryDate &&
          (showIntervals
            ? `${format(deliveryDate, TIME_FORMAT)} - ${format(
                addMinutes(deliveryDate, minutesStepSize),
                TIME_FORMAT,
              )}`
            : format(deliveryDate, 'hh:mm A')),
    }),
  ),
  // withProps(() => ({
  //   orderId: '2142',
  //   deliveryDay: 'Today',
  //   deliveryTime: '01:06 PM',
  //   deliveryType: 'PICKUP',
  //   address: {
  //     name: 'Home',
  //     address: '8133 Northwest 139th Terrace',
  //     city: 'Hialeah',
  //     state: 'FL',
  //     zipcode: '33016',
  //     fullAddress: '540 W 29th St, Hialeah, 33012',
  //   },
  // })),
);

export default ThankYouHOC(ThankYou);
