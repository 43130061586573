import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { actions as userActions } from 'redux/modules/user/reducer';
import dataFetcher from 'HOC/dataFetcher';
import { userAddressesSelector } from 'selectors/userAddressesSelector';
import withConditionalCDM from 'HOC/withConditionalCDM';

const deliveryAddressPickerHOC = compose(
  connect(state => ({
    userAddresses: userAddressesSelector(state),
  })),
  withProps(({ userAddresses, tempUserAddress }) => ({
    userAddresses: [
      ...userAddresses,
      ...(tempUserAddress ? [{ ...tempUserAddress, id: 'temp' }] : []),
    ],
  })),
  withConditionalCDM(
    ({ userAddresses }) => userAddresses && userAddresses.length === 1,
    ({ userAddresses, handleAddressClick }) => {
      setTimeout(() => handleAddressClick(userAddresses[0].id), 0);
    },
  ),
  dataFetcher(state => !state.user.fulfilled, userActions.userInfo),
);

export default deliveryAddressPickerHOC;
