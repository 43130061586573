import React from 'react';
import PropTypes from 'prop-types';
import { compose, withStateHandlers, withHandlers } from 'recompose';
import styled from 'styled-components';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';

const CODE_LENGTH = 6;

const Number = ({
  inputIndex,
  value,
  createOnChange,
  onKeyDown,
  createOnNumberInputRef,
  autoFocus,
}) => (
  <CodeNumber
    type="tel"
    pattern="[0-9]"
    name={inputIndex}
    minLength={1}
    maxLength={CODE_LENGTH}
    value={value[inputIndex] === ' ' ? '' : value[inputIndex]}
    onChange={createOnChange(inputIndex)}
    // onPaste={onPaste}
    onKeyDown={onKeyDown}
    innerRef={createOnNumberInputRef(inputIndex)}
    autoFocus={autoFocus}
  />
);

Number.propTypes = {
  inputIndex: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  createOnChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  createOnNumberInputRef: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

Number.defaultProps = {
  autoFocus: false,
};

const CodeNumberInput = ({ focusFirstEmptyInput, ...props }) => (
  <Inputs onClick={focusFirstEmptyInput}>
    <AutoFocusIfNonTouchDevice>
      {autoFocus => <Number {...props} inputIndex={0} autoFocus={autoFocus} />}
    </AutoFocusIfNonTouchDevice>
    <Number {...props} inputIndex={1} />
    <Number {...props} inputIndex={2} />
    <span>-</span>
    <Number {...props} inputIndex={3} />
    <Number {...props} inputIndex={4} />
    <Number {...props} inputIndex={5} />
  </Inputs>
);

CodeNumberInput.propTypes = {
  focusFirstEmptyInput: PropTypes.func.isRequired,
};

const codeNumberInputHOC = compose(
  withHandlers(() => {
    const inputRefs = Array(CODE_LENGTH).fill(null);
    return {
      createOnNumberInputRef: () => index => ref => {
        inputRefs[index] = ref;
      },
      focusInput: () => index => {
        if (inputRefs[index]) {
          inputRefs[index].focus();
        }
      },
    };
  }),
  withStateHandlers(
    { value: '      ' },
    {
      updateValue: ({ value }, { submitCode, focusInput }) => (
        index,
        newInputValue,
      ) => {
        // backspace
        if (newInputValue.length === 0) {
          focusInput(0);
          return { value: '      ' };
        }
        const newValue = `${value.substr(
          0,
          index,
        )}${newInputValue}${value.substr(index + newInputValue.length)}`.substr(
          0,
          CODE_LENGTH,
        );
        const nextEmptyInputIndex = newValue.indexOf(' ');
        if (nextEmptyInputIndex === -1) {
          submitCode(newValue);
          focusInput(0);
          return { value: '      ' };
        }
        focusInput(nextEmptyInputIndex);
        return { value: newValue };
      },
    },
  ),
  withHandlers({
    createOnChange: ({ updateValue }) => index => e => {
      const { value } = e.target;
      if (+value) {
        updateValue(index, value);
      }
    },
    onKeyDown: ({ updateValue, focusInput }) => e => {
      const index = +e.target.name;
      if (e.keyCode === 8) {
        // backspace
        updateValue(0, '      ');
      } else if (e.keyCode === 37 && index !== 0) {
        // left
        focusInput(index - 1);
      } else if (e.keyCode === 39 && index !== 5) {
        // right
        focusInput(index + 1);
      }
    },
    focusFirstEmptyInput: ({ focusInput, value }) => () => {
      const nextEmptyInputIndex = value.indexOf(' ');
      if (nextEmptyInputIndex !== -1) {
        focusInput(nextEmptyInputIndex);
      }
    },
  }),
);

const Inputs = styled.div`
  display: flex;
  max-width: 100%;
  text-align: center;
  align-items: center;

  > span {
    flex: 1;
    pointer-events: none;
  }
`;

const CodeNumber = styled.input`
  appearance: none;
  flex: 1;
  min-width: 1px; // HACK. I do not know appropriate solution.
  text-align: center;
  pointer-events: none;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  margin: 0;
  height: 52px;
  box-shadow: none;
  input& {
    font-size: 28px;
  }
`;

export default codeNumberInputHOC(CodeNumberInput);
