import { compose } from 'recompose';
import { connect } from 'react-redux';
import { spinnerVisibleSelector } from 'redux/modules/spinner';
import Spinner from './Spinner';

const SpinnerHOC = compose(
  connect(state => ({
    spinnerVisible: spinnerVisibleSelector(state),
  })),
);

export default SpinnerHOC(Spinner);
