import React from 'react';
import styled from 'styled-components';

export const Required = () => <RequiredWrapper> Required </RequiredWrapper>;

export const SettingsCard = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: ${p => p.showError && '2px solid red'};
  margin: -1px;
  padding: 1px;
`;

export const Header = styled.div`
  padding: 18px 15px;
  background: #e7e7e7;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-weight: 400;
`;

const RequiredWrapper = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  background: #939393;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 300;
  letter-spacing: 0.2px;
  padding: 3px 5px;
`;

export const Edit = styled.button`
  width: auto;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;

  &::before {
    font-size: 26px;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 33%;
    transform: translateY(-50%);
    font-weight: normal;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;
`;

export const Name = styled.div`
  flex: 1 1 auto;
  padding-right: 15px;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: ${({ active }) => (active ? '400' : '300')};
`;

export const Item = styled.div`
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  border: ${p => p.showError && '1px solid red'};
  :not(:first-child) {
    border-top: ${p => (p.showError ? '1px solid red' : '1px solid #eeefef')};
  }
`;

export const ItemButton = styled.button`
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
  border: ${p => p.showError && '1px solid red'};
  :not(:first-child) {
    border-top: ${p => (p.showError ? '1px solid red' : '1px solid #eeefef')};
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  flex: 1;
`;
export const DataContainerFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Description = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-top: 16px;
  color: #4c4e4e;
`;

export default SettingsCard;
