import { handleActions } from 'redux-actions';
import { actionTypes as generalActionTypes } from 'redux/modules/general';
import pickBy from 'lodash/pickBy';

// ---
// INITIAL STATE
// ---
const initialState = {
  entities: {},
  order: [],
};

// ---
// REDUCER
// ---

export default handleActions(
  {
    [generalActionTypes.GET_GENERAL_SUCCESS]: (state, action) => ({
      ...state,
      ...pickBy({
        entities: action.payload.entities.variations,
        order: action.payload.result.variations,
      }),
    }),
  },
  initialState,
);

export const actions = {};
