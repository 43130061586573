import { createSelector } from 'reselect';
import get from 'lodash/get';

export const organisationsSelector = state => state.organisation;
export const currentRestaurantIdSelector = createSelector(
  organisationsSelector,
  organisation => organisation.currentRestaurantId,
);

export const restaurantsByIdSelector = createSelector(
  organisationsSelector,
  organisations => organisations.restaurantsById,
);

export const companyNameSelector = state => state.organisation.name;

export const selectedRestaurantSelector = createSelector(
  restaurantsByIdSelector,
  currentRestaurantIdSelector,
  (restaurants, restaurantId) => get(restaurants, restaurantId),
);
