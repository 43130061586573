import { compose, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';

import withPromocodeValidation from 'HOC/withPromocodeValidation';
import { actions as modalActions } from 'components/ReduxModal';
import Promocode from '../components/Promocode';

const PromocodeHOC = compose(
  withPromocodeValidation(),
  connect(null, {
    modalGoBack: modalActions.modalGoBack,
  }),
  withStateHandlers(
    {
      value: '',
    },
    {
      handleChange: () => event => ({ value: event.target.value }),
      resetValue: () => () => ({ value: '' }),
    },
  ),
  withHandlers({
    handleApplyClick: ({ validate, modalGoBack, value, resetValue }) => () => {
      modalGoBack();
      validate(value);
      resetValue();
    },
  }),
);

export default PromocodeHOC(Promocode);
