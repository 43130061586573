import { compose, withHandlers, setDisplayName } from 'recompose';
import connect from 'react-redux/es/connect/connect';
import {
  actions as cartActions,
  backendCartProductsSelector,
} from 'redux/modules/cart';
import { getDeliveryType } from 'selectors/getDeliveryType';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { backendScheduleTimeSelector } from 'selectors/scheduleTimeSelector';

const withPromocodeValidation = ({ silent = false, noSpinner = false } = {}) =>
  compose(
    setDisplayName('withPromocodeValidation'),
    connect(
      state => ({
        backendCartProducts: backendCartProductsSelector(state),
        cartSubtotal: cartSubtotalSelector(state),
        type: getDeliveryType(state),
        backendScheduleTime: backendScheduleTimeSelector(state),
      }),
      {
        promocodeValidate: cartActions.promocodeValidate,
      },
    ),
    withHandlers({
      validate: ({
        promocodeValidate,
        backendCartProducts,
        cartSubtotal,
        type,
        backendScheduleTime,
      }) => value => {
        promocodeValidate(
          {
            coupon: value,
            cart: {
              cartContent: backendCartProducts,
              cartTotal: cartSubtotal,
            },
            orderType: type.toLowerCase(),
            orderDate: backendScheduleTime,
          },
          {
            silent,
            noSpinner,
          },
        );
      },
    }),
  );

export default withPromocodeValidation;
