import React from 'react';
import { connect } from 'react-redux';

function ModeManagerHOC(Component) {
  const ModeManager = props => <Component {...props} />;

  const mapStateToProps = state => ({
    mode: state.mode,
  });

  ModeManager.displayName = `ModeManager(${Component.displayName})`;

  return connect(mapStateToProps)(ModeManager);
}

export default ModeManagerHOC;
