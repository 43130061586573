import { createSelector } from 'reselect';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';

export const getDeliveryTypeFormatted = createSelector(
  [getDeliveryType, dineInEnabledSelector, (_, props) => props],
  (deliveryType, dineInEnabled, { type } = {}) => {
    const method = (type || deliveryType)?.toUpperCase();
    return {
      [true]: 'Not set',
      [method === 'DELIVERY']: 'Delivery',
      [method === 'PICKUP']: 'Pickup',
      [method === 'PICKUP' && dineInEnabled]: 'Dine-in',
    }.true;
  },
);
