import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from 'utils/mediaQueries';

class ModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.targetElement = React.createRef();
  }

  state = {
    opacity: 0.2,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ opacity: 1 }), 1);
  }

  render() {
    const { opacity } = this.state;
    return (
      <ModalContentStyled ref={this.targetElement} opacity={opacity}>
        {this.props.children}
      </ModalContentStyled>
    );
  }
}

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  margin: 0 auto;
  padding-bottom: 60px;
  opacity: ${p => p.opacity};
  transition: opacity 350ms ease-in-out;

  ${media.tablet`
    padding-bottom: 15px;
  `};
`;

const Title = styled.div`
  text-align: center;
  font-size: 35px;
  font-family: 'Montserrat', sans-serif;
  color: #424648;
  font-weight: 300;
  margin-top: 20px;
`;

const SubTitle = styled.div`
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  color: #5e6263;
  font-weight: 300;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${p => (!p.noPadding ? '8px 16px' : '2px 0')};
  flex: 1;
  width: 100%;
  overflow-x: hidden;
`;

const Separator = styled.div`
  margin: 0 -16px;
  width: calc(100% + 32px);
  height: 1px;
  background: #d8d9da;
`;

const PopUpOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  transform: translate3d(0, 0, 0);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;

  [class^='MuiFormControl'],
  [class*=' MuiFormControl'] {
    min-height: 68px;
  }
`;

const FormLink = styled(Link)`
  display: block;
  align-self: flex-end;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #5e6263;
  text-decoration: none;
  margin-top: 10px;
`;

const FormButton = styled.div`
  padding: 24px 0;
  width: 100%;
  > * {
    position: static;
  }
`;

const WallOfText = styled.div`
  margin-top: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #424648;
  line-height: 21px;
  h2,
  h3,
  h4 {
    margin-top: 16px;
    font-weight: 500;
  }
`;

const ModalComponents = {
  Title,
  SubTitle,
  ModalContent,
  ContentContainer,
  Separator,
  PopUpOverlay,
  Form,
  FormLink,
  FormButton,
  WallOfText,
};

export default ModalComponents;
