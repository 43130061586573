import { compose, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrderDishesList from 'components/OrderDishesList';
import { cartItemsSelector, orderDishesKeysSelector } from 'redux/modules/cart';

import { getDeliveryTime } from 'selectors/getDeliveryTime';
import { getDeliveryType } from 'selectors/getDeliveryType';
import deliveryDaySelector from 'selectors/deliveryDaySelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import deliveryEstimateTextSelector from 'selectors/deliveryEstimatesTextSelector';
import { actions as modalActions } from 'components/ReduxModal';

const OrderDisheListHOC = compose(
  connect(
    state => ({
      deliveryEstimateText: deliveryEstimateTextSelector(state),
      deliveryDay: deliveryDaySelector(state),
      deliveryTime: getDeliveryTime(state),
      deliveryType: getDeliveryType(state),
      basket: cartItemsSelector(state),
      orderDishes: orderDishesKeysSelector(state),
      subtotal: cartSubtotalSelector(state),
    }),
    {
      closeModal: modalActions.closeModal,
      showModal: modalActions.showModal,
    },
  ),
  withStateHandlers(
    {
      mode: 'REMOVE',
    },
    {
      onEditClick: ({ mode }) => () => ({
        mode: mode === 'REMOVE' ? 'EDIT' : 'REMOVE',
      }),
    },
  ),
  withRouter,
  withHandlers({
    onDeliveryTimeClick: ({ closeModal, showModal }) => () => {
      closeModal();
      showModal('edit-time');
    },
  }),
);

export default OrderDisheListHOC(OrderDishesList);
