import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { applicationTypeSelector } from 'redux/modules/general';
import Locations from 'routes/Locations/containers/LocationsContainer';
import Spinner from 'components/Spinner';

const EntrypointViewHOC = compose(
  connect(state => ({
    application: applicationTypeSelector(state),
  })),
  branch(
    ({ application }) => application,
    branch(
      ({ application }) => application === 'landing',
      renderComponent(() => <Locations />),
    ),
    renderComponent(() => <Spinner />),
  ),
);

export default EntrypointViewHOC;
