import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import {
  restaurantInfoSelector,
  temporaryClosuresSelector,
} from 'redux/modules/general';
import businessHoursSelector from 'selectors/businessHoursSelector';

import DeliveryFallbackSchedule from './DeliveryFallbackSchedule';

const DeliveryFallbackScheduleHOC = compose(
  connect(state => ({
    restaurantInfo: restaurantInfoSelector(state),
    businessHours: businessHoursSelector(state),
    temporaryClosures: temporaryClosuresSelector(state),
  })),
  withProps(({ restaurantInfo: { restaurantClosedMessage } }) => ({
    restaurantClosedMessage,
  })),
);

export default DeliveryFallbackScheduleHOC(DeliveryFallbackSchedule);
