import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import { actions as userActions } from 'redux/modules/user';
import { actions as modalActions } from 'components/ReduxModal';
import dataFetcher from 'HOC/dataFetcher';
import { userAddressesSelector } from 'selectors/userAddressesSelector';

const MyAddressesHOC = compose(
  dataFetcher(state => !state.user.fulfilled, userActions.userInfo),
  connect(
    state => ({
      addresses: filter(userAddressesSelector(state), ({ name }) =>
        ['HOME', 'WORK', 'OTHER'].includes(name),
      ),
    }),
    {
      showModal: modalActions.showModal,
    },
  ),
  withHandlers({
    onAddressSelect: ({ showModal }) => addressId => {
      showModal('add-new-address', { addressId });
    },
    showModal: ({ showModal }) => () => showModal('add-new-address'),
  }),
);

export default MyAddressesHOC;
