import { createSelector } from 'reselect/lib/index';

import isAppWorkingSelector from 'selectors/isAppWorkingSelector';
import canSoonestSelector from 'selectors/canSoonestSelector';
import canScheduleSelector from 'selectors/canScheduleSelector';

const fallbackScheduleVisibleSelector = createSelector(
  [isAppWorkingSelector, canSoonestSelector, canScheduleSelector],
  (isAppWorking, canSoonest, canSchedule) =>
    Boolean(!isAppWorking || (!canSoonest && !canSchedule)),
);

export default fallbackScheduleVisibleSelector;
