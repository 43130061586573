import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions as modalActions } from 'components/ReduxModal';
import {
  actions as favoritesActions,
  favoriteItemsSelector,
  favoritesSelector,
} from 'redux/modules/favorites';

import Favorites from '../components/Favorites';

const FavoritesHOC = compose(
  withRouter,
  connect(
    state => ({
      favoriteItems: favoriteItemsSelector(state),
      favoritesLoading: favoritesSelector(state).loading,
    }),
    {
      closeModal: modalActions.closeModal,
      getFavorites: favoritesActions.getFavorites,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { favoriteItems, getFavorites } = this.props;
      if (!Object.keys(favoriteItems).length) {
        getFavorites();
      }
    },
  }),
);

export default FavoritesHOC(Favorites);
