import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

const FadeInAnimation = ({
  in: inProp,
  timeout,
  duration,
  children,
  ...restProps
}) => {
  const transitionStyles = {
    entering: { opacity: '0' },
    entered: {
      opacity: '1',
    },
  };

  const defaultStyle = {
    transition: `${duration}ms`,
    opacity: '0',
  };

  return (
    <Transition in={inProp} timeout={timeout} {...restProps}>
      {state => children({ ...defaultStyle, ...transitionStyles[state] })}
    </Transition>
  );
};

FadeInAnimation.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
  timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  children: PropTypes.func,
};

export default FadeInAnimation;
