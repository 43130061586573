import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';

const NoOrders = ({ type }) => (
  <Wrapper>
    <IconWrapper>
      <Icon name="icon-orders-small" size="150px" />
    </IconWrapper>
    <Text>You have no {type} orders at this time</Text>
  </Wrapper>
);

NoOrders.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NoOrders;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 110px;
`;

const IconWrapper = styled.div`
  width: 65px;
  height: 70px;
  color: #cfd0d2;
`;

const Text = styled.div`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  max-width: 200px;
  text-align: center;
  margin-top: 35px;
  font-weight: 300;
  line-height: 20px;
`;
