import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

const Counter = ({ count, onIncClick, onDecClick }) => (
  <Wrapper>
    <Dec onClick={onDecClick} aria-label="Decrease quantity">
      <span>–</span>
    </Dec>
    <Count>
      <span>{count}</span>
    </Count>
    <Inc onClick={onIncClick} aria-label="Increase quantity">
      <span>+</span>
    </Inc>
  </Wrapper>
);

Counter.propTypes = {
  count: PropTypes.number,
  onIncClick: PropTypes.func,
  onDecClick: PropTypes.func,
};

const Wrapper = styled.div`
  display: flex;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Inc = styled.button`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  position: relative;
  width: 50px;
  height: 50px;
  background: #fff;
  border-left: 1px solid #eeefef;
  user-select: none;

  ${media.desktop`
    cursor: pointer;
  `};

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Count = styled.div`
  height: 50px;
  width: 80px;
  background: #fff;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Dec = styled.button`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  width: 50px;
  height: 50px;
  background: #fff;
  position: relative;
  border-right: 1px solid #eeefef;
  user-select: none;

  ${media.desktop`
    cursor: pointer;
  `};

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default Counter;
